const initialState = {
  isOpen: false,
  config: {},
  Component: null,
};

export const openModal = (state, { payload }) => ({
  ...state,
  isOpen: true,
  config: payload.config,
  Component: payload.Component,
});

export const closeModal = () => initialState;

export default initialState;

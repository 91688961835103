import React from 'react';
import { StateToggler, DateFormatter } from 'components/AppTable/CellsComponents';
import i18next from 'i18next';

export const getTableConfig = ({ activeTab, handleToggleState }) => [
  {
    title: i18next.t('tablesLabels.id'),
    field: 'id',
  },
  {
    title: i18next.t('tablesLabels.name'),
    field: 'fullName',
    isSorting: true,
  },
  {
    title: i18next.t('tablesLabels.email'),
    field: 'email',
    isSorting: true,
  },
  activeTab === 'trainers'
    ? {
        title: i18next.t('tablesLabels.type'),
        field: 'isExternal',
        isSorting: true,
        Component: isExternal => [isExternal ? 'External' : 'Trainer'],
      }
    : {
        title: i18next.t('tablesLabels.station'),
        field: 'station',
      },
  {
    title: i18next.t('tablesLabels.state'),
    field: 'isComplaint',
    isSorting: true,
    Component: state => (
      <StateToggler
        value={state}
        activeText={i18next.t('commonValues.compliant')}
        inactiveText={i18next.t('commonValues.notCompliant')}
        onChange={handleToggleState}
      />
    ),
  },
];

export const getDocumentsConfig = () => [
  {
    title: i18next.t('tablesLabels.id'),
    field: 'id',
  },
  {
    title: i18next.t('tablesLabels.name'),
    field: 'name',
  },
  {
    title: i18next.t('tablesLabels.size'),
    field: 'documentSize',
    alignment: 'center',
    Component: size => (
      <div>
        {(size / 1000000).toFixed(3)}
        &ensp; MB
      </div>
    ),
  },
  {
    title: i18next.t('tablesLabels.uploadDate'),
    field: 'createdAt',
    Component: value => <DateFormatter value={value} />,
  },
  {
    title: i18next.t('tablesLabels.byWhom'),
    field: 'uploadedBy',
  },
];

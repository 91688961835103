/* eslint-disable no-param-reassign */
import Joi from '@hapi/joi';
import i18next from 'i18next';

const getSchema = val => {
  return Joi.object({
    SAfirstName: Joi.string()
      .min(2)
      .max(30)
      .required(),
    SAlastName: Joi.string()
      .min(2)
      .max(30)
      .required(),
    SAemail: Joi.string()
      .email({ tlds: false })
      .required(),
    isActive: [Joi.boolean().required(), Joi.string().required()],
    stations: [
      Joi.array()
        .items(Joi.string().required())
        .required(),
      Joi.string().required(),
    ],
    name: Joi.string()
      .min(2)
      .max(30)
      .required(),
    mainColor: Joi.string().required(),
    fontMainColor: Joi.string().required(),
    logoSrc: Joi.string().optional(),
    stationsRemoved: Joi.array(),
    stationsCurrent:
      val.stationsNew && val.stationsNew.length > 0
        ? Joi.array()
        : Joi.array()
            .items(Joi.string().required())
            .min(1)
            .required(),
    stationsNew:
      val.stationsCurrent && val.stationsCurrent.length > 0
        ? Joi.array()
        : Joi.array()
            .items(Joi.string().required())
            .min(1)
            .required(),
  });
};

const validate = values => {
  const { error } = getSchema(values).validate(values, { abortEarly: false });
  if (!error) {
    return {};
  }

  const { details } = error;

  return details.reduce((errors, detail) => {
    switch (detail.context.key) {
      case 'SAfirstName':
        errors[detail.context.key] = i18next.t('errors.firstNameIsRequired');
        break;
      case 'SAlastName':
        errors[detail.context.key] = i18next.t('errors.lastNameIsRequired');
        break;
      case 'SAemail':
        if (detail.context.value === undefined || detail.context.value === '') {
          errors[detail.context.key] = i18next.t('errors.emailIsRequired');
        } else {
          errors[detail.context.key] = i18next.t('errors.emailIsInvalid');
        }
        break;
      case 'name':
        errors[detail.context.key] = i18next.t('errors.nameIsRequired');
        break;
      case 'stationsCurrent':
        errors[detail.context.key] = i18next.t('errors.stationsIsRequired');
        break;
      case 'stationsNew':
        errors[detail.context.key] = i18next.t('errors.stationsIsRequired');
        break;
      default:
        break;
    }
    return errors;
  }, {});
};

export default validate;

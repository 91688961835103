import { http } from '../index';
import { jobTasksModifier } from '../formatters';
import { downloadFileFromBytes, getFileNameFromContentDispositionString } from '../helpers';

export const getJobsTasks = params => {
  return http
    .get('/jobs-tasks', {
      params,
    })
    .then(res => jobTasksModifier(res.data))
    .catch(error => error.response.data);
};

export const getJobTask = id => {
  return http
    .get(`/job-task/${id}`)
    .then(res => res.data)
    .catch(error => error.response.data);
};

export const deleteJobTask = id => {
  return http
    .delete(`/job-task/${id}`)
    .then(res => res.data)
    .catch(error => error.response.data);
};

export const createJobTask = formData => {
  return http
    .post('/jobs-tasks', formData)
    .then(res => res.data)
    .catch(error => error.response.data);
};

export const updateJobTask = (id, formData) => {
  return http
    .put(`/job-task/${id}`, formData)
    .then(res => res.data)
    .catch(error => error.response.data);
};

export const exportJobsTasks = () => {
  return http
    .get('/jobs-tasks/export', {
      responseType: 'blob',
    })
    .then(res => {
      const filename = getFileNameFromContentDispositionString(res);
      downloadFileFromBytes(res.data, filename, '', true);
    })
    .catch(error => error.response.data);
};

import i18next from 'i18next';
import { STATISTIC_BY_EXPIRE_DATE_COLORS } from 'appConstants';

const transformToStatisticsByExpireDate = data => {
  const additionalDonutData = {
    expired: {
      legendTitle: i18next.t('legend.expired'),
      fillColor: STATISTIC_BY_EXPIRE_DATE_COLORS.expired,
    },
    '1_week': {
      legendTitle: i18next.t('legend.expiredInAWeek'),
      fillColor: STATISTIC_BY_EXPIRE_DATE_COLORS['1_week'],
    },
    '1_month': {
      legendTitle: i18next.t('legend.expiredInAMonth'),
      fillColor: STATISTIC_BY_EXPIRE_DATE_COLORS['1_month'],
    },
    '3_month': {
      legendTitle: i18next.t('legend.expiredIn3Month'),
      fillColor: STATISTIC_BY_EXPIRE_DATE_COLORS['3_month'],
    },
    up_to_date: {
      legendTitle: i18next.t('legend.upToDate'),
      fillColor: STATISTIC_BY_EXPIRE_DATE_COLORS.up_to_date,
    },
  };

  if (!data) return [];

  return Object.keys(additionalDonutData)
    .map(key => ({
      ...additionalDonutData[key],
      value: data[key],
    }))
    .filter(item => item.value > 0);
};

export default transformToStatisticsByExpireDate;

import React from 'react';
import PropTypes from 'prop-types';
import { Button, ButtonGroup } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import { ROLES } from 'appConstants';

const useStyles = makeStyles(() => ({
  root: {
    '& [data-pushed="true"][data-val="passed"][disabled]': {
      background: 'rgba(0, 100, 0, 0.2)',
    },
    '& [data-pushed="true"][data-val="failed"][disabled]': {
      background: 'rgba(100, 0, 0, 0.2)',
    },
  },
}));

const PassedState = ({ onChange, ...otherProps }) => {
  const classes = useStyles();
  const { t } = useTranslation('common');
  const { status } = otherProps;

  let isDisabled = false;
  if (status) {
    isDisabled = (status.role === ROLES.USER && status.isPerformedWithSuccess !== null) || status.isFrozen;
  }

  const handleChange = event => {
    const { val: btnStatus, key: userId } = event.currentTarget.dataset;
    onChange(userId, btnStatus);
  };

  return (
    <ButtonGroup className={classes.root} variant="contained" size="small" aria-label="small outlined button group">
      <Button
        data-val="passed"
        data-key={otherProps.id}
        aria-label="passed"
        onClick={handleChange}
        disabled={isDisabled}
        data-pushed={status && status.isPerformedWithSuccess !== null && status.isPerformedWithSuccess}
      >
        {t('students.passed')}
      </Button>

      <Button
        data-val="failed"
        data-key={otherProps.id}
        aria-label="failed"
        onClick={handleChange}
        disabled={isDisabled}
        data-pushed={status && status.isPerformedWithSuccess !== null && !status.isPerformedWithSuccess}
      >
        {t('students.failed')}
      </Button>
    </ButtonGroup>
  );
};

PassedState.defaultProps = {
  onChange: () => {},
};

PassedState.propTypes = {
  onChange: PropTypes.func,
};

export default PassedState;

import { handleActions, createActions } from 'redux-actions';

import initialState, * as handlers from './handlers';

export const actions = createActions({
  AUTHENTICATE: undefined,
  LOGOUT: undefined,
  SET_STATION: undefined,
  SET_COMPANY_INFO: undefined,
  SET_LANG: undefined,
  SET_PROFILE_LANG: undefined,
});

const reducer = handleActions(
  new Map([
    [actions.authenticate, handlers.authenticate],
    [actions.logout, handlers.logout],
    [actions.setStation, handlers.setStation],
    [actions.setCompanyInfo, handlers.setCompanyInfo],
    [actions.setLang, handlers.setLang],
    [actions.setProfileLang, handlers.setProfileLang],
  ]),
  initialState,
);

export default reducer;

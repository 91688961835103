import React from 'react';
import { Button } from '@material-ui/core';

const getConfirmationModalConfig = ({
  title = '',
  confirmAction,
  rejectAction,
  message,
  confirmTitle = 'Yes',
  rejectTitle = 'Cancel',
}) => [
  {
    key: 0,
    size: 'small',
    title,
    actions: [
      {
        Component: () => (
          <Button onClick={confirmAction} variant="contained" color="primary">
            {confirmTitle}
          </Button>
        ),
      },
      {
        Component: () => (
          <Button onClick={rejectAction} variant="outlined">
            {rejectTitle}
          </Button>
        ),
      },
    ],
    modalType: 'notification',
  },
  <p key={1}>{message}</p>,
];

export default getConfirmationModalConfig;

/* eslint-disable no-return-assign */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Rating from '@material-ui/lab/Rating';
import { Box, Typography, Icon, Button } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

const FeedbackForm = ({ onSubmit, onCancel, rating, courseId }) => {
  const { t } = useTranslation('common');

  const ratingNameById = {};
  const ratingData = {
    ...rating,
    trainersQualityArray: rating.trainersQualityArray.map(item => ({
      trainerId: item.trainerId,
      trainerQuality: item.trainerQuality,
    })),
  };
  rating.trainersQualityArray.forEach(item => (ratingNameById[item.trainerId] = item.fullName));

  const [value, setValue] = useState(ratingData);

  return (
    <>
      <Box mb={3}>
        <Typography component="legend">{t('formLabels.course')}</Typography>
        <Rating
          name={value.courseQuality}
          value={value.courseQuality}
          onChange={(event, newValue) => {
            setValue({ ...value, courseQuality: newValue });
          }}
          emptyIcon={<Icon>star_border</Icon>}
        />
      </Box>
      {value.trainersQualityArray.map(trainer => (
        <Box mb={3} key={trainer.trainerId}>
          <Typography component="legend">
            {t('formLabels.trainer')} <small>({ratingNameById[trainer.trainerId]})</small>
          </Typography>
          <Rating
            name={trainer.trainerId}
            value={trainer.trainerQuality}
            onChange={(event, newValue) => {
              const trainerRatingId = event.currentTarget.name;
              const trainersData = [...value.trainersQualityArray].map(item => {
                if (trainerRatingId === item.trainerId.toString()) {
                  // eslint-disable-next-line no-param-reassign
                  item.trainerQuality = newValue;
                }
                return item;
              });
              setValue({ ...value, trainersQualityArray: trainersData });
            }}
            emptyIcon={<Icon>star_border</Icon>}
          />
        </Box>
      ))}
      <Box display="flex" justifyContent="space-between">
        <Button mr={1} onClick={() => onSubmit(courseId, value)} type="submit" variant="outlined" size="large">
          {t('buttons.save')}
        </Button>
        <Button ml={1} onClick={onCancel} type="reset" variant="outlined" size="large">
          {t('buttons.cancel')}
        </Button>
      </Box>
    </>
  );
};

FeedbackForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  rating: PropTypes.shape({
    trainersQualityArray: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  }).isRequired,
  courseId: PropTypes.string.isRequired,
};

export default FeedbackForm;

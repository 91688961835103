const configErrors = {
  'user.firstName': {
    firstName: 'errors.firstNameIsNotMatchPattern',
  },
  firstName: {
    firstName: 'errors.firstNameIsNotMatchPattern',
  },
  'user.lastName': {
    lastName: 'errors.lastNameIsNotMatchPattern',
  },
  lastName: {
    lastName: 'errors.lastNameIsNotMatchPattern',
  },
  'user.alreadyHasOtherRole': {
    email: 'errors.emailExisting',
  },
  'user.emailAlreadyInUse': {
    email: 'errors.emailExisting',
  },
  'user.alreadyHasThisStation': {
    email: 'errors.emailExisting',
  },
  'user.alreadyAppliedToStation': {
    email: 'errors.emailExisting',
  },
  email: {
    email: 'errors.emailIsInvalid',
  },
};

export default configErrors;

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FormHelperText, FormControl, InputLabel } from '@material-ui/core/';
import ChipInput from 'material-ui-chip-input';
import useStyles from './styles';

const CustomChipInput = ({ input: { name }, modifier, onChange, id, label, meta, fullWidth, placeholder, variant }) => {
  const classes = useStyles();
  const isError = meta.error && meta.touched;

  const [currentValue, setCurrentValue] = useState('');

  return (
    <FormControl name={name} fullWidth={fullWidth} error={isError}>
      <InputLabel className={classes.label} shrink htmlFor={id}>
        {label}
      </InputLabel>
      <ChipInput
        id={id}
        className={classes.inputField}
        fullWidth={fullWidth}
        placeholder={placeholder}
        variant={variant}
        name={name}
        error={isError}
        onChange={e => {
          onChange(e);
          setCurrentValue('');
        }}
        onUpdateInput={e => setCurrentValue(modifier(e))}
        inputValue={currentValue}
      />
      {isError && (
        <FormHelperText id="outlined-weight-helper-text" error>
          {meta.error}
        </FormHelperText>
      )}
    </FormControl>
  );
};

CustomChipInput.defaultProps = {
  modifier: e => e.target.value,
};

CustomChipInput.propTypes = {
  input: PropTypes.shape({
    name: PropTypes.string.isRequired,
  }).isRequired,
  label: PropTypes.string.isRequired,
  meta: PropTypes.shape().isRequired,
  id: PropTypes.string.isRequired,
  fullWidth: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string.isRequired,
  variant: PropTypes.string.isRequired,
  modifier: PropTypes.func,
};

export default CustomChipInput;

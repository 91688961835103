const generateColor = () => {
  const hexArray = ['a', 'b', 'c', 'd', 'e', 'f', '0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];
  let color = '#';

  for (let i = 0; i < 6; i++) {
    color += hexArray[Math.floor(Math.random() * hexArray.length)];
  }

  return color;
};

export default generateColor;

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  label: {
    position: 'relative',
    zIndex: 2,
    fontSize: 12,
    transform: 'none',
    marginBottom: 0,
    lineHeight: '30px',
  },
  inputField: {
    position: 'absolute',
    height: '140%',
    width: '120%',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    padding: 0,
    appearance: 'none',
    '& .MuiInputBase-root': {
      '&:before, &:after': {
        display: 'none',
      },
    },
  },
  inputWrapper: {
    height: '35px',
    width: '87px',
    display: 'block',
    position: 'relative',
    borderRadius: '6px',
    overflow: 'hidden',
  },
}));

export default useStyles;

import React from 'react';
import { StateToggler, ColoredRectangle } from 'components/AppTable/CellsComponents';
import i18next from 'i18next';

const getTableConfig = helpers => [
  {
    title: i18next.t('tablesLabels.id'),
    field: 'id',
    isSorting: true,
  },
  {
    title: i18next.t('tablesLabels.name'),
    field: 'name',
    isSorting: true,
  },
  {
    title: i18next.t('tablesLabels.mainColor'),
    field: 'mainColor',
    alignment: 'center',
    Component: color => <ColoredRectangle color={color} />,
  },
  {
    title: i18next.t('tablesLabels.mainFontColor'),
    field: 'fontMainColor',
    alignment: 'center',
    Component: color => <ColoredRectangle color={color} />,
  },
  {
    title: i18next.t('tablesLabels.state'),
    field: 'isActive',
    isSorting: true,
    Component: state => (
      <StateToggler
        value={state}
        activeText={i18next.t('commonValues.active')}
        inactiveText={i18next.t('commonValues.notActive')}
        onChange={helpers.handleToggleState}
      />
    ),
  },
];

export default getTableConfig;

import React from 'react';
import i18next from 'i18next';
import { DateFormatter } from 'components/AppTable/CellsComponents';

const getTableConfig = () => {
  return [
    {
      title: i18next.t('tablesLabels.jobTask'),
      field: 'jobTask',
      isSorting: true,
    },
    {
      title: i18next.t('tablesLabels.course'),
      field: 'course',
      isSorting: true,
    },
    {
      title: i18next.t('tablesLabels.performedDate'),
      field: 'performationDate',
      Component: value => (value === null ? i18next.t('courseDetails.notSpecified') : <DateFormatter value={value} />),
      isSorting: true,
    },
    {
      title: i18next.t('tablesLabels.expiryDate'),
      field: 'coloredData',
      Component: value => {
        if (value && value.expirationDate === null) {
          return i18next.t('courseDetails.notSpecified');
        }
        if (value && value.expirationDate !== null) {
          return (
            <span style={{ color: `${value.color}` }}>
              <DateFormatter value={value.expirationDate} />
            </span>
          );
        }
        return null;
      },
      isSorting: true,
    },
  ];
};

export default getTableConfig;

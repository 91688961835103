import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  dropzone: {
    position: 'relative',
    color: '#A3C1DD',
  },
  dropzoneArea: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: 70,
    borderRadius: 6,
    color: 'currentColor',
    cursor: 'pointer',
  },
  dropzoneWithoutFiles: {
    backgroundColor: 'rgba(6, 89, 164, 0.1)',
    border: '2px dashed rgba(6, 89, 164, 0.3)',
  },
  dropzoneWithFiles: {
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
    border: '2px dashed rgba(169, 169, 169, 0.3)',
  },
  dropzoneAreaSingle: {
    minHeight: 130,
  },
  dropzoneAreaActive: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
  dropzoneAreaAccept: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
  dropzoneAreaReject: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.primary.contrastText,
  },
  dropzoneText: {
    fontSize: '1em',
    lineHeight: '14px',
    margin: 'auto',
    padding: '0 5px',
  },
  dropzoneIcon: {
    height: 24,
    width: 24,
    marginBottom: 0,
    marginTop: 'auto',
    fill: 'currentColor',
  },
  dropzonePreview: {
    display: 'flex',
    flexWrap: 'wrap',
    alignContent: 'flex-start',
  },
  dropzonePreviewSingle: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '90%',
  },
  dropzoneFormatHints: {
    color: '#346a9c',
  },
  dropzoneError: {
    color: theme.palette.error.main,
    margin: '5px 0 0',
  },
}));

export default useStyles;

import React from 'react';
import PropTypes from 'prop-types';
import { ButtonBase, IconButton, Icon } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

const useStyles = makeStyles(theme => ({
  button: {
    cursor: 'pointer',
    color: theme.palette.error.main,
    whiteSpace: 'nowrap',
    padding: 0,
    border: 'none',
  },
  'color-uploaded': {
    color: theme.palette.primary.main,
  },
  'color-upload': {
    color: theme.palette.secondary.main,
  },
}));

const FileManager = ({ value = {}, onChange, onShowCertificate, id }) => {
  const { isPerformedWithSuccess, certificate, isFrozen = false } = value;
  const { t } = useTranslation('common');
  const classes = useStyles();

  const button = classNames({
    [classes.button]: !isPerformedWithSuccess,
    [classes['color-uploaded']]: certificate !== null && !!isPerformedWithSuccess,
    [classes['color-upload']]: certificate === null && !!isPerformedWithSuccess,
  });

  let buttonStatus;
  if (!isPerformedWithSuccess) {
    buttonStatus = t('students.certificateDisabled');
  } else if (!!isPerformedWithSuccess && certificate === null) {
    buttonStatus = t('students.certificateUpload');
  } else {
    buttonStatus = (
      <IconButton
        size="small"
        onClick={() => {
          onShowCertificate(id, certificate.previewUrl);
        }}
      >
        <Icon>cloud_download</Icon>
      </IconButton>
    );
  }

  if (certificate !== null) {
    return buttonStatus;
  }
  return (
    <ButtonBase
      variant="contained"
      className={button}
      type="button"
      data-key={id}
      aria-label="state"
      onClick={() => onChange(id)}
      disabled={!isPerformedWithSuccess || isFrozen}
    >
      {buttonStatus}
    </ButtonBase>
  );
};

FileManager.defaultProps = {
  id: undefined,
};

FileManager.propTypes = {
  value: PropTypes.shape({
    certificate: PropTypes.oneOfType([PropTypes.string, null]),
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  onShowCertificate: PropTypes.func.isRequired,
  id: PropTypes.number,
};

export default FileManager;

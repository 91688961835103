import React from 'react';
import PropTypes from 'prop-types';
import AppBreadcrumbs from 'components/AppBreadcrumbs';
import PageLayout from 'layouts/PageLayout';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { useAsyncFn } from 'react-use';
import { useHistory, useRouteMatch, useLocation } from 'react-router-dom';
import { createLocalCourse, createGlobalCourse } from 'http/courses';
import { routesAliases } from 'router/routes';
import NotFound from 'pages/NotFound';
import { getTrainersCompliant } from 'http/users';
import { useTranslation } from 'react-i18next';
import { ROLES } from 'appConstants';
import CourseForm from '../Form';

const useStyles = makeStyles(theme => ({
  pageContent: {
    padding: theme.spacing(4, 5),
  },
}));

let historyState;

const Create = ({ routeProps }) => {
  const { t } = useTranslation('common');
  const { station, role } = useSelector(state => state.auth.profile);
  const stationId = station ? station.id : undefined;
  const courseTypes = {
    local: {
      createCourse: createLocalCourse.bind(null, stationId),
      fetchTrainers: getTrainersCompliant.bind(null, stationId),
    },
  };

  if (role === ROLES.SUPER) {
    courseTypes.global = {
      createCourse: createGlobalCourse.bind(null),
      fetchTrainers: getTrainersCompliant.bind(null, stationId),
    };
  }

  const classes = useStyles();
  const history = useHistory();
  const routerMatch = useRouteMatch();
  const location = useLocation();
  const { type: courseType } = routerMatch.params;

  if (history.action === 'PUSH') {
    historyState = location.state;
  }

  let backUrl = routesAliases.coursesList;
  if (historyState && historyState.backUrl) {
    backUrl = historyState.backUrl;
  }

  const notFoundError = !courseTypes[courseType];

  const breadCrumbsItems = [
    {
      title: t(routeProps.parentTitle),
      link: routeProps.parentPath,
    },
    {
      title: t(routeProps.title, { type: t(`courses.${courseType}`) }),
      isActive: true,
    },
  ];

  const [submittingState, sendForm] = useAsyncFn(async formData => {
    const course = await courseTypes[courseType].createCourse(formData);
    if (!course.error) {
      history.push(`${routesAliases.editCourse}/${courseType}/${course.id}/${stationId || 'external'}`, { backUrl });
    }
    return course;
  });

  return notFoundError ? (
    <NotFound />
  ) : (
    <PageLayout classes={{ content: classes.pageContent }} pageTitle={<AppBreadcrumbs items={breadCrumbsItems} />}>
      <CourseForm
        onSubmitForm={sendForm}
        submitting={submittingState.loading}
        backUrl={backUrl}
        stationId={stationId}
        courseType={courseType}
        helpers={{
          fetchTrainers: courseTypes[courseType].fetchTrainers,
        }}
        type="create"
      />
    </PageLayout>
  );
};

Create.propTypes = {
  routeProps: PropTypes.shape({
    parentPath: PropTypes.string,
    parentTitle: PropTypes.string,
    title: PropTypes.string,
  }).isRequired,
};

export default Create;

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { VictoryPie, VictoryLegend, VictoryContainer } from 'victory';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Tooltip from './Tooltip';
import calculateStatisticsPercents from './helpers/calculateStatisticsPercents';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  donutTitle: {
    textTransform: 'uppercase',
    fontWeight: 500,
    color: theme.palette.text.grey,
    fontFamily: theme.typography.fontFamily,
    margin: '0 0 10px',
  },
}));

const transformDonutData = (data, shouldPercent) => {
  const legendData = data
    .map(({ legendTitle, fillColor, hideLabel, withExtInfo }) => {
      return {
        name: legendTitle,
        symbol: { fill: fillColor },
        hideLabel,
        labels: { fill: fillColor },
        withExtInfo,
      };
    })
    .filter(title => !title.hideLabel);
  const donutData = data
    .map(({ value, fillColor, hiddenInData }) => {
      return {
        y: value,
        label: shouldPercent ? `${calculateStatisticsPercents(data, value)}%` : value,
        fillColor,
        hiddenInData,
      };
    })
    .filter(item => !item.hiddenInData);

  return [legendData, donutData];
};

const Donut = props => {
  const classes = useStyles();
  const theme = useTheme();
  const [tooltipObj, setTooltipObj] = useState({
    x: 0,
    y: 0,
    show: false,
  });
  const {
    title,
    legendWidth,
    legendHeight,
    legendOrientation,
    legendItemsPerRow,
    donutWidth,
    donutHeight,
    donutEndAngle,
    classes: customClasses,
    donutData,
    donutRadius,
    onLegendClick,
    shouldPercent,
    donutTitle,
    donutLegendTitle,
    donutLegendTitleStyles,
  } = props;

  const [legendData, transformedDonutData] = transformDonutData(donutData, shouldPercent);

  const donutClasses = {
    root: classNames({
      [classes.root]: true,
      [customClasses.root]: !!customClasses.root,
    }),
    legendWrapper: classNames({
      [customClasses.legendWrapper]: !!customClasses.legendWrapper,
    }),
    donutWrapper: classNames({
      [customClasses.donutWrapper]: !!customClasses.donutWrapper,
    }),
  };

  return (
    <div className={donutClasses.root}>
      <div className={donutClasses.legendWrapper}>
        {donutLegendTitle && <p className={`${classes.donutTitle} ${donutLegendTitleStyles}`}>{donutLegendTitle}</p>}
        <VictoryContainer height={legendHeight} width={legendWidth}>
          <VictoryLegend
            standalone={false}
            title={title}
            style={{
              title: {
                textTransform: 'uppercase',
                fontWeight: 500,
                fill: theme.palette.text.grey,
                fontFamily: theme.typography.fontFamily,
              },
              labels: { fontFamily: theme.typography.fontFamily },
            }}
            orientation={legendOrientation}
            gutter={5}
            data={legendData}
            itemsPerRow={legendItemsPerRow}
            events={[
              {
                target: ['data', 'labels'],
                eventHandlers: {
                  onClick: event => {
                    return [
                      {
                        target: 'data',
                        mutation: ({ datum }) => {
                          if (datum.withExtInfo) {
                            setTooltipObj({
                              x: event.clientX,
                              y: event.clientY,
                              show: true,
                            });
                            onLegendClick(props, donutData);
                          }
                        },
                      },
                    ];
                  },
                },
              },
            ]}
          />
        </VictoryContainer>
      </div>
      <div className={donutClasses.donutWrapper}>
        {donutTitle && <p className={classes.donutTitle}>{donutTitle}</p>}
        <VictoryContainer height={donutHeight} width={donutWidth}>
          <VictoryPie
            standalone={false}
            endAngle={donutEndAngle}
            data={transformedDonutData}
            style={{
              data: {
                fill: ({ datum }) => datum.fillColor,
              },
              labels: {
                fill: ({ datum }) => datum.fillColor,
                fontSize: '14px',
                fontWeight: 'bold',
              },
            }}
            innerRadius={donutRadius}
          />
        </VictoryContainer>
      </div>
      <Tooltip
        isOpen={tooltipObj.show}
        x={tooltipObj.x}
        y={tooltipObj.y}
        closeTooltip={(x, y) => {
          setTooltipObj({
            x,
            y,
            show: false,
          });
        }}
        donutData={donutData}
        shouldPercent={shouldPercent}
      />
    </div>
  );
};

Donut.defaultProps = {
  donutLegendTitleStyles: undefined,
  title: undefined,
  donutTitle: undefined,
  donutLegendTitle: undefined,
  classes: {},
  legendWidth: 400,
  legendHeight: 90,
  donutWidth: 400,
  donutHeight: 400,
  legendItemsPerRow: 2,
  legendOrientation: 'horizontal',
  donutEndAngle: -360,
  donutRadius: 110,
  donutData: [],
  onLegendClick: () => {},
  shouldPercent: true,
};

Donut.propTypes = {
  donutLegendTitleStyles: PropTypes.string,
  title: PropTypes.string,
  donutTitle: PropTypes.string,
  donutLegendTitle: PropTypes.string,
  classes: PropTypes.shape({
    root: PropTypes.string,
    legendWrapper: PropTypes.string,
    donutWrapper: PropTypes.string,
    donutTitle: PropTypes.string,
  }),
  legendWidth: PropTypes.number,
  legendHeight: PropTypes.number,
  donutWidth: PropTypes.number,
  donutHeight: PropTypes.number,
  legendItemsPerRow: PropTypes.number,
  legendOrientation: PropTypes.oneOf(['horizontal', 'vertical']),
  donutEndAngle: PropTypes.number,
  donutData: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.number,
      label: PropTypes.string,
      legendTitle: PropTypes.string,
      fillColor: PropTypes.string,
      hideLabel: PropTypes.bool,
      withExtInfo: PropTypes.bool,
    }),
  ),
  donutRadius: PropTypes.number,
  onLegendClick: PropTypes.func,
  shouldPercent: PropTypes.bool,
};

export default Donut;

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  label: {
    position: 'relative',
    zIndex: 2,
    fontSize: 12,
    transform: 'none',
    marginBottom: 0,
    lineHeight: '30px',
  },
  group: {
    flexDirection: 'row',
  },
  radioLabel: {
    '& [class*="PrivateRadioButtonIcon-root"]': {
      position: 'relative',
      '&::after': {
        width: '20px',
        height: '20px',
        content: '',
        display: 'block !important',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        background: 'red',
      },
    },
    '& [class*="PrivateRadioButtonIcon-root"]::after': {
      width: '20px',
      height: '20px',
      content: '',
      display: 'block !important',
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      background: 'red',
    },
    '& svg': {
      fill: theme.palette.primary.main,
    },
    '& .MuiIconButton-label': {
      position: 'relative',
      '&::after': {
        width: '20px',
        height: '20px',
        content: '',
        display: 'block !important',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        background: 'red',
      },
    },
    '& .MuiIconButton-label::after': {
      width: '20px',
      height: '20px',
      content: '',
      display: 'block !important',
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      background: 'red',
    },
    '&.false': {
      '& svg': {
        fill: theme.palette.text.grey,
      },
    },
  },
  active: {
    color: theme.palette.primary.main,
  },
}));

export default useStyles;

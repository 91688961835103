import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  form: {
    maxWidth: 900,
    margin: '0 auto',
  },
  subtitle: {
    fontSize: '18px',
    lineHeight: '21px',
    fontWeight: 700,
  },
  containerSubtitles: {
    marginBottom: '22px',
  },
  gridItem: {
    width: '400px',
    '&:first-child': {
      paddingRight: '50px',
    },
    '&:last-child': {
      paddingLeft: '50px',
    },
    position: 'relative',
  },
  divider: {
    position: 'absolute',
    top: '30px',
    left: '-1px',
    bottom: '30px',
    height: 'auto',
  },
  btnContainer: {
    marginTop: '20px',
  },
  btn: {
    minWidth: '140px',
  },
  btnEdit: {
    color: theme.palette.primary.main,
    fontSize: '12px',
    lineHeight: '14px',
    textTransform: 'uppercase',
    fontWeight: '400',
    marginTop: '10px',
  },
  btnEditIcon: {
    marginRight: '16px',
  },
  checkboxGroup: {
    '& .MuiFormControlLabel-root': {
      flexBasis: '30%',
    },
  },
}));

export default useStyles;

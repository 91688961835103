/* eslint-disable no-param-reassign */
import Joi from '@hapi/joi';
import i18next from 'i18next';

const schema = Joi.object({
  id: Joi.number().required(),
  firstName: Joi.string()
    .min(2)
    .max(30)
    .required(),
  lastName: Joi.string()
    .min(2)
    .max(30)
    .required(),
  email: Joi.string()
    .email({ tlds: false })
    .required(),
  currentPassword: Joi.string()
    .allow('', null)
    .optional(),
  newPassword: Joi.string()
    .allow('', null)
    .optional(),
  confirmation: Joi.string()
    .allow('', null)
    .optional(),
});

const schema2 = Joi.object({
  id: Joi.number().required(),
  firstName: Joi.string()
    .min(2)
    .max(30)
    .required(),
  lastName: Joi.string()
    .min(2)
    .max(30)
    .required(),
  email: Joi.string()
    .email({ tlds: false })
    .required(),
  currentPassword: Joi.string()
    .min(1)
    .required(),
  newPassword: Joi.string()
    .pattern(/^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%^&!+=.\-_*])([a-zA-Z0-9@#$%^&!+=*.\-_]){8,70}$/)
    .invalid(Joi.ref('currentPassword')),
  confirmation: Joi.string()
    .valid(Joi.ref('newPassword'))
    .required(),
});

const validate = values => {
  const { currentPassword, newPassword, confirmation } = values;
  const isPasswordRequire = !!currentPassword || !!newPassword || !!confirmation;
  const { error } = isPasswordRequire
    ? schema2.validate(values, { abortEarly: false })
    : schema.validate(values, { abortEarly: false });

  if (!error) {
    return {};
  }

  const { details } = error;

  return details.reduce((errors, detail) => {
    switch (detail.context.key) {
      case 'firstName':
        errors[detail.context.key] = i18next.t('errors.firstNameIsRequired');
        break;
      case 'lastName':
        errors[detail.context.key] = i18next.t('errors.lastNameIsRequired');
        break;
      case 'email':
        if (detail.context.value === undefined || detail.context.value === '') {
          errors[detail.context.key] = i18next.t('errors.emailIsRequired');
        } else {
          errors[detail.context.key] = i18next.t('errors.emailIsInvalid');
        }
        break;
      case 'currentPassword':
        errors[detail.context.key] = i18next.t('errors.currentPasswordIsRequired');
        break;
      case 'newPassword':
        errors[detail.context.key] = i18next.t('errors.passwordIsMatchPattern');
        break;
      case 'confirmation':
        errors[detail.context.key] = i18next.t('errors.confirmationDoesNotMatchNewPassword');
        break;
      default:
        break;
    }
    return errors;
  }, {});
};

export default validate;

import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    fontSize: '24px',
    color: theme.palette.common.black,
    textTransform: 'capitalize',
  },
  item: {},
  itemTitle: {
    fontSize: '14px',
    opacity: 0.4,
    marginBottom: '2px',
  },
  itemSubtitle: {
    fontSize: '14px',
    marginLeft: '9px',
  },
  divider: {
    margin: '0 40px',
    opacity: 0.4,
    width: 0,
    height: '40px',
    border: `0.5px solid ${theme.palette.common.black}`,
  },
}));

const StatisticsInfo = ({ items }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {items.map((item, index, itemsArray) => {
        return (
          <React.Fragment key={index}>
            <div className={classes.item}>
              <div className={classes.itemTitle}>{item.title}</div>
              <div>
                {item.value}
                <span className={classes.itemSubtitle}>{item.subtitle}</span>
              </div>
            </div>
            {itemsArray.length - 1 !== index && <div className={classes.divider} />}
          </React.Fragment>
        );
      })}
    </div>
  );
};

StatisticsInfo.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      value: PropTypes.number,
      subtitle: PropTypes.string,
    }),
  ).isRequired,
};

export default StatisticsInfo;

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Form, Field } from 'react-final-form';
import { Grid, Box, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CustomTextField from 'components/Forms/TextField';
import CustomSelect from 'components/Forms/CustomSelect';
import { useAsyncFn } from 'react-use';
import { getCourseTemplates } from 'http/courseTemplates';
import Loader from 'components/Loader';
import { transformDataToSelectOptions, generateErrorObject } from 'utils';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import configErrors from './configErrors';
import validate from './validation';

const useStyles = makeStyles(theme => ({
  formHeader: {
    textAlign: 'center',
    marginBottom: '50px',
    fontSize: '18px',
    textTransform: 'capitalize',
    color: theme.palette.text.grey,
  },
  btnContainer: {
    marginTop: '100px',
  },
  btn: {
    minWidth: '140px',
  },
}));

const TaskForm = ({ formType, onSubmitForm, submitting: formSubmitting, initialValues, backUrl }) => {
  const history = useHistory();
  const classes = useStyles();
  const { t } = useTranslation();
  const [formControlsOptions, setFormControlsOptions] = useState({
    courseTemplates: [],
  });
  const [formData, setFormData] = useState({});

  const [formControlsState, getFormControlsOptions] = useAsyncFn(async () => {
    const courseTemplates = await getCourseTemplates();
    if (courseTemplates.items) {
      setFormControlsOptions({
        ...formControlsOptions,
        courseTemplates: transformDataToSelectOptions(courseTemplates.items, 'id', 'name'),
      });
    }
  });

  useEffect(() => {
    getFormControlsOptions();
  }, []);

  useEffect(() => {
    if (formType === 'edit' && initialValues) {
      setFormData(initialValues);
    }
  }, [initialValues]);

  const handleSubmitForm = async values => {
    const transformedFormData = {
      ...values,
      courseTemplatesIds: Array.isArray(values.courseTemplates)
        ? values.courseTemplates.map(courseTemplate => courseTemplate.value)
        : [],
      name: values.name.trim(),
    };
    delete transformedFormData.courseTemplates;

    const submitData = await onSubmitForm(transformedFormData);
    if (submitData && submitData.error && submitData.message) {
      return generateErrorObject(submitData.message, configErrors);
    }

    return undefined;
  };

  const isLoading = formControlsState.loading || formSubmitting;

  return (
    <>
      <h2 className={classes.formHeader}>{formData.name}</h2>
      <Form
        onSubmit={handleSubmitForm}
        initialValues={formData}
        validate={validate}
        render={({ handleSubmit, submitting, form }) => {
          return (
            <form onSubmit={handleSubmit}>
              <Grid container className="mainSection">
                <Grid item xs={4} />
                <Grid container item justify="flex-start" xs={4} direction="column">
                  <Grid item>
                    <Box>
                      <Field
                        type="text"
                        id="name"
                        name="name"
                        label={t('formLabels.jobTaskName')}
                        disabled={submitting}
                        variant="outlined"
                        fullWidth
                        component={CustomTextField}
                        format={(value = '') => value.trim()}
                        formatOnBlur
                      />
                    </Box>
                  </Grid>
                  <Grid item>
                    <Box mt={3}>
                      <Field
                        type="text"
                        id="courseTemplates"
                        name="courseTemplates"
                        label={t('formLabels.relatedCourses')}
                        variant="outlined"
                        placeholder={t('placeholders.chooseCourses')}
                        options={formControlsOptions.courseTemplates}
                        fullWidth
                        isMulti
                        component={CustomSelect}
                      />
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
              <Grid container spacing={3} className={classes.btnContainer}>
                <Grid item xs={6}>
                  <Box textAlign="right">
                    <Button
                      className={classes.btn}
                      type="submit"
                      color="primary"
                      variant="contained"
                      disabled={submitting}
                      size="large"
                    >
                      {t('buttons.save')}
                    </Button>
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <Box textAlign="left">
                    <Button
                      className={classes.btn}
                      variant="outlined"
                      size="large"
                      type="reset"
                      disabled={submitting}
                      onClick={() => {
                        history.push(backUrl);
                      }}
                    >
                      {t('buttons.cancel')}
                    </Button>
                  </Box>
                </Grid>
              </Grid>

              {isLoading && <Loader />}
            </form>
          );
        }}
      />
    </>
  );
};

TaskForm.defaultProps = {
  formType: 'create',
  initialValues: null,
};

TaskForm.propTypes = {
  formType: PropTypes.string,
  onSubmitForm: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  initialValues: PropTypes.shape({}),
  backUrl: PropTypes.string.isRequired,
};

export default TaskForm;

import React from 'react';
import PropTypes from 'prop-types';
import AppBreadcrumbs from 'components/AppBreadcrumbs';
import PageLayout from 'layouts/PageLayout';
import { useAsyncFn } from 'react-use';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch } from 'react-redux';
import { setStudent, getStudents } from 'http/users';
import { actions as modalActions } from 'redux/modal';
import { useHistory } from 'react-router-dom';
import { routesAliases } from 'router/routes';
import { useTranslation } from 'react-i18next';
import StudentsForm from '../Form';

const useStyles = makeStyles(theme => ({
  pageContent: {
    padding: theme.spacing(4, 5),
  },
}));

const Create = ({ routeProps }) => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const breadCrumbsItems = [
    {
      title: t(routeProps.parentTitle),
      link: routeProps.parentPath,
    },
    {
      title: t(routeProps.title),
      isActive: true,
    },
  ];

  const [state, addStudent] = useAsyncFn(async (formData, stationId) => {
    const data = await setStudent(formData, stationId);
    if (!data.error) {
      dispatch(modalActions.openModal({ size: 'small' }, <p>{t('students.studentCreated')}</p>));

      const users = await getStudents();
      if (!users.error) {
        const userId = users.items.filter(item => item.email === formData.email)[0].id;
        history.push(`${routesAliases.editStudent}/${userId}`);
      }
    }
    return data;
  });

  return (
    <PageLayout pageTitle={<AppBreadcrumbs items={breadCrumbsItems} />} classes={{ content: classes.pageContent }}>
      <StudentsForm isLoading={state.loading} onSubmit={addStudent} routesAliases={routesAliases} type="create" />
    </PageLayout>
  );
};

Create.propTypes = {
  routeProps: PropTypes.shape({
    parentPath: PropTypes.string,
    parentTitle: PropTypes.string,
    title: PropTypes.string,
  }).isRequired,
};

export default Create;

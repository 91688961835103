import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import AppTable from 'components/AppTable';
import Loader from 'components/Loader';
import { transformTableData } from 'utils';
import { useAsyncFn } from 'react-use';
import { getTrainerAVGrating } from 'http/courses';
import { useSelector, useDispatch } from 'react-redux';
import { actions } from 'redux/table';

const DocumentsTable = ({ userId, configTable }) => {
  const dispatch = useDispatch();
  const { needForceUpdate, tableData, count } = useSelector(state => state.table);

  const tableConfig = configTable();
  const [columns, documents] = transformTableData(tableConfig, tableData);

  const [documentsFetching, fetchDocuments] = useAsyncFn(async () => {
    const documentsData = await getTrainerAVGrating(userId);
    if (!documentsData.error) {
      dispatch(
        actions.setTableData({
          data: documentsData.items,
          count: documentsData.count,
        }),
      );
    }
  });

  useEffect(() => {
    fetchDocuments();
  }, [userId]);

  useEffect(() => {
    if (needForceUpdate === true) {
      fetchDocuments();
      dispatch(actions.forceUpdateTable(false));
    }
  }, [needForceUpdate]);

  const isLoading = documentsFetching.loading;

  return (
    <>
      <AppTable columns={columns} data={documents} totalCount={count} rowsPerPage={5} />
      {isLoading && <Loader />}
    </>
  );
};

DocumentsTable.propTypes = {
  userId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  configTable: PropTypes.func.isRequired,
};

export default DocumentsTable;

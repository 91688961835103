import React from 'react';
import { DateFormatter } from 'components/AppTable/CellsComponents';
import i18next from 'i18next';

export const getCoursesScheduledConfig = () => [
  {
    title: i18next.t('tablesLabels.id'),
    field: 'id',
  },
  {
    title: i18next.t('tablesLabels.course'),
    field: 'name',
    isSorting: true,
  },
  {
    title: i18next.t('tablesLabels.startDate'),
    field: 'startDate',
    Component: value => <DateFormatter value={value} />,
    isSorting: true,
  },
  {
    title: i18next.t('tablesLabels.expiryDate'),
    field: 'expiryDate',
    Component: value => (value === null ? i18next.t('courseDetails.notSpecified') : <DateFormatter value={value} />),
    isSorting: true,
  },
  {
    title: i18next.t('tablesLabels.jobTask'),
    field: 'jobTask',
    isSorting: true,
  },
  {
    title: 'Course Type',
    field: 'courseType',
    isVisible: false,
  },
  {
    title: i18next.t('tablesLabels.stationId'),
    field: 'stationId',
    isVisible: false,
  },
];

import 'core-js';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider as ReduxProvider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

import './i18n';
import configureStore from 'redux/configureStore';
import GlobalStyle from 'components/GlobalStyle';
import App from './App';

const store = configureStore();

ReactDOM.render(
  <>
    <GlobalStyle />
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <ReduxProvider store={store}>
        <Router>
          <App />
        </Router>
      </ReduxProvider>
    </MuiPickersUtilsProvider>
  </>,
  document.getElementById('root'),
);

import React, { Suspense, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useAsyncFn } from 'react-use';
import { useDispatch } from 'react-redux';
import { profile } from 'http/auth';
import { getCompanyInfoBySlug } from 'http/companies';
import { actions } from 'redux/auth';
import { ThemeProvider } from '@material-ui/core/styles';
import { actions as stationsActions } from 'redux/stations';
import { getSubdomain } from 'utils';
import Loader from 'components/Loader';
import { ToastContainer } from 'react-toastify';
import AppRouter from './router';
import { ROLES } from './appConstants';
import theme from './theme';
import 'react-toastify/dist/ReactToastify.min.css';

let themeCreator = theme();

const App = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const [getCompanyInfoState, getCompanyInfo] = useAsyncFn(async () => {
    const subdomain = getSubdomain(window.location.hostname);
    // const subdomain =
    //   process.env.REACT_APP_STAGE === 'development'
    //     ? process.env.REACT_APP_TEST_SLUG
    //     : getSubdomain(window.location.hostname);

    if (subdomain) {
      const companyData = await getCompanyInfoBySlug(subdomain);
      if (!companyData.error && companyData) {
        dispatch(actions.setCompanyInfo(companyData));
        themeCreator = theme(companyData.mainColor, companyData.fontMainColor);
      }
    }
  });

  const redirectIfWrongSubdomain = companyData => {
    const subdomain = getSubdomain(window.location.hostname);
    const { protocol, hostname } = window.location;
    const hostnameParts = hostname.split('.');
    const domainZone = hostnameParts[hostnameParts.length - 1];

    if (!companyData) {
      if (subdomain !== '') {
        window.location.replace(`${protocol}//${process.env.REACT_APP_DOMAIN}.${domainZone}${location.pathname}`);
      }
    } else if (subdomain !== companyData.slug) {
      window.location.replace(
        `${protocol}//${companyData.slug}.${process.env.REACT_APP_DOMAIN}.${domainZone}${location.pathname}`,
      );
    }
  };

  const [state, getAuthInfo] = useAsyncFn(async () => {
    await getCompanyInfo();

    let profileData;
    if (location.pathname !== '/password/set') {
      profileData = await profile();
    }

    if (profileData && !profileData.error) {
      redirectIfWrongSubdomain(profileData.company);
      dispatch(actions.authenticate(profileData));
      if (profileData.role !== ROLES.HYPER) {
        const { stations } = profileData;
        if (stations.length) {
          dispatch(actions.setStation({ name: stations[0].name, id: stations[0].id }));
          dispatch(stationsActions.setStations(stations));
        }
      }

      history.push(location.pathname);
    }
  });

  useEffect(() => {
    getAuthInfo();
  }, []);

  const isLoading = getCompanyInfoState.loading || state.loading;

  return isLoading ? (
    <Loader />
  ) : (
    <Suspense fallback={<Loader />}>
      <ThemeProvider theme={themeCreator}>
        <AppRouter />
        <ToastContainer />
      </ThemeProvider>
    </Suspense>
  );
};

export default App;

import { http } from '../index';
import { formDataModifier } from '../modifiers';
import { addFullNameField, scheduledCoursesModifier, pastCoursesModifier } from '../formatters';
import { downloadFileFromBytes, getFileNameFromContentDispositionString } from '../helpers';

const multiPartConfig = {
  headers: {
    'Content-Type': 'multipart/form-data',
  },
};

export const getGlobalCourses = params => {
  return http
    .get('/courses', {
      params,
    })
    .then(res => addFullNameField(res.data, 'updatedByUser.info'))
    .catch(error => error.response.data);
};

export const createGlobalCourse = formData => {
  return http
    .post('/courses', formData)
    .then(res => res.data)
    .catch(error => error.response.data);
};

export const updateGlobalCourse = (courseId, formData) => {
  return http
    .put(`/course/${courseId}`, formData)
    .then(res => res.data)
    .catch(error => error.response.data);
};

export const getGlobalCourse = courseId => {
  return http
    .get(`/course/${courseId}`)
    .then(res => res.data)
    .catch(error => error.response.data);
};

export const deleteGlobalCourse = courseId => {
  return http
    .delete(`/course/${courseId}`)
    .then(res => res.data)
    .catch(error => error.response.data);
};

export const getGlobalCourseDocuments = (courseId, params) => {
  return http
    .get(`/course/${courseId}/files`, { params })
    .then(res => res.data)
    .catch(error => error.response.data);
};

export const addGlobalCourseDocument = (courseId, formData) => {
  return http
    .post(`/course/${courseId}/files`, formDataModifier(formData), multiPartConfig)
    .then(res => res.data)
    .catch(error => error.response.data);
};

export const deleteGlobalCourseDocument = (courseId, fileId) => {
  return http
    .delete(`/course/${courseId}/file/${fileId}`)
    .then(res => res.data)
    .catch(error => error.response.data);
};

export const downloadGlobalCourseDocument = (courseId, fileId) => {
  return http
    .get(`/course/${courseId}/file/${fileId}`, {
      responseType: 'blob',
    })
    .then(res => {
      const filename = getFileNameFromContentDispositionString(res);
      downloadFileFromBytes(res.data, filename, '', true);
    })
    .catch(error => error.response.data);
};

export const getLocalCourses = (stationId, params) => {
  return http
    .get(`/station/${stationId}/courses`, {
      params,
    })
    .then(res => addFullNameField(res.data, 'updatedByUser.info'))
    .catch(error => error.response.data);
};

export const createLocalCourse = (stationId, formData) => {
  return http
    .post(`/station/${stationId}/courses`, formData)
    .then(res => res.data)
    .catch(error => error.response.data);
};

export const updateLocalCourse = (stationId, courseId, formData) => {
  return http
    .put(`/station/${stationId}/course/${courseId}`, formData)
    .then(res => res.data)
    .catch(error => error.response.data);
};

export const getLocalCourse = (stationId, courseId) => {
  return http
    .get(`/station/${stationId}/course/${courseId}`)
    .then(res => res.data)
    .catch(error => error.response.data);
};

export const deleteLocalCourse = (stationId, courseId) => {
  return http
    .delete(`/station/${stationId}/course/${courseId}`)
    .then(res => res.data)
    .catch(error => error.response.data);
};

export const getLocalCourseDocuments = (stationId, courseId, params) => {
  return http
    .get(`/station/${stationId}/course/${courseId}/files`, {
      params,
    })
    .then(res => res.data)
    .catch(error => error.response.data);
};

export const addLocalCourseDocument = (stationId, courseId, formData) => {
  return http
    .post(`/station/${stationId}/course/${courseId}/files`, formDataModifier(formData), multiPartConfig)
    .then(res => res.data)
    .catch(error => error.response.data);
};

export const deleteLocalCourseDocument = (stationId, courseId, fileId) => {
  return http
    .delete(`/station/${stationId}/course/${courseId}/file/${fileId}`)
    .then(res => res.data)
    .catch(error => error.response.data);
};

export const downloadLocalCourseDocument = (stationId, courseId, fileId) => {
  return http
    .get(`/station/${stationId}/course/${courseId}/file/${fileId}`, {
      responseType: 'blob',
    })
    .then(res => {
      const filename = getFileNameFromContentDispositionString(res);
      downloadFileFromBytes(res.data, filename, '', true);
    })
    .catch(error => error.response.data);
};

export const importLocalCourses = (stationId, formData) => {
  return http
    .post(`/station/${stationId}/courses/import`, formDataModifier(formData), multiPartConfig)
    .then(res => res.data)
    .catch(error => error.response.data);
};

export const importGlobalCourses = formData => {
  return http
    .post(`/courses/import`, formDataModifier(formData), multiPartConfig)
    .then(res => res.data)
    .catch(error => error.response.data);
};

export const exportLocalCourses = stationId => {
  return http
    .get(`/station/${stationId}/courses/export`, {
      responseType: 'blob',
    })
    .then(res => {
      const filename = getFileNameFromContentDispositionString(res);
      downloadFileFromBytes(res.data, filename, '', true);
    })
    .catch(error => error.response.data);
};

export const exportGlobalCourses = () => {
  return http
    .get(`/courses/export`, {
      responseType: 'blob',
    })
    .then(res => {
      const filename = getFileNameFromContentDispositionString(res);
      downloadFileFromBytes(res.data, filename, '', true);
    })
    .catch(error => error.response.data);
};

export const downloadImportExample = stationId => {
  return http
    .get(`/station/${stationId}/courses/import/example`, {
      responseType: 'blob',
    })
    .then(res => {
      const filename = getFileNameFromContentDispositionString(res);
      downloadFileFromBytes(res.data, filename, '', true);
    })
    .catch(error => error.response.data);
};

export const getScheduledCourses = params => {
  return http
    .get(`/courses/scheduled`, {
      params,
    })
    .then(res => scheduledCoursesModifier(res.data))
    .catch(error => error.response.data);
};

export const getScheduledCoursesByStation = (stationId, params) => {
  return http
    .get(`/station/${stationId}/courses/scheduled`, {
      params,
    })
    .then(res => scheduledCoursesModifier(res.data))
    .catch(error => error.response.data);
};

export const getCoursesInProgress = (userId, params) => {
  return http
    .get(`/student/${userId}/courses/in-progress`, {
      params,
    })
    .then(res => res.data)
    .catch(error => error.response.data);
};

export const getCoursesScheduled = (userId, params) => {
  return http
    .get(`/student/${userId}/courses/scheduled`, {
      params,
    })
    .then(res => res.data)
    .catch(error => error.response.data);
};

export const getCoursesPassed = (userId, params) => {
  return http
    .get(`/student/${userId}/courses/passed`, {
      params,
    })
    .then(res => pastCoursesModifier(res.data))
    .catch(error => error.response.data);
};

export const getCoursesHistory = (userId, params) => {
  return http
    .get(`/student/${userId}/courses/history`, {
      params,
    })
    .then(res => res.data)
    .catch(error => error.response.data);
};

export const setCourseCertificate = (courseId, userId, data) => {
  return http
    .post(`/course/${courseId}/student/${userId}/certificate`, formDataModifier(data), multiPartConfig)
    .then(res => res.data)
    .catch(error => error.response.data);
};

export const downloadCourseCertificate = (courseId, userId) => {
  return http
    .get(`/course/${courseId}/student/${userId}/certificate`, {
      responseType: 'blob',
    })
    .then(res => {
      const filename = getFileNameFromContentDispositionString(res);
      downloadFileFromBytes(res.data, filename, '', true);
    })
    .catch(error => error.response.data);
};

export const deleteCourseCertificate = (courseId, userId) => {
  return http
    .delete(`/course/${courseId}/student/${userId}/certificate`)
    .then(res => res.data)
    .catch(error => error.response.data);
};

export const importHistory = (userId, data) => {
  return http
    .post(`/student/${userId}/courses/history/import`, formDataModifier(data), multiPartConfig)
    .then(res => res.data)
    .catch(error => error.response.data);
};

export const setCourseRating = (courseId, data) => {
  return http
    .post(`/course/${courseId}/rating`, data)
    .then(res => res.data)
    .catch(error => error.response.data);
};

export const getCourseRating = (userId, courseId, data) => {
  return http
    .get(`/course/${courseId}/rating`, data)
    .then(res => res.data)
    .catch(error => error.response.data);
};

export const setGlobalDocumentVisibility = (courseId, fileId, data) => {
  return http
    .post(`/course/${courseId}/file/${fileId}/visibility`, data)
    .then(res => res.data)
    .catch(error => error.response.data);
};

export const setLocalDocumentVisibility = (courseId, fileId, stationId, data) => {
  return http
    .post(`/station/${stationId}/course/${courseId}/file/${fileId}/visibility`, data)
    .then(res => res.data)
    .catch(error => error.response.data);
};

export const getTrainerAVGrating = userId => {
  return http
    .get(`/trainer-course-avg/${userId}/ratings`)
    .then(res => res.data)
    .catch(error => error.response.data);
};

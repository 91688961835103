import React, { useEffect } from 'react';
import AppTable from 'components/AppTable';
import PropTypes from 'prop-types';
import withTableProps from 'HOC/types/withTable';
import Loader from 'components/Loader';
import withTable from 'HOC/withTable';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Icon } from '@material-ui/core';
import { transformTableData, getConfirmationModalConfig } from 'utils';
import { getCourseStudents, setCourseToPerformed, setCourseToFailed } from 'http/courseStudent';
import { setCourseCertificate, downloadCourseCertificate, deleteCourseCertificate } from 'http/courses';
import { useAsyncFn } from 'react-use';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { actions as modalActions } from 'redux/modal';
import { actions } from 'redux/table';
import { SendFileForm } from 'components/CommonForms';
import { ROLES } from 'appConstants';
import { getStudentsConfig } from './helpers/getTableConfig';

const pageAlias = 'TrainerCourseStudents';

const useStyles = makeStyles(theme => ({
  btn: {
    cursor: 'pointer',
    whiteSpace: 'nowrap',
    '&:first-child': {
      marginRight: 20,
    },
    '&:last-child': {
      marginLeft: 20,
    },
  },
  btnDelete: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.common.contrastText,
  },
  btnWrapper: {
    marginTop: 20,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  modal: {
    textAlign: 'center',
    '& .MuiDialog-paper': {
      width: '500px',
    },
  },
  wrapper: {
    textAlign: 'center',
  },
}));

const StudentsTable = ({ tableData, courseId, isFrozen }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const classes = useStyles();
  const { role } = useSelector(state => state.auth.profile);

  const handleCloseModal = () => dispatch(modalActions.closeModal());

  const [addFileState, addFile] = useAsyncFn(async (userId, formData) => {
    const data = await setCourseCertificate(courseId, userId, formData);
    if (!data.error) {
      handleCloseModal();
      dispatch(actions.forceUpdateTable(true));
    }
  });

  const handleAddCertificate = id => {
    dispatch(
      modalActions.openModal(
        {
          size: 'medium',
          title: t('trainingDetails.addCertificateModalTitle'),
        },
        <SendFileForm
          fieldName="certificate"
          onSubmitForm={value => addFile(id, value)}
          rejectAction={() => {
            dispatch(modalActions.closeModal());
          }}
          submitting={addFileState.loading}
          fileFormat=".pdf"
        />,
      ),
    );
  };

  const [stateStudentCourseFailed, setStudentCourseToFailed] = useAsyncFn(async userId => {
    const data = await setCourseToFailed(courseId, userId);
    if (!data.error) {
      handleCloseModal();
      dispatch(actions.forceUpdateTable(true));
    }
    return data;
  });

  const [stateStudentCoursePassed, setStudentCourseToPassed] = useAsyncFn(async userId => {
    const data = await setCourseToPerformed(courseId, userId);
    if (!data.error) {
      handleCloseModal();
      dispatch(actions.forceUpdateTable(true));
    }
    return data;
  });

  const handleToggleState = (userId, status) => {
    let fn;
    let title;
    let message;
    if (status === 'failed') {
      fn = setStudentCourseToFailed;
      title = t('students.modalFailedTitle');
      message = t('students.modalFailedMessage');
    } else if (status === 'passed') {
      fn = setStudentCourseToPassed;
      title = t('students.modalPassedTitle');
      message = t('students.modalPassedMessage');
    }
    const [config, element] = getConfirmationModalConfig({
      title,
      confirmAction: () => fn(userId, status),
      rejectAction: handleCloseModal,
      message,
    });
    dispatch(modalActions.openModal(config, element));
  };

  const handleShowCertificate = (userId, certificateUrl) => {
    let picture;
    if (certificateUrl) {
      picture = <img src={certificateUrl} width="200" height="300" alt="" />;
    } else {
      picture = (
        <Icon color="primary" style={{ fontSize: 150 }}>
          image
        </Icon>
      );
    }
    dispatch(
      modalActions.openModal(
        {
          size: 'large',
          title: t('trainingDetails.certificateModalTitle'),
          customStyles: classes.modal,
        },
        <div className={classes.wrapper}>
          {picture}
          <div className={classes.btnWrapper}>
            <Button
              className={classes.btn}
              variant="contained"
              color="primary"
              size="large"
              onClick={() => {
                downloadCourseCertificate(courseId, userId);
                handleCloseModal();
              }}
            >
              {t('buttons.save')}
            </Button>
            <Button className={classes.btn} variant="outlined" size="large" onClick={handleCloseModal}>
              {t('buttons.cancel')}
            </Button>
            {role !== ROLES.TRAINER && (
              <Button
                className={`${classes.btn} ${classes.btnDelete}`}
                variant="contained"
                color="error"
                size="large"
                onClick={() => deleteCourseCertificate(courseId, userId)}
              >
                {t('buttons.delete')}
              </Button>
            )}
          </div>
        </div>,
      ),
    );
  };

  const tableDataExtended = tableData.data.map(item => ({
    ...item,
    certificateParams: {
      certificate: item.certificate,
      isPerformedWithSuccess: item.studentPerformedState ? item.studentPerformedState.isPerformedWithSuccess : null,
      isFrozen,
    },
    performParams: {
      role: item.role,
      isPerformedWithSuccess: item.studentPerformedState ? item.studentPerformedState.isPerformedWithSuccess : null,
      isFrozen,
    },
  }));

  const tableConfig = getStudentsConfig({ handleToggleState, handleAddCertificate, handleShowCertificate });
  const [columns, students] = transformTableData(tableConfig, tableDataExtended);

  useEffect(() => {
    tableData.handleChangeFetchMethod(pageAlias, getCourseStudents.bind(null, courseId));
  }, []);

  const isLoading =
    tableData.isFetchingTableData || stateStudentCourseFailed.loading || stateStudentCoursePassed.loading;

  return (
    <>
      <AppTable
        columns={columns}
        data={students}
        totalCount={tableData.totalCount}
        rowsPerPage={tableData.pageSize}
        isLazyLoading
        onChangePage={tableData.handleChangePage}
        actionsAlignment="center"
      />
      {isLoading && <Loader />}
    </>
  );
};

StudentsTable.propTypes = {
  tableData: PropTypes.shape(withTableProps).isRequired,
  courseId: PropTypes.string.isRequired,
  isFrozen: PropTypes.bool.isRequired,
};

export default withTable({
  defaultSorting: {
    field: 'id',
    dir: 'desc',
  },
  tablePageSize: 5,
  fetchingData: 'items',
  pageAlias,
})(StudentsTable);

import React from 'react';
import PropTypes from 'prop-types';
import AppTable from 'components/AppTable';
import Loader from 'components/Loader';
import withTableProps from 'HOC/types/withTable';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { transformTableData, getConfirmationModalConfig } from 'utils';
import { useAsyncFn } from 'react-use';
import { actions as modalActions } from 'redux/modal';
import { actions as tableActions } from 'redux/table';
import { deleteUser, getUser, updateUser } from 'http/users';
import { useDispatch } from 'react-redux';
import { getTableConfig } from './helpers/getTableConfig';

const TrainersTable = ({ activeTab, routesAliases, tableData }) => {
  const { t } = useTranslation('common');
  const dispatch = useDispatch();
  const history = useHistory();

  const handleCloseModal = () => dispatch(modalActions.closeModal());

  const [stateActiveTrainer, toggleTrainerState] = useAsyncFn(async (id, isCompliant) => {
    const userData = await getUser(id);

    let updatedUserData;

    if (!userData.error) {
      updatedUserData = {
        stationsIds: userData.stations.map(item => item.id),
        email: userData.email,
        firstName: userData.info.firstName,
        lastName: userData.info.lastName,
        isComplaint: isCompliant,
        isExternal: userData.trainerStatus.isExternal,
        courseTemplateIds: userData.courseTemplates.map(item => item.id),
      };
    }
    const updatedUserDataStatus = await updateUser(updatedUserData, userData.id);

    if (!updatedUserDataStatus.error) {
      handleCloseModal();
      tableData.handleForceUpdate();
    }
  });

  const handleToggleState = event => {
    event.stopPropagation();
    const id = event.currentTarget.dataset.key;
    const isEnable = JSON.parse(event.currentTarget.dataset.val);
    const message = isEnable ? t('trainers.deactivationApproval') : t('trainers.activationApproval');

    const [config, element] = getConfirmationModalConfig({
      title: t('trainers.modalEditTitle'),
      confirmAction: () => toggleTrainerState(id, !isEnable),
      rejectAction: handleCloseModal,
      message,
    });
    dispatch(modalActions.openModal(config, element));
  };

  const tableConfig = getTableConfig({ handleToggleState, activeTab });

  const [stateDeleteTrainer, deleteTrainer] = useAsyncFn(async companyId => {
    const responseData = await deleteUser(companyId);

    if (!responseData.error) {
      handleCloseModal();
      dispatch(tableActions.setTableDeleting(true));
      tableData.handleForceUpdate();
    }
  }, []);

  const handleDeleteTrainers = id => {
    const [config, element] = getConfirmationModalConfig({
      title: t('trainers.modalDeleteTitle'),
      confirmAction: () => deleteTrainer(id),
      rejectAction: handleCloseModal,
      message: t('trainers.deleteApproval'),
    });
    dispatch(modalActions.openModal(config, element));
  };

  const { data, totalCount, pageSize, handleChangePage, handleSorting, isFetchingTableData } = tableData;
  const [columns, users] = transformTableData(tableConfig, data);
  const isLoading = stateDeleteTrainer.loading || stateActiveTrainer.loading || isFetchingTableData;

  return (
    <>
      <AppTable
        columns={columns}
        data={users}
        totalCount={totalCount}
        rowsPerPage={pageSize}
        onChangePage={handleChangePage}
        onColumnSorting={handleSorting}
        currentPage={tableData.page}
        isLazyLoading
        actions={[
          {
            type: 'icon',
            icon: 'edit',
            onClick: event => {
              history.push(`${routesAliases.editTrainer}/${event.currentTarget.dataset.key}`, {
                trainerType: activeTab,
              });
            },
          },
          {
            type: 'icon',
            icon: 'delete',
            color: 'error',
            onClick: event => handleDeleteTrainers(event.currentTarget.dataset.key),
          },
        ]}
      />
      {isLoading && <Loader />}
    </>
  );
};

TrainersTable.propTypes = {
  activeTab: PropTypes.string.isRequired,
  routesAliases: PropTypes.shape({
    editTrainer: PropTypes.string,
  }).isRequired,
  tableData: PropTypes.shape(withTableProps).isRequired,
};

export default TrainersTable;

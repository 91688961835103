import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Form, Field } from 'react-final-form';
import { Grid, Box, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CustomTextField from 'components/Forms/TextField';
import CustomSelect from 'components/Forms/CustomSelect';
import { useAsyncFn } from 'react-use';
import { getJobsTasks } from 'http/jobsTasks';
import Loader from 'components/Loader';
import { transformDataToSelectOptions, generateErrorObject } from 'utils';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import configErrors from './configErrors';
import validate from './validation';
import { mapExpirationMonth } from './helpers';

const useStyles = makeStyles(() => ({
  btnContainer: {
    marginTop: '40px',
  },
  btn: {
    minWidth: '140px',
  },
  gridContainer: {
    marginTop: '40px',
  },
}));

const CourseTemplateForm = ({
  formType,
  onSubmitForm,
  submitting: formSubmitting,
  initialValues,
  backUrl,
}) => {
  const history = useHistory();
  const classes = useStyles();
  const { t } = useTranslation('common');
  const [formControlsOptions, setFormControlsOptions] = useState({
    jobsTasks: [],
  });
  const [formData, setFormData] = useState({});

  const [formControlsState, getFormControlsOptions] = useAsyncFn(async () => {
    const jobsTasks = await getJobsTasks();
    if (jobsTasks.items) {
      setFormControlsOptions({
        ...formControlsOptions,
        jobsTasks: transformDataToSelectOptions(jobsTasks.items, 'id', 'name'),
      });
    }
  });

  useEffect(() => {
    getFormControlsOptions();
  }, []);

  useEffect(() => {
    if (formType === 'edit' && initialValues) {
      setFormData({
        ...initialValues,
        expirationMonthCount: mapExpirationMonth(
          initialValues.expirationMonthCount,
        ),
      });
    }
  }, [initialValues]);

  const handleSubmitForm = async values => {
    const transformedFormData = {
      ...values,
      jobsTasksIds: values.jobsTasks.map(jobTask => jobTask.value),
      name: values.name.trim(),
      expirationMonthCount: values.expirationMonthCount.value,
    };
    delete transformedFormData.jobsTasks;

    const submitData = await onSubmitForm(transformedFormData);
    if (submitData && submitData.error && submitData.message) {
      return generateErrorObject(submitData.message, configErrors);
    }

    return undefined;
  };

  const isLoading = formControlsState.loading || formSubmitting;

  return (
    <Form
      onSubmit={handleSubmitForm}
      initialValues={formData}
      validate={validate}
      render={({ handleSubmit, submitting, form }) => {
        return (
          <form onSubmit={handleSubmit}>
            <Grid container className="mainSection">
              <Grid container justify="flex-start">
                <Grid item xs={4}>
                  <Box mr={2} mb={2}>
                    <Field
                      type="text"
                      id="name"
                      name="name"
                      label={t('formLabels.courseName')}
                      disabled={submitting}
                      variant="outlined"
                      fullWidth
                      component={CustomTextField}
                      format={(value = '') => value.trim()}
                      formatOnBlur
                    />
                  </Box>
                </Grid>
                <Grid item xs={5}>
                  <Box mr={2} mb={2}>
                    <Field
                      type="text"
                      id="jobsTasks"
                      name="jobsTasks"
                      label={t('formLabels.jobsTasks')}
                      variant="outlined"
                      placeholder={t('placeholders.chooseJobTask')}
                      options={formControlsOptions.jobsTasks}
                      fullWidth
                      isMulti
                      component={CustomSelect}
                    />
                  </Box>
                </Grid>
              </Grid>
              <Grid container justify="flex-start">
                <Grid item xs={4}>
                  <Box mr={2}>
                    <Field
                      type="text"
                      id="courseSystemId"
                      name="courseSystemId"
                      label={t('formLabels.courseId')}
                      variant="outlined"
                      fullWidth
                      component={CustomTextField}
                    />
                  </Box>
                </Grid>
                <Grid item xs={5}>
                  <Box mr={2}>
                    <Field
                      type="text"
                      id="expirationMonthCount"
                      name="expirationMonthCount"
                      label={t('formLabels.validityMonth')}
                      variant="outlined"
                      placeholder={t('placeholders.chooseExpirationMonthCount')}
                      options={[
                        { label: '12', value: 12 },
                        { label: '24', value: 24 },
                        { label: '36', value: 36 },
                        { label: 'Infinity', value: 0 },
                      ]}
                      fullWidth
                      component={CustomSelect}
                    />
                  </Box>
                </Grid>
              </Grid>
              <Grid
                container
                justify="space-between"
                className={classes.gridContainer}
              >
                <Grid item xs={9}>
                  <Box mr={2}>
                    <Field
                      type="text"
                      id="description"
                      name="description"
                      label={t('formLabels.description')}
                      variant="outlined"
                      fullWidth
                      multiline
                      rows="4"
                      component={CustomTextField}
                      format={(value = '') => value.trim()}
                      formatOnBlur
                    />
                  </Box>
                </Grid>
              </Grid>
            </Grid>
            <Grid container spacing={3} className={classes.btnContainer}>
              <Grid item xs={6}>
                <Box textAlign="right">
                  <Button
                    className={classes.btn}
                    type="submit"
                    color="primary"
                    variant="contained"
                    disabled={submitting}
                    size="large"
                  >
                    {t('buttons.save')}
                  </Button>
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box textAlign="left">
                  <Button
                    className={classes.btn}
                    variant="outlined"
                    size="large"
                    type="reset"
                    disabled={submitting}
                    onClick={() => {
                      history.push(backUrl);
                    }}
                  >
                    {t('buttons.cancel')}
                  </Button>
                </Box>
              </Grid>
            </Grid>

            {isLoading && <Loader />}
          </form>
        );
      }}
    />
  );
};

CourseTemplateForm.defaultProps = {
  formType: 'create',
  initialValues: null,
};

CourseTemplateForm.propTypes = {
  formType: PropTypes.string,
  onSubmitForm: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  initialValues: PropTypes.shape({
    expirationMonthCount: PropTypes.number,
  }),
  backUrl: PropTypes.string.isRequired,
};

export default CourseTemplateForm;

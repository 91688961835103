import React from 'react';
import PropTypes from 'prop-types';
import { Backdrop, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import calculateStatisticsPercents from './helpers/calculateStatisticsPercents';

const useStyles = makeStyles(theme => ({
  root: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: 'transparent',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    position: 'absolute',
    top: ({ y }) => `${y}px`,
    right: ({ x, innerWidth }) => `${innerWidth - x}px`,
    transform: 'translateX(50%)',
    maxWidth: '500px',
    maxHeight: '50vh',
    overflow: 'auto',
    borderRadius: '20px',
    padding: '30px',
    justifyContent: 'space-between',
    alignItems: 'center',
    boxShadow: `${theme.shadows[1]} !important`,
    overscrollBehavior: 'contain',
  },
  item: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '20px',
    fontFamily: theme.typography.fontFamily,
    fontSize: 14,
    width: '100%',
    '&:last-child': {
      marginBottom: 0,
    },
  },
  circle: {
    width: '20px',
    height: '20px',
    borderRadius: '50%',
    marginRight: '10px',
  },
}));

const Tooltip = ({ x, y, donutData, isOpen, closeTooltip, shouldPercent }) => {
  const classes = useStyles({ x, y, innerWidth: window.innerWidth, innerHeight: window.innerHeight });

  return (
    <Backdrop
      className={classes.root}
      open={isOpen}
      onClick={() => {
        closeTooltip(x, y);
      }}
    >
      <Paper className={classes.content}>
        {donutData
          .filter(data => !data.withExtInfo)
          .map(data => {
            return (
              <div key={data.legendTitle} className={classes.item}>
                <div className={classes.circle} style={{ backgroundColor: data.fillColor }} />
                <span style={{ color: data.fillColor }}>
                  {`${data.legendTitle} - ${
                    shouldPercent ? calculateStatisticsPercents(donutData, data.value) : data.value
                  } ${shouldPercent ? '%' : ''}`}
                </span>
              </div>
            );
          })}
      </Paper>
    </Backdrop>
  );
};

Tooltip.propTypes = {
  x: PropTypes.number.isRequired,
  y: PropTypes.number.isRequired,
  isOpen: PropTypes.bool.isRequired,
  closeTooltip: PropTypes.func.isRequired,
  shouldPercent: PropTypes.bool.isRequired,
  donutData: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.number,
      label: PropTypes.string,
      legendTitle: PropTypes.string,
      fillColor: PropTypes.string,
      hideLabel: PropTypes.bool,
      withExtInfo: PropTypes.bool,
    }),
  ).isRequired,
};

export default Tooltip;

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import { Table } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { actions as tableActions } from 'redux/table';
import AppTableBody from './AppTableBody';
import AppTableHead from './AppTableHead';
import AppTableFooter from './AppTableFooter';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    padding: '10px',
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  tableHeader: {
    fontSize: '30px',
    padding: '10px 20px',
    borderBottom: `1px solid ${theme.palette.text.secondary}`,
    textTransform: 'uppercase',
  },
}));

const AppTable = props => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const {
    columns,
    data,
    currentPage,
    rowsPerPage,
    isLazyLoading,
    totalCount,
    title,
    onChangePage,
    actions,
    alignment,
    actionsAlignment,
    onColumnSorting,
    classes: customClasses,
    systemField,
    extraActionsKeys,
  } = props;

  const { isDeleting } = useSelector(state => state.table);

  const [page, setPage] = React.useState(currentPage || 0);

  const tableClasses = {
    root: classNames({
      [classes.root]: true,
      [customClasses.root]: !!customClasses.root,
    }),
    tableHeader: classNames({
      [classes.tableHeader]: true,
      [customClasses.tableHeader]: !!customClasses.tableHeader,
    }),
    tableWrapper: classNames({
      [classes.tableWrapper]: true,
      [customClasses.tableWrapper]: !!customClasses.tableWrapper,
    }),
  };

  const totalColumns = actions && actions.length ? columns.length + 1 : columns.length;

  const tableData = isLazyLoading ? data : data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  const emptyRows = isLazyLoading
    ? rowsPerPage - data.length
    : rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    if (onChangePage) {
      onChangePage(newPage);
    }
  };

  useEffect(() => {
    setPage(currentPage || 0);
  }, [currentPage]);

  useEffect(() => {
    if (isLazyLoading) {
      if (isDeleting && data && data.length === 0 && page !== 0) {
        setPage(page - 1);
        dispatch(tableActions.setTablePage(page - 1));
        dispatch(tableActions.setTableDeleting(false));
      }
    } else if (isDeleting && data && page !== 0 && currentPage !== page) {
      setPage(page - 1);
      dispatch(tableActions.setTablePage(page - 1));
      dispatch(tableActions.setTableDeleting(false));
    }
  }, [data]);

  return (
    <div className={tableClasses.root}>
      {title && (
        <div className={tableClasses.tableHeader}>
          <span>{title}</span>
        </div>
      )}

      <div className={tableClasses.tableWrapper}>
        <Table>
          <AppTableHead
            actions={actions}
            alignment={alignment}
            actionsAlignment={actionsAlignment}
            columns={columns}
            onColumnSorting={onColumnSorting}
          />
          <AppTableBody
            actions={actions}
            columns={columns}
            data={tableData}
            alignment={alignment}
            columnsNumber={totalColumns}
            emptyRows={emptyRows}
            actionsAlignment={actionsAlignment}
            systemField={systemField}
            extraActionsKeys={extraActionsKeys}
          />
          <AppTableFooter
            onChangePage={handleChangePage}
            page={page}
            totalCount={totalCount}
            rowsPerPage={rowsPerPage}
          />
        </Table>
      </div>
    </div>
  );
};

AppTable.defaultProps = {
  onChangePage: () => {},
  rowsPerPage: 5,
  isLazyLoading: false,
  title: '',
  alignment: 'left',
  actionsAlignment: 'right',
  actions: [],
  onColumnSorting: () => {},
  classes: {},
  systemField: 'id',
  currentPage: 0,
  extraActionsKeys: [],
};

AppTable.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      field: PropTypes.string.isRequired,
      isSorting: PropTypes.bool,
    }),
  ).isRequired,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string,
      title: PropTypes.string,
      icon: PropTypes.string,
      color: PropTypes.string,
      onClick: PropTypes.func,
      disabled: PropTypes.bool,
    }),
  ),
  alignment: PropTypes.string,
  actionsAlignment: PropTypes.string,
  totalCount: PropTypes.number.isRequired,
  onChangePage: PropTypes.func,
  rowsPerPage: PropTypes.number,
  isLazyLoading: PropTypes.bool,
  title: PropTypes.string,
  onColumnSorting: PropTypes.func,
  classes: PropTypes.shape({
    root: PropTypes.string,
    tableHeader: PropTypes.string,
    tableWrapper: PropTypes.string,
  }),
  systemField: PropTypes.string,
  currentPage: PropTypes.number,
  extraActionsKeys: PropTypes.arrayOf(PropTypes.string),
};

export default AppTable;

const configErrors = {
  'courses.maxStudents': {
    maxStudents: 'errors.courseMaxStudentNotNatural',
  },
  'course.badTrainer': {
    trainers: 'errors.trainerIsInvalid',
  },
  'courses.endTime': {
    endTime: 'errors.endTimeInvalid',
  },
};

export default configErrors;

import i18next from 'i18next';
import trimStrByDotCount from './trimStrByDotCount';

const generateErrorObject = (message, config) => {
  let messageArr = [];
  if (Array.isArray(message)) {
    messageArr = [...message];
  } else if (message) {
    messageArr.push(message);
  }

  messageArr = messageArr.map(item => trimStrByDotCount(item, 2));

  const errors = {};

  Object.keys(config).forEach(item => {
    if (messageArr.includes(item)) {
      const errKey = Object.keys(config[item])[0];
      const errVal = i18next.t(Object.values(config[item])[0]);
      errors[errKey] = errVal;
    }
  });

  return errors;
};

export default generateErrorObject;

import moment from 'moment';

const parseDate = date => ({
  hour: moment(date).hour(),
  minute: moment(date).minute(),
  day: moment(date).date(),
  month: moment(date).month(),
  year: moment(date).year(),
});

const transformToSchedulerData = data => {
  return !Array.isArray(data)
    ? []
    : data.map(item => {
        const { startTime, endTime, startDate, endDate, courseType, id, description, name } = item;
        const { hour: startHour, minute: startMinute } = parseDate(`${startDate} ${startTime}`);
        const { hour: endHour, minute: endMinute } = parseDate(`${endDate} ${endTime}`);
        const { year: startYear, month: startMonth, day: startDay } = parseDate(startDate);
        const { year: endYear, month: endMonth, day: endDay } = parseDate(endDate);
        const title = name;

        return {
          id,
          type: courseType,
          start: new Date(
            moment()
              .year(startYear)
              .month(startMonth)
              .date(startDay)
              .hour(startHour)
              .minute(startMinute),
          ),
          end: new Date(
            moment()
              .year(endYear)
              .month(endMonth)
              .date(endDay)
              .hour(endHour)
              .minute(endMinute),
          ),
          desc: description,
          title,
          courseInfo: item,
        };
      });
};

export default transformToSchedulerData;

/* eslint-disable no-param-reassign */
import Joi from '@hapi/joi';
import i18next from 'i18next';

const schema = Joi.object({
  file: Joi.string().required(),
  deliveredBy: Joi.string()
    .min(2)
    .max(30)
    .required(),
  dateOfDelivery: Joi.date().required(),
  expiryDate: Joi.date().required(),
  courseTemplateId: Joi.object({
    label: Joi.string().required(),
    value: Joi.number().required(),
  }).required(),
});

const validate = values => {
  const { error } = schema.validate(values, { abortEarly: false });
  if (!error) {
    return {};
  }

  const { details } = error;

  return details.reduce((errors, detail) => {
    const errorsMapping = {
      'any.required': i18next.t('errors.fieldIsRequired'),
      'array.base': i18next.t('errors.fieldIsRequired'),
      'string.empty': i18next.t('errors.fieldIsRequired'),
      'string.min': i18next.t('errors.fieldIsTooShort'),
      'string.max': i18next.t('errors.fieldIsTooLong'),
    };

    errors[detail.context.key] = errorsMapping[detail.type] || detail.message;
    return errors;
  }, {});
};

export default validate;

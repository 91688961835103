import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  subtitle: {
    fontSize: '18px',
    lineHeight: '21px',
    fontWeight: 700,
    marginBottom: '24px',
  },
  btnContainer: {
    marginTop: '60px',
    marginBottom: 0,
  },
  btn: {
    minWidth: '140px',
  },
  userInfo: {
    width: 'calc(50% - 15px)',
    marginRight: '15px',
  },
  userInfoAddition: {
    width: 'calc(50% - 15px)',
    marginLeft: '15px',
  },
  generalInfo: {
    flexBasis: '65%',
  },
  stationsInfo: {
    paddingLeft: '44px',
    flexBasis: '35%',
  },
  customRadio: {
    '& .MuiFormControlLabel-root': {
      flexBasis: '30%',
    },
    '& .MuiFormLabel-root': {
      visibility: 'hidden',
    },
  },
}));

export default useStyles;

import { STATISTIC_BY_EXPIRE_DATE_COLORS } from 'appConstants';

const makeExtendedData = (data = []) => {
  const extendedData = data.map(item => ({
    ...item,
    fullName: `${item.fristName} ${item.lastName}`,
    coloredData: {
      expirationDate: item.expirationDate,
      color: STATISTIC_BY_EXPIRE_DATE_COLORS[item.expirationType]
        ? STATISTIC_BY_EXPIRE_DATE_COLORS[item.expirationType]
        : 'none',
    },
  }));
  return extendedData;
};

export default makeExtendedData;

export { default as transformTableData } from './transformTableData';
export { default as getSubdomain } from './getSubdomain';
export { default as transformDataToSelectOptions } from './transformDataToSelectOptions';
export { default as transformDataCountriesToSelectOptions } from './transformDataCountriesToSelectOptions';
export { default as getConfirmationModalConfig } from './getConfirmationModalConfig';
export { default as hexToRGBA } from './hexToRGBA';
export { default as transformToStatisticsByExpireDate } from './transformToStatisticsByExpireDate';
export { default as transformToStatisticsByCourses } from './transformToStatisticsByCourses';
export { default as transformToPastByCourses } from './transformToPastByCourses';
export { default as generateColor } from './generateColor';
export { default as generateErrorObject } from './generateErrorObject';

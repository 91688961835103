import React from 'react';
import AppTable from 'components/AppTable';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { transformTableData } from 'utils';
import Loader from 'components/Loader';
import withTableProps from 'HOC/types/withTable';
import { useTranslation } from 'react-i18next';
import getTableConfig from './helpers/getTableConfig';

const CoursesTable = ({ routesAliases, tableData }) => {
  const tableConfig = getTableConfig();
  const history = useHistory();
  const [columns, courses] = transformTableData(tableConfig, tableData.data);
  const { t } = useTranslation('common');

  const isLoading = tableData.isFetchingTableData;

  return (
    <>
      <AppTable
        columns={columns}
        data={courses}
        totalCount={tableData.totalCount}
        rowsPerPage={tableData.pageSize}
        onChangePage={tableData.handleChangePage}
        onColumnSorting={tableData.handleSorting}
        currentPage={tableData.page}
        isLazyLoading
        extraActionsKeys={['stationId']}
        actions={[
          {
            title: t('buttons.view'),
            onClick: event => {
              const { stationid: stationId, key } = event.currentTarget.dataset;
              let courseType = 'global';
              if (stationId) {
                courseType = 'local';
              }
              history.push(`${routesAliases.viewTrainerCourse}/${key}/${courseType}/${stationId || 'external'}`);
            },
          },
        ]}
      />
      {isLoading && <Loader />}
    </>
  );
};

CoursesTable.propTypes = {
  routesAliases: PropTypes.shape({
    viewTrainerCourse: PropTypes.string,
  }).isRequired,
  tableData: PropTypes.shape(withTableProps).isRequired,
};
export default CoursesTable;

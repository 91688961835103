/* eslint-disable no-restricted-syntax */
import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';

import useStyles from './styles';
import Thumbnail from './thumbnail';

const Dropzone = ({ accept, name, multiple, initSrc, onChange, maxSize, minSize, onDelete }) => {
  const classes = useStyles();
  const { t } = useTranslation('common');

  const initValue = [];
  if (initSrc.length) {
    initSrc
      .filter(el => !!el)
      .forEach((item, index) => {
        initValue.push({
          path: `${index}-${item}`,
          preview: item,
        });
      });
  }

  const [files, setFiles] = useState(initValue);
  const [errorMessages, setErrorMessages] = useState([]);
  let hasPicture = !!files.length;

  const getErrorsArr = (rejectedFilesParam, acceptedFiles) => {
    if (!multiple && rejectedFilesParam.length > 1) {
      if (acceptedFiles.length === 0) {
        return [t('upload.fileOnlyOne')];
      }
      return [];
    }

    const multiAccepting = accept.includes('/*');
    const messageArr = rejectedFilesParam.map(item => {
      const message = [];

      if (item.size > maxSize) {
        message.push(t('upload.fileTooBig', { name: item.name, size: maxSize / 1024 / 1024 }));
      } else if (item.size < minSize) {
        message.push(t('upload.fileTooSmall', { name: item.name }));
      }

      if (multiAccepting) {
        if (item.type.substring(0, item.type.indexOf('/')) !== accept.substring(0, accept.indexOf('/'))) {
          message.push(t('upload.fileUnacceptedType', { name: item.name }));
        }
      }

      const uploadedFileName = item.name.match(/\.(\w{2,32})$/);

      if (!accept.includes(uploadedFileName[1])) {
        message.push(t('upload.fileUnacceptedType', { name: item.name }));
      }

      return message;
    });

    return messageArr.length ? messageArr : [];
  };

  const onDrop = useCallback((acceptedFiles, rejectedFiles) => {
    const resultArr = acceptedFiles.map(file => ({
      ...file,
      ...{ preview: URL.createObjectURL(file) },
    }));
    setFiles(resultArr);
    onChange(acceptedFiles);
    setErrorMessages(getErrorsArr(rejectedFiles, acceptedFiles));
  }, []);

  const { getRootProps, getInputProps, isDragActive, isDragReject, isDragAccept } = useDropzone({
    accept,
    maxSize,
    minSize,
    onDrop,
    multiple,
  });

  const handleDelete = e => {
    const fileIndex = e.currentTarget.dataset.index;
    const updatedFiles = [...files];
    updatedFiles.splice(fileIndex, 1);
    setFiles(updatedFiles);
    onChange();
    setErrorMessages([]);
    onDelete();
  };

  const thumbnails = files.map((file, index) => (
    <Thumbnail
      file={file}
      key={`thumbnail-${Date.now()}`}
      index={`thumbnail-${index}`}
      handleDelete={handleDelete}
      isMultipleFiles={false}
    />
  ));

  useEffect(
    () => () => {
      hasPicture = !!files.length;
      files.forEach(file => URL.revokeObjectURL(file.preview));
    },
    [files],
  );

  const dropzoneAreaClasses = classnames({
    [classes.dropzoneArea]: true,
    [classes.dropzoneAreaSingle]: !multiple && hasPicture,
    [classes.dropzoneWithFiles]: hasPicture,
    [classes.dropzoneWithoutFiles]: !hasPicture,
    [classes.dropzoneAreaActive]: isDragActive,
    [classes.dropzoneAreaAccept]: isDragAccept,
    [classes.dropzoneAreaReject]: isDragReject,
  });

  const dropzonePreviewClasses = classnames({
    [classes.dropzonePreview]: true,
    [classes.dropzonePreviewSingle]: !multiple && hasPicture,
  });

  const dropzoneLabel = files.length ? t('formLabels.fileSelected') : t('formLabels.dropFileHere');
  const formats = t('formLabels.uploadFile', { format: accept });

  return (
    <>
      <div className={classes.dropzone}>
        <div {...getRootProps()}>
          <input className={classes.dropzoneInput} {...getInputProps({ multiple, name })} />
          <div className={dropzoneAreaClasses}>
            <svg className={classes.dropzoneIcon} width="36" height="36" fill="none" viewBox="0 0 36 36">
              <path
                fill="#A3C1DD"
                // eslint-disable-next-line max-len
                d="M20.813 26.999h-5.625A1.684 1.684 0 0113.5 25.31V13.497H7.334c-1.252 0-1.878-1.512-.992-2.398L17.037.396a1.362 1.362 0 011.92 0l10.7 10.703c.887.886.261 2.398-.99 2.398H22.5V25.31c0 .935-.752 1.688-1.688 1.688zM36 26.436v7.876c0 .936-.752 1.688-1.688 1.688H1.688A1.684 1.684 0 010 34.312v-7.876c0-.935.752-1.688 1.688-1.688h9.562v.563a3.94 3.94 0 003.938 3.938h5.624a3.94 3.94 0 003.938-3.938v-.563h9.563c.935 0 1.687.753 1.687 1.688zm-8.719 6.188a1.41 1.41 0 00-1.406-1.406 1.41 1.41 0 00-1.406 1.406 1.41 1.41 0 001.406 1.407 1.41 1.41 0 001.406-1.407zm4.5 0a1.41 1.41 0 00-1.406-1.406 1.41 1.41 0 00-1.406 1.406 1.41 1.41 0 001.406 1.407 1.41 1.41 0 001.406-1.407z"
              />
            </svg>
            {isDragActive && isDragAccept && <p className={classes.dropzoneText}>Release to drop the files here</p>}
            {isDragReject && <p className={classes.dropzoneText}>File will be rejected</p>}
            {!isDragActive && <p className={classes.dropzoneText}>{dropzoneLabel}</p>}
          </div>
        </div>
        <div className={dropzonePreviewClasses}>{thumbnails}</div>
      </div>
      {errorMessages.length ? (
        errorMessages.map(item => (
          <p key={item.message} className={classes.dropzoneError}>
            {item}
          </p>
        ))
      ) : (
        <p className={classes.dropzoneFormatHints}>{formats}</p>
      )}
    </>
  );
};

Dropzone.defaultProps = {
  multiple: true,
  accept: '',
  name: '',
  initSrc: [],
  minSize: 0,
  maxSize: 990000000,
  onDelete: () => {},
};

Dropzone.propTypes = {
  accept: PropTypes.string,
  name: PropTypes.string,
  multiple: PropTypes.bool,
  initSrc: PropTypes.arrayOf(PropTypes.string),
  onChange: PropTypes.func.isRequired,
  minSize: PropTypes.number,
  maxSize: PropTypes.number,
  onDelete: PropTypes.func,
};

export default Dropzone;

import React from 'react';
import { DateFormatter } from 'components/AppTable/CellsComponents';
import i18next from 'i18next';

const getTableConfig = () => {
  return [
    {
      title: i18next.t('tablesLabels.id'),
      field: 'courseSystemId',
    },
    {
      title: i18next.t('tablesLabels.course'),
      field: 'name',
      isSorting: true,
    },
    {
      title: i18next.t('tablesLabels.jobsTasks'),
      field: 'jobsTasks',
      Component: jobsTasks =>
        Array.isArray(jobsTasks)
          ? jobsTasks.map(item => item.name).join(', ')
          : null,
    },
    {
      title: i18next.t('tablesLabels.lastUpdate'),
      field: 'updatedAt',
      Component: value => <DateFormatter value={value} />,
      isSorting: true,
    },
    {
      title: '',
      field: 'id',
      isVisible: false,
    },
  ];
};

export default getTableConfig;

import React, { useState } from 'react';
import i18next from 'i18next';
import PropTypes from 'prop-types';
import { Form, Field } from 'react-final-form';
import { Grid, Box, Button, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Loader from 'components/Loader';
import CustomUpload from 'components/Forms/Upload';
import { useTranslation } from 'react-i18next';
import { FORM_ERROR } from 'final-form';
import validate from './validation';

const useStyles = makeStyles(() => ({
  btnContainer: {
    marginTop: '30px',
  },
  btn: {
    minWidth: '140px',
  },
}));

const getImportErrorMessage = (message, formName) => {
  let errorMessage;
  if (typeof message === 'string') {
    const rowError = message.split(`file.${formName}.row.`)[1];
    const importError = message.split(`file.${formName}.errorInImportStructure.`)[1];
    const unknownType = message === 'expirationMonth.unknownType';
    if (unknownType) {
      return i18next.t(`errors.expirationMonthUnknown`);
    }
    const combinedError = rowError || importError;
    if (combinedError) {
      const errorParts = combinedError.split('.');
      if (rowError) {
        errorMessage = i18next.t('errors.importError', {
          row: errorParts[0],
          column: errorParts[1],
        });
      } else if (importError) {
        errorMessage = i18next.t('errors.importErrorStructure', {
          row: errorParts[0],
          column: errorParts[1],
        });
      }
      if (errorParts[2]) {
        errorMessage = i18next.t('errors.importFieldWithValidationError', {
          row: errorParts[0],
          column: errorParts[1],
          error: i18next.t(`errors.${errorParts[2]}`),
        });
      }
    }
  }
  return errorMessage;
};

const SendFileForm = ({
  onSubmitForm,
  submitting: formSubmitting,
  fileFormat,
  maxFileSize,
  confirmTitle,
  rejectTitle,
  rejectAction,
  formType,
  formName,
  fieldName,
}) => {
  const classes = useStyles();
  const { t } = useTranslation('common');
  const [file, setFile] = useState();

  const handleSubmitForm = async () => {
    const responseData = await onSubmitForm({
      [fieldName || 'file']: file,
    });

    const errors = {};

    if (responseData && responseData.error) {
      if (formType === 'import') {
        const errorMessage = getImportErrorMessage(responseData.message, formName);
        if (errorMessage) {
          errors[FORM_ERROR] = errorMessage;
        } else {
          errors[FORM_ERROR] = responseData.message;
        }
      }
    }
    return errors;
  };

  const isLoading = formSubmitting;

  return (
    <Form
      onSubmit={handleSubmitForm}
      validate={validate}
      initialValues={{ file }}
      render={({ handleSubmit, submitting, form, submitError }) => {
        return (
          <form onSubmit={handleSubmit}>
            <Box width="100%" mt={5}>
              <Field
                fullWidth
                id="file"
                name={fieldName || 'file'}
                disabled={submitting}
                onChange={setFile}
                onDelete={() => form.reset()}
                component={CustomUpload}
                accept={fileFormat}
                multiple={false}
                maxSize={maxFileSize}
              />
            </Box>
            {submitError && <Typography color="error">{submitError}</Typography>}
            <Grid container spacing={3} className={classes.btnContainer}>
              <Grid item xs={6}>
                <Box textAlign="right">
                  <Button
                    className={classes.btn}
                    type="submit"
                    color="primary"
                    variant="contained"
                    disabled={submitting || (file !== undefined && file.length === 0)}
                    size="large"
                  >
                    {t(confirmTitle)}
                  </Button>
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box textAlign="left">
                  <Button
                    className={classes.btn}
                    variant="outlined"
                    size="large"
                    type="reset"
                    disabled={submitting}
                    onClick={rejectAction}
                  >
                    {t(rejectTitle)}
                  </Button>
                </Box>
              </Grid>
            </Grid>

            {isLoading && <Loader />}
          </form>
        );
      }}
    />
  );
};

SendFileForm.defaultProps = {
  maxFileSize: 52428800,
  confirmTitle: 'buttons.save',
  rejectTitle: 'buttons.cancel',
  rejectAction: () => {},
  formType: undefined,
  formName: undefined,
  fieldName: undefined,
};

SendFileForm.propTypes = {
  onSubmitForm: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  fileFormat: PropTypes.string.isRequired,
  maxFileSize: PropTypes.number,
  confirmTitle: PropTypes.string,
  rejectTitle: PropTypes.string,
  rejectAction: PropTypes.func,
  formType: PropTypes.string,
  formName: PropTypes.string,
  fieldName: PropTypes.string,
};

export default SendFileForm;

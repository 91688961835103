import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Tabulation from 'components/Tabulation';
import PageLayout from 'layouts/PageLayout';
import PageSelect from 'components/Selects/PageSelect';
import { getJobsTasks } from 'http/jobsTasks';
import SearchFilter from 'components/SearchFilter';
import withTable from 'HOC/withTable';
import withTableProps from 'HOC/types/withTable';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { routesAliases } from 'router/routes';
import {
  getGlobalCourses,
  getLocalCourses,
  deleteGlobalCourse,
  deleteLocalCourse,
  importGlobalCourses,
  importLocalCourses,
  exportGlobalCourses,
  exportLocalCourses,
  downloadImportExample,
} from 'http/courses';
import { SendFileForm } from 'components/CommonForms';
import { useAsyncFn } from 'react-use';
import { actions as modalActions } from 'redux/modal';
import { actions as tableActions } from 'redux/table';
import { useTranslation } from 'react-i18next';
import { ROLES } from 'appConstants';
import CourseTable from './CoursesTable';

const useStyles = makeStyles(() => ({
  pageSelectRoot: {
    padding: '10px 65px 10px 25px',
  },
}));

const getHelpers = stationId => {
  return {
    local: {
      getCourses: getLocalCourses.bind(null, stationId),
      deleteCourse: ({ station, id }) => deleteLocalCourse(station, id),
      exportCourses: exportLocalCourses.bind(null, stationId),
    },
    global: {
      getCourses: getGlobalCourses,
      deleteCourse: ({ id }) => deleteGlobalCourse(id),
      exportCourses: exportGlobalCourses,
    },
  };
};

const pageAlias = 'CoursesList';

let historyState;

const Courses = ({ tableData }) => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const { t } = useTranslation('common');
  const { station, role } = useSelector(state => state.auth.profile);
  const stationId = station ? station.id : undefined;

  const tabsData = [
    {
      label: t('courses.tabLocal'),
      alias: 'local',
    },
  ];

  if (role === ROLES.SUPER) {
    tabsData.unshift({
      label: t('courses.tabGlobal'),
      alias: 'global',
    });
  }

  const [helpers, setHelpers] = useState(getHelpers(stationId));
  const [activeTab, setActiveTab] = useState({
    tabIndex: 0,
    alias: tabsData[0].alias,
  });

  const progressFilterValues = [
    { label: t('courses.withoutValue'), value: undefined },
    { label: t('courses.scheduled'), value: 'scheduled' },
    { label: t('courses.inProgress'), value: 'inProgress' },
    { label: t('courses.completed'), value: 'completed' },
  ];

  const [progressFilterValue, setProgressFilterValue] = useState(progressFilterValues[0].value);

  const handleChangeProgressFilter = value => {
    setProgressFilterValue(value);
    if (value === undefined) {
      tableData.handleRemoveFilter('filter-progress');
    } else {
      tableData.handleFiltering('filter-progress', value);
    }
  };

  const createJobsTasksList = items => {
    const jobsTasksList = [
      {
        label: t('courses.allJobTasks'),
        value: undefined,
      },
    ];

    items.forEach(item =>
      jobsTasksList.push({
        label: item.name,
        value: item.id,
      }),
    );

    return jobsTasksList;
  };

  const [jobsTasksFilterValues, setJobsTasksFilterValues] = useState([{}]);
  const [jobsTasksFilterValue, setJobsTasksFilterValue] = useState(createJobsTasksList([])[0].value);

  const handleChangeJobsTasksFilter = value => {
    setJobsTasksFilterValue(value);
    if (value === undefined) {
      tableData.handleRemoveFilter('job-task-id');
    } else {
      tableData.handleFiltering('job-task-id', value);
    }
  };

  const [fetchingJobsTasks, fetchJobsTasks] = useAsyncFn(async () => {
    const data = await getJobsTasks();
    if (data.error) {
      return data;
    }
    const jobsTasks = createJobsTasksList(data.items);
    setJobsTasksFilterValues(jobsTasks);
    return data;
  });

  const handleTabChange = (event, data) => {
    dispatch(tableActions.cleanUpTable({}));
    dispatch(tableActions.setTableSorting({}));
    setActiveTab({
      tabIndex: data.tabIndex,
      alias: data.alias,
    });
    tableData.handleChangeFetchMethod(pageAlias, getHelpers(stationId)[data.alias].getCourses);
  };

  if (history.action === 'PUSH') {
    historyState = location.state && location.state.courseType;
  }

  const [importState, importCoursesFile] = useAsyncFn(async (formData, currentStationId, tab) => {
    let importResponse;
    if (tab === 'local') {
      importResponse = await importLocalCourses(currentStationId, formData);
    } else {
      importResponse = await importGlobalCourses(formData);
    }
    if (!importResponse.error) {
      dispatch(modalActions.closeModal());
      tableData.handleForceUpdate();
    }
    return importResponse;
  });

  const [exportState, exportCoursesFile] = useAsyncFn(async (currentStationId, courseType) => {
    await getHelpers(currentStationId)[courseType].exportCourses();
  });

  const [downloadExampleState, downloadCourseExample] = useAsyncFn(async currentStationId => {
    await downloadImportExample(currentStationId);
  });

  const handleExportCourses = () => {
    exportCoursesFile(stationId, activeTab.alias);
  };

  const handleDownloadExample = () => {
    downloadCourseExample(stationId);
  };

  const handleImportCourses = () => {
    dispatch(
      modalActions.openModal(
        {
          size: 'medium',
          title: t('courses.importModalTitle'),
        },
        <SendFileForm
          onSubmitForm={values => importCoursesFile({ data: values.file }, stationId, activeTab.alias)}
          formType="import"
          formName="courses"
          rejectAction={() => {
            dispatch(modalActions.closeModal());
          }}
          submitting={importState.loading}
          fileFormat=".xlsx, .csv"
        />,
      ),
    );
  };

  const handleTableSearch = (value, searchAlias) => {
    tableData.handleFiltering(searchAlias, value);
  };

  const handleTableSearchClearing = searchAlias => {
    tableData.handleRemoveFilter(searchAlias);
  };

  useEffect(() => {
    if (historyState) {
      handleTabChange(null, {
        tabIndex: tabsData.findIndex(tab => tab.alias === historyState),
        alias: historyState,
      });
      historyState = undefined;
    } else {
      setHelpers(getHelpers(stationId));
      tableData.handleChangeFetchMethod(pageAlias, getHelpers(stationId)[activeTab.alias].getCourses);
    }
  }, [station]);

  useEffect(() => {
    fetchJobsTasks();
  }, [activeTab]);

  const extraPageActions = [];

  if (role === ROLES.SUPER) {
    extraPageActions.push(
      {
        type: 'button',
        icon: 'import_export',
        title: t('commonActions.actionImport'),
        onClick: handleImportCourses,
      },
      {
        type: 'button',
        icon: 'cloud_download',
        title: t('commonActions.actionDownloadExample'),
        onClick: handleDownloadExample,
      },
    );
  }

  const tableComponent = (
    <CourseTable
      tabAlias={activeTab.alias}
      routesAliases={routesAliases}
      helpers={helpers}
      stationId={stationId}
      tableData={tableData}
    />
  );

  const isLoading =
    exportState.loading || importState.loading || downloadExampleState.loading || fetchingJobsTasks.loading;

  return (
    <PageLayout
      pageTitle={t('courses.pageTitle')}
      showLoader={isLoading}
      actionSectionLeft={[
        {
          type: 'component',
          Component: (
            <SearchFilter searchAlias="search" onSearch={handleTableSearch} onClearSearch={handleTableSearchClearing} />
          ),
        },
      ]}
      actionSectionRight={[
        {
          type: 'button',
          icon: 'add',
          title: t('courses.actionAdd', {
            type: t(`courses.${activeTab.alias}`),
          }),
          onClick: () => {
            history.push(`${routesAliases.createCourse}/${activeTab.alias}`);
          },
        },
        {
          type: 'button',
          icon: 'import_export',
          title: t('commonActions.actionExport'),
          onClick: handleExportCourses,
        },
        ...extraPageActions,
      ]}
    >
      <Tabulation
        tabsData={tabsData}
        onTabChange={handleTabChange}
        activeTab={activeTab.tabIndex}
        commonComponent={tableComponent}
        actions={[
          {
            Component: (
              <PageSelect
                classes={{ root: classes.pageSelectRoot }}
                options={progressFilterValues}
                selectedOption={progressFilterValue}
                onChangeValue={handleChangeProgressFilter}
              />
            ),
          },
          {
            Component: (
              <PageSelect
                classes={{ root: classes.pageSelectRoot }}
                options={jobsTasksFilterValues}
                selectedOption={jobsTasksFilterValue}
                onChangeValue={handleChangeJobsTasksFilter}
              />
            ),
          },
        ]}
      />
    </PageLayout>
  );
};

Courses.propTypes = {
  tableData: PropTypes.shape(withTableProps).isRequired,
};

export default withTable({
  defaultSorting: {},
  fetchingData: 'items',
  sortingFieldsMapping: {
    'updatedByUser.email': 'updatedBy',
  },
  pageAlias,
})(Courses);

import React, { useEffect } from 'react';
import AppTable from 'components/AppTable';
import PageLayout from 'layouts/PageLayout';
import withTable from 'HOC/withTable';
import PropTypes from 'prop-types';
import withTableProps from 'HOC/types/withTable';
import SearchFilter from 'components/SearchFilter';
import { routesAliases } from 'router/routes';
import { actions as modalActions } from 'redux/modal';
import { actions as tableActions } from 'redux/table';
import { SendFileForm } from 'components/CommonForms';
import {
  getCourseTemplates,
  deleteCourseTemplate,
  importTemplates,
  exportTemplates,
  downloadImportExample,
} from 'http/courseTemplates';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useAsyncFn } from 'react-use';
import { useTranslation } from 'react-i18next';
import { transformTableData, getConfirmationModalConfig } from 'utils';
import getTableConfig from './helpers/getTableConfig';

const pageAlias = 'CourseTemplatesList';

const Templates = ({ tableData }) => {
  const dispatch = useDispatch();
  const tableConfig = getTableConfig();
  const history = useHistory();
  const { t } = useTranslation();
  const [columns, templates] = transformTableData(tableConfig, tableData.data);

  const handleCloseModal = () => dispatch(modalActions.closeModal());

  const [stateDeleteTemplate, deleteTemplate] = useAsyncFn(async id => {
    const data = await deleteCourseTemplate(id);

    if (!data.error) {
      handleCloseModal();
      dispatch(tableActions.setTableDeleting(true));
      tableData.handleForceUpdate();
    } else {
      const [config, element] = getConfirmationModalConfig({
        confirmTitle: t('buttons.ok'),
        confirmAction: handleCloseModal,
        rejectAction: handleCloseModal,
        message: t('courseTemplates.cantDelete'),
      });
      dispatch(modalActions.openModal(config, element));
    }
  }, []);

  const handleDeleteTemplate = id => {
    const [config, element] = getConfirmationModalConfig({
      title: t('courseTemplates.deleteTemplateModalTitle'),
      confirmAction: () => deleteTemplate(id),
      rejectAction: handleCloseModal,
      message: t('courseTemplates.deleteTemplateConfirmationMessage'),
    });
    dispatch(modalActions.openModal(config, element));
  };

  const [exportState, exportTemplatesFile] = useAsyncFn(async () => {
    await exportTemplates();
  });

  const [downloadExampleState, downloadTemplateExample] = useAsyncFn(async currentStationId => {
    await downloadImportExample(currentStationId);
  });

  const [importState, importTemplatesData] = useAsyncFn(async formData => {
    const importResponse = await importTemplates(formData);
    if (!importResponse.error) {
      dispatch(modalActions.closeModal());
      tableData.handleForceUpdate();
    }
    return importResponse;
  });

  const handleImportTemplates = () => {
    dispatch(
      modalActions.openModal(
        {
          size: 'medium',
          title: t('courseTemplates.importModalTitle'),
        },

        <SendFileForm
          onSubmitForm={values => {
            return importTemplatesData({
              data: values.file,
            });
          }}
          rejectAction={() => {
            dispatch(modalActions.closeModal());
          }}
          formType="import"
          formName="courseTemplate"
          submitting={importState.loading}
          fileFormat=".xlsx"
        />,
      ),
    );
  };

  const handleTableSearch = (value, searchAlias) => {
    tableData.handleFiltering(searchAlias, value);
  };

  const handleTableSearchClearing = searchAlias => {
    tableData.handleRemoveFilter(searchAlias);
  };

  useEffect(() => {
    tableData.handleChangeFetchMethod(pageAlias, getCourseTemplates);
  }, []);

  const isLoading =
    tableData.isFetchingTableData || stateDeleteTemplate.loading || exportState.loading || downloadExampleState.loading;

  return (
    <PageLayout
      pageTitle={t('courseTemplates.pageTitle')}
      actionSectionLeft={[
        {
          type: 'component',
          Component: (
            <SearchFilter searchAlias="search" onSearch={handleTableSearch} onClearSearch={handleTableSearchClearing} />
          ),
        },
      ]}
      actionSectionRight={[
        {
          type: 'button',
          icon: 'add',
          title: t('courseTemplates.actionAdd'),
          onClick: () => {
            history.push(`${routesAliases.createTemplate}`);
          },
        },
        {
          type: 'button',
          icon: 'import_export',
          title: t('commonActions.actionExport'),
          onClick: exportTemplatesFile,
        },
        {
          type: 'button',
          icon: 'import_export',
          title: t('commonActions.actionImport'),
          onClick: handleImportTemplates,
        },
        {
          type: 'button',
          icon: 'cloud_download',
          title: t('commonActions.actionDownloadExample'),
          onClick: downloadTemplateExample,
        },
      ]}
      showLoader={isLoading}
    >
      <AppTable
        columns={columns}
        data={templates}
        totalCount={tableData.totalCount}
        rowsPerPage={tableData.pageSize}
        onChangePage={tableData.handleChangePage}
        onColumnSorting={tableData.handleSorting}
        currentPage={tableData.page}
        isLazyLoading
        actions={[
          {
            type: 'icon',
            icon: 'edit',
            onClick: event => {
              history.push({
                pathname: `${routesAliases.editTemplate}/${event.currentTarget.dataset.key}`,
              });
            },
          },
          {
            type: 'icon',
            icon: 'delete',
            color: 'error',
            onClick: event => {
              handleDeleteTemplate(event.currentTarget.dataset.key);
            },
          },
        ]}
      />
    </PageLayout>
  );
};

Templates.propTypes = {
  tableData: PropTypes.shape(withTableProps).isRequired,
};

export default withTable({
  defaultSorting: {
    field: 'id',
    dir: 'desc',
  },
  fetchingData: 'items',
  pageAlias,
})(Templates);

import React from 'react';
import { DateFormatter, FileManager } from 'components/AppTable/CellsComponents';
import i18next from 'i18next';

export const getCoursesPastConfig = helpers => [
  {
    title: i18next.t('tablesLabels.id'),
    field: 'id',
  },
  {
    title: i18next.t('tablesLabels.course'),
    field: 'name',
    isSorting: true,
  },
  {
    title: i18next.t('tablesLabels.date'),
    field: 'studentPerformedState.performedDate',
    Component: value => (value === null ? i18next.t('courseDetails.notSpecified') : <DateFormatter value={value} />),
    isSorting: true,
  },
  {
    title: i18next.t('tablesLabels.status'),
    field: 'studentPerformedState.isPerformedWithSuccess',
    Component: value => (value ? i18next.t('students.labelPerformed') : i18next.t('students.failed')),
    isSorting: true,
  },
  {
    title: i18next.t('tablesLabels.certificate'),
    field: 'buttonParams',
    Component: state => (
      <FileManager
        value={state}
        onChange={helpers.handleAddCertificate}
        onShowCertificate={helpers.handleShowCertificate}
      />
    ),
    isSorting: true,
  },
  {
    title: i18next.t('tablesLabels.stationId'),
    field: 'stationId',
    isVisible: false,
  },
];

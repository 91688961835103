import { handleActions, createActions } from 'redux-actions';

import initialState, * as handlers from './handlers';

export const actions = createActions({
  SET_STATIONS: undefined,
  CLEAR_STATIONS: undefined,
});

const reducer = handleActions(
  new Map([
    [actions.setStations, handlers.setStations],
    [actions.clearStations, handlers.clearStations],
  ]),
  initialState,
);

export default reducer;

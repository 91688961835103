const configErrors = {
  'password.isNotValid': {
    currentPassword: 'errors.passwordIsNotCorrect',
  },
  'password.newPassword': {
    newPassword: 'errors.passwordIsMatchPattern',
  },
  'user.firstName': {
    firstName: 'errors.firstNameIsNotMatchPattern',
  },
  'user.lastName': {
    lastName: 'errors.lastNameIsNotMatchPattern',
  },
};

export default configErrors;

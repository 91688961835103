import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import AppTable from 'components/AppTable';
import Loader from 'components/Loader';
import { useTranslation } from 'react-i18next';
import { transformTableData, getConfirmationModalConfig } from 'utils';
import { useAsyncFn } from 'react-use';
import { useSelector, useDispatch } from 'react-redux';
import { actions as modalActions } from 'redux/modal';
import { actions } from 'redux/table';

const DocumentsTable = ({ userId, helpers, configTable }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation('common');
  const { needForceUpdate, tableData, count } = useSelector(state => state.table);

  const handleCloseModal = () => dispatch(modalActions.closeModal());

  const tableConfig = configTable();
  const [columns, documents] = transformTableData(tableConfig, tableData);

  const [documentsFetching, fetchDocuments] = useAsyncFn(async id => {
    let documentsData = await helpers.getDocuments(id);
    if (!documentsData.error) {
      if (!Array.isArray(documentsData)) {
        documentsData = documentsData.items;
      }
      const documentsDataExtended = documentsData.map(item => ({
        ...item,
        byWhom: `${item.uploadedBy.info.firstName} ${item.uploadedBy.info.lastName}`,
        courseTemplateName: item.courseTemplate && item.courseTemplate.name ? item.courseTemplate.name : '',
      }));

      dispatch(
        actions.setTableData({
          data: documentsDataExtended,
          count: documentsData.length,
        }),
      );
    }
  });

  const [documentDeleting, deleteDocument] = useAsyncFn(async documentId => {
    const data = await helpers.deleteDocument(userId, documentId);
    if (!data.error) {
      handleCloseModal();
      dispatch(actions.setTableDeleting(true));
      dispatch(actions.forceUpdateTable(true));
    }
  });

  const handleDeleteDocument = event => {
    const fileId = event.currentTarget.dataset.key;
    const [config, element] = getConfirmationModalConfig({
      title: t('students.modalRemoveDocument'),
      confirmAction: () => deleteDocument(fileId),
      rejectAction: handleCloseModal,
      message: t('students.modalRemoveDocumentConfirmation'),
    });
    dispatch(modalActions.openModal(config, element));
  };

  const [documentDownloading, downloadDocument] = useAsyncFn(async documentId => {
    await helpers.downloadDocument(userId, documentId);
  });

  const handleDownloadDocument = event => {
    downloadDocument(event.currentTarget.dataset.key);
  };

  useEffect(() => {
    fetchDocuments(userId);
  }, [userId]);

  useEffect(() => {
    if (needForceUpdate === true) {
      fetchDocuments(userId);
      dispatch(actions.forceUpdateTable(false));
    }
  }, [needForceUpdate]);

  const tableActions = [
    {
      type: 'icon',
      icon: 'get_app',
      onClick: handleDownloadDocument,
    },
    {
      type: 'icon',
      icon: 'delete',
      color: 'error',
      onClick: handleDeleteDocument,
    },
  ];

  const isLoading = documentsFetching.loading || documentDeleting.loading || documentDownloading.loading;

  return (
    <>
      <AppTable columns={columns} data={documents} totalCount={count} rowsPerPage={5} actions={tableActions} />
      {isLoading && <Loader />}
    </>
  );
};

DocumentsTable.propTypes = {
  userId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  helpers: PropTypes.shape({
    getDocuments: PropTypes.func,
    deleteDocument: PropTypes.func,
    downloadDocument: PropTypes.func,
  }).isRequired,
  configTable: PropTypes.func.isRequired,
};

export default DocumentsTable;

const getPaginationArray = (page, pagesCount, pageRange = 2) => {
  if (pagesCount === 0) return [1];

  let rangeStart = page - pageRange;
  let rangeEnd = page + pageRange;
  const paginationArray = [];

  if (rangeEnd > pagesCount) {
    rangeEnd = pagesCount;
    rangeStart = pagesCount - pageRange * 2;
    rangeStart = rangeStart < 1 ? 1 : rangeStart;
  }

  if (rangeStart <= 1) {
    rangeStart = 1;
    rangeEnd = Math.min(pageRange * 2 + 1, pagesCount);
  }

  for (let i = rangeStart; i <= rangeEnd; i++) {
    paginationArray.push(i);
  }

  if (paginationArray[0] !== 1) {
    paginationArray.unshift(1);
  }

  if (paginationArray[paginationArray.length - 1] !== pagesCount) {
    paginationArray.push(pagesCount);
  }

  return paginationArray;
};

export { getPaginationArray };

import React, { useEffect } from 'react';
import AppTable from 'components/AppTable';
import PageLayout from 'layouts/PageLayout';
import PropTypes from 'prop-types';
import withTableProps from 'HOC/types/withTable';
import withTable from 'HOC/withTable';
import { actions as notificationsActions } from 'redux/notifications';
import { transformTableData } from 'utils';
import { useTranslation } from 'react-i18next';
import { getNotificationsList } from 'http/notifications';
import { useSelector, useDispatch } from 'react-redux';
import getTableConfig from './helpers/getTableConfig';

const Notifications = ({ tableData }) => {
  const dispatch = useDispatch();
  const { newNotificationsCount } = useSelector(state => state.notifications);
  const { t } = useTranslation('common');
  const tableConfig = getTableConfig();
  const [columns, notifications] = transformTableData(tableConfig, tableData.data);

  useEffect(() => {
    if (newNotificationsCount > 0) {
      tableData.handleForceUpdate();
      dispatch(notificationsActions.resetNotificationsCount());
    }
  }, [newNotificationsCount]);

  const isLoading = tableData.isFetchingTableData;
  return (
    <PageLayout pageTitle={t('notifications.pageTitle')} showLoader={isLoading}>
      <AppTable
        columns={columns}
        data={notifications}
        totalCount={tableData.totalCount}
        rowsPerPage={tableData.pageSize}
        onChangePage={tableData.handleChangePage}
        onColumnSorting={tableData.handleSorting}
        isLazyLoading
      />
    </PageLayout>
  );
};

Notifications.propTypes = {
  tableData: PropTypes.shape(withTableProps).isRequired,
};

export default withTable(
  {
    defaultSorting: {
      field: 'id',
      dir: 'desc',
    },
    fetchingData: 'items',
  },
  getNotificationsList,
)(Notifications);

import React from 'react';
import { DateFormatter } from 'components/AppTable/CellsComponents';
import i18next from 'i18next';

export const getStudentsConfig = () => [
  {
    title: i18next.t('tablesLabels.id'),
    field: 'id',
    isSorting: true,
  },
  {
    title: i18next.t('tablesLabels.name'),
    field: 'fullName',
    isSorting: true,
  },
  {
    title: i18next.t('tablesLabels.email'),
    field: 'email',
    isSorting: true,
  },
  {
    title: i18next.t('tablesLabels.startDate'),
    field: 'studentPerformedState.startDate',
    Component: value => <DateFormatter value={value} />,
    isSorting: true,
  },
  {
    title: i18next.t('tablesLabels.expiryDate'),
    field: 'studentPerformedState.externalCourseExpiredDate',
    Component: value => <DateFormatter value={value} />,
    isSorting: true,
  },
];

export const getDocumentsConfig = () => [
  {
    title: i18next.t('tablesLabels.id'),
    field: 'id',
  },
  {
    title: i18next.t('tablesLabels.name'),
    field: 'name',
  },
  {
    title: i18next.t('tablesLabels.size'),
    field: 'documentSize',
    alignment: 'center',
    Component: size => (
      <div>
        {(size / 1000000).toFixed(3)}
        &ensp; MB
      </div>
    ),
  },
  {
    title: i18next.t('tablesLabels.uploadDate'),
    field: 'createdAt',
    Component: value => <DateFormatter value={value} />,
  },
  {
    title: i18next.t('tablesLabels.byWhom'),
    field: 'byWhom',
  },
];

export const getCertificatesConfig = () => [
  {
    title: i18next.t('tablesLabels.id'),
    field: 'id',
  },
  {
    title: i18next.t('tablesLabels.courseTemplateName'),
    field: 'courseTemplateName',
  },
  {
    title: i18next.t('tablesLabels.size'),
    field: 'documentSize',
    alignment: 'center',
    Component: size => (
      <div>
        {(size / 1000000).toFixed(3)}
        &ensp; MB
      </div>
    ),
  },
  {
    title: i18next.t('tablesLabels.expiryDate'),
    field: 'expiryDate',
    Component: value => <DateFormatter value={value} />,
  },
  {
    title: i18next.t('tablesLabels.uploadDate'),
    field: 'createdAt',
    Component: value => <DateFormatter value={value} />,
  },
  {
    title: i18next.t('tablesLabels.byWhom'),
    field: 'deliveredBy',
  },
];

export const getRatingConfig = () => [
  {
    title: i18next.t('tablesLabels.courseName'),
    field: 'courseName',
  },
  {
    title: i18next.t('tablesLabels.courseFeedback'),
    field: 'courseRatingAVG',
  },
  {
    title: i18next.t('tablesLabels.trainerFeedback'),
    field: 'trainerRatingAVG',
  },
  {
    title: i18next.t('tablesLabels.startDate'),
    field: 'startDate',
    Component: value => <DateFormatter value={value} />,
  },
  {
    title: i18next.t('tablesLabels.endDate'),
    field: 'endDate',
    Component: value => <DateFormatter value={value} />,
  },
];

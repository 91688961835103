const initialState = {
  isAuthenticated: false,
  profile: null,
  companyInfo: null,
  lang: 'en',
};

export const authenticate = (state, { payload }) => ({
  ...state,
  isAuthenticated: true,
  profile: payload,
});

export const logout = state => ({
  ...state,
  isAuthenticated: false,
  profile: null,
});

export const setStation = (state, { payload }) => ({
  ...state,
  profile: {
    ...state.profile,
    station: payload,
  },
});

export const setLang = (state, { payload }) => ({
  ...state,
  lang: payload,
});

export const setProfileLang = (state, { payload }) => ({
  ...state,
  profile: {
    ...state.profile,
    lang: payload,
  },
});

export const setCompanyInfo = (state, { payload }) => ({
  ...state,
  companyInfo: payload,
});

export default initialState;

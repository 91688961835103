import React from 'react';
import PropTypes from 'prop-types';
import { FormControl, FormGroup, FormControlLabel, Checkbox } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';

const MultipleChoice = ({ value, onChange, id }) => {
  const { mainColor } = useSelector(state => state.auth.companyInfo);
  const useStyles = makeStyles(() => ({
    checkbox: {
      '& input:checked ~ .MuiSvgIcon-root': {
        color: mainColor,
      },
      '& input:checked[disabled] ~ .MuiSvgIcon-root': {
        color: 'rgba(0, 0, 100, 0.2)',
      },
    },
  }));
  const classes = useStyles();
  const { t } = useTranslation('common');

  const handleChange = event => {
    const visibilityResult = {
      ...value,
      [event.currentTarget.value]: !value[event.currentTarget.value],
    };
    delete visibilityResult.id;
    if (visibilityResult.type) {
      delete visibilityResult.type;
    }
    delete visibilityResult.isDisabled;
    onChange(id, visibilityResult, event.currentTarget.dataset.type);
  };

  return (
    <FormControl component="fieldset">
      <FormGroup row>
        <FormControlLabel
          control={
            <Checkbox
              checked={value && value.isVisibleForStudent}
              onChange={handleChange}
              value="isVisibleForStudent"
              className={classes.checkbox}
              inputProps={{ 'data-type': value && value.type }}
              disabled={value && value.isDisabled}
            />
          }
          label={t('formLabels.student')}
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={value && value.isVisibleForTrainer}
              onChange={handleChange}
              value="isVisibleForTrainer"
              className={classes.checkbox}
              inputProps={{ 'data-type': value && value.type }}
              disabled={value && value.isDisabled}
            />
          }
          label={t('formLabels.trainer')}
        />
      </FormGroup>
    </FormControl>
  );
};

MultipleChoice.defaultProps = {
  onChange: () => {},
  value: undefined,
  id: undefined,
};

MultipleChoice.propTypes = {
  value: PropTypes.shape({
    isVisibleForTrainer: PropTypes.bool,
    isVisibleForStudent: PropTypes.bool,
    isDisabled: PropTypes.bool,
    type: PropTypes.string,
  }),
  onChange: PropTypes.func,
  id: PropTypes.number,
};

export default MultipleChoice;

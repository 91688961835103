import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  langBar: {
    position: 'absolute',
    top: '20px',
    right: '50px',
  },
  wrapper: {
    margin: 'auto',
  },
}));

export default useStyles;

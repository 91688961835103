const initialState = {
  newNotificationsCount: 0,
};

export const setNewNotifications = (state, { payload }) => ({
  newNotificationsCount: payload,
});

export const resetNotificationsCount = () => initialState;

export default initialState;

import React from 'react';
import PropTypes from 'prop-types';
import { Typography, Box } from '@material-ui/core';

const TabPanel = props => {
  const { children, value, index, ...otherProps } = props;

  return value !== index ? null : (
    <Typography component="div" role="tabpanel" {...otherProps}>
      <Box mt={2}>{children}</Box>
    </Typography>
  );
};

TabPanel.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.node, PropTypes.func]).isRequired,
  value: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
};

export default TabPanel;

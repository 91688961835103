import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import AppTable from 'components/AppTable';
import PageLayout from 'layouts/PageLayout';
import SearchFilter from 'components/SearchFilter';
import withTable from 'HOC/withTable';
import withTableProps from 'HOC/types/withTable';
import { useTranslation } from 'react-i18next';
import {
  getStudents,
  deleteUser,
  getUserTasks,
  importUsers,
  exportStudents,
  downloadImportUserExample,
} from 'http/users';
import { transformTableData, getConfirmationModalConfig } from 'utils';
import { useAsyncFn } from 'react-use';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { routesAliases } from 'router/routes';
import { actions as modalActions } from 'redux/modal';
import { actions } from 'redux/table';
import { SendFileForm } from 'components/CommonForms';
import { makeStyles } from '@material-ui/core/styles';
import StudentTasksTable from './StudentTasksTable';
import getTableConfig from './helpers/getTableConfig';

const useStyles = makeStyles(() => ({
  root: {
    '& .MuiDialogContent-root': {
      minWidth: '860px',
    },
  },
}));

const pageAlias = 'StudentsList';

const Students = ({ tableData }) => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation('common');
  const { station } = useSelector(state => state.auth.profile);
  const stationId = station ? station.id : undefined;

  const handleCloseModal = () => dispatch(modalActions.closeModal());

  const [stateStudentTasks, getStudentTasks] = useAsyncFn(async studentId => {
    const data = await getUserTasks(studentId);
    if (!data.error) {
      dispatch(
        modalActions.openModal(
          {
            size: 'large',
            customStyles: classes.root,
            onClose: () => {
              dispatch(modalActions.closeModal());
              dispatch(actions.forceUpdateTable(true));
            },
          },
          <StudentTasksTable studentData={data} studentId={studentId} />,
        ),
      );
    }
  });

  const handleToggleStateModal = event => {
    event.stopPropagation();
    const id = event.currentTarget.dataset.key;
    getStudentTasks(id);
  };

  const tableConfig = getTableConfig({ handleToggleStateModal });
  const [columns, users] = transformTableData(tableConfig, tableData.data);
  const [stateDeleteStudent, deleteStudent] = useAsyncFn(async id => {
    const data = await deleteUser(id);
    if (!data.error) {
      handleCloseModal();
      dispatch(actions.setTableDeleting(true));
      tableData.handleForceUpdate();
    } else {
      const [config, element] = getConfirmationModalConfig({
        confirmTitle: t('students.confirmTitle'),
        confirmAction: handleCloseModal,
        rejectAction: handleCloseModal,
        message: t('students.deleteNotApproval'),
      });
      dispatch(modalActions.openModal(config, element));
    }
  }, []);

  const handleDeleteStudent = id => {
    const [config, element] = getConfirmationModalConfig({
      title: t('students.modalDeleteTitle'),
      confirmAction: () => deleteStudent(id),
      rejectAction: handleCloseModal,
      message: t('students.deleteApproval'),
    });
    dispatch(modalActions.openModal(config, element));
  };

  const [importState, importStudentsFile] = useAsyncFn(async (formData, currentStationId) => {
    const importResponse = await importUsers(currentStationId, 'students', formData);
    if (!importResponse.error) {
      dispatch(modalActions.closeModal());
      tableData.handleForceUpdate();
    }
    return importResponse;
  });

  const [exportState, exportStudentsFile] = useAsyncFn(async () => {
    await exportStudents();
  });

  const [downloadExampleState, downloadStudentsExample] = useAsyncFn(async currentStationId => {
    await downloadImportUserExample(currentStationId, 'students');
  });

  const handleExportStudents = () => {
    exportStudentsFile();
  };

  const handleDownloadExample = () => {
    downloadStudentsExample(stationId);
  };

  const handleImportStudents = () => {
    dispatch(
      modalActions.openModal(
        {
          size: 'medium',
          title: t('students.importModalTitle'),
        },
        <SendFileForm
          onSubmitForm={values => importStudentsFile({ data: values.file }, stationId)}
          formType="import"
          formName="students"
          rejectAction={() => {
            dispatch(modalActions.closeModal());
          }}
          submitting={importState.loading}
          fileFormat=".xlsx, .csv"
        />,
      ),
    );
  };

  const handleTableSearch = (value, searchAlias) => {
    tableData.handleFiltering(searchAlias, value);
  };

  const handleTableSearchClearing = searchAlias => {
    tableData.handleRemoveFilter(searchAlias);
  };

  useEffect(() => {
    tableData.handleChangeFetchMethod(pageAlias, getStudents);
  }, [station]);

  const isLoading =
    exportState.loading ||
    importState.loading ||
    tableData.isFetchingTableData ||
    downloadExampleState.loading ||
    stateDeleteStudent.loading ||
    stateStudentTasks.loading;

  return (
    <PageLayout
      pageTitle={t('students.pageTitle')}
      showLoader={isLoading}
      actionSectionLeft={[
        {
          type: 'component',
          Component: (
            <SearchFilter searchAlias="search" onSearch={handleTableSearch} onClearSearch={handleTableSearchClearing} />
          ),
        },
      ]}
      actionSectionRight={[
        {
          type: 'button',
          icon: 'add',
          title: t('students.actionAdd'),
          onClick: () => {
            history.push(routesAliases.createStudent);
          },
        },
        {
          type: 'button',
          icon: 'import_export',
          title: t('commonActions.actionExport'),
          onClick: handleExportStudents,
        },
        {
          type: 'button',
          icon: 'import_export',
          title: t('commonActions.actionImport'),
          onClick: handleImportStudents,
        },
        {
          type: 'button',
          icon: 'cloud_download',
          title: t('commonActions.actionDownloadExample'),
          onClick: handleDownloadExample,
        },
      ]}
    >
      <AppTable
        columns={columns}
        data={users}
        totalCount={tableData.totalCount}
        rowsPerPage={tableData.pageSize}
        onChangePage={tableData.handleChangePage}
        onColumnSorting={tableData.handleSorting}
        currentPage={tableData.page}
        isLazyLoading
        actions={[
          {
            type: 'icon',
            icon: 'edit',
            onClick: event => {
              history.push({
                pathname: `${routesAliases.editStudent}/${event.currentTarget.dataset.key}`,
              });
            },
          },
          {
            type: 'icon',
            icon: 'delete',
            color: 'error',
            onClick: event => {
              handleDeleteStudent(event.currentTarget.dataset.key);
            },
          },
        ]}
      />
    </PageLayout>
  );
};
Students.propTypes = {
  tableData: PropTypes.shape(withTableProps).isRequired,
};

export default withTable({
  defaultSorting: {
    field: 'id',
  },
  fetchingData: 'items',
  sortingFieldsMapping: {
    jobTasks: 'jobTask',
    stations: 'station',
  },
  pageAlias,
})(Students);

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import PageLayout from 'layouts/PageLayout';
import SearchFilter from 'components/SearchFilter';
import withTable from 'HOC/withTable';
import withTableProps from 'HOC/types/withTable';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { getAdmins, importUsers, exportAdminsStudents, downloadImportUserExample } from 'http/users';
import { routesAliases } from 'router/routes';
import { useAsyncFn } from 'react-use';
import { actions as modalActions } from 'redux/modal';
import { SendFileForm } from 'components/CommonForms';
import { useTranslation } from 'react-i18next';
import AdministratorsTable from './AdministratorsTable';

const pageAlias = 'Administrators';

const Administrators = ({ tableData }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { station } = useSelector(state => state.auth.profile);
  const stationId = station ? station.id : undefined;
  const { t } = useTranslation('common');

  const [importState, importAdminsFile] = useAsyncFn(async (currentStationId, formData) => {
    const importResponse = await importUsers(currentStationId, 'admins', formData);
    if (!importResponse.error) {
      dispatch(modalActions.closeModal());
      tableData.handleForceUpdate();
    }
    return importResponse;
  });

  const exportAdminsFile = async () => {
    await exportAdminsStudents();
  };

  const downloadAdminsExample = async currentStationId => {
    await downloadImportUserExample(currentStationId, 'admins');
  };

  const handleImportAdmins = () => {
    dispatch(
      modalActions.openModal(
        {
          size: 'medium',
          title: t('administrators.importModalTitle'),
        },
        <SendFileForm
          onSubmitForm={values => importAdminsFile(stationId, { data: values.file })}
          rejectAction={() => {
            dispatch(modalActions.closeModal());
          }}
          formType="import"
          formName="admins"
          submitting={importState.loading}
          fileFormat=".xlsx"
        />,
      ),
    );
  };

  const handleTableSearch = (value, searchAlias) => {
    tableData.handleFiltering(searchAlias, value);
  };

  const handleTableSearchClearing = searchAlias => {
    tableData.handleRemoveFilter(searchAlias);
  };

  useEffect(() => {
    tableData.handleChangeFetchMethod(pageAlias, getAdmins);
  }, [station]);

  return (
    <PageLayout
      pageTitle={t('administrators.pageTitle')}
      actionSectionLeft={[
        {
          type: 'component',
          Component: (
            <SearchFilter searchAlias="search" onSearch={handleTableSearch} onClearSearch={handleTableSearchClearing} />
          ),
        },
      ]}
      actionSectionRight={[
        {
          type: 'button',
          icon: 'add',
          title: t('administrators.actionAdd'),
          onClick: () => history.push(routesAliases.createAdministrator),
        },
        {
          type: 'button',
          icon: 'import_export',
          title: t('commonActions.actionExport'),
          onClick: () => exportAdminsFile(),
        },
        {
          type: 'button',
          icon: 'import_export',
          title: t('commonActions.actionImport'),
          onClick: handleImportAdmins,
        },
        {
          type: 'button',
          icon: 'cloud_download',
          title: t('commonActions.actionDownloadExample'),
          onClick: () => downloadAdminsExample(stationId),
        },
      ]}
    >
      <AdministratorsTable tableData={tableData} routesAliases={routesAliases} />
    </PageLayout>
  );
};

Administrators.propTypes = {
  tableData: PropTypes.shape(withTableProps).isRequired,
};

export default withTable({
  defaultSorting: {
    field: 'id',
    dir: 'desc',
  },
  sortingFieldsMapping: {
    'station.name': 'station',
  },
  pageAlias,
  fetchingData: 'items',
})(Administrators);

export const downloadFileFromBytes = (data, fileName, extension = '', isBlob = false) => {
  if (data) {
    let blobData;
    if (isBlob) {
      blobData = new Blob([data], { type: extension });
    } else if (Array.isArray(data)) {
      const buffer = new Uint8Array(data);
      blobData = new Blob([buffer]);
    }
    const url = window.URL.createObjectURL(blobData);
    const link = document.createElement('a');

    link.href = url;
    link.setAttribute('download', `${fileName || new Date().getTime()}${extension}`);
    document.body.appendChild(link);
    link.click();
    link.remove();
    blobData = null;
    URL.revokeObjectURL(url);
  }
};

export const getDeeperValue = (field, data) => {
  const fieldParts = field.split('.');

  if (fieldParts.length === 1) {
    if (data && !!field) {
      return data[field];
    }
    return undefined;
  }

  const currentField = fieldParts.shift();

  return getDeeperValue(fieldParts.join('.'), data[currentField]);
};

export const getFileNameFromContentDispositionString = (response, type = 'attachment') => {
  let filename = '';

  const contentDispositionHeader = response.request.getResponseHeader('Content-Disposition');

  if (contentDispositionHeader && contentDispositionHeader.indexOf(type) !== -1) {
    const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
    const matches = filenameRegex.exec(contentDispositionHeader);
    if (matches != null && matches[1]) {
      filename = matches[1].replace(/['"]/g, '');
    }
  }

  return filename;
};

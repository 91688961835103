import React from 'react';
import PropTypes from 'prop-types';
import AppBreadcrumbs from 'components/AppBreadcrumbs';
import PageLayout from 'layouts/PageLayout';
import { makeStyles } from '@material-ui/core/styles';
import { useAsyncFn } from 'react-use';
import { createCourseTemplate } from 'http/courseTemplates';
import { routesAliases } from 'router/routes';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import TemplateForm from '../Form';

const useStyles = makeStyles(theme => ({
  pageContent: {
    padding: theme.spacing(4, 5),
  },
}));

const Create = ({ routeProps }) => {
  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation();
  const backUrl = routesAliases.courseTemplatesList;

  const breadCrumbsItems = [
    {
      title: t(routeProps.parentTitle),
      link: routeProps.parentPath,
    },
    {
      title: t(routeProps.title),
      isActive: true,
    },
  ];

  const [submittingState, sendForm] = useAsyncFn(async formData => {
    const courseTemplate = await createCourseTemplate(formData);

    if (!courseTemplate.error) {
      history.push(`${routesAliases.editTemplate}/${courseTemplate.id}`);
    }

    return courseTemplate;
  });

  return (
    <PageLayout classes={{ content: classes.pageContent }} pageTitle={<AppBreadcrumbs items={breadCrumbsItems} />}>
      <TemplateForm onSubmitForm={sendForm} submitting={submittingState.loading} backUrl={backUrl} />
    </PageLayout>
  );
};

Create.propTypes = {
  routeProps: PropTypes.shape({
    parentPath: PropTypes.string,
    parentTitle: PropTypes.string,
    title: PropTypes.string,
  }).isRequired,
};

export default Create;

import { generateColor } from 'utils';
import i18next from 'i18next';

const transformToStatisticsByCourses = (data, otherPosition) => {
  const uniqCoursesWithCount = {};
  data.forEach(item => {
    if (uniqCoursesWithCount[item.course]) {
      uniqCoursesWithCount[item.course]++;
    } else {
      uniqCoursesWithCount[item.course] = 1;
    }
  });

  const statData = Object.keys(uniqCoursesWithCount).map(item => ({
    value: uniqCoursesWithCount[item],
    label: item,
    legendTitle: item,
    fillColor: generateColor(),
  }));

  statData.sort((a, b) => b.value - a.value);

  if (statData.length > otherPosition) {
    let otherValue = 0;
    const uniqCoursesWithCountSortedExtended = statData.map((item, index) => {
      if (index > otherPosition - 2) {
        otherValue += item.value;
        return {
          ...item,
          hideLabel: true,
          hiddenInData: true,
        };
      }
      return item;
    });

    return [
      ...uniqCoursesWithCountSortedExtended,
      {
        value: otherValue,
        legendTitle: i18next.t('legend.other'),
        fillColor: '#C84F48',
        hideLabel: false,
        withExtInfo: true,
      },
    ];
  }

  return statData;
};

export default transformToStatisticsByCourses;

import React, { lazy, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import CustomTextField from 'components/Forms/TextField';
import CustomSelect from 'components/Forms/CustomSelect';
import { useTranslation } from 'react-i18next';
import { useAsyncFn } from 'react-use';
import { getJobsTasks } from 'http/jobsTasks';
import { Form, Field } from 'react-final-form';
import { Box, Grid, Button } from '@material-ui/core';
import { actions as modalActions } from 'redux/modal';
import { transformDataToSelectOptions, generateErrorObject } from 'utils';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import configErrors from './configErrors';
import validate from './validation';
import useStyles from './styles';

const Loader = lazy(() => import('components/Loader'));

const StudentsForm = ({ onSubmit, isLoading, studentData, routesAliases, type, courseStudentsCount }) => {
  const classes = useStyles();
  const { t } = useTranslation('common');
  const history = useHistory();
  const dispatch = useDispatch();
  const { station } = useSelector(state => state.auth.profile);
  const stationId = station ? station.id : undefined;

  const [warnings, setWarnings] = useState({});

  const clearWarnings = field => {
    setWarnings(prevState => {
      return {
        ...prevState,
        [field]: undefined,
      };
    });
  };

  const setCustomWarnings = (field, message) => {
    setWarnings(prevState => ({
      ...prevState,
      [field]: message,
    }));

    setTimeout(() => {
      clearWarnings(field);
    }, 1500);
  };

  const [formControlsOptions, setFormControlsOptions] = useState({
    jobTasks: [],
  });

  const [formData, setFormData] = useState(studentData || {});
  const [currentStationId, setCurrentStationId] = useState(stationId);

  const [formControlsState, getFormControlsOptions] = useAsyncFn(async () => {
    const jobTasks = await getJobsTasks();
    if (jobTasks.items) {
      setFormControlsOptions({
        ...formControlsOptions,
        jobTasks: transformDataToSelectOptions(jobTasks.items, 'id', 'name'),
      });
    }
  });

  useEffect(() => {
    getFormControlsOptions();
  }, []);

  useEffect(() => {
    if (studentData) {
      setFormData(studentData);
    }
  }, [studentData]);

  useEffect(() => {
    setCurrentStationId(station.id);
  }, [station]);

  const handleJobTaskError = errorMessage => {
    const jobTaskErrorRegexp = /^user\.jobTask\..+\.hasData$/;

    let erroredJobTasks;
    if (Array.isArray(errorMessage)) {
      erroredJobTasks = errorMessage.reduce((errorsAccum, error) => {
        if (!jobTaskErrorRegexp.test(error)) {
          return errorsAccum;
        }
        if (!errorsAccum) {
          return error.split('.')[2];
        }
        return `${errorsAccum}, ${error.split('.')[2]}`;
      }, '');
    }
    if (typeof errorMessage === 'string' && jobTaskErrorRegexp.test(errorMessage)) {
      // eslint-disable-next-line prefer-destructuring
      erroredJobTasks = errorMessage.split('.')[2];
    }

    if (erroredJobTasks) {
      const errorMessageResponse = t('errors.jobTaskHasData', { jobTask: erroredJobTasks });
      dispatch(modalActions.openModal({ size: 'small' }, <p>{errorMessageResponse}</p>));
    }
    return true;
  };

  const handleSubmitForm = async formValues => {
    const transformedFormData = {
      ...formValues,
      jobsTasksIds: Array.isArray(formValues.jobTasks) ? formValues.jobTasks.map(jobTask => jobTask.value) : [],
    };
    delete transformedFormData.jobTasks;
    delete transformedFormData.station;

    const submitData = await onSubmit(transformedFormData, currentStationId);
    if (submitData && submitData.error && submitData.message) {
      handleJobTaskError(submitData.message);
      return generateErrorObject(submitData.message, configErrors);
    }

    return undefined;
  };

  const isShowLoader = formControlsState.loading || isLoading;

  return (
    <Form
      onSubmit={handleSubmitForm}
      validate={validate}
      initialValues={formData}
      render={({ handleSubmit, submitting, values }) => (
        <form onSubmit={handleSubmit}>
          <Box className={classes.formWrap} display="flex">
            <Box className={classes.column}>
              <Box width="100%" mb={4}>
                <Field
                  type="text"
                  id="firstName"
                  name="firstName"
                  label={t('formLabels.firstName')}
                  disabled={submitting}
                  variant="outlined"
                  fullWidth
                  component={CustomTextField}
                  format={(value = '') => value.trim()}
                  formatOnBlur
                />
              </Box>

              <Box width="100%" mb={4}>
                <Field
                  type="text"
                  id="lastName"
                  name="lastName"
                  label={t('formLabels.lastName')}
                  disabled={submitting}
                  variant="outlined"
                  fullWidth
                  component={CustomTextField}
                  format={(value = '') => value.trim()}
                  formatOnBlur
                />
              </Box>

              <Box width="100%" mb={4}>
                <Field
                  type="text"
                  id="ids"
                  name="ids"
                  label={t('formLabels.idNumber')}
                  disabled={submitting}
                  variant="outlined"
                  fullWidth
                  component={CustomTextField}
                  format={(value = '') => value.trim()}
                  formatOnBlur
                />
              </Box>
            </Box>

            <Box className={classes.column}>
              <Box width="100%" mb={4}>
                <Field
                  type="text"
                  id="jobTasks"
                  name="jobTasks"
                  label={t('formLabels.jobTask')}
                  disabled={submitting}
                  variant="outlined"
                  fullWidth
                  component={CustomSelect}
                  options={formControlsOptions.jobTasks}
                  warningMessage={warnings.courseTemplate}
                  onWrapperClick={() => setCustomWarnings('jobTasks', t('course.haveStudentsOrTrainers'))}
                  isMulti
                  isDisabled={
                    (type === 'edit' && courseStudentsCount > 0) ||
                    (values.courseTemplate && Array.isArray(values.trainers) && values.trainers.length > 0) ||
                    submitting
                  }
                />
              </Box>
            </Box>

            <Box className={classes.column}>
              <Box width="100%" mb={4}>
                <Field
                  type="text"
                  id="department"
                  name="department"
                  label={t('formLabels.department')}
                  disabled={submitting}
                  variant="outlined"
                  fullWidth
                  component={CustomTextField}
                  format={(value = '') => value.trim()}
                  formatOnBlur
                />
              </Box>

              <Box width="100%" mb={0}>
                <Field
                  type="email"
                  id="email"
                  name="email"
                  label={t('formLabels.email')}
                  disabled={submitting}
                  variant="outlined"
                  fullWidth
                  component={CustomTextField}
                  format={(value = '') => value.trim()}
                  formatOnBlur
                />
              </Box>
            </Box>
          </Box>

          <Grid container spacing={3} className={classes.btnContainer}>
            <Grid item xs={6}>
              <Box textAlign="right">
                <Button
                  className={classes.btn}
                  type="submit"
                  color="primary"
                  variant="contained"
                  disabled={submitting}
                  size="large"
                >
                  {t('buttons.save')}
                </Button>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box textAlign="left">
                <Button
                  className={classes.btn}
                  variant="outlined"
                  size="large"
                  type="reset"
                  onClick={() => history.push(routesAliases.studentsList)}
                >
                  {t('buttons.cancel')}
                </Button>
              </Box>
            </Grid>
          </Grid>

          {isShowLoader && <Loader />}
        </form>
      )}
    />
  );
};

StudentsForm.defaultProps = {
  studentData: {
    id: undefined,
    firstName: '',
    lastName: '',
    email: '',
    department: '',
    ids: '',
  },
  courseStudentsCount: 0,
};

StudentsForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  studentData: PropTypes.oneOfType([
    PropTypes.shape({
      firstName: PropTypes.string,
      lastName: PropTypes.string,
      email: PropTypes.string,
      department: PropTypes.string,
      ids: PropTypes.string,
      jobTasks: PropTypes.arrayOf(
        PropTypes.shape({
          label: PropTypes.string,
          value: PropTypes.number,
        }),
      ),
    }),
    PropTypes.bool,
  ]),
  routesAliases: PropTypes.shape({
    studentsList: PropTypes.string.isRequired,
  }).isRequired,
  type: PropTypes.string.isRequired,
  courseStudentsCount: PropTypes.number,
};

export default StudentsForm;

import React from 'react';
import i18next from 'i18next';
import { IconButton, Icon } from '@material-ui/core';
import { Rating } from 'components/AppTable/CellsComponents';

const getTableConfig = helpers => {
  return [
    {
      title: i18next.t('tablesLabels.id'),
      field: 'id',
    },
    {
      title: i18next.t('tablesLabels.course'),
      field: 'name',
      isSorting: true,
    },
    {
      title: i18next.t('tablesLabels.description'),
      field: 'description',
    },
    {
      title: i18next.t('tablesLabels.status'),
      field: 'status',
      alignment: 'center',
    },
    {
      title: i18next.t('tablesLabels.certificate'),
      field: 'certificate',
      alignment: 'center',
      Component: certificate =>
        !certificate ? (
          i18next.t('commonValues.notAvailable')
        ) : (
          <IconButton
            size="small"
            onClick={event => {
              helpers.handleShowCertificate(event.currentTarget.id, certificate && certificate.previewUrl);
            }}
          >
            <Icon>cloud_download</Icon>
          </IconButton>
        ),
    },
    {
      title: i18next.t('tablesLabels.rating'),
      field: 'feedback',
      alignment: 'center',
      Component: val => <Rating val={val} onChange={helpers.handleRatingModal} />,
    },
    {
      title: 'ratingHidden',
      field: 'rating',
      isVisible: false,
    },
    {
      title: i18next.t('tablesLabels.stationId'),
      field: 'stationId',
      isVisible: false,
    },
  ];
};

export default getTableConfig;

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import AppTable from 'components/AppTable';
import Donut from 'components/Donut';
import PageLayout from 'layouts/PageLayout';
import SearchFilter from 'components/SearchFilter';
import StatisticsInfo from 'components/StatisticsInfo';
import makeExtendedData from 'utils/makeExtendedStatisticsData';
import withTable from 'HOC/withTable';
import withTableProps from 'HOC/types/withTable';
import { makeStyles } from '@material-ui/core/styles';
import { transformTableData, transformToStatisticsByExpireDate, transformToStatisticsByCourses } from 'utils';
import { getStatistics } from 'http/statistics';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { routesAliases } from 'router/routes';
import { useSelector } from 'react-redux';
import { ROLES } from 'appConstants';
import getTableConfig from './helpers/getTableConfig';

const pageAlias = 'Statistics';

const useStyles = makeStyles(theme => ({
  statisticsSection: {
    display: 'flex',
    margin: '70px 0 30px 0',
    alignItems: 'center',
  },
  statisticsLeft: {
    flexBasis: '50%',
  },
  statisticsRight: {
    flexBasis: '50%',
  },
  pageWrapper: {
    padding: theme.spacing(1, 4),
  },
  donutRoot: {
    flexDirection: 'row-reverse',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  donutLegendWrapper: {},
  donutWrapper: {},
  divider: {
    width: 0,
    height: '280px',
    border: `1px solid ${theme.palette.text.grey}`,
  },
  tableRoot: {
    padding: '10px 0',
  },
}));

const Statistics = ({ tableData, total, diagrams }) => {
  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation('common');
  const tableConfig = getTableConfig();
  const {
    lang,
    profile: { role },
  } = useSelector(state => state.auth);
  const [donutDataByExpireDate, setDonutDataByExpireDate] = useState([]);
  const [donutDataByCourses, setDonutDataByCourses] = useState([]);
  const [statisticsInfo, setStatisticsInfo] = useState([]);
  const [columns, statistics] = transformTableData(tableConfig, tableData.data.items);

  const handleTableSearch = (value, searchAlias) => {
    tableData.handleFiltering(searchAlias, value);
  };

  const handleTableSearchClearing = searchAlias => {
    tableData.handleRemoveFilter(searchAlias);
  };

  const makeActions =
    role !== ROLES.TRAINER
      ? [
          {
            title: t('buttons.view'),
            onClick: event =>
              history.push({
                pathname: `${routesAliases.editStudent}/${event.currentTarget.dataset.userid}`,
              }),
          },
        ]
      : undefined;

  useEffect(() => {
    tableData.handleChangeFetchMethod(pageAlias, getStatistics);
  }, [lang]);

  useEffect(() => {
    if (tableData.data && tableData.data.items && Array.isArray(tableData.data.items)) {
      const dataExtended = makeExtendedData(tableData.data.items);
      setDonutDataByCourses(transformToStatisticsByCourses(dataExtended, 4));
    }
  }, [tableData]);

  useEffect(() => {
    setStatisticsInfo([
      {
        title: t('statistics.totalCourses'),
        value: total.courses,
        subtitle: t('statistics.subtitleCourses'),
      },
      {
        title: t('statistics.totalStudents'),
        value: total.students,
        subtitle: t('statistics.subtitlePeople'),
      },
    ]);
  }, [total]);

  useEffect(() => {
    setDonutDataByExpireDate(transformToStatisticsByExpireDate(diagrams.courses));
  }, [diagrams]);

  return (
    <PageLayout
      pageTitle={t('statistics.pageTitle')}
      contentHeader={t('statistics.contentHeader')}
      actionSectionLeft={[
        {
          type: 'component',
          Component: <StatisticsInfo items={statisticsInfo} />,
        },
      ]}
      pageContentActions={[
        {
          type: 'component',
          Component: (
            <SearchFilter
              searchAlias="search"
              onSearch={handleTableSearch}
              onClearSearch={handleTableSearchClearing}
              styles={{
                bgColor: '#e5e5e5',
                searchWidth: '250px',
              }}
            />
          ),
        },
      ]}
      classes={{
        content: classes.pageWrapper,
      }}
      showLoader={tableData.isFetchingTableData}
    >
      <div className={classes.mainContent}>
        <div className={classes.statisticsSection}>
          {donutDataByExpireDate && !!donutDataByExpireDate.length && (
            <div className={classes.statisticsLeft}>
              <Donut
                donutTitle={t('legend.coursesByExpiryDate')}
                donutData={donutDataByExpireDate}
                legendWidth={200}
                legendHeight={150}
                legendOrientation="vertical"
                legendItemsPerRow={4}
                classes={{
                  root: classes.donutRoot,
                  legendWrapper: classes.donutLegendWrapper,
                  donutWrapper: classes.donutWrapper,
                }}
              />
            </div>
          )}
          {donutDataByCourses && !!donutDataByCourses.length && (
            <div className={classes.statisticsRight}>
              <Donut
                donutTitle={t('legend.studentsOnCourse')}
                donutData={donutDataByCourses}
                legendWidth={200}
                legendHeight={150}
                legendOrientation="vertical"
                legendItemsPerRow={4}
                shouldPercent={false}
                classes={{
                  root: classes.donutRoot,
                  legendWrapper: classes.donutLegendWrapper,
                  donutWrapper: classes.donutWrapper,
                }}
              />
            </div>
          )}
        </div>

        <AppTable
          columns={columns}
          data={statistics}
          totalCount={tableData.data.totalCount}
          rowsPerPage={tableData.pageSize}
          onChangePage={tableData.handleChangePage}
          currentPage={tableData.page}
          onColumnSorting={tableData.handleSorting}
          extraActionsKeys={['userId']}
          classes={{
            root: classes.tableRoot,
          }}
          actions={makeActions}
        />
      </div>
    </PageLayout>
  );
};

Statistics.defaultProps = {
  total: {},
  diagrams: {},
};

Statistics.propTypes = {
  tableData: PropTypes.shape(withTableProps).isRequired,
  total: PropTypes.shape(),
  diagrams: PropTypes.shape(),
};

export default withTable({
  defaultSorting: {},
  sortingFieldsMapping: {
    course: 'courseName',
    performationDate: 'performedDate',
    coloredData: 'expirationDate',
  },
  fetchWithoutParams: true,
  pageAlias,
  fetchingData: 'statData',
})(Statistics);

/* eslint-disable react/display-name */
import React, { useEffect } from 'react';
import { useAsyncFn } from 'react-use';
import { useSelector, useDispatch } from 'react-redux';
import { actions } from 'redux/table';

export default function withTable(
  { defaultSorting, fetchingData, sortingFieldsMapping = {}, tablePageSize, pageAlias, fetchWithoutParams = false },
  defaultFetchMethod,
) {
  return WrappedComponent => {
    return props => {
      const dispatch = useDispatch();
      const {
        tableData,
        count,
        page,
        sorting,
        filtering,
        pageSize,
        needForceUpdate,
        fetchMethod,
        graphic,
        diagrams,
        total,
      } = useSelector(state => state.table);

      const [isFetchingTableData, getTableData] = useAsyncFn(async (fetchData, params) => {
        const data = await fetchData({
          ...params,
        });

        if (!data.error) {
          dispatch(
            actions.setTableData({
              data: data[fetchingData] || [],
              count: data.count || 0,
              graphic: data.graphic || {},
              total: data.total || {},
              diagrams: data.diagrams || {},
            }),
          );
        }
      });

      const fetchData = alias => {
        let method = defaultFetchMethod;
        if (alias) {
          method = fetchMethod[pageAlias];
        }

        if (method) {
          const sortingObj = sorting.field ? sorting : defaultSorting;
          const requestParams = {
            page,
            pageSize: tablePageSize || pageSize,
            [sortingObj.field]: sortingObj.dir,
            ...filtering,
          };

          getTableData(method, fetchWithoutParams ? undefined : requestParams);
        }
      };

      useEffect(() => {
        fetchData(pageAlias);
      }, [page, sorting, filtering, fetchMethod]);

      useEffect(() => {
        if (needForceUpdate !== false) {
          fetchData(pageAlias);
          dispatch(actions.forceUpdateTable(false));
        }
      }, [needForceUpdate]);

      useEffect(() => {
        return () => {
          dispatch(actions.cleanUpTable());
        };
      }, []);

      const handleForceUpdate = () => {
        dispatch(actions.forceUpdateTable(true));
      };

      const handleChangePage = currentPage => {
        dispatch(actions.setTablePage(currentPage));
      };

      const handleSorting = (field, dir) => {
        const fieldMapping = {
          [field]: field,
          isActive: 'active',
          ...sortingFieldsMapping,
        };
        dispatch(
          actions.setTableSorting({
            field: fieldMapping[field],
            dir,
          }),
        );
      };

      const handleFiltering = (filterName, filterValue) => {
        dispatch(
          actions.setTableFiltering({
            [filterName]: filterValue,
          }),
        );
        dispatch(actions.setTablePage(0));
      };

      const handleRemoveFilter = filterName => {
        dispatch(actions.removeTableFilter(filterName));
        dispatch(actions.setTablePage(0));
      };

      const handleChangeFetchMethod = (alias, method) => {
        dispatch(actions.setFetchMethod({ alias, method }));
        dispatch(actions.setTablePage(0));
      };

      return (
        <WrappedComponent
          tableData={{
            data: tableData,
            totalCount: count,
            page,
            pageSize: tablePageSize || pageSize,
            isFetchingTableData: isFetchingTableData.loading,
            handleChangePage,
            handleSorting,
            handleFiltering,
            handleForceUpdate,
            handleChangeFetchMethod,
            handleRemoveFilter,
          }}
          diagrams={diagrams}
          graphic={graphic}
          total={total}
          {...props}
        />
      );
    };
  };
}

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  container: {
    maxWidth: 950,
  },
  containerSubtitles: {
    marginBottom: 25,
  },
  subtitle: {
    fontSize: 18,
    lineHeight: '21px',
    fontWeight: 700,
  },
  gridItem: {
    paddingLeft: 50,
    paddingRight: 50,
    position: 'relative',
  },
  divider: {
    position: 'absolute',
    top: 30,
    left: '-1px',
    bottom: 30,
    height: 'auto',
  },
  btnContainer: {
    marginTop: '40px',
  },
  btn: {
    minWidth: '140px',
  },
}));

export default useStyles;

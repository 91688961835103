import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Tabulation from 'components/Tabulation';
import SearchFilter from 'components/SearchFilter';
import withTable from 'HOC/withTable';
import withTableProps from 'HOC/types/withTable';
import { Button, Icon } from '@material-ui/core';
import { useAsyncFn } from 'react-use';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { actions as modalActions } from 'redux/modal';
import { actions as tableActions } from 'redux/table';
import { SendFileForm } from 'components/CommonForms';
import { PageSelect } from 'components/Selects';
import { useTranslation } from 'react-i18next';
import { importHistory } from 'http/courses';
import { getJobsTasks } from 'http/jobsTasks';
import { downloadHistoryExample } from 'http/users';
import { makeStyles } from '@material-ui/core/styles';
import CoursesInProgressTable from './CoursesInProgressTable';
import CoursesScheduledTable from './CoursesScheduledTable';
import CoursesPastTable from './CoursesPastTable';
import HistoryTable from './HistoryTable';
import DocumentsTable from './DocumentsTable';

const pageAlias = 'CoursesListDetails';

const getTabActionComponent = (title, icon, action = () => {}) => (
  <Button onClick={action} variant="contained" color="primary" startIcon={<Icon>{icon}</Icon>}>
    {title}
  </Button>
);

const useStyles = makeStyles(() => ({
  searchField: {
    '& > div': {
      marginRight: 0,
    },
  },
}));

const CourseDetails = ({ tableData, userId, helpers, routesAliases }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const classes = useStyles();

  const aliasHelper = {
    inProgress: {
      getCourses: helpers.getCoursesInProgress,
    },
    scheduled: {
      getCourses: helpers.getCoursesScheduled,
    },
    past: {
      getCourses: helpers.getCoursesPassed,
    },
    history: {
      getCourses: helpers.getCoursesHistory,
    },
    documents: {
      getCourses: helpers.getDocuments,
    },
  };

  const historyState = {};

  const [activeTab, setActiveTab] = useState({
    tabIndex: 0,
    alias: 'inProgress',
  });

  const handleTableSearch = (value, searchAlias) => {
    tableData.handleFiltering(searchAlias, value);
  };

  const handleTableSearchClearing = searchAlias => {
    tableData.handleRemoveFilter(searchAlias);
  };

  const handleTabChange = (e, data) => {
    dispatch(tableActions.cleanUpTable({}));
    dispatch(tableActions.setTableSorting({}));
    setActiveTab({
      tabIndex: data.tabIndex,
      alias: data.alias,
    });
  };

  if (history.action === 'PUSH' && location.state) {
    historyState.tabIndex = location.state.tabIndex || 0;
    historyState.alias = location.state.alias || 'inProgress';
  }

  const CoursesInProgressComponent = (
    <CoursesInProgressTable
      tableData={tableData}
      userId={userId}
      helpers={{
        getCourses: helpers.getCoursesInProgress,
        editCourse: helpers.editCourse,
        deleteCourse: helpers.removeStudentFromCourse,
      }}
      routesAliases={routesAliases}
    />
  );

  const CoursesScheduledComponent = (
    <CoursesScheduledTable
      tableData={tableData}
      userId={userId}
      helpers={{
        getCourses: helpers.getCoursesScheduled,
        editCourse: helpers.editCourse,
        deleteCourse: helpers.removeStudentFromCourse,
      }}
    />
  );

  const CoursesPastComponent = (
    <CoursesPastTable
      tableData={tableData}
      userId={userId}
      helpers={{
        getCourses: helpers.getCoursesPassed,
        editCourse: helpers.editCourse,
        deleteCourse: helpers.removeStudentFromCourse,
        setCertificate: helpers.setCertificate,
        downloadCertificate: helpers.downloadCertificate,
        deleteCertificate: helpers.deleteCertificate,
      }}
      routesAliases={routesAliases}
    />
  );

  const HistoryComponent = <HistoryTable tableData={tableData} userId={userId} />;

  const DocumentsTableComponent = (
    <DocumentsTable
      tableData={tableData}
      userId={userId}
      helpers={{
        getDocuments: helpers.getDocuments,
        setDocuments: helpers.setDocuments,
        downloadDocument: helpers.downloadDocument,
        deleteDocument: helpers.deleteDocument,
        getEditorData: helpers.getEditorData,
      }}
    />
  );

  const [addFileState, addFile] = useAsyncFn(async formData => {
    const data = await helpers.setDocuments(formData);
    if (!data.error) {
      dispatch(modalActions.closeModal());
      dispatch(tableActions.forceUpdateTable(true));
    }
  });

  const handleSubmitFileForm = values => {
    addFile(values);
  };

  const handleAddDocument = () => {
    dispatch(
      modalActions.openModal(
        {
          size: 'medium',
          title: t('trainingDetails.addDocumentModalTitle'),
        },
        <SendFileForm
          onSubmitForm={handleSubmitFileForm}
          rejectAction={() => {
            dispatch(modalActions.closeModal());
          }}
          submitting={addFileState.loading}
          fileFormat=".pdf, .pptx, .doc, .docx, .xlsx, .ppt, .jpeg, .jpg, .png"
        />,
      ),
    );
  };

  const createJobsTasksList = items => {
    const jobsTasksList = [
      {
        label: t('courses.allJobTasks'),
        value: undefined,
      },
    ];

    items.forEach(item =>
      jobsTasksList.push({
        label: item.name,
        value: item.id,
      }),
    );

    return jobsTasksList;
  };

  const [jobsTasksFilterValues, setJobsTasksFilterValues] = useState([{}]);
  const [jobsTasksFilterValue, setJobsTasksFilterValue] = useState(createJobsTasksList([])[0].value);

  const handleChangeJobsTasksFilter = value => {
    setJobsTasksFilterValue(value);
    if (value === undefined) {
      tableData.handleRemoveFilter('job-task-id');
    } else {
      tableData.handleFiltering('job-task-id', value);
    }
  };

  // eslint-disable-next-line no-unused-vars
  const [fetchingJobsTasks, fetchJobsTasks] = useAsyncFn(async () => {
    const data = await getJobsTasks();
    if (data.error) {
      return data;
    }
    const jobsTasks = createJobsTasksList(data.items);
    setJobsTasksFilterValues(jobsTasks);
    return data;
  });

  const [importState, importHistoryFile] = useAsyncFn(async formData => {
    const importResponse = await importHistory(userId, formData);
    if (!importResponse.error) {
      dispatch(modalActions.closeModal());
      dispatch(tableActions.forceUpdateTable(true));
    }
    return importResponse;
  });

  // eslint-disable-next-line no-unused-vars
  const [downloadHistoryState, getDownloadHistoryExample] = useAsyncFn(async () => {
    await downloadHistoryExample(userId);
  });

  const handleImportHistory = () => {
    dispatch(
      modalActions.openModal(
        {
          size: 'medium',
          title: t('courses.importModalTitle'),
        },
        <SendFileForm
          onSubmitForm={values => importHistoryFile({ data: values.file })}
          formType="import"
          rejectAction={() => {
            dispatch(modalActions.closeModal());
          }}
          submitting={importState.loading}
          fileFormat=".xlsx, .csv"
        />,
      ),
    );
  };

  useEffect(() => {
    if (Object.keys(historyState).length) {
      handleTabChange(null, {
        tabIndex: historyState.tabIndex,
        alias: historyState.alias,
      });
      delete historyState.tabIndex;
      delete historyState.alias;
      location.state = undefined;
    }
  });

  useEffect(() => {
    const { alias } = activeTab;
    tableData.handleChangeFetchMethod(pageAlias, aliasHelper[alias].getCourses);

    if (alias !== 'documents' || alias !== 'history') {
      fetchJobsTasks();
    }
  }, [activeTab]);

  return (
    <Tabulation
      activeTab={activeTab.tabIndex}
      onTabChange={handleTabChange}
      tabsData={[
        {
          label: t('trainingDetails.coursesInProgress'),
          alias: 'inProgress',
          Component: CoursesInProgressComponent,
        },
        {
          label: t('trainingDetails.coursesScheduled'),
          alias: 'scheduled',
          Component: CoursesScheduledComponent,
        },
        {
          label: t('trainingDetails.pastCourses'),
          alias: 'past',
          Component: CoursesPastComponent,
        },
        {
          label: t('trainingDetails.history'),
          alias: 'history',
          Component: HistoryComponent,
        },
        {
          label: t('trainingDetails.documents'),
          alias: 'documents',
          Component: DocumentsTableComponent,
        },
      ]}
      actions={[
        {
          hiddenOnTabs: [3, 4],
          Component: (
            <PageSelect
              classes={{ root: classes.pageSelectRoot }}
              options={jobsTasksFilterValues}
              selectedOption={jobsTasksFilterValue}
              onChangeValue={handleChangeJobsTasksFilter}
            />
          ),
        },
        {
          hiddenOnTabs: [0, 1, 2, 3],
          Component: getTabActionComponent(t('trainingDetails.actionAddDocument'), 'add', handleAddDocument),
        },
        {
          hiddenOnTabs: [0, 1, 2, 4],
          Component: getTabActionComponent(t('commonActions.actionImport'), 'import_export', handleImportHistory),
        },
        {
          hiddenOnTabs: [0, 1, 2, 4],
          Component: getTabActionComponent(
            t('commonActions.actionDownloadExample'),
            'cloud_download',
            getDownloadHistoryExample,
          ),
        },
        {
          hiddenOnTabs: [4],
          Component: (
            <div className={classes.searchField}>
              <SearchFilter
                styles={{
                  bgColor: '#e5e5e5',
                  searchWidth: '250px',
                }}
                searchAlias="search"
                onSearch={handleTableSearch}
                onClearSearch={handleTableSearchClearing}
              />
            </div>
          ),
        },
      ]}
    />
  );
};

CourseDetails.propTypes = {
  tableData: PropTypes.shape(withTableProps).isRequired,
  userId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  helpers: PropTypes.shape({
    getDocuments: PropTypes.func,
    setDocuments: PropTypes.func,
    deleteDocument: PropTypes.func,
    downloadDocument: PropTypes.func,
    getCoursesInProgress: PropTypes.func,
    getCoursesPassed: PropTypes.func,
    getCoursesHistory: PropTypes.func,
    getCoursesScheduled: PropTypes.func,
    removeStudentFromCourse: PropTypes.func,
    editCourse: PropTypes.func,
    getCertificate: PropTypes.func,
    setCertificate: PropTypes.func,
    getEditorData: PropTypes.func,
    downloadCertificate: PropTypes.func,
    deleteCertificate: PropTypes.func,
  }).isRequired,
  routesAliases: PropTypes.shape({}).isRequired,
};

export default withTable({
  defaultSorting: {
    field: 'id',
    dir: 'desc',
  },
  tablePageSize: 5,
  fetchingData: 'items',
  sortingFieldsMapping: {
    expiryDate: 'expirationDate',
    buttonParams: 'hasCertificate',
    'studentPerformedState.isPerformedWithSuccess': 'courseStatus',
    'studentPerformedState.performedDate': 'performedDate',
  },
  pageAlias,
})(CourseDetails);

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  thumb: {
    position: 'relative',
    marginTop: 10,
    marginRight: 10,
  },
  thumbSingle: {
    margin: '0 auto',
  },
  thumbImg: {
    maxHeight: '102px',
    display: 'block',
    minWidth: 60,
    minHeight: 60,
    maxWidth: 330,
    background: theme.palette.common.contrastText,
    border: 'none',
    outline: 'none',
    appearance: 'none',
    boxShadow: 'none',
  },
  thumbFileName: {
    display: 'block',
    fontSize: '1.3em',
    width: '80%',
    whiteSpace: 'nowrap',
    overflow: 'hidden !important',
    textOverflow: 'ellipsis',
    margin: '0 auto',
    textAlign: 'center',
  },
  thumbRemove: {
    position: 'absolute',
    top: 0,
    right: 0,
    cursor: 'pointer',
    color: theme.palette.error.main,
    opacity: 0.6,
    backgroundColor: 'rgba(255, 255, 255, 0.5)',
    '&:hover': {
      opacity: 1,
    },
  },
}));

export default useStyles;

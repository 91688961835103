/* eslint-disable no-param-reassign */
import React, { lazy, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Form, Field, FormSpy } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { deleteCompanyStation } from 'http/companies';
import { actions as modalActions } from 'redux/modal';
import { Box, Button, ButtonBase, Divider, Grid, Typography, Icon } from '@material-ui/core/';
import CustomTextField from 'components/Forms/TextField';
import CustomRadio from 'components/Forms/Radio';
import CustomCheckbox from 'components/Forms/Checkbox';
import CustomChipInput from 'components/Forms/ChipInput';
import CustomUpload from 'components/Forms/Upload';
import CustomColorPicker from 'components/Forms/ColorPicker';
import { useTheme } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import { generateErrorObject, getConfirmationModalConfig } from 'utils';
import configErrors from './configErrors';
import validate from './validation';
import useStyles from './styles';

const Loader = lazy(() => import('components/Loader'));

const CompaniesForm = ({ onSubmit, isLoading, companyData = {}, routesAliases }) => {
  const theme = useTheme();
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation('common');

  const {
    SAfirstName = '',
    SAlastName = '',
    SAemail = '',
    name = '',
    isActive = companyData.deletedAt === null,
    stations = [],
    mainColor = theme.palette.primary.main,
    fontMainColor = theme.palette.common.black,
    logoSrc = companyData.logo ? companyData.logo : '',
  } = companyData;

  const currentActiveStations = actualStations =>
    actualStations.filter(item => item.deletedAt === null).map(item => item.name);

  const [isEditActive, setEditActive] = useState(false);
  const [currentStations, setCurrentStations] = useState(stations);
  const [currentStationsName, setCurrentStationsName] = useState(currentActiveStations(currentStations));
  const [newStations, setNewStations] = useState([]);

  const [values, setValues] = useState({
    SAfirstName,
    SAlastName,
    SAemail,
    name,
    isActive,
    stationsCurrent: currentStationsName,
    stationsNew: newStations,
    stationsRemoved: [],
    mainColor,
    fontMainColor,
    logoSrc,
  });

  useEffect(() => {
    if (companyData && Object.keys(companyData).length !== 0) {
      const initStations = companyData.stations;
      const initStationsName = currentActiveStations(initStations);
      setCurrentStations(initStations);
      setValues({
        ...values,
        stationsCurrent: initStationsName,
        isActive: companyData.deletedAt === null,
        logoSrc: companyData.logo ? companyData.logo : '',
        SAfirstName: companyData.SAfirstName,
        SAlastName: companyData.SAlastName,
        SAemail: companyData.SAemail,
        name: companyData.name,
        stationsNew: [],
        stationsRemoved: [],
        mainColor: companyData.mainColor,
        fontMainColor: companyData.fontMainColor,
      });
    }
    return () => {
      companyData = {};
    };
  }, [companyData]);

  const [logo, setLogo] = useState('');

  const handleChangeRadio = fieldValue => {
    const isChecked = JSON.parse(fieldValue.toLowerCase());
    setValues({ ...values, isActive: isChecked });
  };

  const handleChangeCurrentStations = event => {
    event.stopPropagation();
    const currentStationsUpdated = currentStations.map(item => {
      if (item.id.toString() === event.target.value) {
        if (item.deletedAt === null) {
          item.deletedAt = true;
        } else {
          item.deletedAt = null;
        }
      }
      return item;
    });
    setCurrentStations(currentStationsUpdated);
    setCurrentStationsName(currentActiveStations(currentStationsUpdated));
    setValues({
      ...values,
      stationsCurrent: currentActiveStations(currentStationsUpdated),
    });
  };

  const handleChangeNewStations = data => {
    setNewStations([...data]);
    setValues({ ...values, stationsNew: data });
  };

  const handleEditActive = () => setEditActive(!isEditActive);

  const handleChangeFile = data => {
    if (!data) {
      setValues({ ...values, logoSrc: '' });
    }
    setLogo(data);
  };

  const handleCloseModal = () => {
    dispatch(modalActions.closeModal());
  };

  const handleDeleteStations = event => {
    event.stopPropagation();
    const stationId = event.target.value;

    const updatedCurrentStations = [];
    const stationRemoved = [];
    currentStations.forEach(item => {
      if (item.id.toString() !== stationId) {
        updatedCurrentStations.push(item);
      } else {
        stationRemoved.push(item);
      }
    });
    setCurrentStations(updatedCurrentStations);
    setCurrentStationsName(currentActiveStations(updatedCurrentStations));
    values.stationsRemoved.push(stationRemoved[0]);
    setValues({
      ...values,
      stationsRemoved: values.stationsRemoved,
      stationsCurrent: currentActiveStations(updatedCurrentStations),
    });
  };

  const handleDeleteStationsTotally = async data => {
    return Promise.all(data.map(item => deleteCompanyStation({ companyId: companyData.id, stationId: item.id })));
  };

  const handleModifier = event => {
    let code = event.target.value;
    if (code) {
      code = code.replace(/[а-я\s\W\d]+/gi, '').toUpperCase();
    }
    if (code.length > 3) {
      return code.slice(0, -1);
    }
    return code;
  };

  const handleFormSubmit = async formData => {
    const stationsForRemoving = values.stationsRemoved || [];
    const data = await handleDeleteStationsTotally(stationsForRemoving);
    const stationsNotRemoved = [];

    data.forEach((responseItem, index) => {
      if (responseItem.error) {
        stationsNotRemoved.push(stationsForRemoving[index].name);
      }
    });

    const stationsRemoved = stationsForRemoving.filter(item => !stationsNotRemoved.includes(item.name));
    const stationsRemovedNames = stationsRemoved.map(item => item.name);
    const finalStations = values.stationsCurrent.filter(item => !stationsRemovedNames.includes(item));

    if (stationsNotRemoved.length) {
      const [config, element] = getConfirmationModalConfig({
        confirmTitle: t('students.confirmTitle'),
        confirmAction: handleCloseModal,
        rejectAction: handleCloseModal,
        message: t('company.modalRemoveStation', {
          stations: stationsNotRemoved.join(', '),
        }),
        modalId: 'stationRemovingError',
      });
      dispatch(modalActions.openModal(config, element));
    }

    const finalFormValues = {
      ...formData,
      stations: JSON.stringify([...finalStations, ...values.stationsNew]),
    };
    delete finalFormValues.stationsCurrent;
    delete finalFormValues.stationsNew;
    delete finalFormValues.stationsRemoved;
    if (logo) {
      finalFormValues.logo = logo;
    }
    const submitData = await onSubmit(finalFormValues, companyData.id);
    if (submitData && (submitData.error || submitData.statusCode === 400) && submitData.message) {
      return generateErrorObject(submitData.message, configErrors);
    }

    return undefined;
  };

  return (
    <Form
      onSubmit={handleFormSubmit}
      validate={validate}
      initialValues={values}
      keepDirtyOnReinitialize
      render={({ handleSubmit, submitting }) => (
        <form onSubmit={handleSubmit} className={classes.form}>
          <FormSpy
            onChange={({ modified, values: formValues }) => {
              if (modified.isActive) {
                handleChangeRadio(formValues.isActive);
              }
            }}
          />
          <Grid container className={classes.containerSubtitles}>
            <Grid className={classes.gridItem} item xs={6}>
              <Box width="100%" mb={0}>
                <Typography className={classes.subtitle} component="h2">
                  {t('company.subtitleInformation')}
                </Typography>
              </Box>
            </Grid>
            <Grid className={classes.gridItem} item xs={6}>
              <Box width="100%" mb={0}>
                <Typography className={classes.subtitle} component="h2">
                  {t('company.subtitleDetails')}
                </Typography>
              </Box>
            </Grid>
          </Grid>

          <Grid container>
            <Grid className={classes.gridItem} item xs={6}>
              <Box width="100%" mb={4}>
                <Field
                  type="text"
                  id="name"
                  name="name"
                  label={t('formLabels.companyName')}
                  disabled={submitting}
                  variant="outlined"
                  fullWidth
                  component={CustomTextField}
                  format={(value = '') => value.replace(/\W\s/g, '').trim()}
                  formatOnBlur
                />
              </Box>

              <Box width="100%" mb={4}>
                <Field
                  id="isActive"
                  name="isActive"
                  label={t('formLabels.companyState')}
                  type="radio"
                  disabled={submitting}
                  variant="outlined"
                  component={CustomRadio}
                  items={[
                    { name: t('commonValues.active'), value: 'true' },
                    { name: t('commonValues.notActive'), value: 'false' },
                  ]}
                  value={values.isActive}
                />
              </Box>

              <Box width="100%" mb={4}>
                <Field
                  id="stationsNew"
                  name="stationsNew"
                  fullWidth
                  label={t('formLabels.addStation')}
                  placeholder={t('formLabels.addStation')}
                  component={CustomChipInput}
                  onChange={handleChangeNewStations}
                  variant="outlined"
                  modifier={handleModifier}
                />
              </Box>
            </Grid>

            <Grid className={classes.gridItem} item xs={6}>
              <Box width="100%" mb={4}>
                <Field
                  type="text"
                  id="SAfirstName"
                  name="SAfirstName"
                  label={t('formLabels.firstName')}
                  disabled={submitting}
                  variant="outlined"
                  fullWidth
                  component={CustomTextField}
                  format={(value = '') => value.trim()}
                  formatOnBlur
                />
              </Box>

              <Box width="100%" mb={4}>
                <Field
                  type="text"
                  id="SAlastName"
                  name="SAlastName"
                  label={t('formLabels.lastName')}
                  disabled={submitting}
                  variant="outlined"
                  fullWidth
                  component={CustomTextField}
                  format={(value = '') => value.trim()}
                  formatOnBlur
                />
              </Box>

              <Box width="100%" mb={4}>
                <Field
                  type="text"
                  id="SAemail"
                  name="SAemail"
                  label={t('formLabels.email')}
                  disabled={submitting}
                  variant="outlined"
                  fullWidth
                  component={CustomTextField}
                  format={(value = '') => value.trim()}
                  formatOnBlur
                />
              </Box>

              <Divider className={classes.divider} orientation="vertical" />
            </Grid>
          </Grid>

          <Grid container>
            <Grid className={classes.gridItem} item xs={6}>
              {companyData.id && (
                <>
                  <Box width="100%" mb={4}>
                    {isEditActive && (
                      <Field
                        type="checkbox"
                        id="stationsCurrent"
                        name="stationsCurrent"
                        label={t('formLabels.companyStation')}
                        customStyles={classes.checkboxGroup}
                        disabled={submitting}
                        onChange={handleDeleteStations}
                        icon={<Icon>delete</Icon>}
                        checkedIcon={<Icon>delete</Icon>}
                        fullWidth
                        component={CustomCheckbox}
                        items={currentStations}
                      />
                    )}

                    {!isEditActive && (
                      <Field
                        type="checkbox"
                        id="stationsCurrent"
                        name="stationsCurrent"
                        customStyles={classes.checkboxGroup}
                        label={t('formLabels.companyStation')}
                        disabled={submitting}
                        onChange={handleChangeCurrentStations}
                        fullWidth
                        component={CustomCheckbox}
                        items={currentStations}
                      />
                    )}

                    <ButtonBase onClick={handleEditActive} className={classes.btnEdit}>
                      {isEditActive ? (
                        t('company.done')
                      ) : (
                        <>
                          <Icon fontSize="small" className={classes.btnEditIcon}>
                            edit
                          </Icon>
                          {t('company.editStations')}
                        </>
                      )}
                    </ButtonBase>
                  </Box>
                </>
              )}
            </Grid>

            <Grid className={classes.gridItem} item xs={6}>
              <Typography className={classes.subtitle} component="h3">
                {t('company.colors')}
              </Typography>

              <Box width="100%" mb={5} mt={2} display="flex">
                <Box mr={4}>
                  <Field
                    id="mainColor"
                    type="color"
                    name="mainColor"
                    fullWidth
                    label={t('formLabels.mainColor')}
                    component={CustomColorPicker}
                    defaultValue={values.mainColor}
                    value={values.mainColor}
                  />
                </Box>

                <Field
                  id="fontMainColor"
                  type="color"
                  name="fontMainColor"
                  label={t('formLabels.mainFontColor')}
                  component={CustomColorPicker}
                  defaultValue={values.fontMainColor}
                  value={values.fontMainColor}
                />
              </Box>

              <Box width="100%" mb={4}>
                <Field
                  fullWidth
                  id="companyLogo"
                  name="logo"
                  disabled={submitting}
                  onChange={handleChangeFile}
                  component={CustomUpload}
                  initSrc={[logoSrc]}
                  accept="image/png, image/jpeg, image/jpg"
                  multiple={false}
                  maxSize={10485760}
                  key={logoSrc}
                />
              </Box>
            </Grid>
          </Grid>

          <Grid container spacing={3} className={classes.btnContainer}>
            <Grid item xs={6}>
              <Box textAlign="right">
                <Button
                  className={classes.btn}
                  type="submit"
                  color="primary"
                  variant="contained"
                  disabled={submitting}
                  size="large"
                >
                  {t('buttons.save')}
                </Button>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box textAlign="left">
                <Button
                  className={classes.btn}
                  variant="outlined"
                  size="large"
                  type="reset"
                  onClick={() => history.push(routesAliases.companiesList)}
                >
                  {t('buttons.cancel')}
                </Button>
              </Box>
            </Grid>
          </Grid>

          {isLoading && <Loader />}
        </form>
      )}
    />
  );
};

CompaniesForm.defaultProps = {
  companyData: {},
};

CompaniesForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  companyData: PropTypes.oneOfType([PropTypes.bool, PropTypes.shape({})]),
  routesAliases: PropTypes.shape({
    companiesList: PropTypes.string.isRequired,
  }).isRequired,
};

export default CompaniesForm;

const configErrors = {
  'user.superAdminInCompany': {
    SAemail: 'errors.companySuperAdminExisting',
  },
  'user.emailAlreadyInUse': {
    SAemail: 'errors.companySuperAdminExisting',
  },
  'company.name': {
    name: 'errors.companyNameIsNotMatchPattern',
  },
  'company.alreadyExists': {
    name: 'errors.companyExisting',
  },
  'company.SAfirstName': {
    SAfirstName: 'errors.firstNameIsNotMatchPattern',
  },
  'company.SAlastName': {
    SAlastName: 'errors.lastNameIsNotMatchPattern',
  },
};

export default configErrors;

import React from 'react';
import PropTypes from 'prop-types';
import AppBreadcrumbs from 'components/AppBreadcrumbs';
import PageLayout from 'layouts/PageLayout';
import { useTranslation } from 'react-i18next';
import { useAsyncFn } from 'react-use';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch } from 'react-redux';
import { setTrainer, getAllTrainers } from 'http/users';
import { actions as modalActions } from 'redux/modal';
import { useHistory, useLocation } from 'react-router-dom';
import { routesAliases } from 'router/routes';
import TrainersForm from '../Form';

const useStyles = makeStyles(theme => ({
  pageContent: {
    padding: theme.spacing(4, 5),
  },
}));

let historyState;

const Create = ({ routeProps }) => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const { t } = useTranslation('common');

  const breadCrumbsItems = [
    {
      title: t(routeProps.parentTitle),
      link: routeProps.parentPath,
    },
    {
      title: t(routeProps.title),
      isActive: true,
    },
  ];

  const backUrl = routesAliases.trainersList;

  if (history.action === 'PUSH') {
    historyState = location.state;
  }

  let trainerType = '';

  if (historyState && historyState.trainerType) {
    trainerType = historyState.trainerType;
  }

  const [state, addTrainer] = useAsyncFn(async (formData, currentStationId) => {
    const data = await setTrainer(formData, currentStationId);
    if (!data.error) {
      dispatch(modalActions.openModal({ size: 'small' }, <p>{t('trainers.created')}</p>));
      const users = await getAllTrainers();
      if (!users.error) {
        const userId = users.items.filter(item => item.email === formData.email)[0].id;
        history.push(`${routesAliases.editTrainer}/${userId}`, { backUrl });
      }
    }
    return data;
  }, []);

  return (
    <PageLayout pageTitle={<AppBreadcrumbs items={breadCrumbsItems} />} classes={{ content: classes.pageContent }}>
      <TrainersForm
        isLoading={state.loading}
        onSubmit={addTrainer}
        routesAliases={routesAliases}
        trainerType={trainerType}
        backUrl={backUrl}
      />
    </PageLayout>
  );
};

Create.propTypes = {
  routeProps: PropTypes.shape({
    parentPath: PropTypes.string,
    parentTitle: PropTypes.string,
    title: PropTypes.string,
  }).isRequired,
};

export default Create;

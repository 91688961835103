/* eslint-disable no-param-reassign */
import Joi from '@hapi/joi';
import i18next from 'i18next';

const schema = Joi.object({
  courseTemplate: Joi.object().required(),
  jobsTasks: Joi.optional(),
  location: Joi.string().required(),
  description: Joi.string(),
  startTime: Joi.date().required(),
  endTime: Joi.date()
    .greater(Joi.ref('startTime'))
    .required(),
  startDate: Joi.date().required(),
  endDate: Joi.date()
    .min(Joi.ref('startDate'))
    .required(),
  maxStudents: Joi.number().required(),
  trainers: Joi.optional(),
  expirationDate: Joi.date()
    .allow('', null)
    .optional(),
  courseSystemId: Joi.number().optional(),
});

const validate = values => {
  const { error } = schema.validate(values, { abortEarly: false });
  if (!error) {
    return {};
  }

  const { details } = error;

  return details.reduce((errors, detail) => {
    const errorsMapping = {
      'object.base': i18next.t('errors.fieldIsRequired'),
      'any.required': i18next.t('errors.fieldIsRequired'),
      'date.min': i18next.t('errors.dateMin', {
        field: (detail.context.limit && detail.context.limit.key) || i18next.t('errors.currentDate'),
      }),
      'date.greater': i18next.t('errors.dateShouldBeGreater', {
        field: detail.context.limit && detail.context.limit.key,
      }),
      'number.base': i18next.t('errors.shouldBeANumber'),
      'date.base': i18next.t('errors.shouldBeValidData'),
      'string.empty': i18next.t('errors.fieldIsRequired'),
    };

    if (detail.type === 'date.base' && detail.context.value === null) {
      detail.type = 'any.required';
    }

    errors[detail.context.key] = errorsMapping[detail.type] || detail.message;

    return errors;
  }, {});
};

export default validate;

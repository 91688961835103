import React from 'react';
import PropTypes from 'prop-types';
import Tabulation from 'components/Tabulation';
import { Button, Icon } from '@material-ui/core';
import { useAsyncFn } from 'react-use';
import { useDispatch } from 'react-redux';
import { actions as modalActions } from 'redux/modal';
import { actions as tableActions } from 'redux/table';
import { SendFileForm } from 'components/CommonForms';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import DocumentsTable from './DocumentsTable';
import StudentsTable from './StudentsTable';
import AddStudent from './AddStudent';

const useStyles = makeStyles(() => ({
  modal: {
    '& .MuiDialog-paper': {
      width: '700px',
    },
  },
}));

const getTabActionComponent = (title, icon, action = () => {}, isDisabled) => (
  <Button disabled={isDisabled} onClick={action} variant="contained" color="primary" startIcon={<Icon>{icon}</Icon>}>
    {title}
  </Button>
);

const CourseDetails = ({
  courseId,
  stationId,
  helpers,
  courseTemplateId,
  handleChangeStudentsCount,
  isExternal,
  routesAliases,
  isFrozen,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation('common');

  const StudentsTableComponent = (
    <StudentsTable
      isFrozen={isFrozen}
      courseId={courseId}
      helpers={{
        fetchCourseStudents: helpers.fetchCourseStudents,
        removeStudentFromCourse: helpers.removeStudentFromCourse,
      }}
      handleChangeStudentsCount={handleChangeStudentsCount}
      routesAliases={routesAliases}
    />
  );

  const DocumentsTableComponent = (
    <DocumentsTable
      isFrozen={isFrozen}
      courseId={courseId}
      stationId={stationId}
      helpers={{
        getDocuments: helpers.getDocuments,
        deleteDocument: helpers.deleteDocument,
        downloadDocument: helpers.downloadDocument,
        getEditorData: helpers.getEditorData,
        setDocumentVisibility: helpers.setDocumentVisibility,
        getCourseTemplatesDocuments: helpers.getCourseTemplatesDocuments,
        setDocumentTemplateVisibility: helpers.setDocumentTemplateVisibility,
        deleteCourseTemplateDocument: helpers.deleteCourseTemplateDocument,
        downloadCourseTemplateDocument: helpers.downloadCourseTemplateDocument,
      }}
      isExternal={isExternal}
      courseTemplateId={courseTemplateId}
    />
  );

  const [addFileState, addFile] = useAsyncFn(async formData => {
    const data = await helpers.addDocument(formData);
    if (!data.error) {
      dispatch(modalActions.closeModal());
      dispatch(tableActions.forceUpdateTable(true));
    }
    return data;
  });

  const [addStudentState, addStudent] = useAsyncFn(async (studentId, formData) => {
    const data = await helpers.addStudentToCourse(studentId, formData);
    if (!data.error) {
      dispatch(modalActions.closeModal());
      dispatch(tableActions.forceUpdateTable(true));
      handleChangeStudentsCount('add');
    }
    return data;
  });

  const handleAddDocument = () => {
    if (isFrozen) return false;
    dispatch(
      modalActions.openModal(
        {
          size: 'medium',
          title: t('courseDetails.addDocumentModalTitle'),
        },

        <SendFileForm
          onSubmitForm={addFile}
          rejectAction={() => {
            dispatch(modalActions.closeModal());
          }}
          submitting={addFileState.loading}
          fileFormat=".pdf, .pptx, .doc, .docx, .xlsx, .ppt, .jpeg, .jpg, .png"
        />,
      ),
    );
    return true;
  };

  const handleSubmitStudentForm = async (studentId, values) => {
    const data = await addStudent(studentId, values);

    return data;
  };

  const handleAddStudent = () => {
    if (isFrozen) return false;
    dispatch(
      modalActions.openModal(
        {
          size: 'medium',
          title: t('courseDetails.addStudentModalTitle'),
          customStyles: classes.modal,
        },
        <AddStudent
          onSubmitForm={handleSubmitStudentForm}
          closeModal={() => {
            dispatch(modalActions.closeModal());
          }}
          submitting={addStudentState.loading}
          courseTemplateId={courseTemplateId}
          courseId={courseId}
          stationId={stationId}
          isExternal={isExternal}
        />,
      ),
    );
    return true;
  };

  return (
    <Tabulation
      tabsData={[
        {
          label: t('courseDetails.manageStudentsTab'),
          alias: 'students',
          Component: StudentsTableComponent,
        },
        {
          label: t('courseDetails.documentsTab'),
          alias: 'documents',
          Component: DocumentsTableComponent,
        },
      ]}
      actions={[
        {
          hiddenOnTabs: [1],
          Component: getTabActionComponent(t('courseDetails.actionAddStudent'), 'add', handleAddStudent, isFrozen),
        },
        {
          hiddenOnTabs: [0],
          Component: getTabActionComponent(t('courseDetails.actionAddDocument'), 'add', handleAddDocument, isFrozen),
        },
      ]}
    />
  );
};

CourseDetails.propTypes = {
  courseId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  stationId: PropTypes.string.isRequired,
  helpers: PropTypes.shape({
    getDocuments: PropTypes.func,
    deleteDocument: PropTypes.func,
    addDocument: PropTypes.func,
    downloadDocument: PropTypes.func,
    fetchCourseStudents: PropTypes.func,
    addStudentToCourse: PropTypes.func,
    fetchAllStudents: PropTypes.func,
    removeStudentFromCourse: PropTypes.func,
    getEditorData: PropTypes.func,
    setDocumentVisibility: PropTypes.func,
    getCourseTemplatesDocuments: PropTypes.func,
    setDocumentTemplateVisibility: PropTypes.func,
    deleteCourseTemplateDocument: PropTypes.func,
    downloadCourseTemplateDocument: PropTypes.func,
  }).isRequired,
  courseTemplateId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  handleChangeStudentsCount: PropTypes.func.isRequired,
  isExternal: PropTypes.bool.isRequired,
  routesAliases: PropTypes.shape({}).isRequired,
  isFrozen: PropTypes.bool.isRequired,
};

export default CourseDetails;

import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import Loader from 'components/Loader';
import PageContent from './PageContent';
import computeActionSection from './helpers/computeActionSection';

const useStyles = makeStyles(theme => ({
  pageHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',

    '& .pageTitle': {
      fontSize: '29px',
      color: theme.palette.primary.main,
    },
  },
  headerActions: {
    display: 'flex',
    alignItems: 'center',

    '& > *': {
      marginLeft: theme.spacing(1),
    },
  },
  actionsSection: {
    display: 'flex',
    margin: '30px 0',
    justifyContent: 'space-between',

    '& .leftSection': {
      display: 'flex',
      alignItems: 'center',
      '& > *': {
        marginRight: theme.spacing(1),
      },
    },

    '& .rightSection': {
      display: 'flex',
      alignItems: 'center',
      flexWrap: 'wrap',
      '& > *': {
        marginLeft: theme.spacing(1),
        marginBottom: '3px',
      },
    },
  },
  content: {
    padding: theme.spacing(2, 4),
    marginTop: theme.spacing(5),
  },
  contentHeader: {
    '& .contentTitle': {
      fontSize: '24px',
      color: theme.palette.primary.light,
      textTransform: 'capitalize',
    },
  },
}));

const PageLayout = props => {
  const classes = useStyles();
  const {
    actionSectionLeft,
    actionSectionRight,
    pageContentActions,
    headerActions,
    pageTitle,
    children,
    classes: customClasses,
    contentHeader,
    showLoader,
    additionSections,
  } = props;

  const layoutPartsClasses = {
    pageHeader: classNames({
      [classes.pageHeader]: true,
      [customClasses.pageHeader]: !!customClasses.pageHeader,
    }),
    headerActions: classNames({
      [classes.headerActions]: true,
      [customClasses.headerActions]: !!customClasses.headerActions,
    }),
    actionsSection: classNames({
      [classes.actionsSection]: true,
      [customClasses.actionsSection]: !!customClasses.actionsSection,
    }),
    content: classNames({
      [classes.content]: true,
      [customClasses.content]: !!customClasses.content,
    }),
    contentHeader: classNames({
      [classes.contentHeader]: true,
      [customClasses.contentHeader]: !!customClasses.contentHeader,
    }),
  };

  return (
    <div>
      <div className={layoutPartsClasses.pageHeader}>
        {React.isValidElement(pageTitle) ? React.cloneElement(pageTitle) : <h1 className="pageTitle">{pageTitle}</h1>}
        <div className={layoutPartsClasses.headerActions}>{computeActionSection(headerActions)}</div>
      </div>
      {!(actionSectionRight.length || actionSectionLeft.length) ? null : (
        <div className={layoutPartsClasses.actionsSection}>
          <div className="leftSection">{computeActionSection(actionSectionLeft)}</div>
          <div className="rightSection">{computeActionSection(actionSectionRight)}</div>
        </div>
      )}
      <PageContent
        contentClass={layoutPartsClasses.content}
        contentHeaderClass={layoutPartsClasses.contentHeader}
        contentHeader={contentHeader}
        actions={pageContentActions}
      >
        {children}
      </PageContent>
      {additionSections &&
        additionSections.map((section, index) => (
          <PageContent
            key={index}
            contentClass={`${classes.content} ${section.contentClass}`}
            contentHeaderClass={`${classes.contentHeader} ${section.contentHeaderClass}`}
            contentHeader={section.contentHeader}
            actions={section.actions}
          >
            {section.component}
          </PageContent>
        ))}
      {showLoader && <Loader />}
    </div>
  );
};

PageLayout.defaultProps = {
  pageTitle: '',
  actionSectionLeft: [],
  actionSectionRight: [],
  headerActions: [],
  pageContentActions: [],
  classes: {},
  showLoader: false,
  contentHeader: '',
  additionSections: [],
};

const actionShape = PropTypes.shape({
  type: PropTypes.string.isRequired,
  title: PropTypes.string,
  icon: PropTypes.string,
  onClick: PropTypes.func,
  isHidden: PropTypes.bool,
  Component: PropTypes.node,
});

PageLayout.propTypes = {
  pageTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  headerActions: PropTypes.arrayOf(actionShape),
  pageContentActions: PropTypes.arrayOf(actionShape),
  actionSectionLeft: PropTypes.arrayOf(actionShape),
  actionSectionRight: PropTypes.arrayOf(actionShape),
  children: PropTypes.node.isRequired,
  classes: PropTypes.shape({
    pageHeader: PropTypes.string,
    headerActions: PropTypes.string,
    actionsSection: PropTypes.string,
    content: PropTypes.string,
    contentHeader: PropTypes.string,
  }),
  showLoader: PropTypes.bool,
  contentHeader: PropTypes.string,
  additionSections: PropTypes.arrayOf(
    PropTypes.shape({
      contentClass: PropTypes.string,
      contentHeaderClass: PropTypes.string,
      contentHeader: PropTypes.string,
      component: PropTypes.node,
      actions: PropTypes.arrayOf(actionShape),
    }),
  ),
};

export default PageLayout;

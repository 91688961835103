const calculateStatisticsPercents = (data, value) => {
  const sum = data.reduce((prevVal, currVal) => {
    const currentValue = currVal.value ? currVal.value : 0;
    return prevVal + currentValue;
  }, 0);

  return ((value / sum) * 100).toFixed(1);
};

export default calculateStatisticsPercents;

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { TableCell, TableHead, TableRow, TableSortLabel, Icon } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  headerCell: {
    color: theme.palette.text.grey,
    textTransform: 'uppercase',
    padding: '10px 16px',
  },
}));

const getInitialColumnsSorting = (columns, prevSorting = {}) => {
  return columns
    .filter(({ isSorting }) => isSorting)
    .reduce(
      (prev, current) => ({
        ...prev,
        [current.field]: prevSorting[current.field] || 'desc',
      }),
      prevSorting,
    );
};

const AppTableHead = props => {
  const classes = useStyles();
  const { columns, alignment, actions, actionsAlignment, onColumnSorting } = props;
  const initialSorting = getInitialColumnsSorting(columns, {});
  const [sorting, setSorting] = useState(initialSorting);

  useEffect(() => {
    setSorting(getInitialColumnsSorting(columns, sorting));
  }, [columns]);

  const sortingHandler = (field, dir) => {
    const nextDir = dir === 'asc' ? 'desc' : 'asc';
    setSorting({
      ...initialSorting,
      [field]: nextDir,
    });

    if (onColumnSorting) {
      onColumnSorting(field, nextDir);
    }
  };

  return (
    <TableHead>
      <TableRow>
        {columns.map((column, index) => {
          const { title, field, columnWidth, hiddenHeader, isVisible } = column;
          const extColumnProps = {};
          if (columnWidth) {
            extColumnProps.style = {
              width: columnWidth,
            };
          }
          return (
            isVisible && (
              <TableCell
                key={index}
                align={column.alignment || alignment}
                classes={{
                  root: classes.headerCell,
                }}
                {...extColumnProps}
              >
                {!sorting[field] ? (
                  !hiddenHeader && title
                ) : (
                  <TableSortLabel
                    direction={sorting[field]}
                    onClick={() => {
                      sortingHandler(field, sorting[field]);
                    }}
                    IconComponent={() => {
                      return sorting[field] === 'desc' ? (
                        <Icon>keyboard_arrow_down</Icon>
                      ) : (
                        <Icon>keyboard_arrow_up</Icon>
                      );
                    }}
                  >
                    {title}
                  </TableSortLabel>
                )}
              </TableCell>
            )
          );
        })}
        {actions && !!actions.length && (
          <TableCell
            align={actionsAlignment}
            classes={{
              root: classes.headerCell,
            }}
          >
            Actions
          </TableCell>
        )}
      </TableRow>
    </TableHead>
  );
};

AppTableHead.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      field: PropTypes.string.isRequired,
      alignment: PropTypes.string,
      isSorting: PropTypes.bool,
      hiddenHeader: PropTypes.bool,
      columnWidth: PropTypes.string,
    }),
  ).isRequired,
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string,
      title: PropTypes.string,
      icon: PropTypes.string,
      color: PropTypes.string,
      onClick: PropTypes.func,
    }),
  ).isRequired,
  alignment: PropTypes.string.isRequired,
  actionsAlignment: PropTypes.string.isRequired,
  onColumnSorting: PropTypes.func.isRequired,
};

export default AppTableHead;

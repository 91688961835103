import React, { lazy } from 'react';
import { Route, Switch } from 'react-router-dom';
import OuterLayout from 'layouts/OuterLayout';
import withLayout from 'HOC/withLayout';
import { BASE_USER_URL } from 'appConstants';
import PrivateRoute from './PrivateRoute';

const Auth = lazy(() => import('pages/Auth'));
const SetNewPassword = lazy(() => import('pages/SetNewPassword'));
const Users = lazy(() => import('./Users'));

const AppRouter = () => {
  return (
    <Switch>
      <Route exact strict path="/password/set" component={withLayout(SetNewPassword, OuterLayout)} />
      <Route path="/auth" component={withLayout(Auth, OuterLayout)} />
      <PrivateRoute path={`${BASE_USER_URL}`} redirectTo="/auth" component={Users} />
    </Switch>
  );
};

export default AppRouter;

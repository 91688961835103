/* eslint-disable no-param-reassign */
import { http } from '../index';
import { formDataModifier } from '../modifiers';

const multiPartConfig = {
  headers: {
    'Content-Type': 'multipart/form-data',
  },
};

export const getCompany = id => {
  return http
    .get(`/company/${id}`)
    .then(res => res.data)
    .catch(error => error.response.data);
};

export const getCompanies = params => {
  return http
    .get('/companies', {
      params,
    })
    .then(res => res.data)
    .catch(error => error.response.data);
};

export const createCompany = data => {
  const config = [];
  config.push(data);
  if (data.logo && typeof data.logo !== 'string' && data.logo.length > 0) {
    const formData = formDataModifier(data);
    config[0] = formData;
    config.push(multiPartConfig);
  }
  return http
    .post('/companies', config[0], config[1])
    .then(res => res.data)
    .catch(error => error.response.data);
};

export const updateCompany = (data, companyId) => {
  const config = [];
  config.push(data);
  if (data.logo && typeof data.logo !== 'string' && data.logo.length > 0) {
    const formData = formDataModifier(data);
    config[0] = formData;
    config.push(multiPartConfig);
  }

  return http
    .put(`/company/${companyId}`, config[0], config[1])
    .then(res => res.data)
    .catch(error => error.response.data);
};

export const deleteCompany = ({ id }) => {
  return http
    .delete(`/company/${id}`)
    .then(res => res.data)
    .catch(error => error.response.data);
};

export const deleteCompanyStation = ({ stationId, companyId }) => {
  return http
    .delete(`/company/${companyId}/station/${stationId}`)
    .then(res => res.data)
    .catch(error => error.response.data);
};

export const disableCompany = ({ id }) => {
  return http
    .get(`/company/${id}/disable`)
    .then(res => res.data)
    .catch(error => error.response.data);
};

export const enableCompany = ({ id }) => {
  return http
    .get(`/company/${id}/enable`)
    .then(res => res.data)
    .catch(error => error.response.data);
};

export const toggleCompanyState = (id, enable) => {
  return http
    .get(`/company/${id}/${enable}`)
    .then(res => res.data)
    .catch(error => error.response.data);
};

export const getCompanyInfoBySlug = data => {
  return http
    .get('/companies/info', {
      params: {
        slug: data,
      },
    })
    .then(res => {
      http.interceptors.request.use(config => {
        if (res.data.id) config.headers.companyid = res.data.id;
        return config;
      });
      return res.data;
    })
    .catch(error => error.response.data);
};

import React from 'react';
import { DateFormatter, MultipleChoice } from 'components/AppTable/CellsComponents';
import i18next from 'i18next';

export const getStudentsConfig = () => [
  {
    title: i18next.t('tablesLabels.id'),
    field: 'id',
  },
  {
    title: i18next.t('tablesLabels.name'),
    field: 'fullName',
  },
  {
    title: i18next.t('tablesLabels.email'),
    field: 'email',
  },
  {
    title: i18next.t('tablesLabels.expiryDate'),
    field: 'expirationDate',
    Component: value => (value === null ? i18next.t('courseDetails.notSpecified') : <DateFormatter value={value} />),
  },
];

export const getDocumentsConfig = helpers => [
  {
    title: i18next.t('tablesLabels.id'),
    field: 'id',
  },
  {
    title: i18next.t('tablesLabels.name'),
    field: 'name',
  },
  {
    title: i18next.t('tablesLabels.size'),
    field: 'documentSize',
    alignment: 'center',
    Component: size => (
      <div>
        {(size / 1000000).toFixed(3)}
        &ensp; MB
      </div>
    ),
  },
  {
    title: i18next.t('tablesLabels.uploadDate'),
    field: 'createdAt',
    Component: value => <DateFormatter value={value} />,
  },
  {
    title: i18next.t('tablesLabels.byWhom'),
    field: 'userUploaded',
  },
  {
    title: i18next.t('tablesLabels.visibility'),
    field: 'visibility',
    Component: value => <MultipleChoice value={value} onChange={helpers.handleToggleVisibility} />,
  },
  {
    title: '',
    field: 'type',
    isVisible: false,
  },
];

import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
  header: {
    fontSize: '40px',
    color: theme.palette.primary.main,
  },
}));

const NotFound = () => {
  const classes = useStyles();
  const { t } = useTranslation('common');
  return <h1 className={classes.header}>{t('errors.pageNotFound')}</h1>;
};

export default NotFound;

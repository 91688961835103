const initialState = {
  tableData: [],
  count: 0,
  page: 0,
  sorting: {},
  filtering: {},
  pageSize: 10,
  needForceUpdate: false,
  fetchMethod: {},
  isDeleting: false,
  graphic: {},
  total: {},
  diagrams: {},
  withoutParams: false,
};

export const setTableData = (state, { payload }) => ({
  ...state,
  tableData: payload.data,
  count: payload.count,
  total: payload.total,
  graphic: payload.graphic,
  diagrams: payload.diagrams,
});

export const setTablePage = (state, { payload }) => ({
  ...state,
  page: payload,
});

export const setTableSorting = (state, { payload }) => ({
  ...state,
  sorting: payload,
});

export const setTableFiltering = (state, { payload }) => ({
  ...state,
  filtering: { ...state.filtering, ...payload },
});

export const forceUpdateTable = (state, { payload }) => ({
  ...state,
  needForceUpdate: payload,
});

export const cleanUpTable = () => initialState;

export const setFetchMethod = (state, { payload }) => ({
  ...state,
  fetchMethod: {
    [payload.alias]: payload.method,
  },
});

export const removeTableFilter = (state, { payload }) => {
  const transformedFiltersObj = { ...state.filtering };
  delete transformedFiltersObj[payload];
  return {
    ...state,
    filtering: transformedFiltersObj,
  };
};

export const setTableDeleting = (state, { payload }) => ({
  ...state,
  isDeleting: payload,
});

export default initialState;

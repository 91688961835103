import React from 'react';
import PropTypes from 'prop-types';
import { Breadcrumbs } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  root: {
    fontSize: '16px',
    fontWeight: 'bold',
    textTransform: 'capitalize',
  },
  link: {
    color: theme.palette.text.grey,
  },
  activeLink: {
    fontSize: '29px',
    color: theme.palette.primary.main,
    lineHeight: 1,
  },
  breadcrumbList: {
    alignItems: 'flex-end',
    color: theme.palette.text.grey,
  },
}));

const AppBreadcrumbs = ({ items }) => {
  const classes = useStyles();

  return (
    <Breadcrumbs
      aria-label="breadcrumb"
      className={classes.root}
      classes={{
        ol: classes.breadcrumbList,
      }}
    >
      {items.map((item, index) => {
        return !item.isActive ? (
          <Link key={index} to={item.link} className={classes.link}>
            {item.title}
          </Link>
        ) : (
          <span key={index} className={classes.activeLink}>
            {item.title}
          </span>
        );
      })}
    </Breadcrumbs>
  );
};

AppBreadcrumbs.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      link: PropTypes.string,
      isActive: PropTypes.bool,
    }),
  ).isRequired,
};

export default AppBreadcrumbs;

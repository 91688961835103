import i18next from 'i18next';

export const mapExpirationMonth = val => {
  let expirationObject;
  switch (val) {
    case 0:
      expirationObject = {
        value: val,
        label: i18next.t('label.infinity'),
      };
      break;
    default:
      expirationObject = {
        value: Number(val),
        label: String(val),
      };
      break;
  }
  return expirationObject;
};

import React from 'react';
import PropTypes from 'prop-types';
import AppTable from 'components/AppTable';
import Loader from 'components/Loader';
import withTableProps from 'HOC/types/withTable';
import { useTranslation } from 'react-i18next';
import { transformTableData, getConfirmationModalConfig } from 'utils';
import { useAsyncFn } from 'react-use';
import { useDispatch } from 'react-redux';
import { actions as modalActions } from 'redux/modal';
import { actions } from 'redux/table';
import { getDocumentsConfig } from './helpers/getDocumentsTableConfig';

const DocumentsTable = ({ tableData, userId, helpers }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation('common');

  const handleCloseModal = () => dispatch(modalActions.closeModal());

  const tableConfig = getDocumentsConfig();
  const [columns, documents] = transformTableData(tableConfig, tableData.data);

  const [documentDeleting, deleteDocument] = useAsyncFn(async documentId => {
    const data = await helpers.deleteDocument(userId, documentId);
    if (!data.error) {
      handleCloseModal();
      dispatch(actions.setTableDeleting(true));
      dispatch(actions.forceUpdateTable(true));
    }
  });

  const handleDeleteDocument = event => {
    const id = event.currentTarget.dataset.key;
    const [config, element] = getConfirmationModalConfig({
      title: t('students.modalRemoveDocument'),
      confirmAction: () => deleteDocument(id),
      rejectAction: handleCloseModal,
      message: t('students.modalRemoveDocumentConfirmation'),
    });
    dispatch(modalActions.openModal(config, element));
  };

  const [documentDownloading, downloadDocument] = useAsyncFn(async documentId => {
    await helpers.downloadDocument(userId, documentId);
  });

  const handleDownloadDocument = event => {
    downloadDocument(event.currentTarget.dataset.key);
  };

  const tableActions = [
    {
      type: 'icon',
      icon: 'get_app',
      onClick: handleDownloadDocument,
    },
    {
      type: 'icon',
      icon: 'delete',
      color: 'error',
      onClick: handleDeleteDocument,
    },
  ];

  const isLoading = documentDeleting.loading || documentDownloading.loading;

  return (
    <>
      <AppTable
        columns={columns}
        data={documents}
        totalCount={tableData.data.length}
        rowsPerPage={5}
        actions={tableActions}
      />
      {isLoading && <Loader />}
    </>
  );
};

DocumentsTable.propTypes = {
  userId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  tableData: PropTypes.shape(withTableProps).isRequired,
  helpers: PropTypes.shape({
    getDocuments: PropTypes.func,
    deleteDocument: PropTypes.func,
    downloadDocument: PropTypes.func,
    getEditorData: PropTypes.func,
  }).isRequired,
};

export default DocumentsTable;

import React from 'react';
import PropTypes from 'prop-types';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { Icon } from '@material-ui/core';
import useStyles from './styles';

const CustomDatePicker = props => {
  const classes = useStyles();
  const {
    input: { name, value, onChange, ...restInput },
    meta,
    variant,
    warningMessage,
    disabled,
    dataFormat,
    ...rest
  } = props;
  const serverError = !meta.dirtySinceLastSubmit && meta.submitError;
  const isError = (meta.error || serverError) && meta.touched;

  const isWarning = warningMessage && disabled;
  const errorText = isError && (meta.error || serverError);

  const warningTextComponent = <span className={classes.warningMessage}>{warningMessage}</span>;

  return (
    <KeyboardDatePicker
      className={classes.datePicker}
      {...rest}
      inputVariant={variant}
      format={dataFormat || 'MM/dd/yyyy'}
      name={name}
      helperText={errorText || (isWarning && warningTextComponent) || undefined}
      error={isError}
      disabled={disabled}
      inputProps={restInput}
      onChange={onChange}
      value={value === '' ? null : value}
      keyboardIcon={<Icon>calendar_today</Icon>}
    />
  );
};

CustomDatePicker.defaultProps = {
  fullWidth: false,
  variant: 'outlined',
  warningMessage: undefined,
  disabled: false,
  dataFormat: undefined,
};

CustomDatePicker.propTypes = {
  input: PropTypes.shape({
    name: PropTypes.string,
    type: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
    onChange: PropTypes.func,
  }).isRequired,
  variant: PropTypes.string,
  label: PropTypes.string.isRequired,
  meta: PropTypes.shape().isRequired,
  id: PropTypes.string.isRequired,
  fullWidth: PropTypes.bool,
  warningMessage: PropTypes.string,
  disabled: PropTypes.bool,
  dataFormat: PropTypes.string,
};

export default CustomDatePicker;

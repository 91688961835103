import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Tabs, Tab } from '@material-ui/core';
import TabPanel from './TabPanel';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    position: 'relative',
  },
  flexContainer: {
    float: 'left',
    color: theme.palette.text.grey,
    '& button': {
      fontSize: '16px',
    },
  },
  tabsActions: {
    display: 'flex',
    float: 'right',
    '& .actionsItem + .actionsItem': {
      marginLeft: '15px',
    },
  },
  clearfix: {
    display: 'flow-root',
    '&:after': {
      content: '',
      clear: 'both',
      display: 'table',
    },
  },
}));

const Tabulation = props => {
  const classes = useStyles();
  const { tabsData, onTabChange, activeTab, commonComponent, actions } = props;
  const [value, setValue] = useState(activeTab);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    if (onTabChange) {
      onTabChange(event, {
        tabIndex: newValue,
        alias: event.currentTarget.dataset.alias,
      });
    }
  };

  useEffect(() => {
    if (activeTab) setValue(activeTab);
  }, [activeTab]);

  return (
    <div className={classes.root}>
      <div className={classes.clearfix}>
        {!!actions.length && (
          <div className={classes.tabsActions}>
            {actions.map(({ hiddenOnTabs, Component }, index) => {
              return hiddenOnTabs && hiddenOnTabs.includes(value) ? null : (
                <div key={index} className="actionsItem">
                  {React.cloneElement(Component)}
                </div>
              );
            })}
          </div>
        )}

        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="inherit"
          className={classes.flexContainer}
        >
          {tabsData.map((tab, index) => (
            <Tab key={index} label={tab.label} data-alias={tab.alias} wrapped />
          ))}
        </Tabs>
      </div>

      {tabsData.map((tab, index) => (
        <TabPanel key={index} value={value} index={index}>
          {tab.Component || commonComponent}
        </TabPanel>
      ))}
    </div>
  );
};

Tabulation.defaultProps = {
  onTabChange: () => {},
  activeTab: 0,
  commonComponent: null,
  actions: [],
};

Tabulation.propTypes = {
  tabsData: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      alias: PropTypes.string.isRequired,
      Component: PropTypes.oneOfType([PropTypes.element, PropTypes.node]),
    }),
  ).isRequired,
  onTabChange: PropTypes.func,
  activeTab: PropTypes.number,
  commonComponent: PropTypes.oneOfType([PropTypes.element, PropTypes.node, PropTypes.func]),
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      hiddenOnTabs: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
      Component: PropTypes.oneOfType([PropTypes.element, PropTypes.node, PropTypes.func]),
    }),
  ),
};

export default Tabulation;

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import AppTable from 'components/AppTable';
import Loader from 'components/Loader';
import { transformTableData, getConfirmationModalConfig } from 'utils';
import { useAsyncFn } from 'react-use';
import { useDispatch, useSelector } from 'react-redux';
import { actions } from 'redux/table';
import { actions as modalActions } from 'redux/modal';
import { useTranslation } from 'react-i18next';
import { ROLES } from 'appConstants';
import { getDocumentsConfig } from './helpers/getTableConfig';

const DocumentsTable = ({ courseId, stationId, helpers, isExternal, isFrozen, courseTemplateId }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation('common');
  const { auth, table } = useSelector(state => state);
  const { needForceUpdate, tableData } = table;
  const { role } = auth.profile;

  const handleCloseModal = () => dispatch(modalActions.closeModal());

  const [files, setFiles] = useState([]);

  const [documentsFetching, fetchDocuments] = useAsyncFn(async () => {
    let courseFiles;
    const dataCourse = await helpers.getDocuments();
    if (!dataCourse.error) {
      courseFiles = dataCourse.items.map(item => ({
        ...item,
        userUploaded: `${item.uploadedBy && item.uploadedBy.info.firstName} ${item.uploadedBy &&
          item.uploadedBy.info.lastName}`,
        visibility: {
          isVisibleForStudent: item.isVisibleForStudent,
          isVisibleForTrainer: item.isVisibleForTrainer,
          id: item.id,
          type: 'course',
        },
        type: 'course',
      }));
    }

    const dataTemplate = await helpers.getCourseTemplatesDocuments(courseTemplateId);
    let templateFiles;
    if (!dataTemplate.error) {
      templateFiles = dataTemplate.items.map(item => ({
        ...item,
        userUploaded: `${item.uploadedByUser && item.uploadedByUser.info.firstName} ${item.uploadedByUser &&
          item.uploadedByUser.info.lastName}`,
        visibility: {
          isVisibleForStudent: item.isVisibleForStudent,
          isVisibleForTrainer: item.isVisibleForTrainer,
          id: item.id,
          type: 'template',
          isDisabled: role !== ROLES.SUPER,
        },
        type: 'template',
      }));
    }

    const combineFiles = [...courseFiles, ...templateFiles];

    setFiles(combineFiles);

    dispatch(
      actions.setTableData({
        data: combineFiles,
        count: combineFiles.length,
      }),
    );

    return combineFiles;
  });

  const [documentStateUpdating, updateDocumentState] = useAsyncFn(async (fileId, data, type) => {
    let fetchResponse;
    if (type === 'template') {
      fetchResponse = helpers.setDocumentTemplateVisibility(courseTemplateId, fileId, data);
    } else {
      const visibilityParams = isExternal ? [courseId, fileId, data] : [courseId, fileId, stationId, data];
      fetchResponse = await helpers.setDocumentVisibility(...visibilityParams);
    }
    fetchDocuments();
    return fetchResponse;
  });

  const handleToggleVisibility = (fileId, data, type) => {
    updateDocumentState(fileId, data, type);
  };

  const tableConfig = getDocumentsConfig({ handleToggleVisibility });
  const [columns, documents] = transformTableData(tableConfig, tableData);

  const [documentDeleting, deleteDocument] = useAsyncFn(async (fileId, type) => {
    let data;
    if (type === 'template') {
      data = await helpers.deleteCourseTemplateDocument(courseTemplateId, fileId);
    } else {
      data = await helpers.deleteDocument(fileId);
    }
    if (!data.error) {
      handleCloseModal();
      dispatch(actions.setTableDeleting(true));
      dispatch(actions.forceUpdateTable(true));
    }
  });

  const handleDeleteDocument = event => {
    const { key: fileId, type } = event.currentTarget.dataset;
    if (type === 'template' && role !== ROLES.SUPER) {
      dispatch(
        modalActions.openModal(
          {
            size: 'medium',
            title: t('errors.notification'),
          },
          <p>{t('errors.noFileRemovePermission')}</p>,
        ),
      );
      return false;
    }

    const [config, element] = getConfirmationModalConfig({
      title: t('courseDetails.deleteDocumentConfirmationTitle'),
      confirmAction: () => deleteDocument(fileId, type),
      rejectAction: handleCloseModal,
      message: t('courseDetails.deleteDocumentConfirmationMessage'),
    });
    dispatch(modalActions.openModal(config, element));
    return true;
  };

  const [documentDownloading, downloadDocument] = useAsyncFn(async (fileId, type) => {
    if (type === 'template') {
      helpers.downloadCourseTemplateDocument(courseTemplateId, fileId);
    } else {
      const courseTypeParams = isExternal ? [courseId, fileId] : [stationId, courseId, fileId];
      await helpers.downloadDocument(...courseTypeParams);
    }
  });

  const handleDownloadDocument = event => {
    const { key, type } = event.currentTarget.dataset;
    downloadDocument(key, type);
  };

  useEffect(() => {
    fetchDocuments();
    return () => {
      dispatch(actions.cleanUpTable());
    };
  }, [courseId]);

  useEffect(() => {
    if (needForceUpdate === true) {
      fetchDocuments();
      dispatch(actions.forceUpdateTable(false));
    }
  }, [needForceUpdate]);

  const tableActions = [
    {
      type: 'icon',
      icon: 'get_app',
      disabled: isFrozen,
      onClick: handleDownloadDocument,
    },
    {
      type: 'icon',
      icon: 'delete',
      color: 'error',
      disabled: isFrozen,
      onClick: handleDeleteDocument,
    },
  ];

  const isLoading =
    documentDeleting.loading ||
    documentDownloading.loading ||
    documentStateUpdating.loading ||
    documentsFetching.loading;

  return (
    <>
      <AppTable
        columns={columns}
        data={documents}
        totalCount={files.length}
        rowsPerPage={5}
        actions={tableActions}
        extraActionsKeys={['type']}
        actionsAlignment="right"
      />
      {isLoading && <Loader />}
    </>
  );
};

DocumentsTable.propTypes = {
  courseId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  stationId: PropTypes.string.isRequired,
  helpers: PropTypes.shape({
    getDocuments: PropTypes.func,
    deleteDocument: PropTypes.func,
    downloadDocument: PropTypes.func,
    getEditorData: PropTypes.func,
    setDocumentVisibility: PropTypes.func,
    getCourseTemplatesDocuments: PropTypes.func,
    setDocumentTemplateVisibility: PropTypes.func,
    deleteCourseTemplateDocument: PropTypes.func,
    downloadCourseTemplateDocument: PropTypes.func,
  }).isRequired,
  isExternal: PropTypes.bool.isRequired,
  isFrozen: PropTypes.bool.isRequired,
  courseTemplateId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
};

export default DocumentsTable;

import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  root: {
    display: 'inline-block',
    backgroundColor: ({ backgroundColor }) => backgroundColor,
    borderRadius: '6px',
    width: '42px',
    height: '30px',
  },
}));

const ColoredRectangle = ({ color }) => {
  const classes = useStyles({ backgroundColor: color });

  return <div className={classes.root} />;
};

ColoredRectangle.propTypes = {
  color: PropTypes.string.isRequired,
};

export default ColoredRectangle;

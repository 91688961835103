import React from 'react';
import { DateFormatter } from 'components/AppTable/CellsComponents';
import i18next from 'i18next';

const getTableConfig = () => {
  return [
    {
      title: i18next.t('tablesLabels.id'),
      field: 'id',
    },
    {
      title: i18next.t('tablesLabels.jobTask'),
      field: 'name',
      isSorting: true,
    },
    {
      title: i18next.t('tablesLabels.updatedBy'),
      field: 'fullName',
      isSorting: true,
    },
    {
      title: i18next.t('tablesLabels.numberOfCourses'),
      field: 'coursesCount',
      alignment: 'center',
    },
    {
      title: i18next.t('tablesLabels.lastUpdate'),
      field: 'updatedAt',
      Component: value => <DateFormatter value={value} />,
      isSorting: true,
    },
  ];
};

export default getTableConfig;

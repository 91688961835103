export const DRAWER_WIDTH = 240;

export const ROLES = {
  HYPER: 'hyper_admin',
  SUPER: 'super_admin',
  ADMIN: 'admin',
  USER: 'student',
  TRAINER: 'trainer',
};

export const BASE_USER_URL = '/';

export const ROUTES = {
  COMPANIES: 'companies',
  DASHBOARD: 'dashboard',
  NOTIFICATIONS: 'notifications',
  ADMINISTRATORS: 'administrators',
  TRAINERS: 'trainers',
  TASKS: 'tasks',
  TEMPLATES: 'templates',
  COURSES: 'courses',
  STATISTICS: 'statistics',
  SCHEDULE: 'schedule',
  PROFILE: 'profile',
};

export const STATISTIC_BY_EXPIRE_DATE_COLORS = {
  expired: '#C84F48',
  '1_week': '#f99f99',
  '1_month': '#DAAD38',
  '3_month': '#876DA7',
  up_to_date: '#2C8957',
};

export const JOB_TASKS_STATUSES = {
  mixed: 'Mixed',
  performed: 'Performed',
  notPerformed: 'Not Performed',
};

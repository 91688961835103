import { http } from '../index';
import { addFullNameField, documentsModifier } from '../formatters';
import { formDataModifier } from '../modifiers';
import {
  downloadFileFromBytes,
  getFileNameFromContentDispositionString,
} from '../helpers';

const multiPartConfig = {
  headers: {
    'Content-Type': 'multipart/form-data',
  },
};

export const getUser = id => {
  return http
    .get(`/user/${id}`)
    .then(res => res.data)
    .catch(error => error.response.data);
};

export const deleteUser = id => {
  return http
    .delete(`/user/${id}`)
    .then(res => res.data)
    .catch(error => error.response.data);
};

export const disableUser = id => {
  return http
    .get(`/user/${id}/disable`)
    .then(res => res.data)
    .catch(error => error.response.data);
};

export const enableUser = id => {
  return http
    .get(`/user/${id}/enable`)
    .then(res => res.data)
    .catch(error => error.response.data);
};

export const toggleUserState = (id, enable) => {
  return http
    .get(`/user/${id}/${enable}`)
    .then(res => res.data)
    .catch(error => error.response.data);
};

export const updateUser = (data, userId) => {
  return http
    .put(`/user/${userId}`, data)
    .then(res => res.data)
    .catch(error => error.response.data);
};

export const updateSelf = data => {
  return http
    .post('/user', data)
    .then(res => res.data)
    .catch(error => error.response.data);
};

export const setNewPassword = data => {
  return http
    .post(`/user/password`, data)
    .then(res => res.data)
    .catch(error => error.response.data);
};

export const getAdmins = params => {
  return http
    .get(`/admins`, {
      params,
    })
    .then(res => res.data)
    .catch(error => error.response.data);
};

export const getAllTrainers = params => {
  return http
    .get(`/trainers`, { params })
    .then(res => res.data)
    .catch(error => error.response.data);
};

export const getExternalTrainers = params => {
  return http
    .get('/trainers/external', { params })
    .then(res => res.data)
    .catch(error => error.response.data);
};

export const getLocalTrainers = (stationId, params) => {
  return http
    .get(`/trainers/local/${stationId}`, { params })
    .then(res => res.data)
    .catch(error => error.response.data);
};

export const getStationTrainersCompliant = stationId => {
  return http
    .get(`/station/${stationId}/trainers/complaint`)
    .then(res => addFullNameField(res.data, 'info'))
    .catch(error => error.response.data);
};

export const getTrainersCompliant = params => {
  return http
    .get(`/trainers/complaint`, {
      params,
    })
    .then(res => res.data)
    .catch(error => error.response.data);
};

export const exportAllTrainers = () => {
  return http
    .get('/trainers/export', {
      responseType: 'blob',
    })
    .then(res => {
      const filename = getFileNameFromContentDispositionString(res);
      downloadFileFromBytes(res.data, filename, '', true);
    })
    .catch(error => error.response.data);
};

export const exportLocalTrainers = stationId => {
  return http
    .get(`/trainers/local/${stationId}/export`, {
      responseType: 'blob',
    })
    .then(res => {
      const filename = getFileNameFromContentDispositionString(res);
      downloadFileFromBytes(res.data, filename, '', true);
    })
    .catch(error => error.response.data);
};

export const exportExternalTrainers = () => {
  return http
    .get('/trainers/external/export', {
      responseType: 'blob',
    })
    .then(res => {
      const filename = getFileNameFromContentDispositionString(res);
      downloadFileFromBytes(res.data, filename, '', true);
    })
    .catch(error => error.response.data);
};

export const getStudents = params => {
  return http
    .get(`/students`, {
      params,
    })
    .then(res => res.data)
    .catch(error => error.response.data);
};

export const setTrainer = (data, stationId) => {
  return http
    .post(`/station/${stationId}/trainer`, data)
    .then(res => res.data)
    .catch(error => error.response.data);
};

export const setStudent = (data, stationId) => {
  return http
    .post(`/station/${stationId}/student`, data)
    .then(res => res.data)
    .catch(error => error.response.data);
};

export const setAdmin = (data, stationId) => {
  return http
    .post(`/station/${stationId}/admin`, data)
    .then(res => res.data)
    .catch(error => error.response.data);
};

export const getUserDocuments = userId => {
  return http
    .get(`/user/${userId}/files`)
    .then(res => documentsModifier(res.data))
    .catch(error => error.response.data);
};

export const setUserDocuments = (userId, data) => {
  return http
    .post(`/user/${userId}/files`, formDataModifier(data), multiPartConfig)
    .then(res => res.data)
    .catch(error => error.response.data);
};

export const downloadUserDocument = (userId, fileId) => {
  return http
    .get(`/user/${userId}/file/${fileId}`, {
      responseType: 'blob',
    })
    .then(res => {
      const filename = getFileNameFromContentDispositionString(res);
      downloadFileFromBytes(res.data, filename, '', true);
    })
    .catch(error => error.response.data);
};

export const deleteUserDocument = (userId, fileId) => {
  return http
    .delete(`/user/${userId}/file/${fileId}`)
    .then(res => res.data)
    .catch(error => error.response.data);
};

export const getUserCertificate = userId => {
  return http
    .get(`/user/${userId}/certificates`)
    .then(res => res.data)
    .catch(error => error.response.data);
};

export const setUserCertificate = (userId, data) => {
  return http
    .post(
      `/user/${userId}/certificates`,
      formDataModifier(data),
      multiPartConfig,
    )
    .then(res => res.data)
    .catch(error => error.response.data);
};

export const downloadUserCertificate = (userId, fileId) => {
  return http
    .get(`/user/${userId}/certificate/${fileId}`, {
      responseType: 'blob',
    })
    .then(res => {
      const filename = getFileNameFromContentDispositionString(res);
      downloadFileFromBytes(res.data, filename, '', true);
    })
    .catch(error => error.response.data);
};

export const deleteUserCertificate = (userId, fileId) => {
  return http
    .delete(`/user/${userId}/certificate/${fileId}`)
    .then(res => res.data)
    .catch(error => error.response.data);
};

export const importUsers = (stationId, role, formData) => {
  return http
    .post(
      `/station/${stationId}/import/${role}`,
      formDataModifier(formData),
      multiPartConfig,
    )
    .then(res => res.data)
    .catch(error => error.response.data);
};

export const exportStudents = () => {
  return http
    .get(`/students/export`, {
      responseType: 'blob',
    })
    .then(res => {
      const filename = getFileNameFromContentDispositionString(res);
      downloadFileFromBytes(res.data, filename, '', true);
    })
    .catch(error => error.response.data);
};

export const exportAdminsStudents = () => {
  return http
    .get('/admins/export', {
      responseType: 'blob',
    })
    .then(res => {
      const filename = getFileNameFromContentDispositionString(res);
      downloadFileFromBytes(res.data, filename, '', true);
    })
    .catch(error => error.response.data);
};

export const exportTrainersStudents = () => {
  return http
    .get('/students/export', {
      responseType: 'blob',
    })
    .then(res => {
      const filename = getFileNameFromContentDispositionString(res);
      downloadFileFromBytes(res.data, filename, '', true);
    })
    .catch(error => error.response.data);
};

export const downloadImportUserExample = (stationId, role) => {
  return http
    .get(`/station/${stationId}/import/${role}/example`, {
      responseType: 'blob',
    })
    .then(res => {
      const filename = getFileNameFromContentDispositionString(res);
      downloadFileFromBytes(res.data, filename, '', true);
    })
    .catch(error => error.response.data);
};

export const downloadHistoryExample = userId => {
  return http
    .get(`/student/${userId}/courses/history/import/example`, {
      responseType: 'blob',
    })
    .then(res => {
      const filename = getFileNameFromContentDispositionString(res);
      downloadFileFromBytes(res.data, filename, '', true);
    })
    .catch(error => error.response.data);
};

export const getUserTasks = userId => {
  return http
    .get(`/student/${userId}/jobTasks`)
    .then(res => res.data)
    .catch(error => error.response.data);
};

export const setLang = params => {
  return http
    .get('/user/locale', { params })
    .then(res => res.data)
    .catch(error => error.response.data);
};

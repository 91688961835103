import React from 'react';
import PropTypes from 'prop-types';
import { FormHelperText, FormControl, InputLabel } from '@material-ui/core/';
import useStyles from './styles';

const CustomTextField = ({ input: { name, type, ...restInput }, fullWidth, id, label, meta, ...rest }) => {
  const classes = useStyles();
  const isError = meta.touched && !meta.valid;

  return (
    <FormControl {...restInput} fullWidth={fullWidth} error={isError}>
      <InputLabel className={classes.label} shrink htmlFor={id}>
        {label}
      </InputLabel>
      <div className={classes.inputWrapper}>
        <input type="color" className={classes.inputField} id={id} name={name} {...rest} {...restInput} />
      </div>
      {isError && (
        <FormHelperText id="outlined-weight-helper-text" error>
          {meta.error}
        </FormHelperText>
      )}
    </FormControl>
  );
};

CustomTextField.defaultProps = {
  fullWidth: false,
};

CustomTextField.propTypes = {
  input: PropTypes.shape({
    name: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
  }).isRequired,
  label: PropTypes.string.isRequired,
  meta: PropTypes.shape().isRequired,
  id: PropTypes.string.isRequired,
  fullWidth: PropTypes.bool,
};

export default CustomTextField;

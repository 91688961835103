import React from 'react';
import PropTypes from 'prop-types';
import { Paper } from '@material-ui/core';
import computeActionSection from './helpers/computeActionSection';

const PageContent = ({ contentClass, contentHeaderClass, children, contentHeader, actions }) => {
  return (
    <Paper className={contentClass}>
      {contentHeader && (
        <div className={contentHeaderClass}>
          <h2 className="contentTitle">{contentHeader}</h2>
          {actions && !!actions.length && <div className="contentActions">{computeActionSection(actions)}</div>}
        </div>
      )}
      {children}
    </Paper>
  );
};

PageContent.defaultProps = {
  actions: [],
};

PageContent.propTypes = {
  contentClass: PropTypes.string.isRequired,
  contentHeaderClass: PropTypes.string.isRequired,
  contentHeader: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string.isRequired,
      title: PropTypes.string,
      icon: PropTypes.string,
      onClick: PropTypes.func,
      Component: PropTypes.node,
    }),
  ),
};
export default PageContent;

import React from 'react';
import PropTypes from 'prop-types';
import { FormHelperText, FormControl } from '@material-ui/core/';
import Dropzone from '../Dropzone';

const CustomUpload = ({ input: { name, multiple, ...restInput }, customStyles, fullWidth, id, meta, ...rest }) => {
  const isError = meta.touched && meta.submitFailed && meta.invalid;

  return (
    <FormControl className={customStyles} {...restInput} fullWidth={fullWidth} error={isError}>
      <Dropzone name={name} id={id} multiple={!!multiple} {...rest} />
      {isError && (
        <FormHelperText id="outlined-weight-helper-text" error>
          {meta.error}
        </FormHelperText>
      )}
    </FormControl>
  );
};

CustomUpload.defaultProps = {
  customStyles: null,
};

CustomUpload.propTypes = {
  input: PropTypes.shape({
    name: PropTypes.string.isRequired,
    multiple: PropTypes.bool,
  }).isRequired,
  meta: PropTypes.shape().isRequired,
  id: PropTypes.string.isRequired,
  fullWidth: PropTypes.bool.isRequired,
  customStyles: PropTypes.string,
};

export default CustomUpload;

const initialState = {
  stations: null,
};

export const setStations = (state, { payload }) => ({
  ...state,
  stations: payload,
});

export const clearStations = () => initialState;

export default initialState;

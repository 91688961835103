import React from 'react';
import { DateFormatter } from 'components/AppTable/CellsComponents';
import i18next from 'i18next';

const getTableConfig = () => {
  return [
    {
      title: i18next.t('tablesLabels.id'),
      field: 'id',
    },
    {
      title: i18next.t('tablesLabels.course'),
      field: 'name',
      isSorting: true,
    },
    {
      title: i18next.t('tablesLabels.jobsTasks'),
      field: 'jobTasks',
      isSorting: true,
    },
    {
      title: i18next.t('tablesLabels.studentsNumber'),
      field: 'studentsNumber',
      alignment: 'center',
    },
    {
      title: i18next.t('tablesLabels.startDate'),
      field: 'startDate',
      Component: value => <DateFormatter value={value} />,
      isSorting: true,
    },
    {
      title: i18next.t('tablesLabels.endDate'),
      field: 'endDate',
      Component: value => <DateFormatter value={value} />,
      isSorting: true,
    },
    {
      title: i18next.t('tablesLabels.stationId'),
      field: 'stationId',
      isVisible: false,
    },
  ];
};

export default getTableConfig;

import React from 'react';
import i18next from 'i18next';
import { DateFormatter } from 'components/AppTable/CellsComponents';

const getTableConfig = () => {
  return [
    {
      title: i18next.t('tablesLabels.id'),
      field: 'userId',
      isVisible: false,
    },
    {
      title: i18next.t('tablesLabels.name'),
      field: 'fullName',
      isSorting: true,
    },
    {
      title: i18next.t('tablesLabels.jobTask'),
      field: 'jobTask',
      isSorting: true,
    },
    {
      title: i18next.t('tablesLabels.expired'),
      field: 'expirationDate',
      dependentField: 'expirationType',
      Component: (value, expirationType) =>
        value === null ? (
          i18next.t('courseDetails.notSpecified')
        ) : (
          <DateFormatter value={value} expirationType={expirationType} />
        ),
      isSorting: true,
    },
  ];
};

export default getTableConfig;

/* eslint-disable no-param-reassign */
import React, { lazy, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import CustomRadio from 'components/Forms/Radio';
import CustomTextField from 'components/Forms/TextField';
import CustomSelect from 'components/Forms/CustomSelect';
import { useTranslation } from 'react-i18next';
import { Form, Field } from 'react-final-form';
import { Box, Typography, Grid, Button } from '@material-ui/core';
import { generateErrorObject, transformDataCountriesToSelectOptions } from 'utils';
import { getCountries } from 'http/info';
import { useHistory } from 'react-router-dom';
import { useAsyncFn } from 'react-use';
import configErrors from './configErrors';
import validate from './validation';
import useStyles from './styles';

const Loader = lazy(() => import('components/Loader'));

const AdministratorsForm = ({ onSubmit, isLoading, administratorsData, routesAliases, type }) => {
  const classes = useStyles();
  const { t } = useTranslation('common');
  const history = useHistory();

  const [formData, setFormData] = useState(administratorsData || {});
  const [formControlsOptions, setFormControlsOptions] = useState({
    countries: [],
  });

  const [formControlsState, getFormControlsOptions] = useAsyncFn(async () => {
    const countries = await getCountries();

    const optionsObj = {
      countries: [],
    };

    if (countries && !countries.error) {
      optionsObj.countries = transformDataCountriesToSelectOptions(countries);
    }

    setFormControlsOptions({
      ...formControlsOptions,
      ...optionsObj,
    });
  });

  useEffect(() => {
    if (administratorsData) {
      setFormData({
        ...administratorsData,
        country: transformDataCountriesToSelectOptions(administratorsData.country),
      });
    }
  }, [administratorsData]);

  useEffect(() => {
    getFormControlsOptions();
  }, []);

  const { stations = [] } = formData;

  stations.map(item => {
    item.value = item.id.toString();
    return item;
  });

  const handleFormSubmit = async formValues => {
    const { stationId } = formValues;
    const transformedFormData = {
      ...formValues,
      country: formValues.country?.value || '',
    };

    if (type === 'edit') {
      transformedFormData.stationsIds = [Number(stationId)];
    }

    delete transformedFormData.stationId;
    delete transformedFormData.stations;
    delete transformedFormData.userStation;

    const submitData = await onSubmit(transformedFormData, stationId);
    if (submitData && submitData.error && submitData.message) {
      return generateErrorObject(submitData.message, configErrors);
    }

    return undefined;
  };

  return (
    <Form
      onSubmit={handleFormSubmit}
      validate={validate}
      initialValues={formData}
      keepDirtyOnReinitialize
      render={({ handleSubmit, submitting, values }) => (
        <form onSubmit={handleSubmit}>
          <Box display="flex" width="100%" mb={0}>
            <Box className={classes.generalInfo}>
              <Typography className={classes.subtitle} component="h2">
                {t('administrators.subtitleInformation')}
              </Typography>

              <Box display="flex" flexWrap="nowrap">
                <Box className={classes.userInfo}>
                  <Box width="100%" mb={4}>
                    <Field
                      type="text"
                      id="firstName"
                      name="firstName"
                      label={t('formLabels.firstName')}
                      disabled={submitting}
                      variant="outlined"
                      fullWidth
                      component={CustomTextField}
                      format={(value = '') => value.trim()}
                      formatOnBlur
                    />
                  </Box>

                  <Box width="100%" mb={4}>
                    <Field
                      type="text"
                      id="lastName"
                      name="lastName"
                      label={t('formLabels.lastName')}
                      disabled={submitting}
                      variant="outlined"
                      fullWidth
                      component={CustomTextField}
                      format={(value = '') => value.trim()}
                      formatOnBlur
                    />
                  </Box>

                  <Box width="100%" mb={4}>
                    <Field
                      type="email"
                      id="email"
                      name="email"
                      label={t('formLabels.email')}
                      disabled={submitting}
                      variant="outlined"
                      fullWidth
                      component={CustomTextField}
                      format={(value = '') => value.trim()}
                      formatOnBlur
                    />
                  </Box>
                </Box>

                <Box className={classes.userInfoAddition}>
                  <Box width="100%" mb={4}>
                    <Field
                      type="text"
                      id="country"
                      name="country"
                      label={t('formLabels.country')}
                      disabled={submitting}
                      variant="outlined"
                      fullWidth
                      placeholder={t('placeholders.country')}
                      options={formControlsOptions.countries}
                      component={CustomSelect}
                      isMulti={false}
                    />
                  </Box>

                  <Box width="100%" mb={4}>
                    <Field
                      type="company"
                      id="company"
                      name="company"
                      label={t('formLabels.company')}
                      disabled={submitting}
                      variant="outlined"
                      fullWidth
                      component={CustomTextField}
                      format={(value = '') => value.trim()}
                      formatOnBlur
                    />
                  </Box>
                </Box>
              </Box>
            </Box>

            <Box className={classes.stationsInfo}>
              <Typography className={classes.subtitle} component="h2">
                {t('administrators.subtitleStations')}
              </Typography>

              <Box>
                <Field
                  type="radio"
                  id="stationId"
                  name="stationId"
                  label={t('formLabels.companyStation')}
                  customStyles={classes.customRadio}
                  disabled={submitting}
                  fullWidth
                  component={CustomRadio}
                  items={stations}
                  value={values.stationId || NaN}
                />
              </Box>
            </Box>
          </Box>

          <Grid container spacing={3} className={classes.btnContainer}>
            <Grid item xs={6}>
              <Box textAlign="right">
                <Button
                  className={classes.btn}
                  type="submit"
                  color="primary"
                  variant="contained"
                  disabled={submitting}
                  size="large"
                >
                  {t('buttons.save')}
                </Button>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box textAlign="left">
                <Button
                  className={classes.btn}
                  variant="outlined"
                  size="large"
                  type="reset"
                  onClick={() => history.push(routesAliases.administratorsList)}
                >
                  {t('buttons.cancel')}
                </Button>
              </Box>
            </Grid>
          </Grid>

          {(isLoading || formControlsState.loading) && <Loader />}
        </form>
      )}
    />
  );
};

AdministratorsForm.defaultProps = {
  administratorsData: {
    firstName: '',
    lastName: '',
    email: '',
    stations: [],
    company: '',
    country: '',
  },
};

AdministratorsForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  administratorsData: PropTypes.oneOfType([
    PropTypes.shape({
      firstName: PropTypes.string,
      lastName: PropTypes.string,
      email: PropTypes.string,
      stations: PropTypes.arrayOf(PropTypes.shape({})),
      company: PropTypes.string,
      country: PropTypes.string,
    }),
    PropTypes.bool,
  ]),
  routesAliases: PropTypes.shape({
    administratorsList: PropTypes.string.isRequired,
  }).isRequired,
  type: PropTypes.string.isRequired,
};

export default AdministratorsForm;

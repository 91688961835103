import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Radio, RadioGroup, FormControlLabel, Box, Button } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    '& .MuiTypography-root': {
      whiteSpace: 'nowrap',
      fontSize: '14px',
      lineHeight: '20px',
    },
  },
  mr50: {
    marginRight: '50px',
  },
  btn: {
    marginLeft: '15px',
  },
}));

const StateToggler = ({ value, onChange, ...otherProps }) => {
  const { t } = useTranslation('common');
  const classes = useStyles();
  const [radioGroupValue, setRadioGroupValue] = useState(value);

  const handleChange = () => {
    setRadioGroupValue(!radioGroupValue);
  };

  const saveState = () => {
    onChange({ taskId: otherProps.id, status: radioGroupValue }, setRadioGroupValue);
  };

  return (
    <div className={classes.root}>
      <RadioGroup aria-label="position" name={`${otherProps.id}`} value={radioGroupValue} onChange={handleChange} row>
        <Box flexWrap="nowrap" display="flex">
          <FormControlLabel
            value="performed"
            control={<Radio color="primary" />}
            label={t('students.labelPerformed')}
            labelPlacement="end"
            className={classes.mr50}
            checked={radioGroupValue}
          />
          <FormControlLabel
            value="notPerformed"
            control={<Radio color="primary" />}
            label={t('students.labelNotPerformed')}
            labelPlacement="end"
            className={classes.radio}
            checked={!radioGroupValue}
          />
        </Box>
      </RadioGroup>

      <Button className={classes.btn} color="primary" variant="contained" onClick={saveState}>
        {t('buttons.save')}
      </Button>
    </div>
  );
};

StateToggler.defaultProps = {
  onChange: () => {},
};

StateToggler.propTypes = {
  value: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]).isRequired,
  onChange: PropTypes.func,
};

export default StateToggler;

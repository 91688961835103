import React from 'react';
import PropTypes from 'prop-types';
import { Typography, Icon, ButtonBase } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { JOB_TASKS_STATUSES } from 'appConstants';
import classNames from 'classnames';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  button: {
    display: 'inline-block',
    cursor: 'pointer',
    border: 'none',
    padding: 0,
    color: theme.palette.text.grey,
    background: 'none',
    whiteSpace: 'nowrap',
  },
  text: {
    color: 'currentColor',
    verticalAlign: 'middle',
    fontSize: '14px',
    lineHeight: '16px',
    textTransform: 'lowercase',
  },
  icon: {
    color: 'currentColor',
    fontSize: '17px',
    marginRight: '1vw',
    verticalAlign: 'middle',
  },
  'color-mixed': {
    color: '#D662C0',
  },
  'color-performed': {
    color: theme.palette.primary.main,
  },
}));

const MultipleStateToggler = ({ value, onChange, ...otherProps }) => {
  const classes = useStyles();

  const button = classNames({
    [classes.button]: true,
    [classes['color-mixed']]: value === JOB_TASKS_STATUSES.mixed,
    [classes['color-performed']]: value === JOB_TASKS_STATUSES.performed,
  });

  return (
    <div className={classes.root}>
      <ButtonBase
        className={button}
        type="button"
        data-key={otherProps.id}
        data-val={value}
        aria-label="state"
        onClick={onChange}
      >
        <Icon className={classes.icon}>lens</Icon>
        <Typography className={classes.text} component="span" variant="body1">
          {value}
        </Typography>
      </ButtonBase>
    </div>
  );
};

MultipleStateToggler.defaultProps = {
  onChange: () => {},
  value: undefined,
};

MultipleStateToggler.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
};

export default MultipleStateToggler;

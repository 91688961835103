import axios from 'axios';
import { toast } from 'react-toastify';
import i18next from 'i18next';

const baseURL = process.env.REACT_APP_BASE_API;
const headers = {
  'Content-Type': 'application/json; charset=utf-8',
};

export const http = axios.create({
  baseURL,
  headers,
  withCredentials: true,
});

http.interceptors.response.use(undefined, error => {
  const { status } = error.response;

  if (status >= 500) {
    toast.error(i18next.t('errors.serverError'), {
      autoClose: 2000,
    });
  }

  return Promise.reject(error);
});

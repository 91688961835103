import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { TableRow, TableFooter, TablePagination } from '@material-ui/core';
import AppTablePagination from './AppTablePagination';

const useStyles = makeStyles(theme => ({
  paginationToolbar: {
    flexDirection: 'row-reverse',
    paddingLeft: 0,
    justifyContent: 'space-between',
    paddingTop: '20px',
  },
  paginationSpacer: {
    display: 'none',
  },
  paginationRoot: {
    borderBottom: 'none',
  },
  paginationCaption: {
    color: theme.palette.text.grey,
    marginRight: theme.spacing(1),
  },
}));

const AppTableFooter = props => {
  const classes = useStyles();
  const { onChangePage, page, totalCount, rowsPerPage } = props;
  return (
    <TableFooter>
      <TableRow>
        <TablePagination
          rowsPerPageOptions={[]}
          count={totalCount}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={onChangePage}
          classes={{
            toolbar: classes.paginationToolbar,
            spacer: classes.paginationSpacer,
            root: classes.paginationRoot,
            caption: classes.paginationCaption,
          }}
          ActionsComponent={AppTablePagination}
        />
      </TableRow>
    </TableFooter>
  );
};

AppTableFooter.propTypes = {
  onChangePage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  totalCount: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

export default AppTableFooter;

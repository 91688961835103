import React from 'react';

export default function withLayout(WrappedComponent, LayoutComponent) {
  return function WithLayout(props) {
    return (
      <LayoutComponent>
        <WrappedComponent {...props} />
      </LayoutComponent>
    );
  };
}

import React from 'react';
import PropTypes from 'prop-types';
import AppTable from 'components/AppTable';
import Loader from 'components/Loader';
import withTableProps from 'HOC/types/withTable';
import { transformTableData, getConfirmationModalConfig } from 'utils';
import { useAsyncFn } from 'react-use';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { actions as modalActions } from 'redux/modal';
import { removeStudentFromHistoryCourse } from 'http/courseStudent';
import { getHistoryConfig } from './helpers/getHistoryTableConfig';

const HistoryTable = ({ tableData, userId }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const tableConfig = getHistoryConfig();
  const [columns, students] = transformTableData(tableConfig, tableData.data);

  const handleCloseModal = () => dispatch(modalActions.closeModal());

  const [removeState, setRemoveStudentFromCourse] = useAsyncFn(async historyId => {
    const removeResponse = await removeStudentFromHistoryCourse(userId, historyId);
    if (!removeResponse.error) {
      handleCloseModal();
      tableData.handleForceUpdate();
      dispatch(modalActions.setTableDeleting(true));
    }
    return removeResponse;
  });

  const handleRemoveStudentFromCourse = event => {
    const courseId = event.currentTarget.dataset.key;
    const [config, element] = getConfirmationModalConfig({
      title: t('students.modalRemoveHistoryTitle'),
      confirmAction: () => setRemoveStudentFromCourse(courseId),
      rejectAction: handleCloseModal,
      message: t('students.modalRemoveHistoryMessage'),
    });
    dispatch(modalActions.openModal(config, element));
  };

  const tableActions = [
    {
      type: 'icon',
      icon: 'delete',
      color: 'error',
      onClick: handleRemoveStudentFromCourse,
    },
  ];

  return (
    <>
      <AppTable
        columns={columns}
        data={students}
        totalCount={tableData.totalCount}
        rowsPerPage={tableData.pageSize}
        isLazyLoading
        onChangePage={tableData.handleChangePage}
        onColumnSorting={tableData.handleSorting}
        actions={tableActions}
      />
      {removeState.loading && <Loader />}
    </>
  );
};

HistoryTable.propTypes = {
  tableData: PropTypes.shape(withTableProps).isRequired,
  userId: PropTypes.string.isRequired,
};

export default HistoryTable;

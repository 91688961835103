import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Portal from '@material-ui/core/Portal';

const useStyles = makeStyles(() => ({
  loaderBox: {
    width: '100vw',
    height: '100vh',
    position: 'fixed',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    backdropFilter: 'blur(10px)',
    zIndex: 9999,
  },
}));

const Loader = () => {
  const classes = useStyles();

  return (
    <Portal>
      <div className={classes.loaderBox}>
        <CircularProgress />
      </div>
    </Portal>
  );
};

export default Loader;

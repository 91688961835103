import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import AppTable from 'components/AppTable';
import withTable from 'HOC/withTable';
import withTableProps from 'HOC/types/withTable';
import SearchFilter from 'components/SearchFilter';
import StatisticsInfo from 'components/StatisticsInfo';
import PageLayout from 'layouts/PageLayout';
import Donut from 'components/Donut';
import { makeStyles } from '@material-ui/core/styles';
import { transformTableData, transformToStatisticsByExpireDate } from 'utils';
import { useSelector } from 'react-redux';
import { getDashboardStatistics } from 'http/statistics';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { routesAliases } from 'router/routes';
import { ROLES } from 'appConstants';
import getTableConfig from './helpers/getTableConfig';

const pageAlias = 'Dashboard';

const useStyles = makeStyles(theme => ({
  mainContent: {
    display: 'flex',
    marginTop: 30,
  },
  mainContentLeft: {
    paddingTop: 30,
    flexGrow: 0,
    display: 'flex',
  },
  mainContentRight: {
    flexGrow: 1,
  },
  pageWrapper: {
    padding: theme.spacing(1, 4),
  },
  donutTitle: {
    marginBottom: 20,
  },
}));

const Dashboard = ({ tableData, total, graphic }) => {
  const classes = useStyles();
  const history = useHistory();
  const { profile, lang } = useSelector(state => state.auth);
  const { t } = useTranslation('common');
  const tableConfig = getTableConfig();
  const [donutData, setDonutData] = useState([]);
  const [statisticsInfo, setStatisticsInfo] = useState([]);
  const [columns, dashboardTableData] = transformTableData(tableConfig, tableData.data);

  const userRole = !!profile && profile.role;
  const permissions = {
    addGlobalCourse: [ROLES.SUPER],
  };

  const handleTableSearch = (value, searchAlias) => {
    tableData.handleFiltering(searchAlias, value);
  };

  const handleTableSearchClearing = searchAlias => {
    tableData.handleRemoveFilter(searchAlias);
  };

  useEffect(() => {
    tableData.handleChangeFetchMethod(pageAlias, getDashboardStatistics);
  }, [lang]);

  useEffect(() => {
    setStatisticsInfo([
      {
        title: t('dashboard.totalCourses'),
        value: total.courses,
        subtitle: t('dashboard.subtitleCourses'),
      },
      {
        title: t('dashboard.totalStudents'),
        value: total.students,
        subtitle: t('dashboard.subtitlePeople'),
      },
      {
        title: t('dashboard.totalTrainers'),
        value: total.trainers,
        subtitle: t('dashboard.subtitlePeople'),
      },
    ]);
  }, [total]);

  useEffect(() => {
    setDonutData(transformToStatisticsByExpireDate(graphic));
  }, [graphic]);

  const isLoading = tableData.isFetchingTableData;

  return (
    <PageLayout
      pageTitle={t('dashboard.pageTitle')}
      contentHeader={t('dashboard.trainingStatistics')}
      actionSectionLeft={[
        {
          type: 'component',
          Component: <StatisticsInfo items={statisticsInfo} />,
        },
      ]}
      actionSectionRight={[
        {
          type: 'button',
          icon: 'add',
          title: t('dashboard.actionAddStudent'),
          onClick: () => history.push(routesAliases.createStudent),
        },
        {
          type: 'button',
          icon: 'add',
          title: t('dashboard.actionAddCourse'),
          onClick: () =>
            history.push(
              `${routesAliases.createCourse}/${permissions.addGlobalCourse.includes(userRole) ? 'global' : 'local'}`,
              { backUrl: routesAliases.dashboard },
            ),
        },
        {
          type: 'button',
          icon: 'add',
          title: t('dashboard.actionAddTrainer'),
          onClick: () => history.push(routesAliases.createTrainer),
        },
      ]}
      pageContentActions={[
        {
          type: 'component',
          Component: (
            <SearchFilter
              searchAlias="search"
              onSearch={handleTableSearch}
              onClearSearch={handleTableSearchClearing}
              styles={{
                bgColor: '#e5e5e5',
                searchWidth: '250px',
              }}
            />
          ),
        },
      ]}
      classes={{
        content: classes.pageWrapper,
      }}
      showLoader={isLoading}
    >
      <div className={classes.mainContent}>
        {donutData && !!donutData.length && (
          <div className={classes.mainContentLeft}>
            <Donut
              donutWidth={430}
              donutHeight={430}
              donutData={donutData}
              donutLegendTitle={t('legend.coursesByExpiryDate')}
              donutLegendTitleStyles={classes.donutTitle}
            />
          </div>
        )}
        <div className={classes.mainContentRight}>
          <AppTable
            columns={columns}
            data={dashboardTableData}
            totalCount={tableData.totalCount}
            rowsPerPage={tableData.pageSize}
            onChangePage={tableData.handleChangePage}
            currentPage={tableData.page}
            onColumnSorting={tableData.handleSorting}
            systemField="studentId"
            extraActionsKeys={['userId']}
            isLazyLoading
            actions={[
              {
                title: t('buttons.view'),
                onClick: event =>
                  history.push({
                    pathname: `${routesAliases.editStudent}/${event.currentTarget.dataset.userid}`,
                  }),
              },
            ]}
          />
        </div>
      </div>
    </PageLayout>
  );
};

Dashboard.defaultProps = {
  total: {},
  graphic: {},
};

Dashboard.propTypes = {
  tableData: PropTypes.shape(withTableProps).isRequired,
  total: PropTypes.shape(),
  graphic: PropTypes.shape(),
};

export default withTable({
  defaultSorting: {},
  fetchingData: 'items',
  pageAlias,
})(Dashboard);

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import PageLayout from 'layouts/PageLayout';
import PageSelect from 'components/Selects/PageSelect';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import { getLocalCourses, exportLocalCourses } from 'http/courses';
import withTable from 'HOC/withTable';
import withTableProps from 'HOC/types/withTable';
import { useAsyncFn } from 'react-use';
import SearchFilter from 'components/SearchFilter';
import { useTranslation } from 'react-i18next';
import { routesAliases } from 'router/routes';
import CourseTable from './CoursesTable';

const useStyles = makeStyles(() => ({
  pageSelectRoot: {
    padding: '10px 65px 10px 25px',
  },
}));

const pageAlias = 'StudentCoursesList';

const StudentCourses = ({ tableData }) => {
  const classes = useStyles();
  const { t } = useTranslation('common');
  const { station, id } = useSelector(state => state.auth.profile);
  const stationId = station ? station.id : undefined;

  const [exportState, exportCoursesFile] = useAsyncFn(async currentStationId => {
    await exportLocalCourses(currentStationId);
  });

  const handleExportCourses = () => {
    exportCoursesFile(stationId);
  };

  const handleTableSearch = (value, searchAlias) => {
    tableData.handleFiltering(searchAlias, value);
  };

  const handleTableSearchClearing = searchAlias => {
    tableData.handleRemoveFilter(searchAlias);
  };

  const selectFilterValues = [
    { label: t('courses.withoutValue'), value: '' },
    { label: t('courses.scheduled'), value: 'scheduled' },
    { label: t('courses.inProgress'), value: 'inProgress' },
    { label: t('courses.passed'), value: 'passed' },
    { label: t('courses.failed'), value: 'failed' },
    { label: t('courses.completed'), value: 'completed' },
  ];

  const [progressFilterValue, setProgressFilterValue] = useState(selectFilterValues[0].value);

  const handleChangeProgressFilter = value => {
    setProgressFilterValue(value);
    if (value === '') {
      tableData.handleRemoveFilter('filter-progress');
    } else {
      tableData.handleFiltering('filter-progress', value);
    }
  };

  useEffect(() => {
    tableData.handleChangeFetchMethod(pageAlias, getLocalCourses.bind(null, stationId));
  }, [station]);

  const tableComponent = <CourseTable routesAliases={routesAliases} tableData={tableData} userId={id} />;

  const isLoading = exportState.loading;

  return (
    <PageLayout
      pageTitle={t('courses.myCourses')}
      showLoader={isLoading}
      actionSectionLeft={[
        {
          type: 'component',
          Component: (
            <SearchFilter searchAlias="search" onSearch={handleTableSearch} onClearSearch={handleTableSearchClearing} />
          ),
        },
      ]}
      actionSectionRight={[
        {
          type: 'component',
          Component: (
            <PageSelect
              classes={{ root: classes.pageSelectRoot }}
              options={selectFilterValues}
              selectedOption={progressFilterValue.value}
              onChangeValue={handleChangeProgressFilter}
            />
          ),
        },
        {
          type: 'button',
          icon: 'import_export',
          title: t('commonActions.actionExport'),
          onClick: handleExportCourses,
        },
      ]}
    >
      {tableComponent}
    </PageLayout>
  );
};

StudentCourses.propTypes = {
  tableData: PropTypes.shape(withTableProps).isRequired,
};

export default withTable({
  defaultSorting: {
    field: 'id',
    dir: 'desc',
  },
  fetchingData: 'items',
  pageAlias,
})(StudentCourses);

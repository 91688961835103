import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import AppBreadcrumbs from 'components/AppBreadcrumbs';
import PageLayout from 'layouts/PageLayout';
import NotFound from 'pages/NotFound';
import { makeStyles } from '@material-ui/core/styles';
import { useAsyncFn } from 'react-use';
import { useSelector } from 'react-redux';
import { getUserCourse } from 'http/courseStudent';
import { useRouteMatch } from 'react-router-dom';
import { routesAliases } from 'router/routes';
import { useTranslation } from 'react-i18next';
import {
  getLocalCourseDocuments,
  getGlobalCourseDocuments,
  downloadGlobalCourseDocument,
  downloadLocalCourseDocument,
} from 'http/courses';
import { getCourseTemplatesDocuments, downloadCourseTemplateDocument } from 'http/courseTemplates';
import DocumentsTable from './DocumentsTable';
import CourseForm from './Form';

const useStyles = makeStyles(theme => ({
  courseContent: {
    padding: theme.spacing(2, 5),
  },
  courseHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& .ContentTitle': {
      display: 'flex',
    },
  },
  pageContent: {
    padding: theme.spacing(4, 5),
  },
}));

const CourseView = ({ routeProps }) => {
  const { t } = useTranslation();
  const { id: userId } = useSelector(state => state.auth.profile);
  const routerMatch = useRouteMatch();
  const { id: courseId, type: courseType, stationId } = routerMatch.params;
  const [notFoundError, setNotFoundError] = useState(false);
  const [initialFormState, setInitialFormState] = useState({});
  const backUrl = routesAliases.studentCourseList;
  const classes = useStyles();

  const breadCrumbsItems = [
    {
      title: t(routeProps.parentTitle),
      link: routeProps.parentPath,
    },
    {
      title: t(routeProps.title),
      isActive: true,
    },
  ];

  const actions = {
    local: {
      getDocuments: getLocalCourseDocuments.bind(null, stationId, courseId),
      downloadDocument: downloadLocalCourseDocument,
    },
    global: {
      getDocuments: getGlobalCourseDocuments.bind(null, courseId),
      downloadDocument: downloadGlobalCourseDocument,
    },
  };

  const [courseTemplateId, setCourseTemplateId] = useState(null);

  const [fetchCourseState, fetchCourseData] = useAsyncFn(async (id, currentUserId) => {
    const course = await getUserCourse(id, currentUserId);
    if (!course.error) {
      setCourseTemplateId(course.courseTemplateId);
      setInitialFormState({
        courseSystemId: course.courseSystemId,
        courseName: course.name,
        maxStudents: course.maxStudents,
        location: course.location,
        description: course.description,
        jobsTasks: course.jobsTasks,
        startTime: `${course.startDate} ${course.startTime}`,
        endTime: `${course.startDate} ${course.endTime}`,
        startDate: course.startDate,
        endDate: course.endDate,
        expirationDate: course.expirationDate,
        trainers: course.trainers,
      });
    } else if (course.statusCode === 404) {
      setNotFoundError(true);
    }
  });

  useEffect(() => {
    fetchCourseData(courseId, userId);
  }, []);

  return notFoundError ? (
    <NotFound />
  ) : (
    <PageLayout
      pageTitle={<AppBreadcrumbs items={breadCrumbsItems} />}
      classes={{ content: classes.pageContent }}
      showLoader={fetchCourseState.loading}
      additionSections={[
        {
          contentClass: classes.courseContent,
          contentHeaderClass: classes.courseHeader,
          contentHeader: t('students.documents'),
          component: courseTemplateId && (
            <DocumentsTable
              courseId={courseId}
              courseType={courseType}
              stationId={stationId}
              courseTemplateId={courseTemplateId}
              helpers={{
                getDocuments: actions[courseType].getDocuments,
                downloadDocument: actions[courseType].downloadDocument,
                getCourseTemplatesDocuments,
                downloadCourseTemplateDocument,
              }}
            />
          ),
        },
      ]}
    >
      <CourseForm initialValues={initialFormState} backUrl={backUrl} />
    </PageLayout>
  );
};

CourseView.propTypes = {
  routeProps: PropTypes.shape({
    parentPath: PropTypes.string,
    parentTitle: PropTypes.string,
    title: PropTypes.string,
  }).isRequired,
};

export default CourseView;

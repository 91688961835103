const transformDataToSelectOptions = (data, valueField, labelField, isTrainer) =>
  !data
    ? []
    : data.map(element => {
        let label = element[labelField];
        if (isTrainer) {
          const tailName = element.station === 'External' ? 'Ext.' : element.station;
          label = `${label} (${tailName})`;
        }
        return {
          label,
          value: element[valueField],
        };
      });

export default transformDataToSelectOptions;

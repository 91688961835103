import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import AppBreadcrumbs from 'components/AppBreadcrumbs';
import PageLayout from 'layouts/PageLayout';
import { useAsyncFn } from 'react-use';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import { getUser, updateUser } from 'http/users';
import { useRouteMatch, useHistory } from 'react-router-dom';
import { routesAliases } from 'router/routes';
import NotFound from 'pages/NotFound';
import { useTranslation } from 'react-i18next';
import AdministratorsForm from '../Form';

const useStyles = makeStyles(theme => ({
  pageContent: {
    padding: theme.spacing(4, 5),
  },
}));

const Edit = ({ routeProps }) => {
  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation();
  const routerMatch = useRouteMatch();
  const { id } = routerMatch.params;
  const { stations } = useSelector(state => state.stations);
  const [notFoundError, setNotFoundError] = useState(false);
  const [initialFormState, setInitialFormState] = useState(false);

  const breadCrumbsItems = [
    {
      title: t(routeProps.parentTitle),
      link: routeProps.parentPath,
    },
    {
      title: t(routeProps.title),
      isActive: true,
    },
  ];

  const [fetchUserState, fetchUserData] = useAsyncFn(async userId => {
    const userData = await getUser(userId);
    if (!userData.error) {
      setInitialFormState({
        firstName: (userData.info && userData.info.firstName) || '',
        lastName: (userData.info && userData.info.lastName) || '',
        email: userData.email || '',
        userStation: userData.stations || '',
        stationId: (userData.stations[0] && userData.stations[0].id.toString()) || '',
        stations: stations || [],
        company: (userData.info && userData.info.info && userData.info.info.company) || '',
        country: (userData.info && userData.info.info && userData.info.info.country) || '',
      });
    } else if (userData.statusCode === 404) {
      setNotFoundError(true);
      return false;
    }
    return userData;
  });

  const [submittingState, editAdministrator] = useAsyncFn(async formData => {
    const data = await updateUser(formData, id);
    if (!data.error) {
      history.push(routesAliases.administratorsList);
    }
    return data;
  }, []);

  useEffect(() => {
    fetchUserData(id);
  }, []);

  return notFoundError ? (
    <NotFound />
  ) : (
    <PageLayout
      pageTitle={<AppBreadcrumbs items={breadCrumbsItems} />}
      classes={{ content: classes.pageContent }}
      showLoader={fetchUserState.loading}
    >
      <AdministratorsForm
        isLoading={submittingState.loading}
        onSubmit={editAdministrator}
        administratorsData={initialFormState}
        routesAliases={routesAliases}
        type="edit"
      />
    </PageLayout>
  );
};

Edit.propTypes = {
  routeProps: PropTypes.shape({
    parentPath: PropTypes.string,
    parentTitle: PropTypes.string,
    title: PropTypes.string,
  }).isRequired,
};

export default Edit;

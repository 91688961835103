import i18next from 'i18next';
import React from 'react';
import { MultipleStateToggler } from 'components/AppTable/CellsComponents';

const getTableConfig = helpers => [
  {
    title: i18next.t('tablesLabels.id'),
    field: 'ids',
  },
  {
    title: i18next.t('tablesLabels.name'),
    field: 'fullName',
    isSorting: true,
  },
  {
    title: i18next.t('tablesLabels.email'),
    field: 'email',
    isSorting: true,
  },
  {
    title: i18next.t('tablesLabels.station'),
    field: 'stations',
    isSorting: true,
  },
  {
    title: i18next.t('tablesLabels.jobsTasks'),
    field: 'jobTasks',
    isSorting: true,
  },
  {
    title: i18next.t('tablesLabels.state'),
    field: 'performedStatus',
    Component: state => <MultipleStateToggler value={state} onChange={helpers.handleToggleStateModal} />,
  },
  {
    title: '',
    field: 'id',
    isVisible: false,
  },
];

export default getTableConfig;

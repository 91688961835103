import React, { useState, useEffect } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import CustomTextField from 'components/Forms/TextField';
import CustomDatePicker from 'components/Forms/CustomDatePicker';
import CustomTimePicker from 'components/Forms/CustomTimePicker';
import CustomSelect from 'components/Forms/CustomSelect';
import Loader from 'components/Loader';
import { Form, Field, FormSpy } from 'react-final-form';
import { Grid, Box, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useAsyncFn } from 'react-use';
import { useSelector } from 'react-redux';
import { useRouteMatch, useHistory } from 'react-router-dom';
import { getCourseTemplates, getCourseTemplate } from 'http/courseTemplates';
import { getTrainersCompliant } from 'http/users';
import { transformDataToSelectOptions, generateErrorObject } from 'utils';
import { useTranslation } from 'react-i18next';
import { ROLES } from 'appConstants';
import configErrors from './configErrors';
import validate from './validation';

const useStyles = makeStyles(theme => ({
  container: {
    flexWrap: 'nowrap',
    [theme.breakpoints.down('md')]: {
      flexWrap: 'wrap',
      justifyContent: 'flex-start',
    },
  },
  btnContainer: {
    marginTop: '40px',
  },
  btn: {
    minWidth: '140px',
  },
  gridContainer: {
    marginTop: '40px',
  },
  jobsTasksSection: {
    color: 'rgba(0, 0, 0, 0.54)',
  },
  jobsTasksLabel: {
    zIndex: 2,
    position: 'relative',
    fontSize: '12px',
    lineHeight: '30px',
    transform: 'none',
    marginBottom: 0,
  },
  jobsTasksItems: {},
  jobTasksItem: {
    padding: '11px 14px',
    borderRadius: '25px',
    border: '1px solid #d3d3d3',
  },
  timeItem: {
    flexBasis: '25%',
  },
}));

const CourseForm = ({
  formType,
  onSubmitForm,
  submitting: formSubmitting,
  initialValues,
  backUrl,
  courseType,
  courseStudentsCount,
  setIsFrozen,
}) => {
  const history = useHistory();
  const classes = useStyles();
  const routerMatch = useRouteMatch();
  const { t } = useTranslation('common');
  const { station, role } = useSelector(state => state.auth.profile);

  const [warnings, setWarnings] = useState({});

  const clearWarnings = field => {
    setWarnings(prevState => {
      return {
        ...prevState,
        [field]: undefined,
      };
    });
  };

  const setCustomWarnings = (field, message) => {
    setWarnings(prevState => ({
      ...prevState,
      [field]: message,
    }));

    setTimeout(() => {
      clearWarnings(field);
    }, 1500);
  };

  const [formControlsOptions, setFormControlsOptions] = useState({
    trainers: [],
    courseTemplates: [],
  });
  const [formData, setFormData] = useState({});

  const [formControlsState, getFormControlsOptions] = useAsyncFn(async courseTemplateId => {
    const templates = await getCourseTemplates();
    const optionsObj = {
      trainers: [],
      courseTemplates: [],
    };

    if (templates.items) {
      optionsObj.courseTemplates = transformDataToSelectOptions(templates.items, 'id', 'name');
    }

    const trainers = await getTrainersCompliant({ 'course-template-id': courseTemplateId });
    if (!trainers.error) {
      optionsObj.trainers = transformDataToSelectOptions(trainers.items, 'id', 'fullName', true);
    }

    setFormControlsOptions({
      ...formControlsOptions,
      ...optionsObj,
    });
  });

  const [templateFetchState, getChosenTemplate] = useAsyncFn(async (id, formValues) => {
    const template = await getCourseTemplate(id);

    if (!template.error) {
      setFormData({
        ...formValues,
        courseTemplate: { label: template.name, value: template.id },
        description: template.description,
        jobsTasks: template.jobsTasks,
      });
      getFormControlsOptions(id);
    }
  });

  useEffect(() => {
    if (formType === 'create') {
      getFormControlsOptions();
    }
  }, []);

  useEffect(() => {
    if (formType === 'edit' && initialValues) {
      setFormData(initialValues);
      getFormControlsOptions(initialValues.courseTemplate.value);
    }
  }, [initialValues]);

  const handleChangeTemplate = (template, formValues) => {
    if (template) {
      getChosenTemplate(template.value, formValues);
    }
  };

  const handleSubmitForm = async (values, isRedirectToList) => {
    const transformedFormData = {
      ...values,
      maxStudents: +values.maxStudents,
      courseTemplateId: values.courseTemplate && values.courseTemplate.value,
      trainersIds: Array.isArray(values.trainers) ? values.trainers.map(trainer => trainer.value) : [],
      startTime: moment(values.startTime).format('HH:mm'),
      endTime: moment(values.endTime).format('HH:mm'),
      startDate: moment(values.startDate).format('DD.MM.YYYY'),
      endDate: moment(values.endDate).format('DD.MM.YYYY'),
    };
    delete transformedFormData.trainers;
    delete transformedFormData.courseTemplate;
    delete transformedFormData.jobsTasks;
    delete transformedFormData.expirationDate;

    const submitData = await onSubmitForm(transformedFormData, isRedirectToList);
    if (submitData && submitData.error && submitData.message) {
      return generateErrorObject(submitData.message, configErrors);
    }

    return undefined;
  };

  const isCourseStarts = courseDate => {
    const currentStartDate = moment(courseDate);
    const now = moment(new Date());

    return now >= currentStartDate;
  };

  const isLoading = formControlsState.loading || templateFetchState.loading || formSubmitting;

  return (
    <Form
      onSubmit={handleSubmitForm}
      initialValues={formData}
      validate={validate}
      render={({ handleSubmit, submitting, values, initialValues: formInitialValues }) => {
        const isCourseStartsOrHaveStudents =
          formType === 'edit' && isCourseStarts(formInitialValues.startDate) && courseStudentsCount > 0;
        const isCourseFutureOrHaveStudents =
          formType === 'edit' && !isCourseStarts(formInitialValues.startDate) && courseStudentsCount > 0;
        const isCoursePassed =
          formType === 'edit' &&
          formInitialValues.endDate &&
          moment(formInitialValues.endDate)
            .add(1, 'days')
            .fromNow()
            .indexOf('ago') !== -1;

        let isAdminAndExternalCourse = false;

        if (formType === 'edit' && role !== ROLES.SUPER) {
          const { stationId } = routerMatch.params;
          isAdminAndExternalCourse = stationId !== station.id.toString();
        }

        const isDataFreezed = [
          isCourseStartsOrHaveStudents,
          isCourseFutureOrHaveStudents,
          isCoursePassed,
          submitting,
          isAdminAndExternalCourse,
        ].includes(true);

        if (isCoursePassed) {
          setIsFrozen(true);
        }

        const isCourseTemplateDisable =
          (formType === 'edit' && courseStudentsCount > 0) ||
          (values.courseTemplate && Array.isArray(values.trainers) && values.trainers.length > 0) ||
          submitting ||
          isCoursePassed ||
          isAdminAndExternalCourse;

        return (
          <form onSubmit={handleSubmit}>
            <FormSpy
              onChange={({ modified, values: formValues }) => {
                if (modified.courseTemplate) {
                  const newValue = formValues.courseTemplate && formValues.courseTemplate.value;
                  const oldValue = values.courseTemplate && values.courseTemplate.value;

                  if (newValue !== oldValue) {
                    handleChangeTemplate(formValues.courseTemplate, formValues);
                  }
                }
                if (modified.trainers && formType === 'edit' && formValues.trainers === null) {
                  handleSubmitForm(formValues, false);
                }
              }}
            />
            <Grid container className="mainSection">
              <Grid container justify="space-between" spacing={4} className={classes.container}>
                <Grid item xs={3} md={4}>
                  <Field
                    type="text"
                    id="startTime"
                    name="startTime"
                    label={t('formLabels.startTime')}
                    disabled={isDataFreezed}
                    variant="outlined"
                    fullWidth
                    component={CustomTimePicker}
                    warningMessage={warnings.startTime}
                    onClick={() => setCustomWarnings('startTime', t('course.haveStudents'))}
                  />
                </Grid>
                <Grid item xs={3} md={4}>
                  <Field
                    type="text"
                    id="endTime"
                    name="endTime"
                    label={t('formLabels.endTime')}
                    disabled={isDataFreezed}
                    variant="outlined"
                    fullWidth
                    component={CustomTimePicker}
                    warningMessage={warnings.endTime}
                    onClick={() => setCustomWarnings('endTime', t('course.haveStudents'))}
                  />
                </Grid>
                <Grid item xs={3} md={4}>
                  <Field
                    type="text"
                    id="startDate"
                    name="startDate"
                    label={t('formLabels.startDate')}
                    disabled={isDataFreezed}
                    variant="outlined"
                    minDate={new Date()}
                    maxDate={values.endDate}
                    fullWidth
                    component={CustomDatePicker}
                    warningMessage={warnings.startDate}
                    onClick={() => setCustomWarnings('startDate', t('course.haveStudents'))}
                  />
                </Grid>
                <Grid item xs={3} md={4}>
                  <Field
                    type="text"
                    id="endDate"
                    name="endDate"
                    label={t('formLabels.endDate')}
                    disabled={isDataFreezed}
                    variant="outlined"
                    minDate={values.startDate || new Date()}
                    fullWidth
                    component={CustomDatePicker}
                    warningMessage={warnings.endDate}
                    onClick={() => setCustomWarnings('endDate', t('course.haveStudents'))}
                  />
                </Grid>
              </Grid>
              <Grid container justify="space-between" className={classes.gridContainer} spacing={4}>
                <Grid container item xs={4} direction="column" justify="flex-start">
                  <Grid container item justify="space-between" spacing={2}>
                    <Grid item xs={12} lg={8}>
                      <Field
                        type="text"
                        id="courseTemplate"
                        name="courseTemplate"
                        label={t('formLabels.courseTemplate')}
                        variant="outlined"
                        placeholder={t('placeholders.chooseTemplate')}
                        options={formControlsOptions.courseTemplates}
                        fullWidth
                        warningMessage={warnings.courseTemplate}
                        onWrapperClick={() => setCustomWarnings('courseTemplate', t('course.haveStudentsOrTrainers'))}
                        isDisabled={isCourseTemplateDisable}
                        component={CustomSelect}
                      />
                    </Grid>
                    <Grid item xs={12} lg={4}>
                      <Field
                        type="text"
                        id="maxStudents"
                        name="maxStudents"
                        label={t('formLabels.maxStudents')}
                        variant="outlined"
                        fullWidth
                        disabled={isCoursePassed || submitting || isAdminAndExternalCourse}
                        component={CustomTextField}
                      />
                    </Grid>
                  </Grid>

                  <Grid container item>
                    <Box mt={2} width="100%">
                      <Field
                        type="text"
                        id="description"
                        name="description"
                        label={t('formLabels.description')}
                        variant="outlined"
                        fullWidth
                        multiline
                        rows="4"
                        component={CustomTextField}
                        format={(value = '') => value.trim()}
                        formatOnBlur
                        disabled={isCoursePassed || submitting || isAdminAndExternalCourse}
                      />
                    </Box>
                  </Grid>
                </Grid>
                <Grid container item xs={4} direction="column" justify="flex-start">
                  <Grid container item>
                    <Field
                      type="text"
                      id="location"
                      name="location"
                      label={t('formLabels.location')}
                      variant="outlined"
                      fullWidth
                      component={CustomTextField}
                      format={(value = '') => value.trim()}
                      formatOnBlur
                      disabled={isDataFreezed}
                    />
                  </Grid>
                  <Grid container item justify="space-between">
                    {!values.jobsTasks || !values.jobsTasks.length ? null : (
                      <Box mt={2}>
                        <div className={classes.jobsTasksSection}>
                          <div className={classes.jobsTasksLabel}>{t('formLabels.jobsTasks')}</div>
                          <Grid container className={classes.jobsTasksItems}>
                            {values.jobsTasks.map(item => {
                              return (
                                <Grid item key={item.id}>
                                  <Box mr={2} mb={1}>
                                    <div className={classes.jobTasksItem}>{item.name}</div>
                                  </Box>
                                </Grid>
                              );
                            })}
                          </Grid>
                        </div>
                      </Box>
                    )}
                  </Grid>
                </Grid>
                <Grid container item xs={4} direction="column" justify="space-between">
                  <Grid item xs={12}>
                    <Field
                      type="text"
                      id="trainers"
                      name="trainers"
                      label={t('formLabels.trainers')}
                      variant="outlined"
                      placeholder={t('placeholders.chooseTrainer')}
                      options={formControlsOptions.trainers}
                      fullWidth
                      isMulti
                      component={CustomSelect}
                      isDisabled={isCoursePassed || submitting || isAdminAndExternalCourse}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid container spacing={3} className={classes.btnContainer}>
              <Grid item xs={6}>
                <Box textAlign="right">
                  <Button
                    className={classes.btn}
                    type="submit"
                    color="primary"
                    variant="contained"
                    disabled={isCoursePassed || submitting || isAdminAndExternalCourse}
                    size="large"
                  >
                    {t('buttons.save')}
                  </Button>
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box textAlign="left">
                  <Button
                    className={classes.btn}
                    variant="outlined"
                    size="large"
                    type="reset"
                    disabled={submitting}
                    onClick={() => {
                      history.push(backUrl, {
                        courseType: isAdminAndExternalCourse ? 'local' : courseType,
                      });
                    }}
                  >
                    {t('buttons.cancel')}
                  </Button>
                </Box>
              </Grid>
            </Grid>

            {isLoading && <Loader />}
          </form>
        );
      }}
    />
  );
};

CourseForm.defaultProps = {
  formType: 'create',
  initialValues: null,
  courseStudentsCount: 0,
  setIsFrozen: () => {},
};

CourseForm.propTypes = {
  formType: PropTypes.string,
  onSubmitForm: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  initialValues: PropTypes.shape({
    courseTemplate: PropTypes.shape({
      value: PropTypes.number,
      label: PropTypes.string,
    }),
    endTime: PropTypes.string,
  }),
  backUrl: PropTypes.string.isRequired,
  courseStudentsCount: PropTypes.number,
  courseType: PropTypes.string.isRequired,
  setIsFrozen: PropTypes.func,
};

export default CourseForm;

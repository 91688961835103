import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import AppTable from 'components/AppTable';
import Loader from 'components/Loader';
import withTableProps from 'HOC/types/withTable';
import withTable from 'HOC/withTable';
import { transformTableData, getConfirmationModalConfig } from 'utils';
import { useAsyncFn } from 'react-use';
import {
  getCourseTemplatesDocuments,
  deleteCourseTemplateDocument,
  downloadCourseTemplateDocument,
  setDocumentVisibility,
} from 'http/courseTemplates';
import { useDispatch } from 'react-redux';
import { actions } from 'redux/table';
import { actions as modalActions } from 'redux/modal';
import { useTranslation } from 'react-i18next';
import { getDocumentsConfig } from './helpers/getTableConfig';

const pageAlias = 'CoursesTemplateFiles';

const DocumentsTable = ({ tableData, courseTemplateId }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [documentStateUpdating, updateDocumentState] = useAsyncFn(async (fileId, data) => {
    const fetchResponse = await setDocumentVisibility(courseTemplateId, fileId, data);
    if (!fetchResponse.error) {
      tableData.handleForceUpdate(true);
    }
    return fetchResponse;
  });

  const handleToggleVisibility = (fileId, data) => {
    updateDocumentState(fileId, data);
  };

  const documentsDataExtended = tableData.data.map(item => ({
    ...item,
    userUploaded: `${item.uploadedByUser && item.uploadedByUser.info.firstName} ${item.uploadedByUser &&
      item.uploadedByUser.info.lastName}`,
    visibility: {
      isVisibleForStudent: item.isVisibleForStudent,
      isVisibleForTrainer: item.isVisibleForTrainer,
      id: item.id,
    },
  }));

  const tableConfig = getDocumentsConfig({ handleToggleVisibility });
  const [columns, documents] = transformTableData(tableConfig, documentsDataExtended);

  const handleCloseModal = () => dispatch(modalActions.closeModal());

  const [documentDeleting, deleteDocument] = useAsyncFn(async (templateId, fileId) => {
    const data = await deleteCourseTemplateDocument(templateId, fileId);
    if (!data.error) {
      handleCloseModal();
      dispatch(actions.setTableDeleting(true));
      dispatch(actions.forceUpdateTable(true));
    }
  });

  const [documentDownloading, downloadDocument] = useAsyncFn(async (templateId, fileId) => {
    await downloadCourseTemplateDocument(templateId, fileId);
  });

  const handleDownloadDocument = event => {
    downloadDocument(courseTemplateId, event.currentTarget.dataset.key);
  };
  const handleDeleteDocument = event => {
    const fileId = event.currentTarget.dataset.key;
    const [config, element] = getConfirmationModalConfig({
      title: t('courseTemplates.deleteDocumentModalTitle'),
      confirmAction: () => deleteDocument(courseTemplateId, fileId),
      rejectAction: handleCloseModal,
      message: t('courseTemplates.deleteDocumentConfirmationMessage'),
    });
    dispatch(modalActions.openModal(config, element));
  };

  useEffect(() => {
    tableData.handleChangeFetchMethod(pageAlias, getCourseTemplatesDocuments.bind(null, courseTemplateId));
  }, []);

  const tableActions = [
    {
      type: 'icon',
      icon: 'get_app',
      onClick: handleDownloadDocument,
    },
    {
      type: 'icon',
      icon: 'delete',
      color: 'error',
      onClick: handleDeleteDocument,
    },
  ];

  const isLoading = documentDeleting.loading || documentDownloading.loading || documentStateUpdating.loading;

  return (
    <>
      <AppTable
        columns={columns}
        data={documents}
        totalCount={tableData.totalCount}
        rowsPerPage={tableData.pageSize}
        onChangePage={tableData.handleChangePage}
        isLazyLoading
        actions={tableActions}
      />
      {isLoading && <Loader />}
    </>
  );
};

DocumentsTable.propTypes = {
  tableData: PropTypes.shape(withTableProps).isRequired,
  courseTemplateId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};

export default withTable({
  defaultSorting: {
    field: 'id',
    dir: 'desc',
  },
  tablePageSize: 5,
  fetchingData: 'items',
  pageAlias,
})(DocumentsTable);

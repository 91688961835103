import React from 'react';
import PropTypes from 'prop-types';
import Tabulation from 'components/Tabulation';
import { Button, Icon } from '@material-ui/core';
import { useAsyncFn } from 'react-use';
import { useDispatch } from 'react-redux';
import { actions as modalActions } from 'redux/modal';
import { actions as tableActions } from 'redux/table';
import { useTranslation } from 'react-i18next';
import { SendFileForm, SendCertificateForm } from 'components/CommonForms';
import { getDocumentsConfig, getCertificatesConfig, getRatingConfig } from './helpers/getTableConfig';
import DocumentsTable from './DocumentsTable';
import FeedbackTable from './FeedbackTable';

const getTabActionComponent = (title, icon, action = () => {}) => (
  <Button onClick={action} variant="contained" color="primary" startIcon={<Icon>{icon}</Icon>}>
    {title}
  </Button>
);

const TrainerDetails = ({ userId, helpers, chosenCourseTemplate }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation('common');

  const DocumentsTableComponent = (
    <DocumentsTable
      type="documents"
      userId={userId}
      configTable={getDocumentsConfig}
      helpers={{
        getDocuments: helpers.getDocuments,
        setDocuments: helpers.setDocuments,
        downloadDocument: helpers.downloadDocument,
        deleteDocument: helpers.deleteDocument,
      }}
    />
  );

  const [addFileState, addFile] = useAsyncFn(async formData => {
    const data = await helpers.setDocuments(formData);
    if (!data.error) {
      dispatch(modalActions.closeModal());
      dispatch(tableActions.forceUpdateTable(true));
    }
  });

  const handleAddDocument = () => {
    dispatch(
      modalActions.openModal(
        {
          size: 'medium',
          title: t('trainers.modalAddDocumentTitle'),
        },
        <SendFileForm
          onSubmitForm={addFile}
          rejectAction={() => {
            dispatch(modalActions.closeModal());
          }}
          submitting={addFileState.loading}
          fileFormat=".pdf, .pptx, .doc, .docx, .xlsx, .ppt, .jpeg, .jpg, .png"
        />,
      ),
    );
  };

  const CertificatesTableComponent = (
    <DocumentsTable
      type="certificates"
      userId={userId}
      configTable={getCertificatesConfig}
      helpers={{
        getDocuments: helpers.getCertificates,
        setDocuments: helpers.setCertificates,
        downloadDocument: helpers.downloadCertificate,
        deleteDocument: helpers.deleteCertificate,
      }}
    />
  );

  const [addCertificateState, addCertificate] = useAsyncFn(async formData => {
    const data = await helpers.setCertificates(userId, formData);
    if (!data.error) {
      dispatch(modalActions.closeModal());
      dispatch(tableActions.forceUpdateTable(true));
    }
  });

  const handleAddCertificate = () => {
    dispatch(
      modalActions.openModal(
        {
          size: 'medium',
          title: t('trainers.modalAddCertificateTitle'),
        },
        <SendCertificateForm
          onSubmitForm={addCertificate}
          chosenCourseTemplate={chosenCourseTemplate}
          rejectAction={() => {
            dispatch(modalActions.closeModal());
          }}
          submitting={addCertificateState.loading}
          fileFormat=".pdf, .jpg, .jpeg, .png"
        />,
      ),
    );
  };

  const FeedbackTableComponent = <FeedbackTable type="certificates" userId={userId} configTable={getRatingConfig} />;

  return (
    <Tabulation
      tabsData={[
        {
          label: 'Certifications',
          alias: 'certifications',
          Component: CertificatesTableComponent,
        },
        {
          label: 'Documents',
          alias: 'documents',
          Component: DocumentsTableComponent,
        },
        {
          label: 'Feedbacks',
          alias: 'feedbacks',
          Component: FeedbackTableComponent,
        },
      ]}
      actions={[
        {
          hiddenOnTabs: [1, 2],
          Component: getTabActionComponent('add certificate', 'add', handleAddCertificate),
        },
        {
          hiddenOnTabs: [0, 2],
          Component: getTabActionComponent('upload document', 'cloud_upload', handleAddDocument),
        },
      ]}
    />
  );
};

TrainerDetails.propTypes = {
  userId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  helpers: PropTypes.shape({
    getDocuments: PropTypes.func,
    setDocuments: PropTypes.func,
    setCertificate: PropTypes.func,
    deleteDocument: PropTypes.func,
    downloadDocument: PropTypes.func,
    getCertificates: PropTypes.func,
    setCertificates: PropTypes.func,
    deleteCertificate: PropTypes.func,
    downloadCertificate: PropTypes.func,
  }).isRequired,
  chosenCourseTemplate: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default TrainerDetails;

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import AppTable from 'components/AppTable';
import PageLayout from 'layouts/PageLayout';
import SearchFilter from 'components/SearchFilter';
import withTableProps from 'HOC/types/withTable';
import withTable from 'HOC/withTable';
import { routesAliases } from 'router/routes';
import { actions as modalActions } from 'redux/modal';
import { actions as tableActions } from 'redux/table';
import { exportJobsTasks, getJobsTasks, deleteJobTask } from 'http/jobsTasks';
import { transformTableData, getConfirmationModalConfig } from 'utils';
import { useAsyncFn } from 'react-use';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import getTableConfig from './helpers/getTableConfig';

const pageAlias = 'JobTasksList';

const JobsTasks = ({ tableData }) => {
  const dispatch = useDispatch();
  const tableConfig = getTableConfig();
  const history = useHistory();
  const { t } = useTranslation();
  const [columns, jobsTasks] = transformTableData(tableConfig, tableData.data);

  const handleCloseModal = () => dispatch(modalActions.closeModal());

  const [stateDeleteJobTask, deleteTask] = useAsyncFn(async id => {
    const data = await deleteJobTask(id);

    if (!data.error) {
      handleCloseModal();
      dispatch(tableActions.setTableDeleting(true));
      tableData.handleForceUpdate();
    } else {
      const [config, element] = getConfirmationModalConfig({
        confirmTitle: t('buttons.ok'),
        confirmAction: handleCloseModal,
        rejectAction: handleCloseModal,
        message: t('jobsTasks.cantDelete'),
      });
      dispatch(modalActions.openModal(config, element));
    }
  }, []);

  const handleDeleteJobTask = id => {
    const [config, element] = getConfirmationModalConfig({
      title: t('jobsTasks.deleteTaskModalTitle'),
      confirmAction: () => deleteTask(id),
      rejectAction: handleCloseModal,
      message: t('jobsTasks.deleteTaskConfirmationMessage'),
    });
    dispatch(modalActions.openModal(config, element));
  };

  const handleTableSearch = (value, searchAlias) => {
    tableData.handleFiltering(searchAlias, value);
  };

  const handleTableSearchClearing = searchAlias => {
    tableData.handleRemoveFilter(searchAlias);
  };

  useEffect(() => {
    tableData.handleChangeFetchMethod(pageAlias, getJobsTasks);
  }, []);

  const isLoading = tableData.isFetchingTableData || stateDeleteJobTask.loading;

  return (
    <PageLayout
      pageTitle={t('jobsTasks.pageTitle')}
      actionSectionLeft={[
        {
          type: 'component',
          Component: (
            <SearchFilter searchAlias="search" onSearch={handleTableSearch} onClearSearch={handleTableSearchClearing} />
          ),
        },
      ]}
      actionSectionRight={[
        {
          type: 'button',
          icon: 'add',
          title: t('jobsTasks.actionAdd'),
          onClick: () => {
            history.push(`${routesAliases.createJobTask}`);
          },
        },
        {
          type: 'button',
          icon: 'import_export',
          title: t('commonActions.actionExport'),
          onClick: exportJobsTasks,
        },
      ]}
      showLoader={isLoading}
    >
      <AppTable
        columns={columns}
        data={jobsTasks}
        totalCount={tableData.totalCount}
        rowsPerPage={tableData.pageSize}
        onChangePage={tableData.handleChangePage}
        onColumnSorting={tableData.handleSorting}
        currentPage={tableData.page}
        isLazyLoading
        actions={[
          {
            type: 'icon',
            icon: 'edit',
            onClick: event => {
              history.push({
                pathname: `${routesAliases.editJobTask}/${event.currentTarget.dataset.key}`,
              });
            },
          },
          {
            type: 'icon',
            icon: 'delete',
            color: 'error',
            onClick: event => {
              handleDeleteJobTask(event.currentTarget.dataset.key);
            },
          },
        ]}
      />
    </PageLayout>
  );
};

JobsTasks.propTypes = {
  tableData: PropTypes.shape(withTableProps).isRequired,
};

export default withTable({
  defaultSorting: {
    field: 'id',
    dir: 'desc',
  },
  fetchingData: 'items',
  pageAlias,
})(JobsTasks);

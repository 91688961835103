import React from 'react';
import PropTypes from 'prop-types';
import AppTable from 'components/AppTable';
import Loader from 'components/Loader';
import withTableProps from 'HOC/types/withTable';
import { actions as modalActions } from 'redux/modal';
import { actions } from 'redux/table';
import { SendFileForm } from 'components/CommonForms';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Icon } from '@material-ui/core';
import { transformTableData } from 'utils';
import { useAsyncFn } from 'react-use';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { getLocalCourse, getGlobalCourse } from 'http/courses';
import CourseView from '../CourseView';
import { getCoursesPastConfig } from './helpers/getCoursesPastTableConfig';

const useStyles = makeStyles(theme => ({
  btn: {
    cursor: 'pointer',
    whiteSpace: 'nowrap',
    '&:first-child': {
      marginRight: 20,
    },
    '&:last-child': {
      marginLeft: 20,
    },
  },
  btnDelete: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.common.contrastText,
  },
  btnWrapper: {
    marginTop: 20,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  modal: {
    textAlign: 'center',
    '& .MuiDialog-paper': {
      width: '500px',
    },
  },
  wrapper: {
    textAlign: 'center',
  },
}));

const StudentsPassedTable = ({ tableData, helpers, userId }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const classes = useStyles();

  const handleCloseModal = () => {
    dispatch(modalActions.closeModal());
  };

  const showCourseInfo = courseInfo => {
    dispatch(
      modalActions.openModal(
        {
          size: 'medium',
          title: t('students.courseDetails'),
        },
        <CourseView course={courseInfo} />,
      ),
    );
  };

  const [localCourseState, getLocalCourseData] = useAsyncFn(async (courseId, stationId) => {
    const data = await getLocalCourse(stationId, courseId);
    if (!data.error) {
      showCourseInfo(data);
    }
  });

  const [globalCourseState, getGlobalCourseData] = useAsyncFn(async courseId => {
    const data = await getGlobalCourse(courseId);
    if (!data.error) {
      showCourseInfo(data);
    }
  });

  const handleShowCourseView = event => {
    const { dataset } = event.currentTarget;
    const { key: courseId, stationid } = dataset;
    if (stationid) {
      getLocalCourseData(courseId, stationid);
    } else {
      getGlobalCourseData(courseId);
    }
  };

  const tableActions = [
    {
      type: 'icon',
      icon: 'remove_red_eye',
      onClick: handleShowCourseView,
    },
  ];

  const [addFileState, addFile] = useAsyncFn(async (courseId, formData) => {
    const data = await helpers.setCertificate(courseId, userId, formData);
    if (!data.error) {
      handleCloseModal();
      dispatch(actions.forceUpdateTable(true));
    }
  });

  const handleAddCertificate = courseId => {
    dispatch(
      modalActions.openModal(
        {
          size: 'medium',
          title: t('trainingDetails.addCertificateModalTitle'),
        },
        <SendFileForm
          fieldName="certificate"
          onSubmitForm={value => addFile(courseId, value)}
          rejectAction={() => {
            dispatch(modalActions.closeModal());
          }}
          submitting={addFileState.loading}
          fileFormat=".pdf"
        />,
      ),
    );
  };

  const [deleteFileState, deleteFile] = useAsyncFn(async courseId => {
    const data = await helpers.deleteCertificate(courseId, userId);
    if (!data.error) {
      handleCloseModal();
      dispatch(actions.forceUpdateTable(true));
    }
  });

  const handleShowCertificate = (courseId, certificateUrl) => {
    let picture;
    if (certificateUrl) {
      picture = <img src={certificateUrl} width="200" height="300" alt="" />;
    } else {
      picture = (
        <Icon color="primary" style={{ fontSize: 150 }}>
          image
        </Icon>
      );
    }
    dispatch(
      modalActions.openModal(
        {
          size: 'large',
          title: t('trainingDetails.certificateModalTitle'),
          customStyles: classes.modal,
        },
        <div className={classes.wrapper}>
          {picture}
          <div className={classes.btnWrapper}>
            <Button
              className={classes.btn}
              variant="contained"
              color="primary"
              size="large"
              onClick={() => {
                helpers.downloadCertificate(courseId, userId);
                handleCloseModal();
              }}
            >
              {t('buttons.save')}
            </Button>
            <Button
              className={`${classes.btn} ${classes.btnDelete}`}
              variant="outlined"
              size="large"
              onClick={handleCloseModal}
            >
              {t('buttons.cancel')}
            </Button>
            <Button
              className={classes.btn}
              variant="contained"
              color="error"
              size="large"
              onClick={() => deleteFile(courseId)}
            >
              {t('buttons.delete')}
            </Button>
          </div>
        </div>,
      ),
    );
  };

  const tableConfig = getCoursesPastConfig({ handleAddCertificate, handleShowCertificate });
  const [columns, students] = transformTableData(tableConfig, tableData.data);
  const isLoading = globalCourseState.loading || localCourseState.loading || deleteFileState.loading;

  return (
    <>
      <AppTable
        columns={columns}
        data={students}
        totalCount={tableData.totalCount}
        rowsPerPage={tableData.pageSize}
        isLazyLoading
        onChangePage={tableData.handleChangePage}
        onColumnSorting={tableData.handleSorting}
        actions={tableActions}
        extraActionsKeys={['stationId']}
      />
      {isLoading && <Loader />}
    </>
  );
};

StudentsPassedTable.propTypes = {
  userId: PropTypes.string.isRequired,
  tableData: PropTypes.shape(withTableProps).isRequired,
  helpers: PropTypes.shape({
    getCourses: PropTypes.func,
    deleteCourse: PropTypes.func,
    editCourse: PropTypes.func,
    getCertificate: PropTypes.func,
    setCertificate: PropTypes.func,
    downloadCertificate: PropTypes.func,
    deleteCertificate: PropTypes.func,
  }).isRequired,
};

export default StudentsPassedTable;

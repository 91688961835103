import React from 'react';
import i18next from 'i18next';
import { DateFormatter, PassedState, FileManager } from 'components/AppTable/CellsComponents';

export const getStudentsConfig = helpers => [
  {
    title: i18next.t('tablesLabels.id'),
    field: 'id',
  },
  {
    title: i18next.t('tablesLabels.name'),
    field: 'fullName',
  },
  {
    title: i18next.t('tablesLabels.jobsTasks'),
    field: 'jobTasks',
  },
  {
    title: i18next.t('tablesLabels.assignedDate'),
    field: 'createdAt',
    Component: value => <DateFormatter value={value} />,
  },
  {
    title: '',
    field: 'performParams',
    Component: state => <PassedState status={state} onChange={helpers.handleToggleState} />,
  },
  {
    title: i18next.t('tablesLabels.certificate'),
    field: 'certificateParams',
    Component: state => (
      <FileManager
        value={state}
        onChange={helpers.handleAddCertificate}
        onShowCertificate={helpers.handleShowCertificate}
      />
    ),
  },
];

export const getDocumentsConfig = () => [
  {
    title: i18next.t('tablesLabels.id'),
    field: 'id',
  },
  {
    title: i18next.t('tablesLabels.name'),
    field: 'name',
  },
  {
    title: i18next.t('tablesLabels.size'),
    field: 'documentSize',
    alignment: 'center',
    Component: size => (
      <div>
        {(size / 1000000).toFixed(3)}
        &ensp; MB
      </div>
    ),
  },
  {
    title: i18next.t('tablesLabels.uploadDate'),
    field: 'createdAt',
    Component: value => <DateFormatter value={value} />,
  },
  {
    title: i18next.t('tablesLabels.byWhom'),
    field: 'userUploaded',
  },
  {
    title: '',
    field: 'type',
    isVisible: false,
  },
];

import React from 'react';
import PropTypes from 'prop-types';
import AppTable from 'components/AppTable';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { transformTableData, getConfirmationModalConfig } from 'utils';
import { useAsyncFn } from 'react-use';
import { actions as modalActions } from 'redux/modal';
import { actions as tableActions } from 'redux/table';
import Loader from 'components/Loader';
import { deleteUser, toggleUserState } from 'http/users';
import { useDispatch } from 'react-redux';
import withTableProps from 'HOC/types/withTable';
import getTableConfig from './helpers/getTableConfig';

const AdministratorsTable = ({ tableData, routesAliases }) => {
  const { t } = useTranslation('common');
  const dispatch = useDispatch();
  const history = useHistory();

  const handleCloseModal = () => dispatch(modalActions.closeModal());

  const [stateActiveAdministrator, toggleAdministratorState] = useAsyncFn(async (id, enable) => {
    const requestData = await toggleUserState(id, enable);

    if (!requestData.error) {
      handleCloseModal();
      tableData.handleForceUpdate();
    }
  });

  const handleToggleState = event => {
    event.stopPropagation();
    const id = event.currentTarget.dataset.key;
    const isEnable = JSON.parse(event.currentTarget.dataset.val);
    const enable = isEnable ? 'disable' : 'enable';
    const message = isEnable ? t('administrators.deactivationApproval') : t('administrators.activationApproval');

    const [config, element] = getConfirmationModalConfig({
      title: t('administrators.modalEditTitle'),
      confirmAction: () => toggleAdministratorState(id, enable),
      rejectAction: handleCloseModal,
      message,
    });
    dispatch(modalActions.openModal(config, element));
  };

  const tableConfig = getTableConfig({ handleToggleState });
  const [columns, users] = transformTableData(tableConfig, tableData.data);

  const [stateDeleteAdministrator, deleteAdministrator] = useAsyncFn(async companyId => {
    const data = await deleteUser(companyId);

    if (data) {
      handleCloseModal();
      dispatch(tableActions.setTableDeleting(true));
      tableData.handleForceUpdate();
    }
  }, []);

  const handleDeleteAdministrator = id => {
    const [config, element] = getConfirmationModalConfig({
      title: t('administrators.modalDeleteTitle'),
      confirmAction: () => deleteAdministrator(id),
      rejectAction: handleCloseModal,
      message: t('administrators.deleteApproval'),
    });
    dispatch(modalActions.openModal(config, element));
  };

  const isLoading =
    tableData.isFetchingTableData || stateDeleteAdministrator.loading || stateActiveAdministrator.loading;

  return (
    <>
      <AppTable
        columns={columns}
        data={users}
        totalCount={tableData.totalCount}
        rowsPerPage={tableData.pageSize}
        onChangePage={tableData.handleChangePage}
        onColumnSorting={tableData.handleSorting}
        currentPage={tableData.page}
        isLazyLoading
        actions={[
          {
            type: 'icon',
            icon: 'edit',
            onClick: event => history.push(`${routesAliases.editAdministrator}/${event.currentTarget.dataset.key}`),
          },
          {
            type: 'icon',
            icon: 'delete',
            color: 'error',
            onClick: event => handleDeleteAdministrator(event.currentTarget.dataset.key),
          },
        ]}
      />
      {isLoading && <Loader />}
    </>
  );
};

AdministratorsTable.propTypes = {
  tableData: PropTypes.shape(withTableProps).isRequired,
  routesAliases: PropTypes.shape({
    editAdministrator: PropTypes.string.isRequired,
  }).isRequired,
};

export default AdministratorsTable;

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  formWrap: {
    maxWidth: '800px',
    margin: '20px auto 0',
  },
  btnContainer: {
    marginTop: '52px',
    marginBottom: 0,
  },
  btn: {
    minWidth: '140px',
  },
  generalInfo: {
    paddingRight: '44px',
    flexBasis: '50%',
    position: 'relative',
    '&::after': {
      position: 'absolute',
      content: '""',
      width: '1px',
      background: '#DBDBDB',
      right: '0',
      top: '5px',
      bottom: 0,
    },
  },
  additionalInfo: {
    paddingLeft: '44px',
    flexBasis: '50%',
  },
  customRadio: {
    '& .MuiFormControlLabel-root': {
      flexBasis: '30%',
    },
    '& .MuiFormLabel-root': {
      visibility: 'hidden',
    },
  },
}));

export default useStyles;

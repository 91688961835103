import React, { useEffect, useState } from 'react';
import PageLayout from 'layouts/PageLayout';
import moment from 'moment';
import PageSelect from 'components/Selects/PageSelect';
import { ROLES } from 'appConstants';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import { useSelector, useDispatch } from 'react-redux';
import { useAsyncFn } from 'react-use';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { useHistory } from 'react-router-dom';
import { routesAliases } from 'router/routes';
import { getScheduledCoursesByStation, getScheduledCourses } from 'http/courses';
import { actions as modalActions } from 'redux/modal';
import Toolbar from './Toolbar';
import transformToSchedulerData from './helpers/transformToSchedulerData';
import transformToSchedulerDataDaily from './helpers/transformToSchedulerDataDaily';
import CourseView from './CourseView';

const localizer = momentLocalizer(moment);

const useStyles = makeStyles(theme => ({
  content: {
    marginTop: theme.spacing(4),
    padding: theme.spacing(6, 8, 4, 8),
    height: '800px',

    '& .rbc-header + .rbc-header': {
      borderLeft: 'none',
    },

    '& .rbc-month-view': {
      borderTopLeftRadius: '20px',
      borderTopRightRadius: '20px',

      '& .rbc-show-more': {
        color: theme.palette.primary.main,
      },
      '& .rbc-off-range-bg': {
        background: '#fff',
      },
      '& .rbc-month-header': {
        background: '#7B7E8E',
        color: '#fff',
        borderTopLeftRadius: '20px',
        borderTopRightRadius: '20px',
      },

      '& .rbc-header': {
        padding: '15px 5px',
      },
    },
  },
}));

const taskColors = {
  global: '#EF746C',
  local: '#3D6FEE',
  default: '#3D6FEE',
};

const Schedule = () => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation('common');
  const { station, role } = useSelector(state => state.auth.profile);
  const stationId = station ? station.id : undefined;
  const coursesTypeOptions = [
    {
      label: t('schedule.allCourses'),
      value: 'all',
    },
    {
      label: t('schedule.globalCourses'),
      value: 'global',
    },
    {
      label: t('schedule.localCourses'),
      value: 'local',
    },
  ];
  const [coursesType, setCoursesType] = useState(coursesTypeOptions[0].value);
  const [courses, setCourses] = useState([]);
  const [filteredCourses, setFilteredCourses] = useState([]);
  const [currentDate, setCurrentDate] = useState(new Date());
  const [currentView, setCurrentView] = useState('month');

  const permissions = {
    addGlobalCourse: [ROLES.SUPER],
    addLocalCourse: [ROLES.SUPER, ROLES.ADMIN],
    editGlobalCourse: [ROLES.SUPER],
    editCourses: [ROLES.SUPER, ROLES.ADMIN],
    relatedSchedule: [ROLES.TRAINER, ROLES.USER],
  };

  const getFilteredCourses = (data, type) => {
    return data.filter(item => {
      let condition = item.type === type;
      if (type === 'all') {
        condition = true;
      }
      return condition;
    });
  };

  const [fetchingCoursesState, fetchCourses] = useAsyncFn(async (currentCoursesType, params, view, stationIdParam) => {
    let coursesData;
    if (role === ROLES.TRAINER) {
      coursesData = await getScheduledCourses(params);
    } else {
      coursesData = await getScheduledCoursesByStation(stationIdParam, params);
    }

    if (!coursesData.error) {
      const relatedCourses = Array.isArray(coursesData) ? coursesData : [];

      const transformedCourses =
        view === 'month' ? transformToSchedulerData(relatedCourses) : transformToSchedulerDataDaily(relatedCourses);

      setCourses(transformedCourses);
      setFilteredCourses(getFilteredCourses(transformedCourses, currentCoursesType));
    }
  });

  const handleNavigate = (date, view, stationIdParam) => {
    setCurrentDate(date);
    const startOfMonth = moment(date).startOf('month');
    const endOfMonth = moment(date).endOf('month');
    const firstDayOfCalendar = startOfMonth.subtract(startOfMonth.day(), 'days');
    const lastDayOfCalendar = endOfMonth.add(6 - endOfMonth.day(), 'days');

    const extraParams = {
      'date-from': moment(firstDayOfCalendar).format('YYYY-MM-DD'),
      'date-to': moment(lastDayOfCalendar).format('YYYY-MM-DD'),
    };
    fetchCourses(coursesType, extraParams, view, stationIdParam);
  };

  const redirectToEditCourse = (courseType, courseId) => {
    history.push(`${routesAliases.editCourse}/${courseType}/${courseId}/${stationId || 'external'}`, {
      backUrl: routesAliases.schedule,
    });
  };

  const showCourseInfo = courseInfo => {
    dispatch(
      modalActions.openModal(
        {
          size: 'medium',
          title: courses.importModalTitle,
        },
        <CourseView course={courseInfo} />,
      ),
    );
  };

  const handleActionOnSelectCourse = (actionPermissions, course) => {
    if (role) {
      if (actionPermissions.includes(role)) {
        redirectToEditCourse(course.type, course.courseInfo.id);
      } else {
        showCourseInfo(course.courseInfo);
      }
    }
  };

  const handleSelectCourse = course => {
    switch (course.type) {
      case 'global':
        handleActionOnSelectCourse(permissions.editGlobalCourse, course);
        break;
      default:
        handleActionOnSelectCourse(permissions.editCourses, course);
        break;
    }
  };

  const handleChangeCourseType = value => {
    setFilteredCourses(getFilteredCourses(courses, value));
    setCoursesType(value);
  };

  const handleChangeView = view => {
    setCurrentView(view);
  };

  useEffect(() => {
    handleNavigate(currentDate, currentView, stationId);
  }, [station, currentView]);

  const isLoading = fetchingCoursesState.loading;

  return (
    <PageLayout
      pageTitle={t('schedule.pageTitle')}
      showLoader={isLoading}
      classes={{
        content: classes.content,
      }}
      headerActions={[
        {
          type: 'component',
          Component: (
            <PageSelect
              onChangeValue={handleChangeCourseType}
              selectedOption={coursesType}
              options={coursesTypeOptions}
            />
          ),
        },
        {
          type: 'button',
          icon: 'add',
          title: t('schedule.addAction', { type: t('courses.global') }),
          isHidden: !role || !permissions.addGlobalCourse.includes(role),
          onClick: () => {
            history.push(`${routesAliases.createCourse}/global`, {
              backUrl: routesAliases.schedule,
            });
          },
        },
        {
          type: 'button',
          icon: 'add',
          title: t('schedule.addAction', { type: t('courses.local') }),
          isHidden: !role || !permissions.addLocalCourse.includes(role),
          onClick: () => {
            history.push(`${routesAliases.createCourse}/local`, {
              backUrl: routesAliases.schedule,
            });
          },
        },
      ]}
    >
      <Calendar
        popup
        selectable
        events={filteredCourses}
        views={{
          month: true,
          week: true,
          day: true,
        }}
        step={60}
        eventPropGetter={event => ({
          style: {
            background: taskColors[event.type] || taskColors.default,
            padding: '3px',
          },
        })}
        formats={{
          weekdayFormat: 'dddd',
        }}
        components={{
          toolbar: Toolbar,
        }}
        onView={handleChangeView}
        onNavigate={e => handleNavigate(e, currentView, stationId)}
        onSelectEvent={handleSelectCourse}
        localizer={localizer}
      />
    </PageLayout>
  );
};

export default Schedule;

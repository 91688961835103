import React from 'react';
import i18next from 'i18next';
import { DateFormatter, MultipleChoice } from 'components/AppTable/CellsComponents';

export const getDocumentsConfig = helpers => [
  {
    title: i18next.t('tablesLabels.id'),
    field: 'id',
  },
  {
    title: i18next.t('tablesLabels.name'),
    field: 'name',
  },
  {
    title: i18next.t('tablesLabels.size'),
    field: 'documentSize',
    alignment: 'center',
    Component: size => (
      <div>
        {(size / 1000000).toFixed(3)}
        &ensp; MB
      </div>
    ),
  },
  {
    title: i18next.t('tablesLabels.uploadDate'),
    field: 'createdAt',
    Component: value => <DateFormatter value={value} />,
  },
  {
    title: i18next.t('tablesLabels.byWhom'),
    field: 'userUploaded',
  },
  {
    title: i18next.t('tablesLabels.visibility'),
    field: 'visibility',
    Component: value => <MultipleChoice value={value} onChange={helpers.handleToggleVisibility} />,
  },
];

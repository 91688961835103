import React from 'react';
import PropTypes from 'prop-types';
import AppTable from 'components/AppTable';
import Loader from 'components/Loader';
import { Button, Icon } from '@material-ui/core';
import { transformTableData } from 'utils';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useAsyncFn } from 'react-use';
import { useDispatch } from 'react-redux';
import { actions as modalActions } from 'redux/modal';
import { setCourseRating, downloadCourseCertificate } from 'http/courses';
import { makeStyles } from '@material-ui/core/styles';
import withTableProps from 'HOC/types/withTable';
import FeedbackForm from './FeedbackForm';
import getTableConfig from './helpers/getTableConfig';

const useStyles = makeStyles(theme => ({
  modal: {
    '& .MuiDialog-paper': {
      width: '350px',
    },
  },
  btn: {
    cursor: 'pointer',
    whiteSpace: 'nowrap',
    '&:first-child': {
      marginRight: 20,
    },
    '&:last-child': {
      marginLeft: 20,
    },
  },
  btnDelete: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.common.contrastText,
  },
  btnWrapper: {
    marginTop: 20,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  modalCertificate: {
    textAlign: 'center',
    '& .MuiDialog-paper': {
      width: '500px',
    },
  },
  wrapper: {
    textAlign: 'center',
  },
}));

const CoursesTable = ({ routesAliases, tableData, userId }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation('common');

  const handleCloseModal = () => {
    dispatch(modalActions.closeModal());
    tableData.handleForceUpdate();
  };

  const [studentRatingState, setStudentRatingState] = useAsyncFn(async (courseId, rating) => {
    const data = await setCourseRating(courseId, rating);
    if (!data.error) {
      handleCloseModal();
    }
  });

  const handleRatingModal = (key, rating) => {
    dispatch(
      modalActions.openModal(
        {
          size: 'large',
          title: t('courses.feedback'),
          customStyles: classes.modal,
        },
        <FeedbackForm courseId={key} rating={rating} onSubmit={setStudentRatingState} onCancel={handleCloseModal} />,
      ),
    );
  };

  const handleShowCertificate = (courseId, certificateUrl) => {
    let picture;
    if (certificateUrl) {
      picture = <img src={certificateUrl} width="200" height="300" alt="" />;
    } else {
      picture = (
        <Icon color="primary" style={{ fontSize: 150 }}>
          image
        </Icon>
      );
    }
    dispatch(
      modalActions.openModal(
        {
          size: 'large',
          title: t('trainingDetails.certificateModalTitle'),
          customStyles: classes.modalCertificate,
        },
        <div className={classes.wrapper}>
          {picture}
          <div className={classes.btnWrapper}>
            <Button
              className={classes.btn}
              variant="contained"
              color="primary"
              size="large"
              onClick={() => {
                downloadCourseCertificate(courseId, userId);
                handleCloseModal();
              }}
            >
              {t('buttons.save')}
            </Button>
            <Button className={classes.btn} variant="outlined" size="large" onClick={handleCloseModal}>
              {t('buttons.cancel')}
            </Button>
          </div>
        </div>,
      ),
    );
  };

  const tableConfig = getTableConfig({ handleRatingModal, handleShowCertificate });
  const history = useHistory();
  const tableDataExtended =
    Array.isArray(tableData.data) &&
    tableData.data.map(item => ({
      ...item,
      feedback: { status: item.status, rating: item.rating },
    }));
  const [columns, courses] = transformTableData(tableConfig, tableDataExtended);

  const isLoading = tableData.isFetchingTableData || studentRatingState.loading;

  return (
    <>
      <AppTable
        columns={columns}
        data={courses}
        totalCount={tableData.totalCount}
        rowsPerPage={tableData.pageSize}
        onChangePage={tableData.handleChangePage}
        onColumnSorting={tableData.handleSorting}
        currentPage={tableData.page}
        extraActionsKeys={['stationId']}
        isLazyLoading
        actions={[
          {
            title: t('buttons.view'),
            onClick: event => {
              const { stationid: stationId, key } = event.currentTarget.dataset;
              let courseType = 'global';
              if (stationId) {
                courseType = 'local';
              }
              history.push(`${routesAliases.viewStudentCourse}/${key}/${courseType}/${stationId || 'external'}`);
            },
          },
        ]}
      />
      {isLoading && <Loader />}
    </>
  );
};

CoursesTable.propTypes = {
  tableData: PropTypes.shape(withTableProps).isRequired,
  routesAliases: PropTypes.shape({
    viewStudentCourse: PropTypes.string,
  }).isRequired,
  userId: PropTypes.number.isRequired,
};

export default CoursesTable;

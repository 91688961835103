import React from 'react';
import PropTypes from 'prop-types';
import AppTable from 'components/AppTable';
import Loader from 'components/Loader';
import withTableProps from 'HOC/types/withTable';
import { useTranslation } from 'react-i18next';
import { useAsyncFn } from 'react-use';
import { useHistory } from 'react-router-dom';
import { transformTableData, getConfirmationModalConfig } from 'utils';
import { setCourseToPerformed, setCourseToFailed } from 'http/courseStudent';
import { useDispatch } from 'react-redux';
import { actions as modalActions } from 'redux/modal';
import { actions } from 'redux/table';
import { getCoursesInProgressConfig } from './helpers/getCoursesInProgressTableConfig';

const CoursesInProgressTable = ({ tableData, helpers, userId, routesAliases }) => {
  const { t } = useTranslation('common');
  const dispatch = useDispatch();
  const history = useHistory();
  const handleCloseModal = () => dispatch(modalActions.closeModal());

  const [settingsStudentCourseStatus, setStudentCourseStatus] = useAsyncFn(async (courseId, status) => {
    const stateParams = [courseId, userId];
    let data;
    if (status === 'passed') {
      data = await setCourseToPerformed(...stateParams);
    } else {
      data = await setCourseToFailed(courseId, userId);
    }
    if (!data.error) {
      handleCloseModal();
      tableData.handleForceUpdate();
      history.push(`${routesAliases.editStudent}/${userId}`, { tabIndex: 2, alias: 'past' });
    }
    return data;
  });

  const handleToggleState = (courseId, status) => {
    let title;
    let message;
    if (status === 'failed') {
      title = t('students.modalFailedTitle');
      message = t('students.modalFailedMessage');
    } else if (status === 'passed') {
      title = t('students.modalPassedTitle');
      message = t('students.modalPassedMessage');
    }
    const [config, element] = getConfirmationModalConfig({
      title,
      confirmAction: () => setStudentCourseStatus(courseId, status),
      rejectAction: handleCloseModal,
      message,
    });
    dispatch(modalActions.openModal(config, element));
  };

  const tableConfig = getCoursesInProgressConfig({ handleToggleState });
  const [columns, students] = transformTableData(tableConfig, tableData.data);

  const tableActions = [
    {
      type: 'icon',
      icon: 'edit',
      onClick: helpers.editCourse,
    },
    {
      type: 'icon',
      icon: 'delete',
      color: 'error',
      onClick: event => {
        helpers.deleteCourse(event);
        dispatch(actions.setTableDeleting(true));
        tableData.handleForceUpdate();
      },
    },
  ];

  const isLoading = settingsStudentCourseStatus.loading;

  return (
    <>
      <AppTable
        columns={columns}
        data={students}
        totalCount={tableData.totalCount}
        rowsPerPage={tableData.pageSize}
        isLazyLoading
        onChangePage={tableData.handleChangePage}
        onColumnSorting={tableData.handleSorting}
        actions={tableActions}
        extraActionsKeys={['stationId']}
      />
      {isLoading && <Loader />}
    </>
  );
};

CoursesInProgressTable.propTypes = {
  tableData: PropTypes.shape(withTableProps).isRequired,
  helpers: PropTypes.shape({
    getCourses: PropTypes.func,
    deleteCourse: PropTypes.func,
    editCourse: PropTypes.func,
  }).isRequired,
  userId: PropTypes.string.isRequired,
  routesAliases: PropTypes.shape({
    editStudent: PropTypes.string.isRequired,
  }).isRequired,
};

export default CoursesInProgressTable;

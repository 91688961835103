import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Loader from 'components/Loader';
import AppTable from 'components/AppTable';
import withTableProps from 'HOC/types/withTable';
import { useAsyncFn } from 'react-use';
import { transformTableData } from 'utils';
import { useTranslation } from 'react-i18next';
import { setJobTaskPerformed, setJobTaskFailed } from 'http/jobTaskStudent';
import Modal from 'components/Modal';
import trimStrByDotCount from 'utils/trimStrByDotCount';
import getTableTabConfig from './helpers/getTableTasksConfig';

const StudentTasksTable = ({ studentData, studentId }) => {
  const { t } = useTranslation('common');
  const [modalConfig, setModalConfig] = useState({
    isOpen: false,
    size: 'medium',
    title: t('errors.notification'),
  });

  const handleClose = () =>
    setModalConfig({
      ...modalConfig,
      isOpen: false,
    });

  const [statePerformed, setJobTaskPerformedAsync] = useAsyncFn(async (taskId, status, setStateToDefault) => {
    let data;
    if (status) {
      data = await setJobTaskPerformed(studentId, taskId);
    } else {
      data = await setJobTaskFailed(studentId, taskId);
    }

    if (!data.error) {
      setModalConfig({
        ...modalConfig,
        isOpen: true,
        children: <p>{t('students.jobTaskPerformed')}</p>,
      });
    }

    if (data.statusCode >= 400 && data.statusCode < 500) {
      if (data.message.indexOf('jobTask.courseTemplatesExpired') !== -1) {
        setModalConfig({
          ...modalConfig,
          isOpen: true,
          children: <p>{t('students.jobTaskExpired', { courses: trimStrByDotCount(data.message, 2, 'right') })}</p>,
        });
        setStateToDefault(!status);
      }
      if (data.message.indexOf('jobTask.notFound') !== -1) {
        setModalConfig({
          ...modalConfig,
          isOpen: true,
          children: <p>{t('students.jobTaskNotFound')}</p>,
        });
        setStateToDefault(!status);
      }
      if (data.message.indexOf('jobTask.alreadyPerformed') !== -1) {
        setModalConfig({
          ...modalConfig,
          isOpen: true,
          children: <p>{t('students.jobTaskAlreadyPerformed')}</p>,
        });
      }
      return false;
    }

    return data;
  });

  const handleToggleState = ({ taskId, status }, fn) => {
    if (!status) {
      setModalConfig({
        ...modalConfig,
        isOpen: true,
        children: <p>{t('students.jobTaskNotPerformed')}</p>,
      });
      return false;
    }
    setJobTaskPerformedAsync(taskId, status, fn);
    return true;
  };
  const tableConfig = getTableTabConfig({ handleToggleState });
  const [columns, tasks] = transformTableData(tableConfig, studentData);
  const isLoading = statePerformed.loading;

  return (
    <>
      <AppTable columns={columns} data={tasks} totalCount={tasks.length} rowsPerPage={10} />
      <Modal {...modalConfig} onClose={handleClose} />
      {isLoading && <Loader />}
    </>
  );
};

StudentTasksTable.propTypes = {
  studentData: PropTypes.shape(withTableProps).isRequired,
  studentId: PropTypes.string.isRequired,
};

export default StudentTasksTable;

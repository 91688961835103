import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import AppTable from 'components/AppTable';
import Loader from 'components/Loader';
import withTableProps from 'HOC/types/withTable';
import withTable from 'HOC/withTable';
import { transformTableData, getConfirmationModalConfig } from 'utils';
import { useAsyncFn } from 'react-use';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { actions as modalActions } from 'redux/modal';
import { actions } from 'redux/table';
import { useTranslation } from 'react-i18next';
import { getStudentsConfig } from './helpers/getTableConfig';

const pageAlias = 'CoursesDetails';

const StudentsTable = ({ tableData, helpers, handleChangeStudentsCount, routesAliases, isFrozen }) => {
  const tableConfig = getStudentsConfig();
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation('common');
  const [columns, students] = transformTableData(tableConfig, tableData.data);

  const handleCloseModal = () => {
    dispatch(modalActions.closeModal());
    tableData.handleForceUpdate();
  };

  const [studentDeleting, deleteStudent] = useAsyncFn(async id => {
    const data = await helpers.removeStudentFromCourse(id);
    if (!data.error) {
      handleCloseModal();
      dispatch(actions.setTableDeleting(true));
      handleChangeStudentsCount('subtract');
    }
    const handleServerError = errorMessage => {
      dispatch(
        modalActions.openModal(
          {
            size: 'medium',
            title: t('errors.notification'),
          },
          <p>{errorMessage}</p>,
        ),
      );
    };
    if (data.statusCode === 400 && data.message === 'user.hasPerformedState') {
      handleServerError(t('errors.studentHasPerformedState'));
    }
  });

  const handleDeleteStudent = event => {
    const studentId = event.currentTarget.dataset.key;
    const [config, element] = getConfirmationModalConfig({
      title: t('courseDetails.deleteStudentConfirmationTitle'),
      confirmAction: () => deleteStudent(studentId),
      rejectAction: handleCloseModal,
      message: t('courseDetails.deleteStudentConfirmationMessage'),
    });
    dispatch(modalActions.openModal(config, element));
  };

  const tableActions = [
    {
      type: 'icon',
      icon: 'edit',
      disabled: isFrozen,
      onClick: event => history.push(`${routesAliases.editStudent}/${event.currentTarget.dataset.key}`),
    },
    {
      type: 'icon',
      icon: 'delete',
      color: 'error',
      disabled: isFrozen,
      onClick: handleDeleteStudent,
    },
  ];

  useEffect(() => {
    tableData.handleChangeFetchMethod(pageAlias, helpers.fetchCourseStudents);
  }, []);

  const isLoading = studentDeleting.loading;

  return (
    <>
      <AppTable
        columns={columns}
        data={students}
        totalCount={tableData.totalCount}
        rowsPerPage={tableData.pageSize}
        isLazyLoading
        onChangePage={tableData.handleChangePage}
        actions={tableActions}
        actionsAlignment="right"
      />
      {isLoading && <Loader />}
    </>
  );
};

StudentsTable.propTypes = {
  tableData: PropTypes.shape(withTableProps).isRequired,
  helpers: PropTypes.shape({
    fetchCourseStudents: PropTypes.func,
    removeStudentFromCourse: PropTypes.func,
  }).isRequired,
  handleChangeStudentsCount: PropTypes.func.isRequired,
  routesAliases: PropTypes.shape({
    editStudent: PropTypes.string,
  }).isRequired,
  isFrozen: PropTypes.bool.isRequired,
};

export default withTable({
  defaultSorting: {
    field: 'id',
    dir: 'desc',
  },
  tablePageSize: 5,
  fetchingData: 'items',
  pageAlias,
})(StudentsTable);

import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Box, Chip, Typography, Divider } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
  root: {
    minWidth: '500px',
  },
  divider: {
    margin: '20px 0',
  },
  time: {
    display: 'inline-block',
    marginLeft: '5px',
    color: theme.palette.error.main,
  },
  chip: {
    marginRight: '5px',
    marginBottom: '5px',
  },
  infoLabel: {
    display: 'inline-block',
    marginBottom: '2px',
  },
}));

const CourseView = ({ course }) => {
  const classes = useStyles();
  const { t } = useTranslation('common');
  const {
    startTime,
    endTime,
    startDate,
    endDate,
    maxStudents,
    description,
    jobTasks,
    trainers,
    name,
    location,
  } = course;

  return (
    <div className={classes.root}>
      <div className={classes.headSection}>
        <Grid container justify="space-between">
          <Grid container item xs={5} direction="column">
            <Grid item>
              <Box mb={2}>
                {t('schedule.startDate')}
                {':'}
                <span className={classes.time}>{moment(startDate).format('MM/DD/YYYY')}</span>
              </Box>
            </Grid>
            <Grid item>
              <Box>
                {t('schedule.endDate')}
                {':'}
                <span className={classes.time}>{moment(endDate).format('MM/DD/YYYY')}</span>
              </Box>
            </Grid>
          </Grid>
          <Grid container item xs={5} direction="column">
            <Grid item>
              <Box mb={2}>
                {t('schedule.startingTime')}
                {':'}
                <span className={classes.time}>{moment(`${startDate} ${startTime}`).format('LT')}</span>
              </Box>
            </Grid>
            <Grid item>
              <Box>
                {t('schedule.endingTime')}
                {':'}
                <span className={classes.time}>{moment(`${endDate} ${endTime}`).format('LT')}</span>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </div>
      <Divider className={classes.divider} />
      <div className={classes.mainContent}>
        <Grid container direction="column">
          <Grid container item justify="space-between">
            <Grid item xs={5}>
              <Box mb={2}>
                <span className={classes.infoLabel}>
                  {t('schedule.courseName')}
                  {':'}
                </span>
                <Typography variant="body2" color="textSecondary" component="p">
                  {name}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={5}>
              <Box mb={2}>
                <span className={classes.infoLabel}>
                  {t('schedule.maxStudents')}
                  {':'}
                </span>
                <Typography variant="body2" color="textSecondary" component="p">
                  {maxStudents}
                </Typography>
              </Box>
            </Grid>
          </Grid>
          <Grid container item justify="space-between">
            <Grid item xs={5}>
              <Box mb={2}>
                <span className={classes.infoLabel}>
                  {t('schedule.jobTasks')}
                  {':'}
                </span>
                <Typography variant="body2" color="textSecondary" component="div">
                  {jobTasks &&
                    jobTasks.map((task, index) => (
                      <Chip key={index} className={classes.chip} label={task} variant="outlined" />
                    ))}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={5}>
              <Box mb={2}>
                <span className={classes.infoLabel}>
                  {t('schedule.trainers')}
                  {':'}
                </span>
                <Typography variant="body2" color="textSecondary" component="div">
                  {trainers &&
                    trainers.map((trainer, index) => (
                      <Chip key={index} className={classes.chip} label={trainer.fullName} variant="outlined" />
                    ))}
                </Typography>
              </Box>
            </Grid>
          </Grid>
          <Grid item>
            <Grid item>
              <Box mb={2}>
                <span className={classes.infoLabel}>
                  {t('schedule.location')}
                  {':'}
                </span>
                <Typography variant="body2" color="textSecondary" component="p">
                  {location}
                </Typography>
              </Box>
            </Grid>
          </Grid>
          <Grid item>
            <Grid item>
              <Box mb={2}>
                <span className={classes.infoLabel}>
                  {t('schedule.description')}
                  {':'}
                </span>
                <Typography variant="body2" color="textSecondary" component="p">
                  {description}
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

const dateType = PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]);
CourseView.propTypes = {
  course: PropTypes.shape({
    startTime: dateType,
    endTime: dateType,
    startDate: dateType,
    endDate: dateType,
    maxStudents: PropTypes.number,
    description: PropTypes.string,
    jobTasks: PropTypes.arrayOf(PropTypes.string),
    trainers: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        fullName: PropTypes.string,
      }),
    ),
    name: PropTypes.string,
    location: PropTypes.string,
  }).isRequired,
};

export default CourseView;

import React from 'react';
import PropTypes from 'prop-types';
import { fade, makeStyles } from '@material-ui/core/styles';
import { InputBase, Icon } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: ({ bgColor }) => fade(bgColor || '#fff', 0.7),
    '&:hover': {
      backgroundColor: ({ bgColor }) => fade(bgColor || '#fff', 0.9),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: ({ searchWidth }) => searchWidth || '100%',
  },
  searchIcon: {
    width: theme.spacing(7),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 7),
    transition: theme.transitions.create('width'),
    width: '100%',
  },
}));

const SearchFilter = ({ searchAlias, placeholder, onSearch, onClearSearch, styles, ...props }) => {
  const classes = useStyles({ bgColor: styles.bgColor, searchWidth: styles.searchWidth });
  const { t } = useTranslation('common');
  const handleChangeValue = e => {
    const currentValue = e.currentTarget.value;
    if (currentValue === '') onClearSearch(searchAlias);
  };

  const handleEnterPress = e => {
    const currentValue = e.currentTarget.value;
    if (e.keyCode === 13) {
      if (currentValue !== '') onSearch(currentValue, searchAlias);
    }
  };

  return (
    <>
      <div className={classes.search} {...props}>
        <div className={classes.searchIcon}>
          <Icon color="primary">search</Icon>
        </div>
        <InputBase
          placeholder={t(placeholder)}
          onChange={handleChangeValue}
          classes={{
            root: classes.inputRoot,
            input: classes.inputInput,
          }}
          inputProps={{ 'aria-label': 'search' }}
          onKeyDown={handleEnterPress}
        />
      </div>
    </>
  );
};

SearchFilter.defaultProps = {
  placeholder: 'placeholders.search',
  onClearSearch: () => {},
  styles: {
    bgColor: '#fff',
    searchWidth: '100%',
  },
};

SearchFilter.propTypes = {
  searchAlias: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  onSearch: PropTypes.func.isRequired,
  onClearSearch: PropTypes.func,
  styles: PropTypes.shape({
    bgColor: PropTypes.string,
    searchWidth: PropTypes.string,
  }),
};

export default SearchFilter;

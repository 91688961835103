import moment from 'moment';

const parseDate = date => ({
  hour: moment(date).hour(),
  minute: moment(date).minute(),
  day: moment(date).date(),
  month: moment(date).month(),
  year: moment(date).year(),
});

const transformToSchedulerDataDaily = data => {
  const transformedData = [];

  if (Array.isArray(data)) {
    data.forEach(item => {
      const { startTime, endTime, startDate, endDate, courseType, id, description, courseName } = item;
      const { hour: startHour, minute: startMinute } = parseDate(`${startDate} ${startTime}`);
      const { hour: endHour, minute: endMinute } = parseDate(`${endDate} ${endTime}`);
      const { year: startYear, month: startMonth, day: startDay } = parseDate(startDate);
      const { year: endYear, month: endMonth, day: endDay } = parseDate(endDate);

      const momentStartDate = moment(startDate);

      let dateDifference = Math.abs(endDay - startDay + (endMonth - startMonth) + (endYear - startYear));
      let newDate = momentStartDate;

      while (dateDifference >= 0) {
        const { year: newDateYear, month: newDateMonth, day: newDateDay } = parseDate(newDate);
        const newEventObj = {
          id,
          type: courseType,
          desc: description,
          title: courseName,
          courseInfo: item,
          start: new Date(
            moment()
              .year(newDateYear)
              .month(newDateMonth)
              .date(newDateDay)
              .hour(startHour)
              .minute(startMinute),
          ),
          end: new Date(
            moment()
              .year(newDateYear)
              .month(newDateMonth)
              .date(newDateDay)
              .hour(endHour)
              .minute(endMinute),
          ),
        };
        transformedData.push(newEventObj);

        if (dateDifference === 0) {
          break;
        }

        newDate = moment(newDate).add(1, 'd');
        const parsedNextDay = parseDate(newDate);
        dateDifference = Math.abs(
          endDay - parsedNextDay.day + (endMonth - parsedNextDay.month) + (endYear - parsedNextDay.year),
        );
      }
    });
  }

  return transformedData;
};

export default transformToSchedulerDataDaily;

import { handleActions, createActions } from 'redux-actions';

import initialState, * as handlers from './handlers';

export const actions = createActions({
  SET_TABLE_DATA: undefined,
  SET_TABLE_PAGE: undefined,
  SET_TABLE_SORTING: undefined,
  SET_TABLE_FILTERING: undefined,
  SET_TABLE_DELETING: undefined,
  SET_FETCH_METHOD: undefined,
  CLEAN_UP_TABLE: undefined,
  FORCE_UPDATE_TABLE: undefined,
  REMOVE_TABLE_FILTER: undefined,
});

const reducer = handleActions(
  new Map([
    [actions.setTableData, handlers.setTableData],
    [actions.setTablePage, handlers.setTablePage],
    [actions.setTableSorting, handlers.setTableSorting],
    [actions.setTableFiltering, handlers.setTableFiltering],
    [actions.setTableDeleting, handlers.setTableDeleting],
    [actions.cleanUpTable, handlers.cleanUpTable],
    [actions.forceUpdateTable, handlers.forceUpdateTable],
    [actions.setFetchMethod, handlers.setFetchMethod],
    [actions.removeTableFilter, handlers.removeTableFilter],
  ]),
  initialState,
);

export default reducer;

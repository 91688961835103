import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Select, MenuItem } from '@material-ui/core';
import classNames from 'classnames';

const useStyles = makeStyles(theme => ({
  icon: {
    color: theme.palette.primary.contrastText,
    right: 10,
  },
  selectRoot: {
    '& .MuiSelect-selectMenu.MuiSelect-selectMenu': {
      paddingRight: '45px',
    },
  },
  root: {
    position: 'relative',
    backgroundColor: theme.palette.primary.light,
    fontSize: 14,
    padding: '8px 45px 8px 25px',
    borderRadius: '26px',
    minWidth: '40px',
    color: theme.palette.primary.contrastText,
    textAlign: 'center',

    '&:focus': {
      backgroundColor: theme.palette.primary.light,
      borderRadius: '26px',
    },
  },
}));

const PageSelect = ({ options, onChangeValue, placeholder, selectedOption, classes: customClasses }) => {
  const classes = useStyles();
  const [value, setValue] = useState(placeholder ? '' : selectedOption || options[0].value);

  const selectClasses = {
    root: classNames({
      [classes.root]: true,
      [customClasses.root]: !!customClasses.root,
    }),
    icon: classNames({
      [classes.icon]: true,
      [customClasses.icon]: !!customClasses.icon,
    }),
    selectRoot: classNames({
      [classes.selectRoot]: true,
      [customClasses.selectRoot]: !!customClasses.selectRoot,
    }),
  };

  const handleChange = event => {
    setValue(event.target.value);
    onChangeValue(event.target.value);
  };

  useEffect(() => {
    if (selectedOption) setValue(selectedOption);
  }, [selectedOption]);

  return (
    <Select
      className={selectClasses.selectRoot}
      value={value}
      onChange={handleChange}
      inputProps={{
        classes: {
          root: selectClasses.root,
        },
      }}
      displayEmpty
      disableUnderline
      classes={{
        icon: selectClasses.icon,
      }}
    >
      {placeholder && (
        <MenuItem key={placeholder} value="">
          {placeholder}
        </MenuItem>
      )}

      {options.map(({ value: optionValue, label }) => (
        <MenuItem key={optionValue + label} value={optionValue}>
          {label}
        </MenuItem>
      ))}
    </Select>
  );
};

PageSelect.defaultProps = {
  onChangeValue: () => {},
  placeholder: null,
  selectedOption: null,
  classes: {},
};

PageSelect.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      label: PropTypes.string,
    }),
  ).isRequired,
  onChangeValue: PropTypes.func,
  placeholder: PropTypes.string,
  selectedOption: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  classes: PropTypes.shape({
    root: PropTypes.string,
    icon: PropTypes.string,
    selectRoot: PropTypes.string,
  }),
};

export default PageSelect;

import React from 'react';
import PropTypes from 'prop-types';
import AppBreadcrumbs from 'components/AppBreadcrumbs';
import PageLayout from 'layouts/PageLayout';
import { useAsyncFn } from 'react-use';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector, useDispatch } from 'react-redux';
import { setAdmin } from 'http/users';
import { actions as modalActions } from 'redux/modal';
import { useHistory } from 'react-router-dom';
import { routesAliases } from 'router/routes';
import { useTranslation } from 'react-i18next';
import AdministratorsForm from '../Form';

const useStyles = makeStyles(theme => ({
  pageContent: {
    padding: theme.spacing(4, 5),
  },
}));

const Create = ({ routeProps }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const breadCrumbsItems = [
    {
      title: t(routeProps.parentTitle),
      link: routeProps.parentPath,
    },
    {
      title: t(routeProps.title),
      isActive: true,
    },
  ];

  const dispatch = useDispatch();
  const history = useHistory();
  const { stations } = useSelector(state => state.stations);

  const initialFormState = {
    stations,
  };

  const [state, addAdministrator] = useAsyncFn(async (formData, stationId) => {
    const data = await setAdmin(formData, stationId);
    if (!data.error) {
      dispatch(modalActions.openModal({ size: 'small' }, <p>{t('administrators.modalCreatedTitle')}</p>));
      history.push(routesAliases.administratorsList);
    }
    return data;
  }, []);

  return (
    <PageLayout pageTitle={<AppBreadcrumbs items={breadCrumbsItems} />} classes={{ content: classes.pageContent }}>
      <AdministratorsForm
        isLoading={state.loading}
        onSubmit={addAdministrator}
        administratorsData={initialFormState}
        routesAliases={routesAliases}
        type="create"
      />
    </PageLayout>
  );
};

Create.propTypes = {
  routeProps: PropTypes.shape({
    parentPath: PropTypes.string,
    parentTitle: PropTypes.string,
    title: PropTypes.string,
  }).isRequired,
};

export default Create;

const getDeeperValue = (field, data, extData = {}) => {
  const { Component, dependentField } = extData;
  const fieldParts = field.split('.');

  if (fieldParts.length === 1) {
    if (data && !!field) {
      return Component ? Component(data[field], data[dependentField]) : data[field];
    }
    return undefined;
  }

  const currentField = fieldParts.shift();

  return getDeeperValue(fieldParts.join('.'), data[currentField], extData);
};

const transformTableData = (columnsConfig, tableData) => {
  const transformedData = [];
  const columns = [];

  columnsConfig.forEach(config => {
    const {
      field,
      Component,
      dependentField,
      title,
      isSorting,
      alignment,
      isVisible = true,
      hiddenHeader = false,
      columnWidth,
    } = config;

    columns.push({
      field,
      title,
      isSorting,
      alignment,
      isVisible,
      hiddenHeader,
      columnWidth,
    });

    if (tableData && tableData.length) {
      tableData.forEach((data, index) => {
        const fieldValue = getDeeperValue(field, data, { Component, dependentField });
        transformedData[index] = {
          ...transformedData[index],
          [field]: {
            value: fieldValue,
            isVisible,
          },
        };
      });
    }
  });

  return [columns, transformedData];
};

export default transformTableData;

import React, { useState, useEffect } from 'react';
import AppTable from 'components/AppTable';
import PropTypes from 'prop-types';
import Loader from 'components/Loader';
import { transformTableData } from 'utils';
import { useAsyncFn } from 'react-use';
import { useDispatch, useSelector } from 'react-redux';
import { actions } from 'redux/table';
import { getDocumentsConfig } from './helpers/getTableConfig';

const DocumentsTable = ({ courseId, helpers, courseType, stationId, courseTemplateId }) => {
  const dispatch = useDispatch();
  const { needForceUpdate, tableData } = useSelector(state => state.table);

  const tableConfig = getDocumentsConfig();
  const [columns, documents] = transformTableData(tableConfig, tableData);
  const [files, setFiles] = useState([]);

  const [documentsFetching, fetchDocuments] = useAsyncFn(async () => {
    let courseFiles;
    const dataCourse = await helpers.getDocuments();
    if (!dataCourse.error) {
      courseFiles = dataCourse.items.map(item => ({
        ...item,
        userUploaded: `${item.uploadedBy && item.uploadedBy.info.firstName} ${item.uploadedBy &&
          item.uploadedBy.info.lastName}`,
        visibility: {
          isVisibleForStudent: item.isVisibleForStudent,
          isVisibleForTrainer: item.isVisibleForTrainer,
          id: item.id,
          type: 'course',
        },
        type: 'course',
      }));
    }

    const dataTemplate = await helpers.getCourseTemplatesDocuments(courseTemplateId);
    let templateFiles;
    if (!dataTemplate.error) {
      templateFiles = dataTemplate.items.map(item => ({
        ...item,
        userUploaded: `${item.uploadedByUser && item.uploadedByUser.info.firstName} ${item.uploadedByUser &&
          item.uploadedByUser.info.lastName}`,
        visibility: {
          isVisibleForStudent: item.isVisibleForStudent,
          isVisibleForTrainer: item.isVisibleForTrainer,
          id: item.id,
          type: 'template',
        },
        type: 'template',
      }));
    }

    const combineFiles = [...courseFiles, ...templateFiles];

    setFiles(combineFiles);

    dispatch(
      actions.setTableData({
        data: combineFiles,
        count: combineFiles.length,
      }),
    );

    return combineFiles;
  });

  const [documentDownloading, downloadDocument] = useAsyncFn(async (documentId, type) => {
    if (type === 'course') {
      const downloadParams = courseType === 'local' ? [stationId, courseId, documentId] : [courseId, documentId];
      await helpers.downloadDocument(...downloadParams);
    } else {
      await helpers.downloadCourseTemplateDocument(courseTemplateId, documentId);
    }
  });

  const handleDownloadDocument = event => {
    const { key, type } = event.currentTarget.dataset;
    downloadDocument(key, type);
  };

  useEffect(() => {
    fetchDocuments();
    return () => {
      dispatch(actions.cleanUpTable());
    };
  }, [courseId]);

  useEffect(() => {
    if (needForceUpdate === true) {
      fetchDocuments();
      dispatch(actions.forceUpdateTable(false));
    }
  }, [needForceUpdate]);

  const tableActions = [
    {
      type: 'icon',
      icon: 'get_app',
      onClick: handleDownloadDocument,
    },
  ];

  const isLoading = documentsFetching.loading || documentDownloading.loading;

  return (
    <>
      <AppTable
        columns={columns}
        data={documents}
        totalCount={files.length}
        rowsPerPage={5}
        onChangePage={tableData.handleChangePage}
        actions={tableActions}
        extraActionsKeys={['type']}
      />
      {isLoading && <Loader />}
    </>
  );
};

DocumentsTable.propTypes = {
  courseId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  courseType: PropTypes.string.isRequired,
  stationId: PropTypes.string.isRequired,
  helpers: PropTypes.shape({
    getDocuments: PropTypes.func,
    downloadDocument: PropTypes.func,
    getCourseTemplatesDocuments: PropTypes.func,
    downloadCourseTemplateDocument: PropTypes.func,
  }).isRequired,
  courseTemplateId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
};

export default DocumentsTable;

import React from 'react';
import PropTypes from 'prop-types';
import { Typography, Icon, ButtonBase } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  button: {
    display: 'inline-block',
    cursor: 'pointer',
    border: 'none',
    padding: 0,
    color: theme.palette.text.grey,
    background: 'none',
    whiteSpace: 'nowrap',
  },
  text: {
    color: 'currentColor',
    verticalAlign: 'middle',
    fontSize: '14px',
    lineHeight: '16px',
    textTransform: 'lowercase',
  },
  icon: {
    color: 'currentColor',
    fontSize: '17px',
    marginRight: '1vw',
    verticalAlign: 'middle',
  },
  'color-active': {
    color: theme.palette.primary.light,
  },
}));

const StateToggler = ({ value, activeText, inactiveText, onChange, ...otherProps }) => {
  const classes = useStyles();

  const button = classNames({
    [classes.button]: true,
    [classes['color-active']]: value,
  });

  return (
    <div className={classes.root}>
      <ButtonBase
        className={button}
        type="button"
        data-key={otherProps.id}
        data-val={value}
        aria-label="state"
        onClick={onChange}
      >
        <Icon className={classes.icon}>lens</Icon>
        <Typography className={classes.text} component="span" variant="body1">
          {value ? activeText : inactiveText}
        </Typography>
      </ButtonBase>
    </div>
  );
};

StateToggler.defaultProps = {
  onChange: () => {},
  value: undefined,
};

StateToggler.propTypes = {
  value: PropTypes.bool,
  activeText: PropTypes.string.isRequired,
  inactiveText: PropTypes.string.isRequired,
  onChange: PropTypes.func,
};

export default StateToggler;

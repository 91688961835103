import { http } from '../index';
import { formDataModifier } from '../modifiers';
import { downloadFileFromBytes, getFileNameFromContentDispositionString } from '../helpers';

export const getCourseTemplates = params => {
  return http
    .get('/course-templates', {
      params,
    })
    .then(res => res.data)
    .catch(error => error.response.data);
};

export const getCourseTemplate = id => {
  return http
    .get(`/course-template/${id}`)
    .then(res => res.data)
    .catch(error => error.response.data);
};

export const createCourseTemplate = formData => {
  return http
    .post('/course-templates', formData)
    .then(res => res.data)
    .catch(error => error.response.data);
};

export const updateCourseTemplate = (id, formData) => {
  return http
    .put(`/course-template/${id}`, formData)
    .then(res => res.data)
    .catch(error => error.response.data);
};

export const deleteCourseTemplate = id => {
  return http
    .delete(`/course-template/${id}`)
    .then(res => res.data)
    .catch(error => error.response.data);
};

export const getCourseTemplatesDocuments = (id, params) => {
  return http
    .get(`/course-template/${id}/files`, { params })
    .then(res => res.data)
    .catch(error => error.response.data);
};

export const addCourseTemplateDocument = (id, formData) => {
  return http
    .post(`/course-template/${id}/files`, formDataModifier(formData), {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    .then(res => res.data)
    .catch(error => error.response.data);
};

export const deleteCourseTemplateDocument = (id, fileId) => {
  return http
    .delete(`/course-template/${id}/file/${fileId}`)
    .then(res => res.data)
    .catch(error => error.response.data);
};

export const downloadCourseTemplateDocument = (id, fileId) => {
  return http
    .get(`/course-template/${id}/file/${fileId}`, {
      responseType: 'blob',
    })
    .then(res => {
      const filename = getFileNameFromContentDispositionString(res);
      downloadFileFromBytes(res.data, filename, '', true);
    })
    .catch(error => error.response.data);
};

export const importTemplates = formData => {
  return http
    .post(`/course-templates/import`, formDataModifier(formData), {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    .then(res => res.data)
    .catch(error => error.response.data);
};

export const exportTemplates = () => {
  return http
    .get(`/course-templates/export`, {
      responseType: 'blob',
    })
    .then(res => {
      const filename = getFileNameFromContentDispositionString(res);
      downloadFileFromBytes(res.data, filename, '', true);
    })
    .catch(error => error.response.data);
};

export const downloadImportExample = () => {
  return http
    .get(`/course-templates/import/example`, {
      responseType: 'blob',
    })
    .then(res => {
      const filename = getFileNameFromContentDispositionString(res);
      downloadFileFromBytes(res.data, filename, '', true);
    })
    .catch(error => error.response.data);
};

export const setDocumentVisibility = (courseTemplateId, fileId, data) => {
  return http
    .post(`/course-template/${courseTemplateId}/file/${fileId}/visibility`, data)
    .then(res => res.data)
    .catch(error => error.response.data);
};

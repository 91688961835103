import { http } from '../index';

export const login = credentials => {
  return http
    .post('/auth/login', credentials)
    .then(res => {
      let loginResponse = res.data;
      if (res.response && res.response.data) {
        loginResponse = res.response.data;
      }
      return loginResponse;
    })
    .catch(error => error.response.data);
};

export const logout = () => {
  return http
    .get('/auth/logout')
    .then(res => res.data)
    .catch(error => error.response.data);
};

export const profile = () => {
  return http
    .get('/auth/me')
    .then(res => res.data)
    .catch(error => error.response.data);
};

export const forgotPassword = data => {
  return http
    .post('/auth/forgot-password', data)
    .then(res => res.data)
    .catch(error => error.response.data);
};

export const resetPassword = data => {
  return http
    .post('/auth/reset-password', data)
    .then(res => res.data)
    .catch(error => error.response.data);
};

import React from 'react';
import PropTypes from 'prop-types';
import AppTable from 'components/AppTable';
import withTableProps from 'HOC/types/withTable';
import { transformTableData } from 'utils';
import { useDispatch } from 'react-redux';
import { actions } from 'redux/table';
import { getCoursesScheduledConfig } from './helpers/getCoursesScheduledTableConfig';

const CoursesScheduledTable = ({ tableData, helpers }) => {
  const tableConfig = getCoursesScheduledConfig();
  const [columns, students] = transformTableData(tableConfig, tableData.data);
  const dispatch = useDispatch();

  const tableActions = [
    {
      type: 'icon',
      icon: 'edit',
      onClick: helpers.editCourse,
    },
    {
      type: 'icon',
      icon: 'delete',
      color: 'error',
      onClick: event => {
        helpers.deleteCourse(event);
        dispatch(actions.setTableDeleting(true));
        tableData.handleForceUpdate();
      },
    },
  ];

  return (
    <AppTable
      columns={columns}
      data={students}
      totalCount={tableData.totalCount}
      rowsPerPage={tableData.pageSize}
      isLazyLoading
      onChangePage={tableData.handleChangePage}
      onColumnSorting={tableData.handleSorting}
      actions={tableActions}
      extraActionsKeys={['stationId']}
    />
  );
};

CoursesScheduledTable.propTypes = {
  tableData: PropTypes.shape(withTableProps).isRequired,
  helpers: PropTypes.shape({
    getCourses: PropTypes.func,
    deleteCourse: PropTypes.func,
    editCourse: PropTypes.func,
  }).isRequired,
};

export default CoursesScheduledTable;

import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { routesMapToRoles } from 'router/routes';
import { BASE_USER_URL } from 'appConstants';

const PrivateRoute = ({ component: Component, redirectTo, ...rest }) => {
  const { isAuthenticated, profile } = useSelector(state => state.auth);
  const location = useLocation();

  if (isAuthenticated) {
    const { role } = profile;
    const path = routesMapToRoles[role][0].to;
    const currLocation = location.pathname;
    return (
      <>
        <Route {...rest} component={Component} />
        <Redirect to={currLocation === BASE_USER_URL ? path : currLocation} />
      </>
    );
  }

  return <Redirect to={redirectTo} />;
};

PrivateRoute.propTypes = {
  component: PropTypes.elementType.isRequired,
  redirectTo: PropTypes.string,
};

PrivateRoute.defaultProps = {
  redirectTo: '/auth',
};

export default PrivateRoute;

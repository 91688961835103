/* eslint-disable react/jsx-wrap-multilines */
import React from 'react';
import PropTypes from 'prop-types';
import { Checkbox, FormGroup, FormControl, FormControlLabel, FormHelperText, InputLabel } from '@material-ui/core/';
import useStyles from './styles';

const CustomCheckbox = ({
  input: { name, ...restInput },
  fullWidth,
  customStyles,
  onChange,
  id,
  label,
  meta,
  ...rest
}) => {
  const classes = useStyles();
  const isError = meta.touched && !meta.valid;

  return (
    <FormControl className={customStyles} component="fieldset" {...restInput} fullWidth={fullWidth} error={isError}>
      <InputLabel className={classes.label} shrink htmlFor={id}>
        {label}
      </InputLabel>
      <FormGroup className={classes.group} aria-label={name} name={name} onChange={e => onChange(e)}>
        {rest.items.map(item => (
          <FormControlLabel
            key={item.id}
            value={item.id}
            checked={item.deletedAt === null}
            control={
              <Checkbox
                className={classes.checkbox}
                checked={item.deletedAt === null}
                onChange={e => onChange(e)}
                value={item.id}
                {...rest}
              />
            }
            label={item.name}
          />
        ))}
      </FormGroup>
      {isError && (
        <FormHelperText id="outlined-weight-helper-text" error>
          {meta.error}
        </FormHelperText>
      )}
    </FormControl>
  );
};

CustomCheckbox.defaultProps = {
  fullWidth: false,
  customStyles: undefined,
};

CustomCheckbox.propTypes = {
  input: PropTypes.shape({
    name: PropTypes.string,
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  meta: PropTypes.shape().isRequired,
  id: PropTypes.string.isRequired,
  customStyles: PropTypes.string,
  fullWidth: PropTypes.bool,
};

export default CustomCheckbox;

import React from 'react';
import PropTypes from 'prop-types';
import { IconButton, Icon } from '@material-ui/core';

const Rating = ({ onChange, val, id }) => {
  const disableStatuses = ['In Progress', 'Scheduled', 'Completed'];
  return (
    <IconButton
      disabled={disableStatuses.includes(val.status)}
      size="small"
      onClick={() => onChange(id, val.rating)}
    >
      {val.rating && val.rating.courseQuality === null ? (
        <Icon>star_border</Icon>
      ) : (
        <Icon>star</Icon>
      )}
    </IconButton>
  );
};

Rating.defaultProps = {
  onChange: () => {},
};

Rating.propTypes = {
  onChange: PropTypes.func,
  val: PropTypes.shape({
    status: PropTypes.string.isRequired,
    rating: PropTypes.shape({
      courseQuality: PropTypes.oneOfType([PropTypes.string, null]),
    }).isRequired,
  }).isRequired,
  id: PropTypes.number.isRequired,
};

export default Rating;

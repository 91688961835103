import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import AppBreadcrumbs from 'components/AppBreadcrumbs';
import PageLayout from 'layouts/PageLayout';
import NotFound from 'pages/NotFound';
import { useAsyncFn } from 'react-use';
import { makeStyles } from '@material-ui/core/styles';
import { getCompany, updateCompany } from 'http/companies';
import { useRouteMatch, useHistory } from 'react-router-dom';
import { routesAliases } from 'router/routes';
import { useTranslation } from 'react-i18next';
import CompanyForm from '../Form';

const useStyles = makeStyles(theme => ({
  pageContent: {
    padding: theme.spacing(5, 5),
  },
}));

const Edit = ({ routeProps }) => {
  const classes = useStyles();
  const history = useHistory();
  const routerMatch = useRouteMatch();
  const { t } = useTranslation();
  const { id } = routerMatch.params;
  const [notFoundError, setNotFoundError] = useState(false);
  const [initialFormState, setInitialFormState] = useState(false);

  const breadCrumbsItems = [
    {
      title: t(routeProps.parentTitle),
      link: routeProps.parentPath,
    },
    {
      title: t(routeProps.title),
      isActive: true,
    },
  ];

  const [fetchUserState, fetchUserData] = useAsyncFn(async () => {
    const companyData = await getCompany(id);
    if (!companyData.error) {
      setInitialFormState({
        ...companyData,
        SAfirstName: companyData.superAdministrator ? companyData.superAdministrator.firstName : '',
        SAlastName: companyData.superAdministrator ? companyData.superAdministrator.lastName : '',
        SAemail: companyData.superAdministrator ? companyData.superAdministrator.email : '',
      });
    } else if (companyData.statusCode === 404) {
      setNotFoundError(true);
      return false;
    }
    return companyData;
  });

  const [submittingState, editCompany] = useAsyncFn(async formData => {
    const data = await updateCompany(formData, id);
    if (!data.error) {
      history.push(routesAliases.companiesList);
    }
    return data;
  }, []);

  useEffect(() => {
    fetchUserData(id);
  }, []);

  return notFoundError ? (
    <NotFound />
  ) : (
    <PageLayout
      pageTitle={<AppBreadcrumbs items={breadCrumbsItems} />}
      classes={{ content: classes.pageContent }}
      showLoader={fetchUserState.loading}
    >
      <CompanyForm
        isLoading={submittingState.loading}
        onSubmit={editCompany}
        companyData={initialFormState}
        routesAliases={routesAliases}
      />
    </PageLayout>
  );
};

Edit.propTypes = {
  routeProps: PropTypes.shape({
    parentPath: PropTypes.string,
    parentTitle: PropTypes.string,
    title: PropTypes.string,
  }).isRequired,
};

export default Edit;

import { handleActions, createActions } from 'redux-actions';

import initialState, * as handlers from './handlers';

export const actions = createActions({
  SET_NEW_NOTIFICATIONS: undefined,
  RESET_NOTIFICATIONS_COUNT: undefined,
});

const reducer = handleActions(
  new Map([
    [actions.setNewNotifications, handlers.setNewNotifications],
    [actions.resetNotificationsCount, handlers.resetNotificationsCount],
  ]),
  initialState,
);

export default reducer;

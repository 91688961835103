import React from 'react';
import { DoubleStateToggler } from 'components/AppTable/CellsComponents';
import i18next from 'i18next';

const getTableTasksConfig = helpers => [
  {
    title: i18next.t('tablesLabels.id'),
    field: 'id',
  },
  {
    title: i18next.t('tablesLabels.jobTask'),
    field: 'name',
  },
  {
    title: i18next.t('tablesLabels.state'),
    field: 'isPerformed',
    Component: state => <DoubleStateToggler value={state} onChange={helpers.handleToggleState} />,
  },
];

export default getTableTasksConfig;

const configErrors = {
  'coursesTemplates.alreadyExistsInCompany': {
    name: 'errors.courseTemplateExisting',
  },
  'courseTemplate.alreadyExistsInCompany': {
    name: 'errors.courseTemplateExisting',
  },
  'courseTemplate.withSameIdAlreadyExists': {
    courseSystemId: 'errors.courseSystemIdExisting',
  },
};

export default configErrors;

const configErrors = {
  'user.firstName': {
    firstName: 'errors.firstNameIsNotMatchPattern',
  },
  firstName: {
    firstName: 'errors.firstNameIsNotMatchPattern',
  },
  'user.lastName': {
    lastName: 'errors.lastNameIsNotMatchPattern',
  },
  lastName: {
    lastName: 'errors.lastNameIsNotMatchPattern',
  },
  'user.alreadyAppliedToStation': {
    email: 'errors.emailExisting',
  },
  'user.emailAlreadyInUse': {
    email: 'errors.emailExisting',
  },
  'user.courseTemplateIds': {
    courseTemplateIds: 'errors.courseTemplateIsRequired',
  },
};

export default configErrors;

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import AppBreadcrumbs from 'components/AppBreadcrumbs';
import PageLayout from 'layouts/PageLayout';
import NotFound from 'pages/NotFound';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { useAsyncFn } from 'react-use';
import { actions as modalActions } from 'redux/modal';
import {
  updateGlobalCourse,
  getGlobalCourse,
  getGlobalCourseDocuments,
  addGlobalCourseDocument,
  deleteGlobalCourseDocument,
  downloadGlobalCourseDocument,
  updateLocalCourse,
  getLocalCourse,
  getLocalCourseDocuments,
  addLocalCourseDocument,
  deleteLocalCourseDocument,
  downloadLocalCourseDocument,
  setGlobalDocumentVisibility,
  setLocalDocumentVisibility,
} from 'http/courses';
import {
  getCourseTemplatesDocuments,
  setDocumentVisibility,
  deleteCourseTemplateDocument,
  downloadCourseTemplateDocument,
} from 'http/courseTemplates';
import { getStudents, getUser } from 'http/users';
import { getCourseStudents, addStudentToCourse, removeStudentFromCourse } from 'http/courseStudent';
import { useRouteMatch, useHistory, useLocation } from 'react-router-dom';
import { routesAliases } from 'router/routes';
import { useTranslation } from 'react-i18next';
import { ROLES } from 'appConstants';
import CourseForm from '../Form';
import CourseDetails from './CourseDetails/index';

const useStyles = makeStyles(theme => ({
  courseDetailsContent: {
    padding: theme.spacing(2, 5),
  },
  pageContent: {
    padding: theme.spacing(4, 5),
  },
}));

let historyState;

const Edit = ({ routeProps }) => {
  const { t } = useTranslation('common');
  const { role } = useSelector(state => state.auth.profile);
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const accessRoles = [ROLES.SUPER, ROLES.ADMIN];
  const routerMatch = useRouteMatch();
  const { id: courseId, type: courseType, stationId } = routerMatch.params;

  let backUrl = routesAliases.coursesList;

  if (history.action === 'PUSH') {
    historyState = location.state;
  }

  if (historyState && historyState.backUrl) {
    backUrl = historyState.backUrl;
  }

  const courseTypes = {
    local: {
      getCourse: getLocalCourse.bind(null, stationId),
      updateCourse: updateLocalCourse.bind(null, stationId),
      getDocuments: getLocalCourseDocuments.bind(null, stationId, courseId),
      addDocument: addLocalCourseDocument.bind(null, stationId, courseId),
      deleteDocument: deleteLocalCourseDocument.bind(null, stationId, courseId),
      downloadDocument: downloadLocalCourseDocument,
      fetchCourseStudents: getCourseStudents.bind(null, courseId),
      addStudentToCourse: addStudentToCourse.bind(null, courseId),
      removeStudentFromCourse: removeStudentFromCourse.bind(null, courseId),
      fetchAllStudents: getStudents,
      setDocumentVisibility: setLocalDocumentVisibility,
    },
  };

  if (accessRoles.includes(role)) {
    courseTypes.global = {
      getCourse: getGlobalCourse.bind(null),
      updateCourse: updateGlobalCourse.bind(null),
      getDocuments: getGlobalCourseDocuments.bind(null, courseId),
      addDocument: addGlobalCourseDocument.bind(null, courseId),
      deleteDocument: deleteGlobalCourseDocument.bind(null, courseId),
      downloadDocument: downloadGlobalCourseDocument,
      fetchCourseStudents: getCourseStudents.bind(null, courseId),
      addStudentToCourse: addStudentToCourse.bind(null, courseId),
      removeStudentFromCourse: removeStudentFromCourse.bind(null, courseId),
      fetchAllStudents: getStudents,
      setDocumentVisibility: setGlobalDocumentVisibility,
    };
  }

  const [notFoundError, setNotFoundError] = useState(!courseTypes[courseType]);
  const [initialFormState, setInitialFormState] = useState(null);
  const [courseStudentsCount, setCourseStudentsCount] = useState(0);
  const [isExternal, setIsExternal] = useState(false);
  const [courseTemplateId, setCourseTemplateId] = useState('');
  const [isFrozen, setIsFrozen] = useState(false);

  const classes = useStyles();
  const breadCrumbsItems = [
    {
      title: t(routeProps.parentTitle),
      link: routeProps.parentPath,
    },
    {
      title: t(routeProps.title, { type: t(`courses.${courseType}`) }),
      isActive: true,
    },
  ];

  const [submittingState, sendForm] = useAsyncFn(async (formData, isRedirectToList = true) => {
    const course = await courseTypes[courseType].updateCourse(courseId, formData);
    if (!course.error) {
      if (isRedirectToList) {
        history.push(backUrl, { courseType });
      }
      return false;
    }

    const handleServerError = errorMessage => {
      dispatch(
        modalActions.openModal(
          {
            size: 'medium',
            title: t('errors.notification'),
          },
          <p>{errorMessage}</p>,
        ),
      );
    };

    if (course.statusCode === 400 && course.message === 'course.cantEditAlreadyEndedCourse') {
      handleServerError(t('errors.impossibleEditEndedCourse'));
    }
    if (course.statusCode === 404 && course.message === 'course.wasEnded') {
      handleServerError(t('errors.endedCourse'));
    }
    return course;
  });

  const [fetchCourseState, fetchCourseData] = useAsyncFn(async id => {
    const course = await courseTypes[courseType].getCourse(id);
    setIsExternal(course.stationId === null);

    if (!course.error) {
      const {
        courseSystemId,
        maxStudents,
        location: courseLocation,
        description,
        courseTemplate,
        jobsTasks: courseJobsTasks,
        startDate,
        startTime,
        endTime,
        endDate,
        expirationDate,
        trainers,
        courseTemplateId: courseTemplateIdForm,
      } = course;
      setCourseTemplateId(courseTemplateIdForm);
      setInitialFormState({
        courseSystemId,
        maxStudents,
        location: courseLocation,
        description,
        courseTemplate: {
          label: courseTemplate.name,
          value: courseTemplate.id,
        },
        jobsTasks: courseJobsTasks,
        startTime: `${startDate} ${startTime}`,
        endTime: `${endDate} ${endTime}`,
        startDate,
        endDate,
        expirationDate,
        trainers: trainers.map(trainer => ({
          value: trainer.id,
          label: [
            `${trainer.info.firstName} ${trainer.info.lastName}`,
            trainer.trainerStatus.isExternal ? '(Ext.)' : `(${trainer.stations[0].name})`,
          ].join(' '),
        })),
      });

      setCourseStudentsCount(course.students.length);
    } else if (course.statusCode === 404) {
      setNotFoundError(true);
    }
  });

  const handleChangeStudentsCount = action => {
    switch (action) {
      case 'add':
        setCourseStudentsCount(courseStudentsCount + 1);
        break;
      case 'subtract':
        setCourseStudentsCount(courseStudentsCount - 1);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (courseTypes[courseType]) {
      fetchCourseData(courseId);
    }
  }, []);

  return notFoundError ? (
    <NotFound />
  ) : (
    <PageLayout
      pageTitle={<AppBreadcrumbs items={breadCrumbsItems} />}
      classes={{ content: classes.pageContent }}
      additionSections={[
        {
          contentClass: classes.courseDetailsContent,
          contentHeader: t('courses.courseDetails'),
          component: (
            <CourseDetails
              courseId={courseId}
              stationId={stationId}
              courseTemplateId={courseTemplateId}
              handleChangeStudentsCount={handleChangeStudentsCount}
              helpers={{
                getDocuments: courseTypes[courseType].getDocuments,
                addDocument: courseTypes[courseType].addDocument,
                deleteDocument: courseTypes[courseType].deleteDocument,
                downloadDocument: courseTypes[courseType].downloadDocument,
                fetchCourseStudents: courseTypes[courseType].fetchCourseStudents,
                addStudentToCourse: courseTypes[courseType].addStudentToCourse,
                removeStudentFromCourse: courseTypes[courseType].removeStudentFromCourse,
                fetchAllStudents: courseTypes[courseType].fetchAllStudents,
                setDocumentVisibility: courseTypes[courseType].setDocumentVisibility,
                getEditorData: getUser,
                getCourseTemplatesDocuments,
                setDocumentTemplateVisibility: setDocumentVisibility,
                deleteCourseTemplateDocument,
                downloadCourseTemplateDocument,
              }}
              isExternal={isExternal}
              routesAliases={routesAliases}
              isFrozen={isFrozen}
            />
          ),
        },
      ]}
      showLoader={fetchCourseState.loading}
    >
      <CourseForm
        onSubmitForm={sendForm}
        submitting={submittingState.loading}
        formType="edit"
        courseId={courseId}
        courseType={courseType}
        backUrl={backUrl}
        initialValues={initialFormState}
        courseStudentsCount={courseStudentsCount}
        setIsFrozen={setIsFrozen}
      />
    </PageLayout>
  );
};

Edit.propTypes = {
  routeProps: PropTypes.shape({
    parentPath: PropTypes.string,
    parentTitle: PropTypes.string,
    title: PropTypes.string,
  }).isRequired,
};

export default Edit;

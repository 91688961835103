import React from 'react';
import { Button, Icon } from '@material-ui/core';

const computeActionSection = actions => {
  return actions.map((element, index) => {
    const { type, title, icon, onClick, Component, isHidden } = element;

    if (isHidden) {
      return null;
    }

    switch (type) {
      case 'component':
        return <React.Fragment key={index}>{Component || null}</React.Fragment>;
      case 'button':
        return (
          <Button
            key={element.title}
            onClick={onClick}
            variant="contained"
            color="primary"
            startIcon={<Icon>{icon}</Icon>}
          >
            {title}
          </Button>
        );
      default:
        return null;
    }
  });
};

export default computeActionSection;

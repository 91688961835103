import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Loader from 'components/Loader';
import CustomDatePicker from 'components/Forms/CustomDatePicker';
import CustomSelect from 'components/Forms/CustomSelect';
import { Form, Field, FormSpy } from 'react-final-form';
import { Grid, Box, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useAsyncFn } from 'react-use';
import { transformDataToSelectOptions } from 'utils';
import { useTranslation } from 'react-i18next';
import { getStudents } from 'http/users';
import { getStudentStatus } from 'http/courseStudent';
import validate from './validation';

const useStyles = makeStyles(theme => ({
  btnContainer: {
    marginTop: '40px',
  },
  btn: {
    minWidth: '140px',
  },
  helpRoot: {
    color: theme.palette.error.main,
  },
  helpList: {
    paddingLeft: 0,
    listStylePosition: 'inside',
  },
}));

const AddStudent = ({
  onSubmitForm,
  closeModal,
  submitting: formSubmitting,
  courseTemplateId,
  courseId,
  stationId,
  isExternal,
}) => {
  const classes = useStyles();
  const { t } = useTranslation('common');

  const [students, setStudents] = useState([]);
  const [studentStatus, setStudentStatus] = useState([]);
  const [expiryDateVisibility, setExpiryDateVisibility] = useState(false);

  const fetchStudentsInAsync = async () => {
    const { items: fetchedStudents } = await getStudents({
      'course-template-id': courseTemplateId,
      'not-in-course': courseId,
    });
    if (!fetchedStudents.length) {
      return false;
    }

    if (isExternal) {
      setStudents(transformDataToSelectOptions(fetchedStudents, 'id', 'fullName') || []);
      return true;
    }

    fetchedStudents.map(item => item.stationsIds.includes(stationId));
    setStudents(transformDataToSelectOptions(fetchedStudents, 'id', 'fullName') || []);
    return true;
  };

  const [fetchStudentsState, fetchStudents] = useAsyncFn(fetchStudentsInAsync);

  const handleSubmitForm = async values => {
    const transformedValues = {
      ...values,
      externalExpiredDate: values.externalExpiredDate
        ? moment(values.externalExpiredDate).format('DD.MM.YYYY')
        : undefined,
    };
    delete transformedValues.student;

    const submitData = await onSubmitForm(values.student.value, transformedValues);
    if (submitData && submitData.error && submitData.message) {
      return { student: t(submitData.message) };
    }

    return {};
  };

  const [fetchingStudentStatus, fetchStudentStatus] = useAsyncFn(async userId => {
    const fetchedStudentStatus = await getStudentStatus(courseId, userId);
    if (!fetchedStudentStatus.error) {
      setExpiryDateVisibility(fetchedStudentStatus.isUserNeedExparationDate || false);
      setStudentStatus(fetchedStudentStatus.existedCourses);
    }
  });

  const handleGetStudentStatus = userId => {
    fetchStudentStatus(userId);
  };

  useEffect(() => {
    fetchStudents();
  }, []);

  const isLoading = formSubmitting || fetchStudentsState.loading || fetchingStudentStatus.loading;

  return (
    <Form
      onSubmit={handleSubmitForm}
      validate={validate}
      render={({ handleSubmit, submitting }) => {
        return (
          <form onSubmit={handleSubmit}>
            <FormSpy
              onChange={({ modified, values: formValues, active: activeStudentSelection }) => {
                if (!modified.student || !activeStudentSelection) {
                  return false;
                }
                if (formValues.student) {
                  handleGetStudentStatus(formValues.student.value);
                  return false;
                }

                setExpiryDateVisibility(false);
                setStudentStatus([]);
                return true;
              }}
            />
            <Grid container>
              <Grid item container>
                <Grid item xs={12}>
                  <Box mb={4}>
                    <Field
                      type="text"
                      id="student"
                      name="student"
                      label={t('formLabels.student')}
                      variant="outlined"
                      placeholder={t('placeholders.chooseStudent')}
                      options={students}
                      fullWidth
                      maxMenuHeight={200}
                      component={CustomSelect}
                    />
                  </Box>
                </Grid>
              </Grid>
              {studentStatus.length > 0 && (
                <div className={classes.helpRoot}>
                  <div className={classes.helpTitle}>{t('students.studentAssignedMessage')}</div>
                  <ul className={classes.helpList}>
                    {studentStatus.map(item => (
                      <li
                        key={item.id}
                        className={classes.helpItem}
                      >{`${item.startDate} ${item.startTime} - ${item.endDate} ${item.endTime} :  ${item.name}`}</li>
                    ))}
                  </ul>
                </div>
              )}
              {expiryDateVisibility && (
                <Grid item container>
                  <Grid item xs={12}>
                    <Box>
                      <Field
                        type="text"
                        id="externalExpiredDate"
                        name="externalExpiredDate"
                        label={t('formLabels.expirationDate')}
                        disabled={submitting}
                        variant="outlined"
                        fullWidth
                        component={CustomDatePicker}
                      />
                    </Box>
                  </Grid>
                </Grid>
              )}
            </Grid>
            <Grid container spacing={3} className={classes.btnContainer}>
              <Grid item xs={6}>
                <Box textAlign="right">
                  <Button
                    className={classes.btn}
                    type="submit"
                    color="primary"
                    variant="contained"
                    disabled={submitting}
                    size="large"
                  >
                    {t('buttons.save')}
                  </Button>
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box textAlign="left">
                  <Button
                    className={classes.btn}
                    variant="outlined"
                    size="large"
                    type="reset"
                    disabled={submitting}
                    onClick={closeModal}
                  >
                    {t('buttons.cancel')}
                  </Button>
                </Box>
              </Grid>
            </Grid>

            {isLoading && <Loader />}
          </form>
        );
      }}
    />
  );
};

AddStudent.propTypes = {
  onSubmitForm: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  courseTemplateId: PropTypes.number.isRequired,
  courseId: PropTypes.string.isRequired,
  stationId: PropTypes.string.isRequired,
  isExternal: PropTypes.bool.isRequired,
};

export default AddStudent;

import { http } from '../index';
import { addFullNameField } from '../formatters';

export const getCourseStudents = (courseId, params) => {
  return http
    .get(`/course/${courseId}/students`, {
      params,
    })
    .then(res => addFullNameField(res.data, 'info'))
    .catch(error => error.response.data);
};

export const getUserCourse = (courseId, userId) => {
  return http
    .get(`/course/${courseId}/student/${userId}`)
    .then(res => res.data)
    .catch(error => error.response.data);
};

export const addStudentToCourse = (courseId, userId, formData) => {
  return http
    .post(`/course/${courseId}/student/${userId}`, formData)
    .then(res => res.data)
    .catch(error => error.response.data);
};

export const removeStudentFromCourse = (courseId, userId) => {
  return http
    .delete(`/course/${courseId}/student/${userId}`)
    .then(res => res.data)
    .catch(error => error.response.data);
};

export const setCourseToPerformed = (courseId, userId) => {
  return http
    .get(`/course/${courseId}/student/${userId}/performed`)
    .then(res => res.data)
    .catch(error => error.response.data);
};

export const setCourseToFailed = (courseId, userId) => {
  return http
    .get(`/course/${courseId}/student/${userId}/failed`)
    .then(res => res.data)
    .catch(error => error.response.data);
};

export const removeStudentFromHistoryCourse = (userId, historyId) => {
  return http
    .delete(`/student/${userId}/courses/history/${historyId}`)
    .then(res => res.data)
    .catch(error => error.response.data);
};

export const getStudentStatus = (courseId, userId) => {
  return http
    .get(`/course/${courseId}/student/${userId}/status`)
    .then(res => res.data)
    .catch(error => error.response.data);
};

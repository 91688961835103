import React from 'react';
import PropTypes from 'prop-types';
import AppBreadcrumbs from 'components/AppBreadcrumbs';
import PageLayout from 'layouts/PageLayout';
import { useAsyncFn } from 'react-use';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch } from 'react-redux';
import { createCompany } from 'http/companies';
import { actions as modalActions } from 'redux/modal';
import { useHistory } from 'react-router-dom';
import { routesAliases } from 'router/routes';
import { useTranslation } from 'react-i18next';
import CompaniesForm from '../Form';

const useStyles = makeStyles(theme => ({
  pageContent: {
    padding: theme.spacing(5, 5),
  },
}));

const Create = ({ routeProps }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const breadCrumbsItems = [
    {
      title: t(routeProps.parentTitle),
      link: routeProps.parentPath,
    },
    {
      title: t(routeProps.title),
      isActive: true,
    },
  ];

  const [state, addCompany] = useAsyncFn(async values => {
    const data = await createCompany(values);
    if (!data.error) {
      dispatch(modalActions.openModal({ size: 'small' }, <p>{t('company.companyCreated')}</p>));
      history.push(routesAliases.companiesList);
    }
    if (Array.isArray(data.message)) {
      return { ...data, message: data.message[0] };
    }
    return data;
  }, []);

  return (
    <PageLayout pageTitle={<AppBreadcrumbs items={breadCrumbsItems} />} classes={{ content: classes.pageContent }}>
      <CompaniesForm isLoading={state.loading} onSubmit={addCompany} routesAliases={routesAliases} />
    </PageLayout>
  );
};

Create.propTypes = {
  routeProps: PropTypes.shape({
    parentPath: PropTypes.string,
    parentTitle: PropTypes.string,
    title: PropTypes.string,
  }).isRequired,
};

export default Create;

import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { getCompanies } from 'http/companies';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import { routesAliases } from 'router/routes';
import PageLayout from 'layouts/PageLayout';
import withTable from 'HOC/withTable';
import withTableProps from 'HOC/types/withTable';
import SearchFilter from 'components/SearchFilter';
import CompaniesTable from './CompaniesTable';

const useStyles = makeStyles(theme => ({
  content: {
    padding: theme.spacing(4, 2),
  },
}));

const Companies = ({ tableData }) => {
  const { t } = useTranslation('common');
  const classes = useStyles();
  const history = useHistory();
  const { isFetchingTableData } = tableData;

  const handleTableSearch = (value, searchAlias) => {
    tableData.handleFiltering(searchAlias, value);
  };

  const handleTableSearchClearing = searchAlias => {
    tableData.handleRemoveFilter(searchAlias);
  };

  return (
    <PageLayout
      pageTitle={t('company.pageTitle')}
      actionSectionLeft={[
        {
          type: 'component',
          Component: (
            <SearchFilter searchAlias="search" onSearch={handleTableSearch} onClearSearch={handleTableSearchClearing} />
          ),
        },
      ]}
      actionSectionRight={[
        {
          type: 'button',
          icon: 'add',
          title: t('company.actionAdd'),
          onClick: () => history.push(routesAliases.createCompany),
        },
      ]}
      classes={{
        content: classes.content,
      }}
      showLoader={isFetchingTableData}
    >
      <CompaniesTable tableData={tableData} routesAliases={routesAliases} />
    </PageLayout>
  );
};

Companies.propTypes = {
  tableData: PropTypes.shape(withTableProps).isRequired,
};

export default withTable(
  {
    defaultSorting: {
      field: 'id',
    },
    sortingFieldsMapping: {
      'station.name': 'station',
    },
    fetchingData: 'items',
  },
  getCompanies,
)(Companies);

import { http } from '../index';

export const setJobTaskPerformed = (studentId, taskId) => {
  return http
    .get(`/job-task/${taskId}/perform/${studentId}`)
    .then(res => res.data)
    .catch(error => error.response.data);
};

export const setJobTaskFailed = (studentId, taskId) => {
  return http
    .get(`/job-task/${taskId}/failed/${studentId}`)
    .then(res => res.data)
    .catch(error => error.response.data);
};

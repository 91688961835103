import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useAsyncFn } from 'react-use';
import { setLang } from 'http/users';
import { PageSelect } from 'components/Selects';
import { useDispatch, useSelector } from 'react-redux';
import { actions as authActions } from 'redux/auth';

const ChangeLang = () => {
  const { i18n } = useTranslation();
  const dispatch = useDispatch();
  const { lang, profile } = useSelector(state => state.auth);
  const resultLang = (profile && profile.lang) || lang;

  const [chosenLang, setChosenLang] = useState(resultLang);

  // eslint-disable-next-line no-unused-vars
  const [fetchingLang, fetchLang] = useAsyncFn(async value => {
    const data = await setLang({ lang: value });
    if (data.error) return data;
    dispatch(authActions.setProfileLang(value));
    setChosenLang(value);
    return data;
  });

  const handleChangeLang = value => {
    dispatch(authActions.setLang(value));
    if (profile) {
      fetchLang(value);
    }
  };

  const changeLanguage = value => {
    if (i18n.language !== value) {
      i18n.changeLanguage(value);
      handleChangeLang(value);
    }
  };

  useEffect(() => {
    setChosenLang(resultLang);
    changeLanguage(resultLang);
  }, [profile]);

  return (
    <PageSelect
      options={[
        {
          label: 'ENG',
          value: 'en',
        },
        {
          label: 'ESP',
          value: 'es',
        },
        {
          label: 'FRA',
          value: 'fr',
        },
        {
          label: 'ITA',
          value: 'it',
        },
      ]}
      onChangeValue={changeLanguage}
      selectedOption={chosenLang}
    />
  );
};

export default ChangeLang;

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  label: {
    position: 'relative',
    zIndex: 2,
    fontSize: 12,
    transform: 'none',
    marginBottom: 0,
    lineHeight: '30px',
  },
  inputField: {
    '&:hover': {
      '& fieldset': {
        borderColor: `${theme.palette.primary.main} !important`,
      },
    },
    '& fieldset': {
      border: '1px solid #d3d3d3',
      fontSize: 16,
      lineHeight: 18,
      '&:focus': {
        borderColor: theme.palette.primary.main,
      },
    },
    '& .MuiInputBase-root': {
      padding: '0 0 0 4px',
    },
    '& .MuiButtonBase-root': {
      margin: '4px 4px 4px 0',
      backgroundColor: theme.palette.primary.main,
      color: '#fff',
    },
    '& .MuiInputBase-input': {
      padding: '4px 0 4px 10px',
    },
    '& .MuiSvgIcon-root': {
      fill: '#fff',
    },
  },
}));

export default useStyles;

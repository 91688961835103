/* eslint-disable consistent-return */
import React from 'react';
import { useAsyncFn } from 'react-use';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { updateSelf, setNewPassword } from 'http/users';
import { profile } from 'http/auth';
import { actions as authActions } from 'redux/auth';
import { actions as modalActions } from 'redux/modal';
import PageLayout from 'layouts/PageLayout';
import { ROLES } from '../../appConstants';
import ProfileForm from './Form';

const Profile = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation('common');

  const [state, changeData] = useAsyncFn(async values => {
    const { firstName, lastName, email, currentPassword, newPassword, id } = values;
    const isPasswordChanged = newPassword !== '' || newPassword !== '';
    const userData = { firstName, lastName, email };

    const updatedUserData = await updateSelf(userData, id);
    if (updatedUserData.error) {
      return updatedUserData;
    }

    if (isPasswordChanged) {
      const updatedPassword = await setNewPassword({
        oldPassword: currentPassword,
        newPassword,
      });
      if (updatedPassword.error) {
        return updatedPassword;
      }
    }

    const userUpdatedData = await profile();
    if (userUpdatedData.error) {
      return userUpdatedData.error;
    }

    dispatch(authActions.authenticate(userUpdatedData));

    if (userUpdatedData.role !== ROLES.HYPER) {
      const { stations } = userUpdatedData;
      if (stations.length) {
        dispatch(authActions.setStation({ name: stations[0].name, id: stations[0].id }));
      }
    }

    dispatch(modalActions.openModal({ size: 'small' }, <p>{t('profile.dataChangedSuccessfully')}</p>));
  }, []);

  return (
    <PageLayout pageTitle={t('profile.title')}>
      <ProfileForm isLoading={state.loading} onSubmit={changeData} />
    </PageLayout>
  );
};

export default Profile;

import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { TableBody, TableCell, TableRow, Button, IconButton, Icon } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  'button-error': {
    color: theme.palette.error.main,
  },
  tableCell: {
    padding: '10px 16px',
    color: theme.palette.text.common,
    maxWidth: '300px',
  },
}));

const AppTableBody = props => {
  const {
    actions,
    data,
    alignment,
    columnsNumber,
    emptyRows,
    actionsAlignment,
    columns,
    systemField,
    extraActionsKeys,
  } = props;
  const classes = useStyles();

  return (
    <TableBody>
      {data &&
        !!data.length &&
        data.map((row, rowIndex) => (
          <TableRow key={`row-${rowIndex}`}>
            {Object.keys(row).map((column, colIndex) => {
              return !row[column].isVisible ? null : (
                <TableCell
                  key={`col-${rowIndex}-${colIndex}`}
                  align={columns[colIndex].alignment || alignment}
                  classes={{
                    root: classes.tableCell,
                  }}
                >
                  {React.isValidElement(row[column].value)
                    ? React.cloneElement(row[column].value, {
                        id: row[systemField] && row[systemField].value,
                      })
                    : row[column].value}
                </TableCell>
              );
            })}
            {actions && !!actions.length && (
              <TableCell
                align={actionsAlignment}
                classes={{
                  root: classes.tableCell,
                }}
              >
                {actions.map((action, actionIndex) => {
                  const { onClick, icon, title, color, type, disabled } = action;
                  const extraKeys = {};
                  if (Array.isArray(extraActionsKeys)) {
                    extraActionsKeys.forEach(key => {
                      if (
                        row[key] &&
                        row[key].value &&
                        row[key].value instanceof Object &&
                        Object.keys(row[key].value).length
                      ) {
                        extraKeys[`data-${key.toLowerCase()}`] = JSON.stringify(row[key].value);
                      } else {
                        extraKeys[`data-${key.toLowerCase()}`] = row[key] && row[key].value;
                      }
                    });
                  }
                  const buttonComponentOptions = {
                    key: `action-${rowIndex}-${actionIndex}`,
                    variant: 'text',
                    size: 'small',
                    color: 'primary',
                    className: color ? classes[`button-${color}`] : '',
                    'data-key': row[systemField] && row[systemField].value,
                    onClick,
                    disabled,
                    ...extraKeys,
                  };

                  let ButtonComponent = null;

                  switch (type) {
                    case 'icon':
                      ButtonComponent = (
                        <IconButton title={action.title} {...buttonComponentOptions}>
                          <Icon>{icon || ''}</Icon>
                        </IconButton>
                      );
                      break;
                    default:
                      ButtonComponent = <Button {...buttonComponentOptions}>{title || ''}</Button>;
                  }
                  return ButtonComponent;
                })}
              </TableCell>
            )}
          </TableRow>
        ))}
      {emptyRows > 0 && (
        <TableRow style={{ height: 53 * emptyRows }}>
          <TableCell
            colSpan={columnsNumber}
            classes={{
              root: classes.tableCell,
            }}
          />
        </TableRow>
      )}
    </TableBody>
  );
};

AppTableBody.defaultProps = {
  emptyRows: 0,
};

AppTableBody.propTypes = {
  columnsNumber: PropTypes.number.isRequired,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string,
      title: PropTypes.string,
      icon: PropTypes.string,
      color: PropTypes.string,
      onClick: PropTypes.func,
    }),
  ).isRequired,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      field: PropTypes.string.isRequired,
      alignment: PropTypes.string,
      isSorting: PropTypes.bool,
    }),
  ).isRequired,
  emptyRows: PropTypes.number,
  alignment: PropTypes.string.isRequired,
  actionsAlignment: PropTypes.string.isRequired,
  systemField: PropTypes.string.isRequired,
  extraActionsKeys: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default AppTableBody;

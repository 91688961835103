import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import AppTable from 'components/AppTable';
import Loader from 'components/Loader';
import { transformTableData } from 'utils';
import { useAsyncFn } from 'react-use';
import { useSelector, useDispatch } from 'react-redux';
import { actions } from 'redux/table';

import { getDocumentsConfig } from './helpers/getTableConfig';

const DocumentsTable = ({ courseId, helpers, courseType, stationId, courseTemplateId }) => {
  const dispatch = useDispatch();
  const { needForceUpdate, tableData } = useSelector(state => state.table);
  const [files, setFiles] = useState([]);

  const tableConfig = getDocumentsConfig();
  const [columns, documents] = transformTableData(tableConfig, tableData);

  const [documentsFetching, fetchDocuments] = useAsyncFn(async () => {
    const combinedFiles = [];

    const courseDocuments = await helpers.getDocuments();
    if (!courseDocuments.error) {
      if (!courseDocuments.items) return;
      courseDocuments.items.forEach(item => {
        combinedFiles.push({
          ...item,
          type: 'course',
          uploadedBy: `${item.uploadedBy.info.firstName} ${item.uploadedBy.info.lastName}`,
        });
      });
    }

    const templateDocuments = await helpers.getCourseTemplatesDocuments(courseTemplateId);
    if (!templateDocuments.error) {
      if (!templateDocuments.items) return;
      templateDocuments.items.forEach(item => {
        combinedFiles.push({
          ...item,
          type: 'template',
          uploadedBy: `${item.uploadedByUser.info.firstName} ${item.uploadedByUser.info.lastName}`,
        });
      });
    }

    setFiles(combinedFiles);

    dispatch(
      actions.setTableData({
        data: combinedFiles,
        count: combinedFiles.length,
      }),
    );
  });

  const [documentDownloading, downloadDocument] = useAsyncFn(async (documentId, type) => {
    if (type === 'course') {
      const downloadParams = courseType === 'local' ? [stationId, courseId, documentId] : [courseId, documentId];
      await helpers.downloadDocument(...downloadParams);
    } else if (type === 'template') {
      await helpers.downloadCourseTemplateDocument(courseTemplateId, documentId);
    }
  });

  const handleDownloadDocument = event => {
    const { key, type } = event.currentTarget.dataset;
    downloadDocument(key, type);
  };

  useEffect(() => {
    fetchDocuments(courseId);
    return () => {
      dispatch(actions.cleanUpTable());
    };
  }, [courseId]);

  useEffect(() => {
    if (needForceUpdate === true) {
      fetchDocuments(courseId);
      dispatch(actions.forceUpdateTable(false));
    }
  }, [needForceUpdate]);

  const tableActions = [
    {
      type: 'icon',
      icon: 'get_app',
      onClick: handleDownloadDocument,
    },
  ];

  const isLoading = documentsFetching.loading || documentDownloading.loading;

  return (
    <>
      <AppTable
        columns={columns}
        data={documents}
        totalCount={files.length}
        rowsPerPage={5}
        actions={tableActions}
        extraActionsKeys={['type']}
      />
      {isLoading && <Loader />}
    </>
  );
};

DocumentsTable.propTypes = {
  courseId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  helpers: PropTypes.shape({
    getDocuments: PropTypes.func,
    deleteDocument: PropTypes.func,
    downloadDocument: PropTypes.func,
    getCourseTemplatesDocuments: PropTypes.func,
    downloadCourseTemplateDocument: PropTypes.func,
  }).isRequired,
  courseType: PropTypes.string.isRequired,
  stationId: PropTypes.string.isRequired,
  courseTemplateId: PropTypes.number.isRequired,
};

export default DocumentsTable;

/* eslint-disable react/jsx-wrap-multilines */
import React, { useState, lazy } from 'react';
import PropTypes from 'prop-types';
import { Box, Button, Container, Divider, Grid, IconButton, InputAdornment, Typography } from '@material-ui/core/';
import CustomTextField from 'components/Forms/TextField';
import { Form, Field } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { generateErrorObject } from 'utils';
import configErrors from './configErrors';
import validate from './validation';
import useStyles from './styles';

const Loader = lazy(() => import('components/Loader'));

const ProfileForm = ({ onSubmit, isLoading }) => {
  const classes = useStyles();
  const { t } = useTranslation('common');

  const { id, email, info = {} } = useSelector(state => state.auth.profile);
  const { firstName = '', lastName = '' } = info;

  const defaultValues = {
    id,
    firstName,
    lastName,
    email,
    currentPassword: '',
    newPassword: '',
    confirmation: '',
  };

  const [isPasswordVisible, changeVisibility] = useState({
    currentPassword: false,
    newPassword: false,
    confirmation: false,
  });

  const handleClickShowPassword = name => {
    changeVisibility({ ...isPasswordVisible, [name]: !isPasswordVisible[name] });
  };

  const handleMouseDownPassword = event => {
    event.preventDefault();
  };

  const handleFormSubmit = async formValues => {
    const submitData = await onSubmit(formValues);
    if (submitData && submitData.error && submitData.message) {
      return generateErrorObject(submitData.message, configErrors);
    }
    return undefined;
  };

  return (
    <Box pt={15} pb={10}>
      <Container className={classes.container} maxWidth="md">
        <Form
          onSubmit={handleFormSubmit}
          validate={validate}
          initialValues={defaultValues}
          render={({ handleSubmit, submitting, form }) => (
            <form onSubmit={handleSubmit}>
              <Grid container className={classes.containerSubtitles}>
                <Grid className={classes.gridItem} item xs={6}>
                  <Box width="100%" mb={0}>
                    <Typography className={classes.subtitle} component="h2">
                      {t('profile.subtitleGeneral')}
                    </Typography>
                  </Box>
                </Grid>
                <Grid className={classes.gridItem} item xs={6}>
                  <Box width="100%" mb={0}>
                    <Typography className={classes.subtitle} component="h2">
                      {t('profile.subtitlePassword')}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>

              <Grid container>
                <Grid className={classes.gridItem} item xs={6}>
                  <Box width="100%" mb={4}>
                    <Field
                      type="text"
                      id="firstName"
                      name="firstName"
                      label={t('formLabels.firstName')}
                      disabled={submitting}
                      variant="outlined"
                      fullWidth
                      component={CustomTextField}
                      format={(value = '') => value.trim()}
                      formatOnBlur
                    />
                  </Box>

                  <Box width="100%" mb={4}>
                    <Field
                      type="text"
                      id="lastName"
                      name="lastName"
                      label={t('formLabels.lastName')}
                      disabled={submitting}
                      variant="outlined"
                      fullWidth
                      component={CustomTextField}
                      format={(value = '') => value.trim()}
                      formatOnBlur
                    />
                  </Box>

                  <Box width="100%" mb={4}>
                    <Field
                      type="email"
                      id="email"
                      name="email"
                      label={t('formLabels.email')}
                      disabled={submitting}
                      variant="outlined"
                      fullWidth
                      component={CustomTextField}
                      format={(value = '') => value.trim()}
                      formatOnBlur
                    />
                  </Box>
                </Grid>

                <Grid className={classes.gridItem} item xs={6}>
                  <Box width="100%" mb={4}>
                    <Field
                      type={isPasswordVisible.currentPassword ? 'text' : 'password'}
                      id="currentPassword"
                      name="currentPassword"
                      label={t('formLabels.current')}
                      disabled={submitting}
                      variant="outlined"
                      fullWidth
                      component={CustomTextField}
                      autoComplete="nope"
                      format={(value = '') => value.trim()}
                      formatOnBlur
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => handleClickShowPassword('currentPassword')}
                            onMouseDown={handleMouseDownPassword}
                          >
                            {isPasswordVisible.currentPassword ? (
                              <i className="material-icons icon-eye">visibility</i>
                            ) : (
                              <i className="material-icons">visibility_off</i>
                            )}
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                  </Box>

                  <Box width="100%" mb={4}>
                    <Field
                      type={isPasswordVisible.newPassword ? 'text' : 'password'}
                      id="newPassword"
                      name="newPassword"
                      label={t('formLabels.new')}
                      disabled={submitting}
                      variant="outlined"
                      fullWidth
                      component={CustomTextField}
                      autoComplete="nope"
                      format={(value = '') => value.trim()}
                      formatOnBlur
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => handleClickShowPassword('newPassword')}
                            onMouseDown={handleMouseDownPassword}
                          >
                            {isPasswordVisible.newPassword ? (
                              <i className="material-icons icon-eye">visibility</i>
                            ) : (
                              <i className="material-icons">visibility_off</i>
                            )}
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                  </Box>

                  <Box width="100%" mb={4}>
                    <Field
                      type={isPasswordVisible.confirmation ? 'text' : 'password'}
                      id="confirmation"
                      name="confirmation"
                      label={t('formLabels.confirmation')}
                      disabled={submitting}
                      variant="outlined"
                      fullWidth
                      component={CustomTextField}
                      autoComplete="nope"
                      format={(value = '') => value.trim()}
                      formatOnBlur
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => handleClickShowPassword('confirmation')}
                            onMouseDown={handleMouseDownPassword}
                          >
                            {isPasswordVisible.confirmation ? (
                              <i className="material-icons icon-eye">visibility</i>
                            ) : (
                              <i className="material-icons">visibility_off</i>
                            )}
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                  </Box>

                  <Divider className={classes.divider} orientation="vertical" />
                </Grid>
              </Grid>

              <Grid container spacing={4} className={classes.btnContainer}>
                <Grid item xs={6}>
                  <Box textAlign="right">
                    <Button
                      className={classes.btn}
                      type="submit"
                      color="primary"
                      variant="contained"
                      disabled={submitting}
                      size="large"
                    >
                      {t('buttons.save')}
                    </Button>
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <Box textAlign="left">
                    <Button className={classes.btn} variant="outlined" size="large" type="reset" onClick={form.reset}>
                      {t('buttons.cancel')}
                    </Button>
                  </Box>
                </Grid>
              </Grid>

              {isLoading && <Loader />}
            </form>
          )}
        />
      </Container>
    </Box>
  );
};

ProfileForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default ProfileForm;

import React from 'react';
import AppTable from 'components/AppTable';
import PropTypes from 'prop-types';
import Loader from 'components/Loader';
import withTableProps from 'HOC/types/withTable';
import { useHistory } from 'react-router-dom';
import { transformTableData, getConfirmationModalConfig } from 'utils';
import { useDispatch, useSelector } from 'react-redux';
import { actions as modalActions } from 'redux/modal';
import { actions as tableActions } from 'redux/table';
import { useAsyncFn } from 'react-use';
import { useTranslation } from 'react-i18next';
import { ROLES } from 'appConstants';
import { getAdminTableConfig, getTableConfig } from './helpers/getTableConfig';

const CoursesTable = ({ tabAlias, routesAliases, helpers, stationId, tableData = {} }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation('common');
  const { role } = useSelector(state => state.auth.profile);
  let tableConfig;
  if (role === ROLES.ADMIN) {
    tableConfig = getAdminTableConfig();
  } else {
    tableConfig = getTableConfig();
  }
  const [columns, courses] = transformTableData(tableConfig, tableData.data);

  const handleCloseModal = () => dispatch(modalActions.closeModal());

  const [stateDeleteCourse, deleteCourse] = useAsyncFn(async (station, id) => {
    const data = await helpers[tabAlias].deleteCourse({ station, id });

    if (!data.error) {
      handleCloseModal();
      dispatch(tableActions.setTableDeleting(true));
      dispatch(tableActions.forceUpdateTable());
    } else {
      let errorMessage = data.message;
      let studentName;
      if (errorMessage.includes('fullName')) {
        const errorParts = errorMessage.split('.');
        studentName = errorParts[errorParts.length - 1];
        errorParts.pop();
        errorMessage = errorParts.join('.');
      }
      const [config, element] = getConfirmationModalConfig({
        confirmTitle: t('buttons.ok'),
        confirmAction: handleCloseModal,
        rejectAction: handleCloseModal,
        message: t(errorMessage, { name: studentName }),
      });
      dispatch(modalActions.openModal(config, element));
    }
  }, []);

  const handleDeleteCourse = id => {
    const [config, element] = getConfirmationModalConfig({
      title: t('courses.deleteModalTitle'),
      confirmAction: () => deleteCourse(stationId, id),
      rejectAction: handleCloseModal,
      message: t('courses.deleteConfirmationMessage'),
    });

    dispatch(modalActions.openModal(config, element));
  };

  const isLoading = stateDeleteCourse.isLoading || tableData.isFetchingTableData;

  return (
    <>
      <AppTable
        columns={columns}
        data={courses}
        totalCount={tableData.totalCount}
        rowsPerPage={tableData.pageSize}
        onChangePage={tableData.handleChangePage}
        onColumnSorting={tableData.handleSorting}
        currentPage={tableData.page}
        extraActionsKeys={['stationId']}
        isLazyLoading
        actions={[
          {
            type: 'icon',
            icon: 'edit',
            onClick: event => {
              const { key, stationid: responseStationId } = event.currentTarget.dataset;
              history.push(`${routesAliases.editCourse}/${tabAlias}/${key}/${responseStationId || 'external'}`);
            },
          },
          {
            type: 'icon',
            icon: 'delete',
            color: 'error',
            onClick: event => {
              handleDeleteCourse(event.currentTarget.dataset.key);
            },
          },
        ]}
      />
      {isLoading && <Loader />}
    </>
  );
};

CoursesTable.propTypes = {
  tabAlias: PropTypes.string.isRequired,
  routesAliases: PropTypes.shape({
    editCourse: PropTypes.string,
  }).isRequired,
  helpers: PropTypes.shape({
    global: PropTypes.shape({
      getCourses: PropTypes.func,
      deleteCourse: PropTypes.func,
    }),
    local: PropTypes.shape({
      getCourses: PropTypes.func,
      deleteCourse: PropTypes.func,
    }),
  }).isRequired,
  stationId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  tableData: PropTypes.shape(withTableProps).isRequired,
};
export default CoursesTable;

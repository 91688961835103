import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import AppBreadcrumbs from 'components/AppBreadcrumbs';
import PageLayout from 'layouts/PageLayout';
import { useAsyncFn } from 'react-use';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector, useDispatch } from 'react-redux';
import {
  getUserDocuments,
  setUserDocuments,
  downloadUserDocument,
  deleteUserDocument,
  getUser,
  updateUser,
} from 'http/users';
import { getJobsTasks } from 'http/jobsTasks';
import {
  getCoursesInProgress,
  getCoursesScheduled,
  getCoursesPassed,
  getCoursesHistory,
  setCourseCertificate,
  downloadCourseCertificate,
  deleteCourseCertificate,
} from 'http/courses';
import { removeStudentFromCourse } from 'http/courseStudent';
import { useRouteMatch, useHistory } from 'react-router-dom';
import { actions as modalActions } from 'redux/modal';
import { actions as tableActions } from 'redux/table';
import { routesAliases } from 'router/routes';
import { transformDataToSelectOptions, getConfirmationModalConfig } from 'utils';
import NotFound from 'pages/NotFound';
import { useTranslation } from 'react-i18next';
import StudentsForm from '../Form';
import CourseDetails from './CourseDetails/index';

const useStyles = makeStyles(theme => ({
  courseDetailsContent: {
    padding: theme.spacing(2, 5),
  },
  pageContent: {
    padding: theme.spacing(4, 5),
  },
}));

const Edit = ({ routeProps }) => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const breadCrumbsItems = [
    {
      title: t(routeProps.parentTitle),
      link: routeProps.parentPath,
    },
    {
      title: t(routeProps.title),
      isActive: true,
    },
  ];

  const routerMatch = useRouteMatch();
  const { id: userId } = routerMatch.params;

  const actions = {
    getUserDocuments: getUserDocuments.bind(null, userId),
    setUserDocuments: setUserDocuments.bind(null, userId),
    downloadUserDocument,
    deleteUserDocument: deleteUserDocument.bind(null),
    getUser: getUser.bind(null, userId),
    getJobsTasks,
    getCoursesInProgress: getCoursesInProgress.bind(null, userId),
    getCoursesScheduled: getCoursesScheduled.bind(null, userId),
    getCoursesPassed: getCoursesPassed.bind(null, userId),
    getCoursesHistory: getCoursesHistory.bind(null, userId),
    setCourseCertificate,
    downloadCourseCertificate,
    deleteCourseCertificate,
    removeStudentFromCourse,
    editCourse: event => {
      const { key: courseId, stationid: stationId } = event.currentTarget.dataset;
      if (stationId) {
        history.push(`${routesAliases.editCourse}/local/${courseId}/${stationId || 'external'}`);
      } else {
        history.push(`${routesAliases.editCourse}/global/${courseId}/${stationId || 'external'}`);
      }
    },
  };

  const { station } = useSelector(state => state.auth.profile);
  const stationId = station ? station.id : undefined;

  const [notFoundError, setNotFoundError] = useState(false);
  const [initialFormState, setInitialFormState] = useState(false);

  const [fetchUserState, fetchUserData] = useAsyncFn(async () => {
    const userData = await getUser(userId);
    if (!userData.error) {
      setInitialFormState({
        firstName: (userData.info && userData.info.firstName) || '',
        lastName: (userData.info && userData.info.lastName) || '',
        email: userData.email || '',
        company: (userData.info && userData.info.info && userData.info.info.company) || '',
        country: (userData.info && userData.info.info && userData.info.info.country) || '',
        phone: (userData.info && userData.info.info && userData.info.info.phone) || '',
        jobTasks: transformDataToSelectOptions(userData.jobTasks, 'id', 'name'),
        ids: (userData.info && userData.info.ids) || '',
        station: station || [],
        department: (userData.info && userData.info.info && userData.info.info.department) || '',
        stationsIds: userData.stations.map(item => item.id),
      });
    } else if (userData.statusCode === 404) {
      setNotFoundError(true);
      return false;
    }
    return true;
  });

  useEffect(() => {
    fetchUserData(userId);
  }, []);

  const [state, editStudent] = useAsyncFn(async formData => {
    const data = await updateUser(formData, userId);
    if (!data.error) {
      history.push(routesAliases.studentsList);
    }
    return data;
  }, []);

  const handleCloseModal = () => {
    dispatch(modalActions.closeModal());
    dispatch(tableActions.forceUpdateTable(true));
  };

  const [studentDeleting, deleteStudent] = useAsyncFn(async courseId => {
    const data = await removeStudentFromCourse(courseId, userId);
    if (!data.error) {
      handleCloseModal();
      dispatch(modalActions.setTableDeleting(true));
      dispatch(modalActions.forceUpdateTable(true));
    }
  });

  const handleDeleteStudent = event => {
    const courseId = event.currentTarget.dataset.key;
    const [config, element] = getConfirmationModalConfig({
      title: t('courseDetails.deleteStudentConfirmationTitle'),
      confirmAction: () => deleteStudent(courseId),
      rejectAction: handleCloseModal,
      message: t('courseDetails.deleteStudentConfirmationMessage'),
    });
    dispatch(modalActions.openModal(config, element));
  };

  return notFoundError ? (
    <NotFound />
  ) : (
    <PageLayout
      pageTitle={<AppBreadcrumbs items={breadCrumbsItems} />}
      classes={{ content: classes.pageContent }}
      additionSections={[
        {
          contentClass: classes.courseDetailsContent,
          contentHeader: t('students.trainingDetails'),
          component: (
            <CourseDetails
              userId={userId}
              helpers={{
                getDocuments: actions.getUserDocuments,
                setDocuments: actions.setUserDocuments,
                downloadDocument: actions.downloadUserDocument,
                deleteDocument: actions.deleteUserDocument,
                getJobTasks: actions.getJobsTasks,
                getCoursesInProgress: actions.getCoursesInProgress,
                getCoursesScheduled: actions.getCoursesScheduled,
                getCoursesPassed: actions.getCoursesPassed,
                getCoursesHistory: actions.getCoursesHistory,
                removeStudentFromCourse: handleDeleteStudent,
                editCourse: actions.editCourse,
                setCertificate: setCourseCertificate,
                getEditorData: actions.getUser,
                downloadCertificate: actions.downloadCourseCertificate,
                deleteCertificate: actions.deleteCourseCertificate,
              }}
              stationId={stationId}
              routesAliases={routesAliases}
            />
          ),
        },
      ]}
      showLoader={fetchUserState.loading || studentDeleting.loading}
    >
      <StudentsForm
        isLoading={state.loading}
        onSubmit={editStudent}
        studentData={initialFormState}
        routesAliases={routesAliases}
        type="edit"
      />
    </PageLayout>
  );
};

Edit.propTypes = {
  routeProps: PropTypes.shape({
    parentPath: PropTypes.string,
    parentTitle: PropTypes.string,
    title: PropTypes.string,
  }).isRequired,
};

export default Edit;

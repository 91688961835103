import React from 'react';
import { DateFormatter } from 'components/AppTable/CellsComponents';
import i18next from 'i18next';

export const getHistoryConfig = () => [
  {
    title: i18next.t('tablesLabels.id'),
    field: 'id',
  },
  {
    title: i18next.t('tablesLabels.course'),
    field: 'name',
    isSorting: true,
  },
  {
    title: i18next.t('tablesLabels.expiryDate'),
    field: 'expirationDate',
    Component: value => <DateFormatter value={value} />,
    isSorting: true,
  },
  {
    title: i18next.t('tablesLabels.performedDate'),
    field: 'performedDate',
    Component: value => <DateFormatter value={value} />,
    isSorting: true,
  },
  {
    title: i18next.t('tablesLabels.byWhom'),
    field: 'byWhom',
  },
];

import { BASE_USER_URL, ROLES } from 'appConstants';
import Dashboard from 'pages/Dashboard';
import Notifications from 'pages/Notifications';
import Administrators from 'pages/Administrators';
import Students from 'pages/Students';
import Trainers from 'pages/Trainers';
import Tasks from 'pages/Tasks';
import Templates from 'pages/Templates';
import Courses from 'pages/Courses';
import Statistics from 'pages/Statistics';
import StudentStatistics from 'pages/StudentStatistics';
import Schedule from 'pages/Schedule';
import Profile from 'pages/Profile';
import Companies from 'pages/Companies';
import CreateCompany from 'pages/Companies/Create';
import EditCompany from 'pages/Companies/Edit';
import EditAdministrator from 'pages/Administrators/Edit';
import CreateAdministrator from 'pages/Administrators/Create';
import EditTrainer from 'pages/Trainers/Edit';
import CreateTrainer from 'pages/Trainers/Create';
import EditCourse from 'pages/Courses/Edit';
import CreateCourse from 'pages/Courses/Create';
import EditTemplate from 'pages/Templates/Edit';
import CreateTemplate from 'pages/Templates/Create';
import EditJobTask from 'pages/Tasks/Edit';
import CreateJobTask from 'pages/Tasks/Create';
import EditStudent from 'pages/Students/Edit';
import CreateStudent from 'pages/Students/Create';
import StudentCourses from 'pages/StudentCourses';
import TrainerCourses from 'pages/TrainerCourses';
import TrainerStudents from 'pages/TrainerStudents';
import CourseView from 'pages/TrainerCourses/CourseView';
import StudentsCourseView from 'pages/StudentCourses/CourseView';

const routes = {
  companies: {
    to: `${BASE_USER_URL}companies`,
    title: 'routes.companies',
    icon: 'flight',
    component: Companies,
    rotateIcon: true,
    children: [
      {
        to: '/edit/:id',
        title: 'routes.editCompany',
        component: EditCompany,
      },
      {
        to: '/create',
        title: 'routes.createCompany',
        component: CreateCompany,
      },
    ],
  },
  dashboard: {
    to: `${BASE_USER_URL}dashboard`,
    title: 'routes.dashboard',
    icon: 'dashboard',
    component: Dashboard,
  },
  trainerCourses: {
    to: `${BASE_USER_URL}courses`,
    title: 'routes.courses',
    icon: 'list',
    component: TrainerCourses,
    children: [
      {
        to: '/view/:id/:type/:stationId',
        title: 'routes.viewCourse',
        component: CourseView,
      },
    ],
  },
  trainerStudents: {
    to: `${BASE_USER_URL}students`,
    title: 'routes.students',
    icon: 'accessibility_new',
    component: TrainerStudents,
  },
  notifications: {
    to: `${BASE_USER_URL}notifications`,
    title: 'routes.notifications',
    icon: 'notifications',
    component: Notifications,
  },
  administrators: {
    to: `${BASE_USER_URL}administrators`,
    title: 'routes.administrators',
    icon: 'supervisor_account',
    component: Administrators,
    children: [
      {
        to: '/edit/:id',
        title: 'routes.editAdmin',
        component: EditAdministrator,
      },
      {
        to: '/create',
        title: 'routes.createAdmin',
        component: CreateAdministrator,
      },
    ],
  },
  students: {
    to: `${BASE_USER_URL}students`,
    title: 'routes.students',
    icon: 'accessibility_new',
    component: Students,
    children: [
      {
        to: '/edit/:id',
        title: 'routes.editStudent',
        component: EditStudent,
      },
      {
        to: '/create',
        title: 'routes.createStudent',
        component: CreateStudent,
      },
    ],
  },
  trainers: {
    to: `${BASE_USER_URL}trainers`,
    title: 'routes.trainers',
    icon: 'assignment',
    component: Trainers,
    children: [
      {
        to: '/edit/:id',
        title: 'routes.editTrainer',
        component: EditTrainer,
      },
      {
        to: '/create',
        title: 'routes.createTrainer',
        component: CreateTrainer,
      },
    ],
  },
  tasks: {
    to: `${BASE_USER_URL}tasks`,
    title: 'routes.jobTasks',
    icon: 'work',
    component: Tasks,
    children: [
      {
        to: '/edit/:id',
        title: 'routes.editJobTask',
        component: EditJobTask,
      },
      {
        to: '/create',
        title: 'routes.createJobTask',
        component: CreateJobTask,
      },
    ],
  },
  templates: {
    to: `${BASE_USER_URL}templates`,
    title: 'routes.courseTemplates',
    icon: 'dns',
    component: Templates,
    children: [
      {
        to: '/edit/:id',
        title: 'routes.editCourseTemplate',
        component: EditTemplate,
      },
      {
        to: '/create',
        title: 'routes.createCourseTemplate',
        component: CreateTemplate,
      },
    ],
  },
  courses: {
    to: `${BASE_USER_URL}courses`,
    title: 'routes.courses',
    icon: 'list',
    component: Courses,
    children: [
      {
        to: '/edit/:type/:id/:stationId',
        title: 'routes.editCourse',
        component: EditCourse,
      },
      {
        to: '/create/:type',
        title: 'routes.createCourse',
        component: CreateCourse,
      },
    ],
  },
  studentCourses: {
    to: `${BASE_USER_URL}courses`,
    title: 'routes.courses',
    icon: 'list',
    component: StudentCourses,
    children: [
      {
        to: '/view/:id/:type/:stationId',
        title: 'routes.viewCourse',
        component: StudentsCourseView,
      },
    ],
  },
  statistics: {
    to: `${BASE_USER_URL}statistics`,
    title: 'routes.statistics',
    icon: 'trending_up',
    component: Statistics,
  },
  studentStatistics: {
    to: `${BASE_USER_URL}statistics`,
    title: 'routes.statistics',
    icon: 'trending_up',
    component: StudentStatistics,
  },
  schedule: {
    to: `${BASE_USER_URL}schedule`,
    title: 'routes.schedule',
    icon: 'calendar_today',
    component: Schedule,
  },
  profile: {
    to: `${BASE_USER_URL}profile`,
    title: 'routes.profile',
    icon: 'settings',
    component: Profile,
  },
};

export const routesMapToRoles = {
  [ROLES.HYPER]: [routes.companies, routes.profile],
  [ROLES.SUPER]: [
    routes.dashboard,
    routes.notifications,
    routes.administrators,
    routes.trainers,
    routes.students,
    routes.tasks,
    routes.templates,
    routes.courses,
    routes.statistics,
    routes.schedule,
    routes.profile,
  ],
  [ROLES.ADMIN]: [
    routes.dashboard,
    routes.notifications,
    routes.trainers,
    routes.students,
    routes.courses,
    routes.statistics,
    routes.schedule,
    routes.profile,
  ],
  [ROLES.TRAINER]: [
    routes.trainerCourses,
    routes.notifications,
    routes.trainerStudents,
    routes.statistics,
    routes.schedule,
    routes.profile,
  ],
  [ROLES.USER]: [
    routes.studentCourses,
    routes.notifications,
    routes.studentStatistics,
    routes.schedule,
    routes.profile,
  ],
};

export const routesAliases = {
  editCourse: `${routes.courses.to}/edit`,
  createCourse: `${routes.courses.to}/create`,
  createTemplate: `${routes.templates.to}/create`,
  editTemplate: `${routes.templates.to}/edit`,
  jobsTasksList: routes.tasks.to,
  coursesList: routes.courses.to,
  courseTemplatesList: routes.templates.to,
  editJobTask: `${routes.tasks.to}/edit`,
  createJobTask: `${routes.tasks.to}/create`,
  administratorsList: `${routes.administrators.to}`,
  editAdministrator: `${routes.administrators.to}/edit`,
  createAdministrator: `${routes.administrators.to}/create`,
  trainersList: routes.trainers.to,
  editTrainer: `${routes.trainers.to}/edit`,
  createTrainer: `${routes.trainers.to}/create`,
  studentsList: `${routes.students.to}/`,
  editStudent: `${routes.students.to}/edit`,
  createStudent: `${routes.students.to}/create`,
  schedule: routes.schedule.to,
  dashboard: routes.dashboard.to,
  viewTrainerCourse: `${routes.trainerCourses.to}/view`,
  trainerCourseList: routes.trainerCourses.to,
  studentCourseList: routes.studentCourses.to,
  viewStudentCourse: `${routes.studentCourses.to}/view`,
  notificationsList: routes.notifications.to,
  editCompany: `${routes.companies.to}/edit`,
  createCompany: `${routes.companies.to}/create`,
  companiesList: `${routes.companies.to}`,
};

export default routes;

import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Icon } from '@material-ui/core';

import useStyles from './thumbnail.styles';

const acceptedImagesTypes = ['png', 'jpg', 'jpeg', 'gif', 'bmp'];

const Thumbnail = ({ file, index, handleDelete, isMultipleFiles }) => {
  const classes = useStyles();

  const dataIndex = index || Date.now().toString();
  const alt = file.name || 'image';

  const thumbClasses = classnames({
    [classes.thumb]: true,
    [classes.thumbSingle]: !isMultipleFiles,
  });

  const fileByParts = file.path.split('.');
  const fileExtension = fileByParts[fileByParts.length - 1];
  const isFileOfImageType = acceptedImagesTypes.includes(fileExtension);

  return isFileOfImageType ? (
    <div className={thumbClasses} key={file.path}>
      <img src={file.preview} className={classes.thumbImg} alt={alt} />
      <Icon className={classes.thumbRemove} data-index={dataIndex} onClick={handleDelete}>
        clear
      </Icon>
    </div>
  ) : (
    <div className={classes.thumbFileName}>{file.path}</div>
  );
};

Thumbnail.defaultProps = {
  file: null,
  isMultipleFiles: false,
  index: 0,
  handleDelete: () => {
    throw new Error('No handler on image deletion!');
  },
};

Thumbnail.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  file: PropTypes.any,
  index: PropTypes.string,
  isMultipleFiles: PropTypes.bool,
  handleDelete: PropTypes.func,
};

export default Thumbnail;

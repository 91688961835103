import { generateColor } from 'utils';
import i18next from 'i18next';

const transformToPastByCourses = (data = [], otherPosition) => {
  const statData = data.map(item => ({
    value: item.count,
    label: item.name,
    legendTitle: item.name,
    fillColor: generateColor(),
  }));

  statData.sort((a, b) => b.value - a.value);

  if (statData.length > otherPosition) {
    let otherValue = 0;
    const coursesSorted = statData.map((item, index) => {
      if (index > otherPosition - 2) {
        otherValue += item.value;
        return {
          ...item,
          hideLabel: true,
          hiddenInData: true,
        };
      }
      return item;
    });

    return [
      ...coursesSorted,
      {
        value: otherValue,
        legendTitle: i18next.t('legend.other'),
        fillColor: '#C84F48',
        hideLabel: false,
        withExtInfo: true,
      },
    ];
  }

  return statData;
};

export default transformToPastByCourses;

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import AppTable from 'components/AppTable';
import Donut from 'components/Donut';
import PageLayout from 'layouts/PageLayout';
import SearchFilter from 'components/SearchFilter';
import StatisticsInfo from 'components/StatisticsInfo';
import withTable from 'HOC/withTable';
import withTableProps from 'HOC/types/withTable';
import { makeStyles } from '@material-ui/core/styles';
import { transformTableData, transformToStatisticsByExpireDate, transformToPastByCourses } from 'utils';
import { useSelector } from 'react-redux';
import { getStatistics } from 'http/statistics';
import { useTranslation } from 'react-i18next';
import getTableConfig from './helpers/getTableConfig';

const pageAlias = 'Statistics';

const useStyles = makeStyles(theme => ({
  statisticsSection: {
    display: 'flex',
    margin: '70px 0 30px 0',
    alignItems: 'center',
    justifyContent: 'center',
  },
  pageWrapper: {
    padding: theme.spacing(1, 4),
  },
  donutRoot: {
    flexDirection: 'row-reverse',
    alignItems: 'center',
  },
  donutLegendWrapper: {
    flex: 0.6,
  },
  donutWrapper: {
    flex: 1,
  },
  divider: {
    width: 0,
    height: '280px',
    border: `1px solid ${theme.palette.text.grey}`,
  },
  tableRoot: {
    padding: '10px 0',
  },
}));

const StudentStatistics = ({ tableData, total, diagrams }) => {
  const classes = useStyles();
  const { t } = useTranslation('common');
  const { lang } = useSelector(state => state.auth);
  const [donutDataByExpireDate, setDonutDataByExpireDate] = useState([]);
  const [donutDataByCourses, setDonutDataByCourses] = useState([]);
  const [statisticsInfo, setStatisticsInfo] = useState([]);
  const tableConfig = getTableConfig();
  const [columns, statistics] = transformTableData(tableConfig, tableData.data.items);

  const handleTableSearch = (value, searchAlias) => {
    tableData.handleFiltering(searchAlias, value);
  };

  const handleTableSearchClearing = searchAlias => {
    tableData.handleRemoveFilter(searchAlias);
  };

  useEffect(() => {
    tableData.handleChangeFetchMethod(pageAlias, getStatistics);
  }, [lang]);

  useEffect(() => {
    setStatisticsInfo([
      {
        title: t('statistics.totalCourses'),
        value: total.courses,
        subtitle: t('statistics.subtitleCourses'),
      },
      {
        title: t('statistics.totalPerformedCourses'),
        value: total.performed,
        subtitle: t('statistics.subtitleCourses'),
      },
    ]);
  }, [total]);

  useEffect(() => {
    setDonutDataByExpireDate(transformToStatisticsByExpireDate(diagrams.courses));
    setDonutDataByCourses(transformToPastByCourses(diagrams.passed, 4));
  }, [diagrams]);

  return (
    <PageLayout
      pageTitle={t('statistics.pageTitle')}
      contentHeader={t('statistics.contentHeader')}
      actionSectionLeft={[
        {
          type: 'component',
          Component: <StatisticsInfo items={statisticsInfo} />,
        },
      ]}
      pageContentActions={[
        {
          type: 'component',
          Component: (
            <SearchFilter
              searchAlias="search"
              onSearch={handleTableSearch}
              onClearSearch={handleTableSearchClearing}
              styles={{
                bgColor: '#e5e5e5',
                searchWidth: '250px',
              }}
            />
          ),
        },
      ]}
      classes={{
        content: classes.pageWrapper,
      }}
      showLoader={tableData.isFetchingTableData}
    >
      <div className={classes.mainContent}>
        <div className={classes.statisticsSection}>
          {donutDataByExpireDate && !!donutDataByExpireDate.length && (
            <div className={classes.statisticsLeft}>
              <Donut
                donutTitle={t('legend.totalCoursesNumber')}
                donutData={donutDataByExpireDate}
                legendWidth={150}
                legendHeight={150}
                legendOrientation="vertical"
                legendItemsPerRow={4}
                classes={{
                  root: classes.donutRoot,
                  legendWrapper: classes.donutLegendWrapper,
                  donutWrapper: classes.donutWrapper,
                }}
              />
            </div>
          )}
          {donutDataByCourses && !!donutDataByCourses.length && (
            <div className={classes.statisticsRight}>
              <Donut
                donutTitle={t('legend.totalPerformedCoursesNumber')}
                donutData={donutDataByCourses}
                legendWidth={150}
                legendHeight={150}
                legendOrientation="vertical"
                legendItemsPerRow={4}
                classes={{
                  root: classes.donutRoot,
                  legendWrapper: classes.donutLegendWrapper,
                  donutWrapper: classes.donutWrapper,
                }}
                shouldPercent={false}
              />
            </div>
          )}
        </div>

        <AppTable
          columns={columns}
          data={statistics}
          totalCount={tableData.data.totalCount}
          rowsPerPage={tableData.pageSize}
          onChangePage={tableData.handleChangePage}
          currentPage={tableData.page}
          onColumnSorting={tableData.handleSorting}
          extraActionsKeys={['userId']}
          isLazyLoading
          classes={{
            root: classes.tableRoot,
          }}
        />
      </div>
    </PageLayout>
  );
};

StudentStatistics.defaultProps = {
  total: {},
  diagrams: {},
};

StudentStatistics.propTypes = {
  tableData: PropTypes.shape(withTableProps).isRequired,
  total: PropTypes.shape(),
  diagrams: PropTypes.shape(),
};

export default withTable({
  defaultSorting: {},
  sortingFieldsMapping: {
    course: 'courseName',
    performationDate: 'performedDate',
    coloredData: 'expirationDate',
  },
  pageAlias,
  fetchingData: 'statData',
})(StudentStatistics);

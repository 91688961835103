const trimStrByDotCount = (err, count, sideOfDot = 'left') => {
  if (err.split('.').length > count) {
    if (sideOfDot === 'left') {
      return err.substring(0, err.lastIndexOf('.'));
    }
    return err.substring(err.lastIndexOf('.') + 1);
  }
  return err;
};

export default trimStrByDotCount;

import React from 'react';
import PropTypes from 'prop-types';
import { Radio, RadioGroup, FormControl, FormControlLabel, FormHelperText, InputLabel } from '@material-ui/core/';
import useStyles from './styles';

const CustomRadio = ({ input: { name, value, ...restInput }, customStyles, id, label, meta, ...rest }) => {
  const classes = useStyles();
  const isError = meta.touched && !meta.valid;

  return (
    <FormControl
      className={customStyles}
      component="fieldset"
      {...restInput}
      fullWidth={rest.fullWidth}
      error={isError}
    >
      <InputLabel className={classes.label} shrink htmlFor={id}>
        {label}
      </InputLabel>
      <RadioGroup className={classes.group} aria-label={name} name={name}>
        {rest.items.map(item => (
          <FormControlLabel
            key={item.value}
            label={item.name}
            value={item.value}
            className={`${classes.radioLabel} ${item.value === value.toString() && classes.active}`}
            control={<Radio checked={item.value === value.toString()} />}
          />
        ))}
      </RadioGroup>
      {isError && (
        <FormHelperText id="outlined-weight-helper-text" error>
          {meta.error}
        </FormHelperText>
      )}
    </FormControl>
  );
};

CustomRadio.defaultProps = {
  customStyles: undefined,
};

CustomRadio.propTypes = {
  input: PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
  }).isRequired,
  label: PropTypes.string.isRequired,
  meta: PropTypes.shape().isRequired,
  id: PropTypes.string.isRequired,
  customStyles: PropTypes.string,
};

export default CustomRadio;

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import AppTable from 'components/AppTable';
import PageLayout from 'layouts/PageLayout';
import SearchFilter from 'components/SearchFilter';
import withTableProps from 'HOC/types/withTable';
import withTable from 'HOC/withTable';
import { useTranslation } from 'react-i18next';
import { getStudents, exportTrainersStudents } from 'http/users';
import { transformTableData } from 'utils';
import { useSelector } from 'react-redux';
import getTableConfig from './helpers/getTableConfig';

const pageAlias = 'TrainerStudentsList';

const TrainerStudents = ({ tableData }) => {
  const { t } = useTranslation('common');
  const { station } = useSelector(state => state.auth.profile);

  const tableConfig = getTableConfig();
  const [columns, users] = transformTableData(tableConfig, tableData.data);

  const isLoading = tableData.isFetchingTableData;

  const handleTableSearch = (value, searchAlias) => {
    tableData.handleFiltering(searchAlias, value);
  };

  const handleTableSearchClearing = searchAlias => {
    tableData.handleRemoveFilter(searchAlias);
  };

  const handleExportStudents = async () => {
    await exportTrainersStudents();
  };

  useEffect(() => {
    tableData.handleChangeFetchMethod(pageAlias, getStudents);
  }, [station]);

  return (
    <PageLayout
      pageTitle={t('students.pageTitle')}
      actionSectionLeft={[
        {
          type: 'component',
          Component: (
            <SearchFilter searchAlias="search" onSearch={handleTableSearch} onClearSearch={handleTableSearchClearing} />
          ),
        },
      ]}
      actionSectionRight={[
        {
          type: 'button',
          icon: 'import_export',
          title: t('commonActions.actionExport'),
          onClick: handleExportStudents,
        },
      ]}
      showLoader={isLoading}
    >
      <AppTable
        columns={columns}
        data={users}
        totalCount={tableData.totalCount}
        rowsPerPage={tableData.pageSize}
        onChangePage={tableData.handleChangePage}
        onColumnSorting={tableData.handleSorting}
        currentPage={tableData.page}
        isLazyLoading
      />
    </PageLayout>
  );
};
TrainerStudents.propTypes = {
  tableData: PropTypes.shape(withTableProps).isRequired,
};

export default withTable({
  defaultSorting: {},
  fetchingData: 'items',
  pageAlias,
})(TrainerStudents);

import { http } from '../index';
import { dashboardModifier, statisticsModifier } from '../formatters';

export const getDashboardStatistics = params => {
  return http
    .get('/dashboard', {
      params,
    })
    .then(res => dashboardModifier(res.data))
    .catch(error => error.response.data);
};

export const getStatistics = params => {
  return http
    .get('/statistic', {
      params,
    })
    .then(res => statisticsModifier(res.data))
    .catch(error => error.response.data);
};

import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { STATISTIC_BY_EXPIRE_DATE_COLORS } from 'appConstants';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  root: {
    color: ({ textColor }) => textColor,
  },
}));

const DateFormatter = ({ value, format, expirationType }) => {
  const classes = useStyles({ textColor: STATISTIC_BY_EXPIRE_DATE_COLORS[expirationType] });
  const dateValue = value ? moment(value).format(format) : '';
  return <span className={classes.root}>{dateValue}</span>;
};

DateFormatter.defaultProps = {
  format: 'MM/DD/YYYY',
  value: undefined,
  expirationType: undefined,
};

DateFormatter.propTypes = {
  value: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
  format: PropTypes.string,
  expirationType: PropTypes.string,
};

export default DateFormatter;

import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Dialog, DialogTitle, DialogContent, DialogActions } from '@material-ui/core';
import classnames from 'classnames';

const useStyles = makeStyles(theme => ({
  root: {
    '&>.MuiBackdrop-root': {
      backdropFilter: 'blur(10px)',
    },
    '& .MuiDialog-paper': {
      maxWidth: 'unset',
      padding: '30px 50px',
    },
  },
  modalHeader: {
    margin: 0,
    '&:empty': {
      display: 'none',
    },
  },
  modalActions: {
    margin: 0,
    alignItems: 'center',
    justifyContent: 'center',
    '&:empty': {
      display: 'none',
    },
  },
  modalContent: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(3),
  },
  'modalContent-small': {
    maxWidth: '300px',
  },
  'modalContent-medium': {
    maxWidth: '600px',
  },
  'modalContent-large': {
    maxWidth: '948px',
    width: '100%',
  },
  notification: {
    '& .MuiDialogTitle-root': {
      padding: '0',
      '& .MuiTypography-root': {
        fontWeight: 'bold',
        fontSize: '24px',
        lineHeight: '28px',
      },
    },
    '& .MuiDialogContent-root': {
      padding: '0',
      fontSize: '18px',
      lineHeight: '150%',
      '& p': {
        margin: '42px 0 39px',
      },
    },
    '& .MuiDialogActions-root': {
      padding: '0',
      justifyContent: 'space-around',
    },
    '& .MuiButtonBase-root': {
      minWidth: '140px',
    },
  },
}));

const Modal = props => {
  const classes = useStyles();
  const { title, actions, isOpen, children, onClose, size, maxWidth, customStyles, modalType } = props;

  const dialogClasses = classnames({
    [classes.root]: true,
    [classes[modalType]]: !!modalType,
    [customStyles]: !!customStyles,
  });

  return (
    <Dialog onClose={onClose} open={isOpen} className={dialogClasses} maxWidth={maxWidth}>
      <DialogTitle disableTypography className={classes.modalHeader}>
        {title && (
          <Typography variant="h5" color="primary">
            {title}
          </Typography>
        )}
      </DialogTitle>
      <DialogContent className={`${classes.modalContent} ${classes[`modalContent-${size}`]}`}>{children}</DialogContent>
      {!!actions.length && (
        <DialogActions className={classes.modalActions}>
          {actions.map(action => {
            const { Component } = action;
            return <Component key={action} />;
          })}
        </DialogActions>
      )}
    </Dialog>
  );
};

Modal.defaultProps = {
  title: '',
  isOpen: false,
  onClose: () => {},
  size: 'large',
  actions: [],
  children: null,
  maxWidth: null,
  customStyles: null,
  modalType: null,
};

Modal.propTypes = {
  title: PropTypes.string,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  size: PropTypes.string,
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      Component: PropTypes.oneOfType([PropTypes.node, PropTypes.func]).isRequired,
    }),
  ),
  children: PropTypes.node,
  maxWidth: PropTypes.string,
  customStyles: PropTypes.string,
  modalType: PropTypes.string,
};

export default Modal;

export const formDataModifier = obj => {
  const formData = new FormData();
  Object.keys(obj).forEach(key => {
    if (Array.isArray(obj[key]) && obj[key][0] instanceof File) {
      obj[key].forEach((item, index) => formData.append(key, obj[key][index], obj[key][index].name));
    } else if (typeof obj[key] === 'object' && obj[key] instanceof File) {
      formData.append(key, obj[key], obj[key].name);
    } else {
      formData.append(key, obj[key]);
    }
  });
  return formData;
};

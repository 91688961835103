import { handleActions, createActions } from 'redux-actions';

import initialState, * as handlers from './handlers';

export const actions = createActions({
  OPEN_MODAL: (config, Component) => ({ config, Component }),
  CLOSE_MODAL: undefined,
});

const reducer = handleActions(
  new Map([
    [actions.openModal, handlers.openModal],
    [actions.closeModal, handlers.closeModal],
  ]),
  initialState,
);

export default reducer;

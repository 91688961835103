export const transformDataCountriesToSelectOptions = data => {
  if (!data) return [];
  let elements = data;
  if (typeof data === 'string') {
    elements = [data];
  }
  return elements.map(element => ({
    label: element,
    value: element,
  }));
};

export default transformDataCountriesToSelectOptions;

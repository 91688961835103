import React, { useState, useEffect } from 'react';
import Tabulation from 'components/Tabulation';
import PageLayout from 'layouts/PageLayout';
import SearchFilter from 'components/SearchFilter';
import withTable from 'HOC/withTable';
import withTableProps from 'HOC/types/withTable';
import {
  getAllTrainers,
  getLocalTrainers,
  getExternalTrainers,
  deleteUser,
  importUsers,
  exportAllTrainers,
  exportLocalTrainers,
  exportExternalTrainers,
  downloadImportUserExample,
} from 'http/users';
import PropTypes from 'prop-types';
import { useHistory, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { routesAliases } from 'router/routes';
import { useAsyncFn } from 'react-use';
import { actions as modalActions } from 'redux/modal';
import { SendFileForm } from 'components/CommonForms';
import { useTranslation } from 'react-i18next';
import TrainersTable from './TrainersTable';

const getHelpers = stationId => {
  return {
    all: {
      getTrainers: getAllTrainers,
      deleteCourse: ({ station, id }) => deleteUser(station, id),
      exportTrainers: exportAllTrainers,
    },
    trainers: {
      getTrainers: getLocalTrainers.bind(null, stationId),
      deleteCourse: ({ station, id }) => deleteUser(station, id),
      exportTrainers: exportLocalTrainers.bind(null, stationId),
    },
    external: {
      getTrainers: getExternalTrainers,
      deleteCourse: ({ id }) => deleteUser(id),
      exportTrainers: exportExternalTrainers,
    },
  };
};

const pageAlias = 'TrainersList';

let historyState;

const Trainers = ({ tableData }) => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { station } = useSelector(state => state.auth.profile);
  const stationId = station ? station.id : undefined;

  const tabsData = [
    {
      label: t('trainers.allTrainersTab'),
      alias: 'all',
    },
    {
      label: t('trainers.trainersTab'),
      alias: 'trainers',
    },
    {
      label: t('trainers.externalTab'),
      alias: 'external',
    },
  ];

  const [helpers, setHelpers] = useState(getHelpers(stationId));
  const [activeTab, setActiveTab] = useState({
    tabIndex: 0,
    alias: 'all',
  });

  const handleTabChange = (event, data) => {
    setActiveTab({
      tabIndex: data.tabIndex,
      alias: data.alias,
    });
    tableData.handleChangeFetchMethod(pageAlias, getHelpers(stationId)[data.alias].getTrainers);
  };

  if (history.action === 'PUSH') {
    historyState = location.state && location.state.trainerType;
  }

  const [importState, importTrainersFile] = useAsyncFn(async (currentStationId, formData) => {
    const importResponse = await importUsers(currentStationId, 'trainers', formData);
    if (!importResponse.error) {
      dispatch(modalActions.closeModal());
      tableData.handleForceUpdate();
    }
    return importResponse;
  });

  const [exportState, exportTrainersFile] = useAsyncFn(async currentStationId => {
    await getHelpers(stationId)[currentStationId.alias].exportTrainers();
  });

  const [downloadExampleState, downloadTrainersExample] = useAsyncFn(async currentStationId => {
    await downloadImportUserExample(currentStationId, 'trainers');
  });

  const handleExportTrainers = () => {
    exportTrainersFile(activeTab);
  };

  const handleDownloadExample = () => {
    downloadTrainersExample(stationId);
  };

  const handleImportTrainers = () => {
    dispatch(
      modalActions.openModal(
        {
          size: 'medium',
          title: t('trainers.importModalTitle'),
        },
        <SendFileForm
          onSubmitForm={values => importTrainersFile(stationId, { data: values.file })}
          rejectAction={() => {
            dispatch(modalActions.closeModal());
          }}
          formType="import"
          formName="trainers"
          submitting={importState.loading}
          fileFormat=".xlsx"
        />,
      ),
    );
  };

  const handleTableSearch = (value, searchAlias) => {
    tableData.handleFiltering(searchAlias, value);
  };

  const handleTableSearchClearing = searchAlias => {
    tableData.handleRemoveFilter(searchAlias);
  };

  useEffect(() => {
    if (historyState) {
      handleTabChange(null, {
        tabIndex: tabsData.findIndex(tab => tab.alias === historyState),
        alias: historyState,
      });
      historyState = undefined;
    } else {
      setHelpers(getHelpers(stationId));
      tableData.handleChangeFetchMethod(pageAlias, getHelpers(stationId)[activeTab.alias].getTrainers);
    }
  }, [station]);

  const tableComponent = (
    <TrainersTable
      activeTab={activeTab.alias}
      routesAliases={routesAliases}
      helpers={helpers}
      stationId={stationId}
      tableData={tableData}
    />
  );

  const isLoading = exportState.loading || importState.loading || downloadExampleState.loading;

  return (
    <PageLayout
      pageTitle={t('trainers.pageTitle')}
      showLoader={isLoading}
      actionSectionLeft={[
        {
          type: 'component',
          Component: (
            <SearchFilter searchAlias="search" onSearch={handleTableSearch} onClearSearch={handleTableSearchClearing} />
          ),
        },
      ]}
      actionSectionRight={[
        {
          type: 'button',
          icon: 'add',
          title: t('trainers.actionAdd'),
          onClick: () => {
            history.push(routesAliases.createTrainer, { trainerType: activeTab.alias });
          },
        },
        {
          type: 'button',
          icon: 'import_export',
          title: t('commonActions.actionExport'),
          onClick: handleExportTrainers,
        },
        {
          type: 'button',
          icon: 'import_export',
          title: t('commonActions.actionImport'),
          onClick: handleImportTrainers,
        },
        {
          type: 'button',
          icon: 'cloud_download',
          title: t('commonActions.actionDownloadExample'),
          onClick: handleDownloadExample,
        },
      ]}
    >
      <Tabulation
        tabsData={tabsData}
        activeTab={activeTab.tabIndex}
        onTabChange={handleTabChange}
        commonComponent={tableComponent}
      />
    </PageLayout>
  );
};

Trainers.propTypes = {
  tableData: PropTypes.shape(withTableProps).isRequired,
};

export default withTable({
  defaultSorting: {
    field: 'id',
    dir: 'desc',
  },
  fetchingData: 'items',
  pageAlias,
})(Trainers);

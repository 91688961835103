import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import AppBreadcrumbs from 'components/AppBreadcrumbs';
import PageLayout from 'layouts/PageLayout';
import NotFound from 'pages/NotFound';
import { makeStyles } from '@material-ui/core/styles';
import { useAsyncFn } from 'react-use';
import { getLocalCourse, getGlobalCourse } from 'http/courses';
import { useRouteMatch } from 'react-router-dom';
import { routesAliases } from 'router/routes';
import { useTranslation } from 'react-i18next';
import StudentDetails from './StudentDetails';
import CourseForm from './Form';

const useStyles = makeStyles(theme => ({
  courseContent: {
    padding: theme.spacing(2, 5),
  },
  courseHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& .ContentTitle': {
      display: 'flex',
    },
  },
  pageContent: {
    padding: theme.spacing(4, 5),
  },
}));

const CourseView = ({ routeProps }) => {
  const routerMatch = useRouteMatch();
  const { t } = useTranslation();
  const { id: courseId, type: courseType, stationId } = routerMatch.params;
  const [notFoundError, setNotFoundError] = useState(false);
  const [initialFormState, setInitialFormState] = useState({});
  const [courseTemplateId, setCourseTemplateId] = useState('');
  const [isFrozen, setFrozen] = useState(false);
  const backUrl = routesAliases.trainerCourseList;
  const classes = useStyles();

  const breadCrumbsItems = [
    {
      title: t(routeProps.parentTitle),
      link: routeProps.parentPath,
    },
    {
      title: t(routeProps.title),
      isActive: true,
    },
  ];

  const [fetchCourseState, fetchCourseData] = useAsyncFn(async (id, currentStationId) => {
    let course;
    switch (courseType) {
      case 'global':
        course = await getGlobalCourse(courseId);
        break;
      case 'local':
        course = await getLocalCourse(currentStationId, id);
        break;
      default:
        setNotFoundError(true);
        break;
    }

    if (!course.error) {
      setCourseTemplateId(course.courseTemplateId);
      setFrozen(/^(in)\s/.test(moment(course.startDate).fromNow()));
      setInitialFormState({
        courseSystemId: course.courseSystemId,
        courseName: course.courseTemplate.name,
        maxStudents: course.maxStudents,
        location: course.location,
        description: course.description,
        jobsTasks: course.jobsTasks,
        startTime: `${course.startDate} ${course.startTime}`,
        endTime: `${course.startDate} ${course.endTime}`,
        startDate: course.startDate,
        endDate: course.endDate,
        expirationDate: course.expirationDate,
        trainers: course.trainers,
      });
    } else if (course.statusCode === 404) {
      setNotFoundError(true);
    }
  });

  useEffect(() => {
    fetchCourseData(courseId, stationId);
  }, []);

  return notFoundError ? (
    <NotFound />
  ) : (
    <PageLayout
      pageTitle={<AppBreadcrumbs items={breadCrumbsItems} />}
      classes={{ content: classes.pageContent }}
      showLoader={fetchCourseState.loading}
      additionSections={[
        {
          contentClass: classes.courseContent,
          contentHeaderClass: classes.courseHeader,
          contentHeader: t('courses.students'),
          component: (
            <StudentDetails
              isFrozen={isFrozen}
              courseTemplateId={courseTemplateId}
              courseId={courseId}
              courseType={courseType}
            />
          ),
        },
      ]}
    >
      <CourseForm initialValues={initialFormState} backUrl={backUrl} />
    </PageLayout>
  );
};

CourseView.propTypes = {
  routeProps: PropTypes.shape({
    parentPath: PropTypes.string,
    parentTitle: PropTypes.string,
    title: PropTypes.string,
  }).isRequired,
};

export default CourseView;

import React from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { actions as modalActions } from 'redux/modal';
import { Box, Grid, Container } from '@material-ui/core/';
import ChangeLang from 'components/ChangeLang';
import Modal from 'components/Modal';
import useStyles from './styles';

const AppLayout = ({ children }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { isOpen, config, Component } = useSelector(state => state.modal);

  const handleCloseModal = () => {
    dispatch(modalActions.closeModal());
  };

  return (
    <Grid className={classes.wrapper} container direction="column" justify="center" alignItems="stretch">
      <Grid item>
        <Container component="main" maxWidth="xs">
          <div className={classes.langBar}>
            <ChangeLang />
          </div>
          <Box>{children}</Box>
        </Container>
      </Grid>
      <Modal isOpen={isOpen} onClose={handleCloseModal} {...config}>
        {Component}
      </Modal>
    </Grid>
  );
};

AppLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AppLayout;

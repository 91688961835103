import { combineReducers } from 'redux';

import auth from './auth';
import modal from './modal';
import stations from './stations';
import table from './table';
import notifications from './notifications';

const rootReducer = combineReducers({
  auth,
  modal,
  stations,
  table,
  notifications,
});

export default rootReducer;

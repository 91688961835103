/* eslint-disable no-param-reassign */
import makeExtendedData from 'utils/makeExtendedStatisticsData';
import { getDeeperValue } from './helpers';

const setFullNameInArray = (data, fromField) => {
  if (Array.isArray(data)) {
    let fullName = '';
    const transformedItems = data.map(item => {
      const info = getDeeperValue(fromField, item);
      if (info) {
        fullName = `${info.firstName} ${info.lastName}`;
      }
      return {
        ...item,
        fullName,
      };
    });

    return transformedItems;
  }
  return data;
};

export const addFullNameField = (data, fromField) => {
  let returnData = data;
  if (data.items) {
    const transformedItems = setFullNameInArray(data.items, fromField);
    returnData = {
      ...data,
      items: transformedItems,
    };
  } else {
    returnData = setFullNameInArray(data, fromField);
  }

  return returnData;
};

export const tasksModifier = data => {
  const updatedData = data.jobTasks.map(item => {
    item.isPerformed = item.performed ? 'performed' : 'notPerformed';
    item.jobTask = item.name;
    return item.name;
  });
  return { ...data, updatedData };
};

export const dashboardModifier = data => ({
  ...data,
  ...data.table,
});

export const statisticsModifier = data => {
  const dataExtended = makeExtendedData(data.table.items);
  return {
    ...data,
    statData: {
      items: dataExtended,
      totalCount: data.table.count,
    },
  };
};

export const scheduledCoursesModifier = data => {
  let transformedScheduledCourses = [];

  if (data.global) {
    const globalCourses = data.global.map(course => ({
      ...course,
      courseType: 'global',
    }));

    transformedScheduledCourses = [...transformedScheduledCourses, ...globalCourses];
  }

  if (data.local) {
    const localCourses = data.local.map(course => ({
      ...course,
      courseType: 'local',
    }));
    transformedScheduledCourses = [...transformedScheduledCourses, ...localCourses];
  }

  return transformedScheduledCourses;
};

export const jobTasksModifier = data => {
  const jobTasks = data.items.map(item => ({
    ...item,
    fullName: `${item.updatedByUser.info.firstName} ${item.updatedByUser.info.lastName}`,
  }));
  return { ...data, items: [...jobTasks] };
};

export const pastCoursesModifier = data => {
  const dataExtended = data.items.map(item => ({
    ...item,
    buttonParams: {
      certificate: item.certificate,
      isPerformedWithSuccess: item.studentPerformedState ? item.studentPerformedState.isPerformedWithSuccess : null,
    },
  }));
  return { ...data, items: dataExtended };
};

export const documentsModifier = data => {
  const dataUpdated = data.map(item => ({
    ...item,
    byWhom: `${item.uploadedBy.info.firstName} ${item.uploadedBy.info.lastName}`,
  }));
  return { items: dataUpdated };
};

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  timePicker: {
    '& .MuiFormLabel-root': {
      position: 'relative',
      zIndex: 2,
      fontSize: 12,
      transform: 'none',
      marginBottom: 0,
      lineHeight: '30px',
    },

    '& label + .MuiInput-formControl': {
      marginTop: 0,
    },

    '&:hover': {
      '& fieldset': {
        borderColor: `${theme.palette.primary.main} !important`,
      },
    },
    '& .MuiInputAdornment-positionEnd': {
      position: 'relative',
      zIndex: 2,
    },
    '& input': {
      position: 'relative',
      zIndex: 2,
      padding: '11px 14px',
      minWidth: '84px',
    },
    '& fieldset': {
      backgroundColor: '#fff',
      border: '1px solid #d3d3d3',
      fontSize: 16,
      lineHeight: 0,
      zIndex: 1,
      '&:focus': {
        borderColor: theme.palette.primary.main,
      },
      '& legend': {
        width: '0 !important',
        display: 'block',
      },
    },
  },
  warningMessage: {
    color: theme.palette.secondary.main,
  },
}));

export default useStyles;

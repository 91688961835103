import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { IconButton, Icon } from '@material-ui/core';
import classNames from 'classnames';
import { getPaginationArray } from './helpers';

const useStyles = makeStyles(theme => ({
  root: {
    flexShrink: 0,
  },
  paginationContainer: {
    display: 'inline-block',
    verticalAlign: 'middle',
    '& button': {
      display: 'inline-block',
      backgroundColor: 'transparent',
      border: 'none',
      fontWeight: 500,
      textTransform: 'uppercase',
      height: '24px',
      minWidth: '24px',
      borderRadius: '24px',
      lineHeight: '24px',
      color: theme.palette.text.common,
      letterSpacing: '.03em',
      margin: '0 2px',

      '&:focus': {
        outline: 'none',
      },

      '&.activePage': {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
      },
    },
  },
  paginationItem: {
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
  },
  paginationDots: {
    fontSize: '16px',
  },
}));

const AppTablePagination = ({ count, page, rowsPerPage, onChangePage }) => {
  const classes = useStyles();
  const theme = useTheme();

  const pagesCount = Math.ceil(count / rowsPerPage);
  const paginationArray = getPaginationArray(page + 1, pagesCount);

  const handleBackButtonClick = event => {
    onChangePage(event, page - 1);
  };

  const handleNextButtonClick = event => {
    onChangePage(event, page + 1);
  };

  const handlePageClick = event => {
    onChangePage(event, event.currentTarget.dataset.page - 1);
  };

  return (
    <div className={classes.root}>
      <IconButton onClick={handleBackButtonClick} disabled={page === 0} color="primary" size="small">
        {theme.direction === 'rtl' ? <Icon>keyboard_arrow_right</Icon> : <Icon>keyboard_arrow_left</Icon>}
      </IconButton>
      <div className={classes.paginationContainer}>
        {paginationArray.map((pageNumber, index) => {
          const dots = (
            <button type="button" className={classes.paginationDots}>
              &hellip;
            </button>
          );
          const pageButton = (
            <button
              type="button"
              key={pageNumber}
              data-page={pageNumber}
              className={classNames({
                [`${classes.paginationItem}`]: true,
                activePage: pageNumber === page + 1,
              })}
              onClick={handlePageClick}
            >
              {pageNumber}
            </button>
          );

          if (index === 1 && pageNumber > 2) {
            return (
              <React.Fragment key={pageNumber}>
                {dots}
                {pageButton}
              </React.Fragment>
            );
          }

          if (index === paginationArray.length - 2 && pageNumber < pagesCount - 1) {
            return (
              <React.Fragment key={pageNumber}>
                {pageButton}
                {dots}
              </React.Fragment>
            );
          }

          return pageButton;
        })}
      </div>
      <IconButton onClick={handleNextButtonClick} disabled={page >= pagesCount - 1} color="primary" size="small">
        {theme.direction === 'rtl' ? <Icon>keyboard_arrow_left</Icon> : <Icon>keyboard_arrow_right</Icon>}
      </IconButton>
    </div>
  );
};

AppTablePagination.propTypes = {
  count: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
};

export default AppTablePagination;

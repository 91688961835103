import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  formWrap: {
    margin: '20px 0 0',
  },
  btnContainer: {
    marginTop: '52px',
    marginBottom: 0,
  },
  btn: {
    minWidth: '140px',
  },
  column: {
    width: '33%',
    '&:first-child': {
      paddingRight: '30px',
    },
    '&:last-child': {
      paddingLeft: '30px',
    },
    '&:nth-child(2)': {
      padding: '0 15px',
    },
  },
}));

export default useStyles;

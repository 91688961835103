import React from 'react';
import PropTypes from 'prop-types';
import AppTable from 'components/AppTable';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { transformTableData, getConfirmationModalConfig } from 'utils';
import { useAsyncFn } from 'react-use';
import { actions as modalActions } from 'redux/modal';
import { toggleCompanyState } from 'http/companies';
import { useDispatch } from 'react-redux';
import Loader from 'components/Loader';
import withTableProps from 'HOC/types/withTable';
import getTableConfig from './helpers/getTableConfig';

const CompaniesTable = ({ tableData, routesAliases }) => {
  const { t } = useTranslation('common');
  const dispatch = useDispatch();
  const history = useHistory();

  const handleCloseModal = () => dispatch(modalActions.closeModal());

  const [companyState, setCompanyState] = useAsyncFn(async (id, enable) => {
    const requestData = await toggleCompanyState(id, enable);

    if (!requestData.error) {
      handleCloseModal();
      tableData.handleForceUpdate();
    }
  });

  const handleToggleState = event => {
    event.stopPropagation();
    const id = event.currentTarget.dataset.key;
    const isEnable = JSON.parse(event.currentTarget.dataset.val);
    const enable = isEnable ? 'disable' : 'enable';
    const message = isEnable ? t('company.deactivationApproval') : t('company.activationApproval');

    const [config, element] = getConfirmationModalConfig({
      title: t('company.modalTitle'),
      confirmAction: () => setCompanyState(id, enable),
      rejectAction: handleCloseModal,
      message,
    });
    dispatch(modalActions.openModal(config, element));
  };

  const tableConfig = getTableConfig({ handleToggleState });
  const [columns, users] = transformTableData(tableConfig, tableData.data);

  const isLoading = tableData.isFetchingTableData || companyState.loading;

  return (
    <>
      <AppTable
        columns={columns}
        data={users}
        totalCount={tableData.totalCount}
        rowsPerPage={tableData.pageSize}
        onChangePage={tableData.handleChangePage}
        onColumnSorting={tableData.handleSorting}
        currentPage={tableData.page}
        actionsAlignment="center"
        isLazyLoading
        actions={[
          {
            type: 'icon',
            icon: 'edit',
            onClick: event => history.push(`${routesAliases.editCompany}/${event.currentTarget.dataset.key}`),
          },
        ]}
      />
      {isLoading && <Loader />}
    </>
  );
};

CompaniesTable.propTypes = {
  tableData: PropTypes.shape(withTableProps).isRequired,
  routesAliases: PropTypes.shape({
    editCompany: PropTypes.string.isRequired,
  }).isRequired,
};

export default CompaniesTable;

import React from 'react';
import { DateFormatter } from 'components/AppTable/CellsComponents';
import i18next from 'i18next';

export const getTableConfig = () => {
  return [
    {
      title: i18next.t('tablesLabels.id'),
      field: 'sid',
    },
    {
      title: i18next.t('tablesLabels.course'),
      field: 'name',
      isSorting: true,
    },
    {
      title: i18next.t('tablesLabels.updatedBy'),
      field: 'updatedBy',
      isSorting: true,
    },
    {
      title: i18next.t('tablesLabels.maxStudents'),
      field: 'maxStudents',
      alignment: 'center',
    },
    {
      title: i18next.t('tablesLabels.lastUpdate'),
      field: 'updatedAt',
      Component: value => <DateFormatter value={value} />,
      isSorting: true,
    },
    {
      title: '',
      field: 'id',
      isVisible: false,
    },
    {
      title: '',
      field: 'stationId',
      isVisible: false,
    },
  ];
};

export const getAdminTableConfig = () => {
  return [
    {
      title: i18next.t('tablesLabels.id'),
      field: 'sid',
    },
    {
      title: i18next.t('tablesLabels.course'),
      field: 'name',
      isSorting: true,
    },
    {
      title: i18next.t('tablesLabels.jobsTasks'),
      field: 'jobTasks',
      isSorting: true,
    },
    {
      title: i18next.t('tablesLabels.maxStudents'),
      field: 'maxStudents',
      alignment: 'center',
    },
    {
      title: i18next.t('tablesLabels.lastUpdate'),
      field: 'updatedAt',
      Component: value => <DateFormatter value={value} />,
      isSorting: true,
    },
    {
      title: '',
      field: 'id',
      isVisible: false,
    },
    {
      title: '',
      field: 'stationId',
      isVisible: false,
    },
  ];
};

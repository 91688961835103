import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, IconButton, Icon } from '@material-ui/core';
import PageSelect from 'components/Selects/PageSelect';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: theme.spacing(3),
  },
  navigationSection: {
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.text.grey,
  },
  navigationLabel: {
    marginLeft: theme.spacing(2),
    fontSize: '22px',
  },
}));

const Toolbar = props => {
  const classes = useStyles();
  const { views, label, onNavigate, onView, view } = props;
  const { t } = useTranslation('common');
  const selectValues = views.map(viewTitle => ({
    label: t(`schedule.${viewTitle}`),
    value: viewTitle,
  }));

  return (
    <div className={classes.root}>
      <div className={classes.navigationSection}>
        <div>
          <IconButton
            size="small"
            onClick={() => {
              onNavigate('PREV');
            }}
          >
            <Icon>navigate_before</Icon>
          </IconButton>
          <IconButton
            size="small"
            onClick={() => {
              onNavigate('NEXT');
            }}
          >
            <Icon>navigate_next</Icon>
          </IconButton>
        </div>
        <div className={classes.navigationLabel}>{label}</div>
      </div>
      <div>
        <PageSelect
          options={selectValues}
          onChangeValue={value => {
            onView(value);
          }}
          selectedOption={view}
        />
      </div>
    </div>
  );
};

Toolbar.propTypes = {
  views: PropTypes.arrayOf(PropTypes.string).isRequired,
  label: PropTypes.string.isRequired,
  onNavigate: PropTypes.func.isRequired,
  onView: PropTypes.func.isRequired,
  view: PropTypes.string.isRequired,
};

export default Toolbar;

/* eslint-disable no-param-reassign */
import Joi from '@hapi/joi';
import i18next from 'i18next';

const schema = Joi.object({
  firstName: Joi.string()
    .min(2)
    .max(30)
    .required(),
  lastName: Joi.string()
    .min(2)
    .max(30)
    .required(),
  email: Joi.string()
    .email({ tlds: false })
    .required(),
  isExternal: Joi.boolean().required(),
  isComplaint: Joi.boolean().required(),
  company: Joi.string()
    .allow('', null)
    .max(30),
  country: Joi.string()
    .allow('', null)
    .max(30),
  phone: Joi.string()
    .allow('', null)
    .max(30),
  ids: Joi.string()
    .min(1)
    .max(30)
    .required(),
  courseTemplates: Joi.array()
    .items(
      Joi.object({
        label: Joi.string().required(),
        value: Joi.number().required(),
      })
        .min(1)
        .required(),
    )
    .required(),
});

const validate = values => {
  const { error } = schema.validate(values, { abortEarly: false });

  if (!error) {
    return {};
  }

  const { details } = error;

  return details.reduce((errors, detail) => {
    switch (detail.context.key) {
      case 'firstName':
        errors[detail.context.key] = i18next.t('errors.firstNameIsRequired');
        break;
      case 'lastName':
        errors[detail.context.key] = i18next.t('errors.lastNameIsRequired');
        break;
      case 'email':
        if (detail.context.value === undefined || detail.context.value === '') {
          errors[detail.context.key] = i18next.t('errors.emailIsRequired');
        } else {
          errors[detail.context.key] = i18next.t('errors.emailIsInvalid');
        }
        break;
      case 'ids':
        errors[detail.context.key] = i18next.t('errors.idNumberIsRequired');
        break;
      case 'courseTemplates':
        errors[detail.context.key] = i18next.t('errors.courseTemplateIsRequired');
        break;
      default:
        break;
    }
    return errors;
  }, {});
};

export default validate;

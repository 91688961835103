import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import AppBreadcrumbs from 'components/AppBreadcrumbs';
import PageLayout from 'layouts/PageLayout';
import { useAsyncFn } from 'react-use';
import { makeStyles } from '@material-ui/core/styles';
import {
  getUserDocuments,
  setUserDocuments,
  downloadUserDocument,
  deleteUserDocument,
  getUser,
  updateUser,
  setUserCertificate,
  getUserCertificate,
  deleteUserCertificate,
  downloadUserCertificate,
} from 'http/users';
import { useRouteMatch, useHistory, useLocation } from 'react-router-dom';
import { routesAliases } from 'router/routes';
import NotFound from 'pages/NotFound';
import { useTranslation } from 'react-i18next';
import TrainersForm from '../Form';
import TrainerDetails from './TrainerDetails/index';

const useStyles = makeStyles(theme => ({
  pageContent: {
    padding: theme.spacing(4, 5),
  },
}));

let historyState;

const Edit = ({ routeProps }) => {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const { t } = useTranslation('common');
  const breadCrumbsItems = [
    {
      title: t(routeProps.parentTitle),
      link: routeProps.parentPath,
    },
    {
      title: t(routeProps.title),
      isActive: true,
    },
  ];

  const routerMatch = useRouteMatch();
  const { id: userId } = routerMatch.params;

  const backUrl = routesAliases.trainersList;

  if (history.action === 'PUSH') {
    historyState = location.state;
  }

  let trainerType = '';

  if (historyState && historyState.trainerType) {
    trainerType = historyState.trainerType;
  }

  const actions = {
    getDocuments: getUserDocuments.bind(null, userId),
    setDocuments: setUserDocuments.bind(null, userId),
    downloadDocument: downloadUserDocument,
    deleteDocument: deleteUserDocument,
    getUser: getUser.bind(null, userId),
    getCertificates: getUserCertificate.bind(null, userId),
    setCertificates: setUserCertificate,
    deleteCertificate: deleteUserCertificate,
    downloadCertificate: downloadUserCertificate,
  };

  const [notFoundError, setNotFoundError] = useState(false);
  const [initialFormState, setInitialFormState] = useState(false);
  const [chosenCourseTemplate, setChosenCourseTemplate] = useState([]);

  const [fetchUserState, fetchUserData] = useAsyncFn(async () => {
    const userData = await getUser(userId);
    if (!userData.error) {
      setInitialFormState({
        ids: (userData.info && userData.info.ids) || '',
        firstName: (userData.info && userData.info.firstName) || '',
        lastName: (userData.info && userData.info.lastName) || '',
        email: userData.email || '',
        company: (userData.info && userData.info.info && userData.info.info.company) || '',
        country: (userData.info && userData.info.info && userData.info.info.country) || '',
        phone: (userData.info && userData.info.info && userData.info.info.phone) || '',
        isExternal: (userData.trainerStatus && userData.trainerStatus.isExternal) || false,
        isComplaint: (userData.trainerStatus && userData.trainerStatus.isComplaint) || false,
        courseTemplates: userData.courseTemplates,
        stationsIds: userData.stations.map(item => item.id),
      });
    } else if (userData.statusCode === 404) {
      setNotFoundError(true);
      return false;
    }
    return true;
  });

  useEffect(() => {
    fetchUserData(userId);
  }, []);

  const [state, editTrainer] = useAsyncFn(async formData => {
    const data = await updateUser(formData, userId);
    if (!data.error) {
      history.push(routesAliases.trainersList, { trainerType });
    }
    return data;
  }, []);

  return notFoundError ? (
    <NotFound />
  ) : (
    <PageLayout
      pageTitle={<AppBreadcrumbs items={breadCrumbsItems} />}
      classes={{ content: classes.pageContent }}
      additionSections={[
        {
          contentClass: classes.courseDetailsContent,
          contentHeader: t('trainers.trainerDetails'),
          component: (
            <TrainerDetails
              userId={userId}
              chosenCourseTemplate={chosenCourseTemplate}
              helpers={{
                getDocuments: actions.getDocuments,
                setDocuments: actions.setDocuments,
                downloadDocument: actions.downloadDocument,
                deleteDocument: actions.deleteDocument,
                getCertificates: actions.getCertificates,
                setCertificates: actions.setCertificates,
                deleteCertificate: actions.deleteCertificate,
                downloadCertificate: actions.downloadCertificate,
              }}
            />
          ),
        },
      ]}
      showLoader={fetchUserState.loading}
    >
      <TrainersForm
        isLoading={state.loading}
        onSubmit={editTrainer}
        trainersData={initialFormState}
        routesAliases={routesAliases}
        backUrl={backUrl}
        trainerType={trainerType}
        setChosenCourseTemplate={setChosenCourseTemplate}
      />
    </PageLayout>
  );
};

Edit.propTypes = {
  routeProps: PropTypes.shape({
    parentPath: PropTypes.string,
    parentTitle: PropTypes.string,
    title: PropTypes.string,
  }).isRequired,
};

export default Edit;

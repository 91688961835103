import React from 'react';
import PropTypes from 'prop-types';
import Tabulation from 'components/Tabulation';
import {
  getLocalCourseDocuments,
  getGlobalCourseDocuments,
  downloadGlobalCourseDocument,
  downloadLocalCourseDocument,
} from 'http/courses';
import { getCourseTemplatesDocuments, downloadCourseTemplateDocument } from 'http/courseTemplates';
import { useSelector } from 'react-redux';
import DocumentsTable from './DocumentsTable';
import StudentsTable from './StudentsTable';

const CourseDetails = ({ courseId, courseType, courseTemplateId, isFrozen }) => {
  const { id: stationId } = useSelector(state => state.auth.profile.station);
  const actions = {
    local: {
      getDocuments: getLocalCourseDocuments.bind(null, stationId, courseId),
      downloadDocument: downloadLocalCourseDocument,
    },
    global: {
      getDocuments: getGlobalCourseDocuments.bind(null, courseId),
      downloadDocument: downloadGlobalCourseDocument,
    },
  };

  const StudentsTableComponent = <StudentsTable courseId={courseId} isFrozen={isFrozen} />;

  const DocumentsTableComponent = (
    <DocumentsTable
      courseId={courseId}
      courseType={courseType}
      stationId={stationId}
      helpers={{
        getDocuments: actions[courseType].getDocuments,
        downloadDocument: actions[courseType].downloadDocument,
        getCourseTemplatesDocuments,
        downloadCourseTemplateDocument,
      }}
      courseTemplateId={courseTemplateId}
    />
  );

  return (
    <Tabulation
      tabsData={[
        {
          label: 'Students',
          alias: 'certifications',
          Component: StudentsTableComponent,
        },
        {
          label: 'Documents',
          alias: 'documents',
          Component: DocumentsTableComponent,
        },
      ]}
    />
  );
};

CourseDetails.propTypes = {
  courseId: PropTypes.string.isRequired,
  courseType: PropTypes.string.isRequired,
  courseTemplateId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  isFrozen: PropTypes.bool.isRequired,
};

export default CourseDetails;

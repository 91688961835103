import { http } from '../index';

export const getNotificationsList = params => {
  return http
    .get('/notifications/list', { params })
    .then(res => res.data)
    .catch(error => error.response.data);
};

export const getNewNotifications = () => {
  return http
    .get('/notifications/new')
    .then(res => res.data)
    .catch(error => error.response.data);
};

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Form, Field } from 'react-final-form';
import { Grid, Box, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CustomTextField from 'components/Forms/TextField';
import CustomDatePicker from 'components/Forms/CustomDatePicker';
import CustomTimePicker from 'components/Forms/CustomTimePicker';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
  container: {
    flexWrap: 'nowrap',
    [theme.breakpoints.down('md')]: {
      flexWrap: 'wrap',
      justifyContent: 'flex-start',
    },
  },
  btnContainer: {
    marginTop: '40px',
  },
  btn: {
    minWidth: '140px',
  },
  gridContainer: {
    marginTop: '40px',
  },
  chipsSection: {
    color: 'rgba(0, 0, 0, 0.54)',
  },
  chipsLabel: {
    zIndex: 2,
    position: 'relative',
    fontSize: '12px',
    lineHeight: '30px',
    transform: 'none',
    marginBottom: 0,
  },
  chipsItem: {
    padding: '11px 14px',
    borderRadius: '25px',
    border: '1px solid #d3d3d3',
  },
}));

const CourseForm = ({ initialValues, backUrl }) => {
  const history = useHistory();
  const classes = useStyles();
  const { t } = useTranslation('common');
  const [formData, setFormData] = useState({});

  useEffect(() => {
    setFormData(initialValues);
  }, [initialValues]);

  return (
    <Form
      onSubmit={() => {}}
      initialValues={formData}
      render={({ values }) => {
        return (
          <form>
            <Grid container className="mainSection">
              <Grid container justify="space-between" spacing={4} className={classes.container}>
                <Grid item xs={3} md={4}>
                  <Field
                    type="text"
                    id="startTime"
                    name="startTime"
                    label={t('formLabels.startTime')}
                    disabled
                    variant="outlined"
                    fullWidth
                    component={CustomTimePicker}
                  />
                </Grid>
                <Grid item xs={3} md={4}>
                  <Field
                    type="text"
                    id="endTime"
                    name="endTime"
                    label={t('formLabels.endTime')}
                    disabled
                    variant="outlined"
                    fullWidth
                    component={CustomTimePicker}
                  />
                </Grid>
                <Grid item xs={3} md={4}>
                  <Field
                    type="text"
                    id="startDate"
                    name="startDate"
                    label={t('formLabels.startDate')}
                    disabled
                    variant="outlined"
                    minDate={new Date()}
                    maxDate={values.endDate}
                    fullWidth
                    component={CustomDatePicker}
                  />
                </Grid>
                <Grid item xs={3} md={4}>
                  <Field
                    type="text"
                    id="endDate"
                    name="endDate"
                    label={t('formLabels.endDate')}
                    disabled
                    variant="outlined"
                    minDate={values.startDate || new Date()}
                    fullWidth
                    component={CustomDatePicker}
                  />
                </Grid>
                <Grid item xs={3} md={4}>
                  <Field
                    type="text"
                    id="expirationDate"
                    name="expirationDate"
                    label={t('formLabels.expirationDate')}
                    disabled
                    variant="outlined"
                    fullWidth
                    minDate={values.endDate || new Date()}
                    component={CustomDatePicker}
                  />
                </Grid>
              </Grid>
              <Grid container justify="space-between" className={classes.gridContainer} spacing={4}>
                <Grid container item xs={4} direction="column" justify="flex-start">
                  <Grid container item justify="space-between" spacing={2}>
                    <Grid item xs={12} lg={8}>
                      <Field
                        type="text"
                        id="courseName"
                        name="courseName"
                        label={t('formLabels.courseName')}
                        variant="outlined"
                        fullWidth
                        disabled
                        component={CustomTextField}
                      />
                    </Grid>
                    <Grid item xs={12} lg={4}>
                      <Field
                        type="text"
                        id="maxStudents"
                        name="maxStudents"
                        label={t('formLabels.maxStudents')}
                        variant="outlined"
                        fullWidth
                        disabled
                        component={CustomTextField}
                      />
                    </Grid>
                  </Grid>
                  <Grid container item>
                    <Box mt={2} width="100%">
                      <Field
                        type="text"
                        id="description"
                        name="description"
                        label={t('formLabels.description')}
                        variant="outlined"
                        fullWidth
                        multiline
                        rows="4"
                        disabled
                        component={CustomTextField}
                      />
                    </Box>
                  </Grid>
                </Grid>
                <Grid container item xs={4} direction="column" justify="flex-start">
                  <Grid container item>
                    <Field
                      type="text"
                      id="location"
                      name="location"
                      label={t('formLabels.location')}
                      variant="outlined"
                      fullWidth
                      disabled
                      component={CustomTextField}
                    />
                  </Grid>
                  <Grid container item justify="space-between">
                    {!values.jobsTasks || !values.jobsTasks.length ? null : (
                      <Box mt={2}>
                        <div className={classes.chipsSection}>
                          <div className={classes.chipsLabel}>{t('formLabels.jobsTasks')}</div>
                          <Grid container>
                            {values.jobsTasks.map(item => {
                              return (
                                <Grid item key={item.id}>
                                  <Box mr={2} mb={1}>
                                    <div className={classes.chipsItem}>{item.name}</div>
                                  </Box>
                                </Grid>
                              );
                            })}
                          </Grid>
                        </div>
                      </Box>
                    )}
                  </Grid>
                </Grid>
                <Grid container item xs={4} justify="space-between">
                  {!values.trainers || !values.trainers.length ? null : (
                    <Grid container item align="flex-end">
                      <div className={classes.chipsSection}>
                        <div className={classes.chipsLabel}>{t('formLabels.trainers')}</div>
                        <Grid container>
                          {values.trainers.map(item => {
                            return (
                              <Grid item key={item.id}>
                                <Box mr={2} mb={1}>
                                  <div className={classes.chipsItem}>
                                    {`${item.info.firstName} ${item.info.lastName}`}
                                  </div>
                                </Box>
                              </Grid>
                            );
                          })}
                        </Grid>
                      </div>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Grid container spacing={3} justify="center" className={classes.btnContainer}>
              <Grid item xs={12}>
                <Box textAlign="center">
                  <Button
                    className={classes.btn}
                    variant="contained"
                    size="large"
                    color="primary"
                    type="reset"
                    onClick={() => {
                      history.push(backUrl);
                    }}
                  >
                    {t('buttons.back')}
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </form>
        );
      }}
    />
  );
};

CourseForm.defaultProps = {
  initialValues: null,
};

CourseForm.propTypes = {
  initialValues: PropTypes.shape({}),
  backUrl: PropTypes.string.isRequired,
};

export default CourseForm;

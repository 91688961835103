import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { makeStyles } from '@material-ui/core/styles';
import {
  NoOptionsMessage,
  Control,
  Option,
  Placeholder,
  SingleValue,
  ValueContainer,
  MultiValue,
  Menu,
} from './selectComponents';

const components = {
  Control,
  Menu,
  MultiValue,
  NoOptionsMessage,
  Option,
  Placeholder,
  SingleValue,
  ValueContainer,
};

const useStyles = makeStyles(theme => ({
  root: {
    '& label': {
      lineHeight: '30px',
    },
    '& label + .MuiInput-formControl': {
      marginTop: '30px',
    },
    '& .MuiInput-underline.Mui-error:after': {
      border: `1px solid ${theme.palette.error.main}`,
    },
    '& .MuiInput-underline:before': {
      display: 'none',
    },
    '& .MuiInput-underline:after': {
      top: 0,
      border: `2px solid ${theme.palette.primary.main}`,
      borderRadius: '25px',
      transition: 'unset',
    },
  },
  input: {
    display: 'flex',
    height: 'unset',
    padding: '2.5px 14px 2.5px 10px',
    border: '1px solid #d3d3d3',
    borderRadius: '25px',

    '&:hover': {
      borderColor: theme.palette.primary.main,
    },
  },
  valueContainer: {
    minWidth: '100px',
    display: 'flex',
    flexWrap: 'wrap',
    flex: 1,
    alignItems: 'center',
    overflow: 'hidden',
  },
  chip: {
    margin: theme.spacing(0.5, 0.2),
    background: theme.palette.primary.light,
    color: theme.palette.primary.contrastText,
    [theme.breakpoints.down('lg')]: {
      maxWidth: '100%',
    },
    [theme.breakpoints.up('lg')]: {
      maxWidth: '165px',
    },
  },
  noOptionsMessage: {
    padding: theme.spacing(1, 2),
  },
  singleValue: {
    fontSize: 16,
  },
  placeholder: {
    fontSize: 16,
  },
  paper: {
    boxShadow: `${theme.shadows[1]} !important`,
    position: 'absolute',
    zIndex: 4,
    marginTop: theme.spacing(1),
    left: 0,
    right: 0,
    borderRadius: '10px',
  },
  divider: {
    height: theme.spacing(2),
  },
  warningMessage: {
    color: theme.palette.secondary.main,
  },
}));

const CustomSelect = ({ input, label, meta, warningMessage, isDisabled, onWrapperClick, ...rest }) => {
  const classes = useStyles();
  const serverError = !meta.dirtySinceLastSubmit && meta.submitError;
  const isError = (meta.error || serverError) && meta.touched;

  const isWarning = warningMessage && isDisabled;
  const errorMessage = isError && (meta.error || serverError);

  const warningTextComponent = <span className={classes.warningMessage}>{warningMessage}</span>;

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events
    <div className={classes.root} tabIndex="0" role="button" onClick={onWrapperClick}>
      <Select
        classes={classes}
        textFieldProps={{
          label,
          error: isError,
          helperText: errorMessage || (isWarning && warningTextComponent),
          InputLabelProps: {
            shrink: true,
          },
        }}
        components={components}
        isClearable
        isDisabled={isDisabled}
        {...input}
        {...rest}
      />
    </div>
  );
};

CustomSelect.defaultProps = {
  onWrapperClick: () => {},
  isDisabled: false,
  warningMessage: undefined,
};

CustomSelect.propTypes = {
  input: PropTypes.shape({
    name: PropTypes.string,
    type: PropTypes.string.isRequired,
  }).isRequired,
  label: PropTypes.string.isRequired,
  meta: PropTypes.shape().isRequired,
  id: PropTypes.string.isRequired,
  onWrapperClick: PropTypes.func,
  isDisabled: PropTypes.bool,
  warningMessage: PropTypes.string,
};

export default CustomSelect;

/* eslint-disable no-param-reassign */
import React, { lazy, useState, useEffect } from 'react';
import CustomTextField from 'components/Forms/TextField';
import CustomRadio from 'components/Forms/Radio';
import CustomSelect from 'components/Forms/CustomSelect';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Form, Field, FormSpy } from 'react-final-form';
import { Box, Grid, Button } from '@material-ui/core';
import { useAsyncFn } from 'react-use';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { transformDataToSelectOptions, generateErrorObject, transformDataCountriesToSelectOptions } from 'utils';
import { getCourseTemplates } from 'http/courseTemplates';
import { getCountries } from 'http/info';
import configErrors from './configErrors';
import validate from './validation';
import useStyles from './styles';

const Loader = lazy(() => import('components/Loader'));

const TrainersForm = ({ onSubmit, isLoading, trainersData, trainerType, backUrl, setChosenCourseTemplate }) => {
  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation('common');

  const { station } = useSelector(state => state.auth.profile);
  const stationId = station ? station.id : undefined;

  const [formData, setFormData] = useState(trainersData || {});
  const [currentStationId, setCurrentStationId] = useState(stationId);
  const [formControlsOptions, setFormControlsOptions] = useState({
    courseTemplates: [],
    countries: [],
  });

  const [formControlsState, getFormControlsOptions] = useAsyncFn(async () => {
    const templates = await getCourseTemplates();
    const countries = await getCountries();

    const optionsObj = {
      courseTemplates: [],
      countries: [],
    };

    if (templates.items) {
      optionsObj.courseTemplates = transformDataToSelectOptions(templates.items, 'id', 'name');
    }

    if (countries && !countries.error) {
      optionsObj.countries = transformDataCountriesToSelectOptions(countries);
    }

    setFormControlsOptions({
      ...formControlsOptions,
      ...optionsObj,
    });
  });

  useEffect(() => {
    if (trainersData) {
      setFormData({
        ...trainersData,
        courseTemplates: transformDataToSelectOptions(trainersData.courseTemplates, 'id', 'name'),
        country: transformDataCountriesToSelectOptions(trainersData.country),
      });
      setChosenCourseTemplate(trainersData.courseTemplates);
    }
    return () => {
      trainersData = {};
    };
  }, [trainersData]);

  useEffect(() => {
    getFormControlsOptions();
  }, []);

  useEffect(() => {
    setCurrentStationId(station.id);
  }, [station]);

  const { isExternal = 'false', isComplaint = 'true' } = formData;

  const handleChangeRadio = (name, val) => {
    setFormData({ ...formData, [name]: val });
  };

  const handleFormSubmit = async formValues => {
    const transformedFormData = {
      ...formValues,
      courseTemplateIds: formValues.courseTemplates.map(item => item.value),
      country: formValues.country.value,
      isComplaint: JSON.parse(isComplaint),
      isExternal: JSON.parse(isExternal),
    };

    delete transformedFormData.courseTemplates;

    const submitData = await onSubmit(transformedFormData, currentStationId);
    if (submitData && submitData.error && submitData.message) {
      return generateErrorObject(submitData.message, configErrors);
    }

    setChosenCourseTemplate(formValues.courseTemplates);

    return undefined;
  };

  return (
    <Form
      onSubmit={handleFormSubmit}
      validate={validate}
      initialValues={formData}
      keepDirtyOnReinitialize
      render={({ handleSubmit, submitting }) => (
        <form onSubmit={handleSubmit}>
          <FormSpy
            onChange={({ modified, values: formValues }) => {
              if (modified.isExternal) {
                handleChangeRadio('isExternal', formValues.isExternal);
              }

              if (modified.isComplaint) {
                handleChangeRadio('isComplaint', formValues.isComplaint);
              }
            }}
          />
          <Box className={classes.formWrap} display="flex">
            <Box className={classes.generalInfo}>
              <Box width="100%" mb={4}>
                <Field
                  type="radio"
                  id="isExternal"
                  name="isExternal"
                  label={t('formLabels.type')}
                  disabled={submitting}
                  fullWidth
                  component={CustomRadio}
                  items={[
                    { name: t('trainers.trainer'), value: 'false' },
                    { name: t('trainers.externalProvider'), value: 'true' },
                  ]}
                  value={isExternal}
                  defaultValue={isExternal}
                />
              </Box>

              <Box width="100%" mb={4}>
                <Field
                  type="text"
                  id="firstName"
                  name="firstName"
                  label={t('formLabels.firstName')}
                  disabled={submitting}
                  variant="outlined"
                  fullWidth
                  component={CustomTextField}
                  format={(value = '') => value.trim()}
                  formatOnBlur
                />
              </Box>

              <Box width="100%" mb={4}>
                <Field
                  type="text"
                  id="lastName"
                  name="lastName"
                  label={t('formLabels.lastName')}
                  disabled={submitting}
                  variant="outlined"
                  fullWidth
                  component={CustomTextField}
                  format={(value = '') => value.trim()}
                  formatOnBlur
                />
              </Box>

              <Box width="100%" mb={4}>
                <Field
                  type="email"
                  id="email"
                  name="email"
                  label={t('formLabels.email')}
                  disabled={submitting}
                  variant="outlined"
                  fullWidth
                  component={CustomTextField}
                  format={(value = '') => value.trim()}
                  formatOnBlur
                />
              </Box>

              <Box width="100%" mb={0}>
                <Field
                  type="text"
                  id="ids"
                  name="ids"
                  label={t('formLabels.idNumber')}
                  disabled={submitting}
                  variant="outlined"
                  fullWidth
                  component={CustomTextField}
                  format={(value = '') => value.trim()}
                  formatOnBlur
                />
              </Box>
            </Box>

            <Box className={classes.additionalInfo}>
              <Box width="100%" mb={4}>
                <Field
                  type="radio"
                  id="isComplaint"
                  name="isComplaint"
                  label={t('formLabels.state')}
                  disabled={submitting}
                  fullWidth
                  component={CustomRadio}
                  items={[
                    { name: t('trainers.compliant'), value: 'true' },
                    { name: t('trainers.notCompliant'), value: 'false' },
                  ]}
                  value={isComplaint}
                  defaultValue={isComplaint}
                />
              </Box>

              <Box width="100%" mb={4}>
                <Field
                  type="text"
                  id="company"
                  name="company"
                  label={t('formLabels.company')}
                  disabled={submitting}
                  variant="outlined"
                  fullWidth
                  component={CustomTextField}
                  format={(value = '') => value.trim()}
                  formatOnBlur
                />
              </Box>

              <Box width="100%" mb={4}>
                <Field
                  type="text"
                  id="country"
                  name="country"
                  label={t('formLabels.country')}
                  variant="outlined"
                  placeholder={t('placeholders.country')}
                  options={formControlsOptions.countries}
                  fullWidth
                  isMulti={false}
                  component={CustomSelect}
                />
              </Box>

              <Box width="100%" mb={4}>
                <Field
                  type="text"
                  id="phone"
                  name="phone"
                  label={t('formLabels.phone')}
                  disabled={submitting}
                  variant="outlined"
                  fullWidth
                  component={CustomTextField}
                  format={(value = '') => value.trim()}
                  formatOnBlur
                />
              </Box>

              <Box width="100%" mb={0}>
                <Field
                  type="text"
                  id="courseTemplates"
                  name="courseTemplates"
                  label={t('formLabels.courseTemplate')}
                  variant="outlined"
                  placeholder={t('placeholders.chooseTemplate')}
                  options={formControlsOptions.courseTemplates}
                  fullWidth
                  isMulti
                  component={CustomSelect}
                />
              </Box>
            </Box>
          </Box>

          <Grid container spacing={3} className={classes.btnContainer}>
            <Grid item xs={6}>
              <Box textAlign="right">
                <Button
                  className={classes.btn}
                  type="submit"
                  color="primary"
                  variant="contained"
                  disabled={submitting}
                  size="large"
                >
                  {t('buttons.save')}
                </Button>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box textAlign="left">
                <Button
                  className={classes.btn}
                  variant="outlined"
                  size="large"
                  type="reset"
                  onClick={() => {
                    history.push(backUrl, {
                      trainerType,
                    });
                  }}
                >
                  {t('buttons.cancel')}
                </Button>
              </Box>
            </Grid>
          </Grid>

          {(isLoading || formControlsState.loading) && <Loader />}
        </form>
      )}
    />
  );
};

TrainersForm.defaultProps = {
  trainersData: {
    firstName: '',
    lastName: '',
    email: '',
    company: '',
    country: '',
    phone: '',
    isComplaint: 'true',
    isExternal: 'false',
  },
  setChosenCourseTemplate: () => {},
};

TrainersForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  trainersData: PropTypes.oneOfType([
    PropTypes.shape({
      firstName: PropTypes.string,
      lastName: PropTypes.string,
      email: PropTypes.string,
      company: PropTypes.string,
      country: PropTypes.string,
      phone: PropTypes.string,
      isComplaint: PropTypes.bool,
      isExternal: PropTypes.bool,
    }),
    PropTypes.bool,
  ]),
  backUrl: PropTypes.string.isRequired,
  trainerType: PropTypes.string.isRequired,
  setChosenCourseTemplate: PropTypes.func,
};

export default TrainersForm;

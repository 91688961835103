import React from 'react';
import i18next from 'i18next';
import { DateFormatter } from 'components/AppTable/CellsComponents';

const getTableConfig = () => {
  return [
    {
      title: i18next.t('tablesLabels.date'),
      field: 'createdAt',
      columnWidth: '200px',
      Component: value => <DateFormatter value={value} format="MM/DD/YYYY LT" />,
    },
    {
      title: i18next.t('tablesLabels.message'),
      field: 'message',
      Component: value => <span dangerouslySetInnerHTML={{ __html: value }} />,
    },
  ];
};

export default getTableConfig;

import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Icon } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    color: theme.palette.text.grey,
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
  },
}));

const CustomPlaceholder = ({ icon, label }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Icon>{icon}</Icon>
      <span>{label}</span>
    </div>
  );
};

CustomPlaceholder.propTypes = {
  icon: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
};

export default CustomPlaceholder;

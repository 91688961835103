/* eslint-disable no-param-reassign */
import Joi from '@hapi/joi';
import i18next from 'i18next';

const schema = Joi.object({
  student: Joi.object().required(),
  externalExpiredDate: Joi.date().optional(),
});

const validate = values => {
  const { error } = schema.validate(values, { abortEarly: false });
  if (!error) {
    return {};
  }

  const { details } = error;

  return details.reduce((errors, detail) => {
    const errorsMapping = {
      'object.base': i18next.t('errors.fieldIsRequired'),
      'any.required': i18next.t('errors.fieldIsRequired'),
      'date.base': i18next.t('errors.shouldBeValidData'),
    };

    if (!(detail.type === 'date.base' && detail.context.value === null)) {
      errors[detail.context.key] = errorsMapping[detail.type] || detail.message;
    }

    return errors;
  }, {});
};

export default validate;

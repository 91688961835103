import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  label: {
    position: 'relative',
    zIndex: 2,
    fontSize: 12,
    transform: 'none',
    marginBottom: 0,
    lineHeight: '30px',
  },
  group: {
    flexDirection: 'row',
  },
  checkbox: {
    '& .material-icons.MuiIcon-root': {
      color: theme.palette.error.main,
    },
  },
  stationsCurrent: {
    '& .MuiFormControlLabel-root': {
      flexBasis: '30%',
    },
  },
}));

export default useStyles;

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { fade, makeStyles, useTheme } from '@material-ui/core/styles';
import { Paper } from '@material-ui/core';
import Select from 'react-select';
import CustomPlaceholder from './CustomPlaceholder';

let searchSelectPrefix = '';

const useStyles = makeStyles(() => ({
  root: {
    [`& .${searchSelectPrefix}__control`]: {
      minWidth: '200px',
    },
    [`& .${searchSelectPrefix}__option`]: {
      borderRadius: '10px',
    },
    [`& .${searchSelectPrefix}__menu`]: {
      borderRadius: '10px',
    },
  },
}));

const SearchSelect = props => {
  const {
    onChangeValue,
    options,
    placeholder,
    classNamePrefix,
    customTheme,
    searchIcon,
    selectedValue,
    isClearable,
  } = props;
  searchSelectPrefix = classNamePrefix;
  const appTheme = useTheme();
  const classes = useStyles({
    borderRadius: customTheme.borderRadius,
  });
  const [selectedOption, setSelectedOption] = useState(null);

  const handleChangeValue = option => {
    setSelectedOption(option);
    onChangeValue(option);
  };
  useEffect(() => {
    setSelectedOption(selectedValue);
  }, [selectedValue]);
  return (
    <Paper className={classes.root}>
      <Select
        value={selectedOption}
        onChange={handleChangeValue}
        components={{
          Placeholder: () => <CustomPlaceholder icon={searchIcon} label={placeholder} />,
        }}
        options={options}
        classNamePrefix={classNamePrefix}
        theme={theme => ({
          ...theme,
          ...customTheme,
          colors: {
            ...theme.colors,
            primary: fade(appTheme.palette.primary.main, 0.4),
            primary25: appTheme.palette.primary.light,
            ...customTheme.colors,
          },
        })}
        isClearable={isClearable}
        isSearchable
      />
    </Paper>
  );
};

SearchSelect.defaultProps = {
  onChangeValue: () => {},
  placeholder: 'Search...',
  classNamePrefix: 'app-search-select',
  customTheme: {
    borderRadius: '26px',
    colors: {},
  },
  searchIcon: 'search',
  isClearable: false,
  selectedValue: null,
};

SearchSelect.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      label: PropTypes.string.isRequired,
    }),
  ).isRequired,
  onChangeValue: PropTypes.func,
  placeholder: PropTypes.string,
  customTheme: PropTypes.shape({
    borderRadius: PropTypes.string,
    colors: PropTypes.object,
  }),
  searchIcon: PropTypes.string,
  classNamePrefix: PropTypes.string,
  isClearable: PropTypes.bool,
  selectedValue: PropTypes.shape({
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    label: PropTypes.string.isRequired,
  }),
};

export default SearchSelect;

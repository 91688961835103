import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import AppBreadcrumbs from 'components/AppBreadcrumbs';
import PageLayout from 'layouts/PageLayout';
import { makeStyles } from '@material-ui/core/styles';
import { useAsyncFn } from 'react-use';
import { updateJobTask, getJobTask } from 'http/jobsTasks';
import { useRouteMatch, useHistory } from 'react-router-dom';
import NotFound from 'pages/NotFound';
import { transformDataToSelectOptions } from 'utils';
import { routesAliases } from 'router/routes';
import { useTranslation } from 'react-i18next';
import TaskForm from '../Form';

const useStyles = makeStyles(theme => ({
  pageContent: {
    padding: theme.spacing(4, 5),
  },
}));

const Edit = ({ routeProps }) => {
  const routerMatch = useRouteMatch();
  const history = useHistory();
  const { t } = useTranslation();
  const { id: jobTaskId } = routerMatch.params;
  const [notFoundError, setNotFoundError] = useState(false);
  const [initialFormState, setInitialFormState] = useState(null);
  const backUrl = routesAliases.jobsTasksList;

  const classes = useStyles();
  const breadCrumbsItems = [
    {
      title: t(routeProps.parentTitle),
      link: routeProps.parentPath,
    },
    {
      title: t(routeProps.title),
      isActive: true,
    },
  ];

  const [submittingState, sendForm] = useAsyncFn(async formData => {
    const jobTask = await updateJobTask(jobTaskId, formData);
    if (!jobTask.error) {
      history.push(backUrl);
    }
    return jobTask;
  });

  const [fetchJobTaskState, fetchJobTaskData] = useAsyncFn(async id => {
    const jobTask = await getJobTask(id);
    if (!jobTask.error) {
      setInitialFormState({
        name: jobTask.name,
        courseTemplates: transformDataToSelectOptions(jobTask.courseTemplates, 'id', 'name'),
      });
    } else if (jobTask.statusCode === 404) {
      setNotFoundError(true);
    }
    return jobTask;
  });

  useEffect(() => {
    fetchJobTaskData(jobTaskId);
  }, []);

  return notFoundError ? (
    <NotFound />
  ) : (
    <PageLayout
      pageTitle={<AppBreadcrumbs items={breadCrumbsItems} />}
      classes={{ content: classes.pageContent }}
      showLoader={fetchJobTaskState.loading}
    >
      <TaskForm
        onSubmitForm={sendForm}
        submitting={submittingState.loading}
        formType="edit"
        jobTaskId={jobTaskId}
        initialValues={initialFormState}
        backUrl={backUrl}
      />
    </PageLayout>
  );
};

Edit.propTypes = {
  routeProps: PropTypes.shape({
    parentPath: PropTypes.string,
    parentTitle: PropTypes.string,
    title: PropTypes.string,
  }).isRequired,
};

export default Edit;

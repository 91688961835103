import PropTypes from 'prop-types';

const withTableProps = {
  data: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.shape({})), PropTypes.shape()]),
  totalCount: PropTypes.number,
  page: PropTypes.number,
  pageSize: PropTypes.number,
  isFetchingTableData: PropTypes.bool,
  handleChangePage: PropTypes.func,
  handleSorting: PropTypes.func,
  handleForceUpdate: PropTypes.func,
  handleRemoveFilter: PropTypes.func,
  handleFiltering: PropTypes.func,
  handleChangeFetchMethod: PropTypes.func,
};

export default withTableProps;

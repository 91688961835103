import { createMuiTheme } from '@material-ui/core/styles';
import createPalette from '@material-ui/core/styles/createPalette';
import { hexToRGBA } from 'utils';

const theme = (mainColor, mainTextColor) => {
  const palette = createPalette({
    common: {
      black: '#505050',
      contrastText: '#fff',
    },
    primary: {
      main: mainColor || '#0659A4',
    },
    secondary: {
      main: '#8FA9DA',
      contrastText: '#fff',
    },
    error: {
      main: '#E55950',
    },
    text: {
      grey: '#B9B9B9',
      common: mainTextColor || '#505050',
    },
  });

  return createMuiTheme({
    palette: {
      ...palette,
      primary: {
        ...palette.primary,
        light: hexToRGBA(palette.primary.main, 0.6),
      },
    },
    typography: {
      fontFamily: [
        'Ubuntu',
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(','),
      button: {
        textTransform: 'none',
      },
      body2: {
        color: palette.text.common,
      },
    },
    shape: {
      borderRadius: 25,
    },
    overrides: {
      MuiButton: {
        containedPrimary: {
          boxShadow: '0 6px 10px -10px rgba(0,0,0,0.6) !important',
        },
        containedSecondary: {
          boxShadow: '0 6px 10px -10px rgba(0,0,0,0.6) !important',
        },
      },
      MuiPaper: {
        elevation1: {
          boxShadow: '0 6px 10px -10px rgba(0,0,0,0.6) !important',
        },
      },
    },
  });
};

export default theme;

import React from 'react';
import i18next from 'i18next';
import { DateFormatter } from 'components/AppTable/CellsComponents';

const getTableConfig = () => [
  {
    title: i18next.t('tablesLabels.id'),
    field: 'id',
  },
  {
    title: i18next.t('tablesLabels.name'),
    field: 'fullName',
    isSorting: true,
  },
  {
    title: i18next.t('tablesLabels.course'),
    field: 'course',
  },
  {
    title: i18next.t('tablesLabels.jobsTasks'),
    field: 'jobTask',
    isSorting: true,
  },
  {
    title: i18next.t('tablesLabels.expiryDate'),
    field: 'expirationDate',
    Component: value => (value === null ? i18next.t('courseDetails.notSpecified') : <DateFormatter value={value} />),
    isSorting: true,
  },
];

export default getTableConfig;

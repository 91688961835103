/* eslint-disable no-param-reassign */
import Joi from '@hapi/joi';
import i18next from 'i18next';

const schema = Joi.object({
  name: Joi.string()
    .trim()
    .required(),
  description: Joi.string().required(),
  jobsTasks: Joi.array()
    .items(Joi.object().required())
    .required(),
  courseSystemId: Joi.string()
    .min(1)
    .max(30)
    .required(),
  expirationMonthCount: Joi.object({
    label: Joi.string().required(),
    value: Joi.number().required(),
  }).required(),
});

const validate = values => {
  const { error } = schema.validate(values, { abortEarly: false });
  if (!error) {
    return {};
  }

  const { details } = error;

  return details.reduce((errors, detail) => {
    const errorsMapping = {
      'array.base': i18next.t('errors.fieldIsRequired'),
      'object.base': i18next.t('errors.fieldIsRequired'),
      'any.required': i18next.t('errors.fieldIsRequired'),
      'string.empty': i18next.t('errors.fieldIsRequired'),
    };

    errors[detail.context.key] = errorsMapping[detail.type] || detail.message;
    return errors;
  }, {});
};

export default validate;

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import AppBreadcrumbs from 'components/AppBreadcrumbs';
import PageLayout from 'layouts/PageLayout';
import { makeStyles } from '@material-ui/core/styles';
import { useAsyncFn } from 'react-use';
import { updateCourseTemplate, getCourseTemplate, addCourseTemplateDocument } from 'http/courseTemplates';
import { useRouteMatch, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import NotFound from 'pages/NotFound';
import { transformDataToSelectOptions } from 'utils';
import { actions as modalActions } from 'redux/modal';
import { actions as tableActions } from 'redux/table';
import { routesAliases } from 'router/routes';
import { SendFileForm } from 'components/CommonForms';
import { useTranslation } from 'react-i18next';
import TemplateForm from '../Form';
import DocumentsTable from './DocumentsTable';

const useStyles = makeStyles(theme => ({
  courseTemplateDocumentsContent: {
    padding: theme.spacing(2, 5),
  },
  courseTemplateDocumentsHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& .ContentTitle': {
      display: 'flex',
    },
  },
  pageContent: {
    padding: theme.spacing(4, 5),
  },
}));

const Edit = ({ routeProps }) => {
  const dispatch = useDispatch();
  const routerMatch = useRouteMatch();
  const history = useHistory();
  const { t } = useTranslation();
  const { id: courseTemplateId } = routerMatch.params;
  const [notFoundError, setNotFoundError] = useState(false);
  const [initialFormState, setInitialFormState] = useState(null);
  const backUrl = routesAliases.courseTemplatesList;
  const classes = useStyles();
  const breadCrumbsItems = [
    {
      title: t(routeProps.parentTitle),
      link: routeProps.parentPath,
    },
    {
      title: t(routeProps.title),
      isActive: true,
    },
  ];

  const [submittingState, sendForm] = useAsyncFn(async formData => {
    const courseTemplate = await updateCourseTemplate(courseTemplateId, formData);
    if (!courseTemplate.error) {
      history.push(backUrl);
    }
    return courseTemplate;
  });

  const [fetchCourseTemplateState, fetchCourseTemplateData] = useAsyncFn(async id => {
    const courseTemplate = await getCourseTemplate(id);

    if (!courseTemplate.error) {
      setInitialFormState({
        name: courseTemplate.name,
        description: courseTemplate.description,
        jobsTasks: transformDataToSelectOptions(courseTemplate.jobsTasks, 'id', 'name'),
        expirationMonthCount: courseTemplate.expirationMonthCount,
        courseSystemId: courseTemplate.courseSystemId,
      });
    } else if (courseTemplate.statusCode === 404) {
      setNotFoundError(true);
    }

    return courseTemplate;
  });

  const [addFileState, addFile] = useAsyncFn(async (id, formData) => {
    const data = await addCourseTemplateDocument(id, formData);
    if (!data.error) {
      dispatch(modalActions.closeModal());
      dispatch(tableActions.forceUpdateTable(true));
    }
    return data;
  });

  const handleSubmitAddFileForm = values => {
    addFile(courseTemplateId, values);
  };

  const handleAddDocument = () => {
    dispatch(
      modalActions.openModal(
        {
          size: 'medium',
          title: t('courseTemplates.addDocumentModalTitle'),
        },
        <SendFileForm
          onSubmitForm={handleSubmitAddFileForm}
          rejectAction={() => {
            dispatch(modalActions.closeModal());
          }}
          submitting={addFileState.loading}
          fileFormat=".pdf, .pptx, .doc, .docx, .xlsx, .ppt, .jpeg, .jpg, .png"
        />,
      ),
    );
  };

  useEffect(() => {
    fetchCourseTemplateData(courseTemplateId);
  }, []);

  return notFoundError ? (
    <NotFound />
  ) : (
    <PageLayout
      pageTitle={<AppBreadcrumbs items={breadCrumbsItems} />}
      classes={{ content: classes.pageContent }}
      additionSections={[
        {
          contentClass: classes.courseTemplateDocumentsContent,
          contentHeaderClass: classes.courseTemplateDocumentsHeader,
          contentHeader: t('courseTemplates.documents'),
          component: <DocumentsTable courseTemplateId={courseTemplateId} />,
          actions: [
            {
              type: 'button',
              icon: 'add',
              title: t('courseTemplates.actionAddDocument'),
              onClick: handleAddDocument,
            },
          ],
        },
      ]}
      showLoader={fetchCourseTemplateState.loading || addFileState.loading}
    >
      <TemplateForm
        onSubmitForm={sendForm}
        submitting={submittingState.loading}
        formType="edit"
        courseTemplateId={courseTemplateId}
        initialValues={initialFormState}
        backUrl={backUrl}
      />
    </PageLayout>
  );
};

Edit.propTypes = {
  routeProps: PropTypes.shape({
    parentPath: PropTypes.string,
    parentTitle: PropTypes.string,
    title: PropTypes.string,
  }).isRequired,
};

export default Edit;
